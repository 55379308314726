import {
    SET_FURNITURES,
    SET_FURNITURE_DETAILS
} from '../action/types';

const initialState = {
    furnitures: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_FURNITURES: {
            return {
                ...state,
                furnitures: action.payload
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}

