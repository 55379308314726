import React, { useEffect, useState } from "react";
import axios from "axios";
import { privateReq } from "../../config/axiosConfig";
import { Box, Select, Typography, Avatar } from "@mui/material";
import { assetUrl, imageReq } from "../../config/axiosConfig";
import Header from "../../components/Header";

import toast from "react-hot-toast";
import ButtonContained from "../../components/Buttons/ButtonContained";
import ButtonOutlined from "../../components/Buttons/ButtonOutlined";
import { Directions } from "@mui/icons-material";

const UserList = ({ user_loggedin }) => {
  const [users, setUsers] = useState([]);
  const [expandedUser, setExpandedUser] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await privateReq.get(`/users-list?page=${page}`);
        const sortedUsers = response.data.users.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setUsers(sortedUsers);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [page]);

  const handleDelete = async (id) => {
    try {
      console.log(id);
     const res = await privateReq.delete(`/users-admin/${id}`);
      setUsers(users.filter((user) => user.id !== id));
      toast.success(res?.data?.message)
    } catch (error) {
      console.error("Error deleting user:", error);
      // Handle error scenarios, show error message, etc.
    }
  };

  const toggleExpand = (id) => {
    setExpandedUser(expandedUser === id ? null : id);
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  return (
    <>
      <Header />
      <div style={styles.container}>
        <h1 style={styles.heading}>User List</h1>

        {/* <div
          style={{
            display: "flex",
            justifyContent: "start",

            alignItems: "start",
            flexWrap: "wrap",
          }}
        >
          {users.map((user) => (
            <div className="col-lg-4 col-md-6 col-xs-12 mt-3 d-flex flex-row ">
              <div
                style={{
                  minWidth: "90%",
                }}
                className="card  card-container-user-desc  border-0 h-100 "
              >
                <Box
                  sx={[styles.borderRadius12]}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                  //key={index}
                  className="d-flex  flex-column  justify-content-between shadow-none px-4 py-3"
                >
                  <div className="d-flex align-items-center">
                    <Avatar
                      sx={{ width: "72px", height: "72px" }}
                      alt={users?.users?.name || ""}
                      src={assetUrl + users?.avatarImage}
                      // src={card.imageSrc}
                    />

                    <Box className="px-2 ">
                      <Typography
                        sx={[
                          styles.fontFamilyDS,
                          styles.fbBold,
                          styles.fs20,
                          styles.color240501,
                          styles.fbBold,
                          styles.textTransformCapitalize,
                        ]}
                      >
                        {user.name}
                      </Typography>
                      <Typography
                        className="text-capitalize text-color979797"
                        sx={[styles.fontFamilyDS, styles.fs20]}
                      >
                        admin
                      </Typography>

                      <p
                        style={{
                          color: "gray",
                          fontSize: "small",
                        }}
                      >
                        {user.phone}
                      </p>
                    </Box>
                  </div>

                  <div className="py-4">
                    <Typography
                      className="text-color979797"
                      sx={[
                        styles.fontFamilyDS,
                        ,
                        styles.fs16,
                        {
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                        },
                      ]}
                    >
                      {users?.desc || "No description to show"}
                    </Typography>
                  </div>
                  <div>
                    <div className="py-2">
                      <Box className="d-flex">
                        <Typography
                          className="w-40"
                          sx={[
                            styles.fontFamilyDS,
                            styles.fs16,
                            styles.fontWeight500,
                          ]}
                        >
                          Language:
                        </Typography>

                        <Typography
                          className="text-color979797 text-capitalize"
                          sx={[styles.fontFamilyDS, styles.fs16]}
                        >
                          {users?.language}
                        </Typography>
                      </Box>
                      <Typography
                        className="text-color979797 text-capitalize"
                        sx={[styles.fontFamilyDS, styles.fs16]}
                      >
                        {new Date(user.created).toLocaleString()}
                      </Typography>
                      <Typography
                        className="w-40 d-flex"
                        sx={[
                          styles.fontFamilyDS,
                          styles.fs16,
                          styles.fontWeight500,
                        ]}
                      >
                        {user.email}
                      </Typography>
                      <Typography
                        className="w-40"
                        sx={[
                          styles.fontFamilyDS,
                          styles.fs16,
                          styles.fontWeight500,
                        ]}
                      >
                        {user.address}
                      </Typography>
                      <Typography
                        className="w-40 d-flex"
                        sx={[
                          styles.fontFamilyDS,
                          styles.fs16,
                          styles.fontWeight500,
                        ]}
                      >
                        status:{user.status}
                      </Typography>
                      <Typography
                        className="w-40 d-flex"
                        sx={[
                          styles.fontFamilyDS,
                          styles.fs16,
                          styles.fontWeight500,
                        ]}
                      >
                        credits:{user.credits}
                      </Typography>
                      <Typography
                        className="w-40 d-flex"
                        sx={[
                          styles.fontFamilyDS,
                          styles.fs16,
                          styles.fontWeight500,
                        ]}
                      >
                        pincode:{user.pinCode}
                      </Typography>
                      <Typography
                        className="w-40 d-flex"
                        sx={[
                          styles.fontFamilyDS,
                          styles.fs16,
                          styles.fontWeight500,
                        ]}
                      >
                        OTP:{user.otp}
                      </Typography>
                      <Typography
                        className="w-40 d-flex"
                        sx={[
                          styles.fontFamilyDS,
                          styles.fs16,
                          styles.fontWeight500,
                        ]}
                      >
                        Role:{user.role}
                      </Typography>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
            // <div style={{
            //   height: '100px',
            //   width: '100px',
            //   backgroundColor: 'red'
            // }}>

            // </div>
          ))}
        </div> */}

        {users.map((user) => (
          <div
            key={user.id}
            style={styles.userCard}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = "#f5f5f5")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = "#fff")
            }
          >
            <div style={styles.userInfo} onClick={() => toggleExpand(user.id)}>
              <p style={styles.name}>{user.name}</p>
              <p style={styles.phone}>{user.phone}</p>
            </div>
            {user_loggedin?.role === "admin" && (
              <button
                onClick={() => {
                  handleDelete(user.id);
                }}
              >
                delete
              </button>
            )}
            {expandedUser === user.id && (
              <div style={styles.expandedInfo}>
                <p>
                  <strong>Email:</strong> {user.email}
                </p>
                <p>
                  <strong>Address:</strong> {user.address}
                </p>
                <p>
                  <strong>Created:</strong>{" "}
                  {new Date(user.created).toLocaleString()}
                </p>
                <p>
                  <strong>Credits:</strong> {user.credits}
                </p>
                <p>
                  <strong>Role:</strong> {user.role}
                </p>
                <p>
                  <strong>Status:</strong> {user.status}
                </p>
                <p>
                  <strong>OTP:</strong> {user.otp}
                </p>
                <p>
                  <strong>Description:</strong> {user.desc}
                </p>
                <p>
                  <strong>Pin Code:</strong> {user.pinCode}
                </p>
              </div>
            )}
          </div>
        ))}
        <div style={styles.pagination}>
          <button
            onClick={handlePreviousPage}
            disabled={page === 1}
            style={styles.button}
          >
            Previous
          </button>
          <span style={styles.pageInfo}>
            Page {page} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={page === totalPages}
            style={styles.button}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    marginTop: "100px",
    width: "80%",
    margin: " auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  heading: {
    textAlign: "center",
    color: "#333",
  },
  userCard: {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "15px",
    marginBottom: "15px",
    cursor: "pointer",
    transition: "background-color 0.3s ease, box-shadow 0.3s ease",
    backgroundColor: "#fff",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  },
  userInfo: {
    display: "flex",
    justifyContent: "space-between",
  },
  name: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#555",
  },
  phone: {
    fontSize: "16px",
    color: "#888",
  },
  expandedInfo: {
    marginTop: "10px",
    padding: "10px",
    backgroundColor: "#f9f9f9",
    borderRadius: "5px",
    boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
  },
  button: {
    padding: "10px 20px",
    margin: "0 10px",
    cursor: "pointer",
    borderRadius: "5px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    color: "black",
    transition: "background-color 0.3s ease, color 0.3s ease",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  },
  buttonHover: {
    backgroundColor: "#007BFF",
    color: "#fff",
  },
  buttonDisabled: {
    cursor: "not-allowed",
    backgroundColor: "#e0e0e0",
  },
  pageInfo: {
    fontSize: "16px",
  },
};

export default UserList;
