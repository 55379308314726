import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
const customStyles = {
  customModal: {
    // border: 'none'
    borderRadius: "12px",
  },
  overlay: {
    position: "fixed",
    inset: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 9999,
    left: 0,
  },
  content: {
    maxWidth: "70%",
    margin: "0 auto",
    borderRadius: "20px",
  },
};
const customStylesMobile = {
  ReactModal__Content: {
    border: "none",
  },
  customModal: {
    border: "none",
    marginTop: "100px",
  },
  overlay: {
    position: "fixed",
    inset: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 9999,
    left: 0,
  },
  content: {
    maxWidth: "100%",
    border: "none",
    margin: "0 auto",
    marginTop: "75px",
    borderRadius: "20px",
  },
};

export default function RatingModal({
  children,
  state,
  stateFunc,
  title,
  handleClose,
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Modal
      isOpen={state}
      style={!isMobile ? customStyles : customStylesMobile}
      contentLabel="Example Modal"
      onRequestClose={(e) => {
        // e.stopPropagation();
        e.preventDefault();
        stateFunc(false);
      }}
      contentClassName="custom-modal"
    >
      <h2
        style={{
          color: "#240501",
          fontWeight: "bold",
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        {title}
      </h2>
      <a
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          display: "inline-block",
          fontSize: 30,
          color: "#000",
        }}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          stateFunc(false);
        }}
      >
        <CloseRoundedIcon fontSize={!isMobile ? "25px" : "18px"} />
      </a>

      {children}
    </Modal>
  );
}
