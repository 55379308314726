import React from 'react';
import classes from '../../styles/styles.js';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import AboutTopSvg from '../../assets/images/about-top.svg';
import AboutBottomSvg from '../../assets/images/about-bottom.svg';
import homeabout from '../../assets/images/homeabout.png';
import homeaboutbottom from '../../assets/images/homeaboutbottom.svg';
import './style.css';

const HomeAbouSection = () => {
  const theme = useTheme();
  return (
    // <div
    //   className='container home-about-section'
    //   // sx={classes.aboutBox}
    // >
    //   <div className='about-box'>
    //     <div className='row mb-0 px-0 py-0 g-0'>
    //       {/* container sx={[classes.gridContainer, { paddingBottom: 0 }]} justify="center" */}

    //       <div
    //         className='col-lg-6 col-md-6 col-xs-12 about-top-box'
    //         style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    //       >
    //         {/* item xs={12} md={6} sx={classes.padding0} */}

    //         <img
    //           src={homeabout}
    //           className='about-left-img'
    //         />
    //       </div>
    //       <div className='col-lg-6 col-md-6 col-xs-12 '>
    //         {/* item xs={12}
    //                  md={6} sx={[classes.aboutRightItem, classes.padding0]} */}
    //         <div className='about-top-item-text'>
    //           <Typography
    //             sx={[
    //               classes.titleAbout,
    //               {
    //                 [theme.breakpoints.between('xs', 'md')]: [classes.titleAboutSmXs],
    //               },
    //             ]}
    //           >
    //             About Us
    //           </Typography>
    //           <Typography
    //             sx={[
    //               classes.subtitleAbout,
    //               {
    //                 [theme.breakpoints.between('xs', 'md')]: classes.subtitleAboutXsSm,
    //               },
    //             ]}
    //           >
    //             Discover Gharwala
    //             {/* <sup style={{ fontSize: "15px" }}>TM</sup> */}: Your All-in-One comprehensive Solution for Property and Home Services
    //           </Typography>
    //           <Typography
    //             sx={[
    //               classes.descAbout,
    //               {
    //                 [theme.breakpoints.between('xs', 'md')]: [classes.descAboutSmXS],
    //               },
    //             ]}
    //           >
    //             Welcome to Gharwala
    //             {/* <sup style={{ fontSize: "15px" }}>TM</sup> */}, where we revolutionize your real estate and home services experience. Offering a
    //             comprehensive range of services, Gharwala
    //             {/* <sup style={{ fontSize: "15px" }}>TM</sup> */} is your go-to destination for property solutions and more. Whether you're searching for the
    //             perfect place to rent or buy, furnishing your home with our curated collection of furniture and appliances, or seamlessly booking essential home
    //             services like maids, electricians, and plumbers – we've got you covered. Our platform brings together the convenience of furniture and home
    //             appliance rentals, a diverse range of property listings, and an array of additional services, all designed to meet your home-related needs.
    //             Explore the ultimate destination for all things property and home services with Gharwala
    //             {/* <sup style={{ fontSize: "15px" }}>TM</sup> */}. Your journey towards a comfortable and well-managed living space begins here.
    //           </Typography>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className='sell-box'>
    //     <div className='row mt-0'>
    //       {/* container spacing={6} sx={[classes.gridContainer, { paddingTop: 0 }]} justify="center" */}
    //       <div className='col-lg-6 col-md-6 col-xs-12 sell-top-bottom-mobile'>
    //         <div className='sell-left-item-text'>
    //           {/* item xs={12} md={6} sx={[classes.aboutRightItem, classes.padding0]}> */}
    //           <Typography
    //             sx={[
    //               classes.titleAbout,
    //               {
    //                 [theme.breakpoints.between('xs', 'md')]: [classes.titleAboutSmXs],
    //               },
    //             ]}
    //           >
    //             WHY CHOOSE US
    //           </Typography>
    //           <Typography
    //             sx={[
    //               classes.subtitleAbout,
    //               {
    //                 [theme.breakpoints.between('xs', 'md')]: classes.subtitleAboutXsSm,
    //               },
    //             ]}
    //           >
    //             Why Gharwala{/* <sup style={{ fontSize: "15px" }}>TM</sup> */}?
    //           </Typography>
    //           <Typography
    //             sx={[
    //               classes.descAbout,
    //               {
    //                 [theme.breakpoints.between('xs', 'md')]: [classes.descAboutSmXS],
    //               },
    //             ]}
    //           >
    //             At Gharwala{/* <sup style={{ fontSize: "15px" }}>TM</sup> */}, we redefine your housing experience by seamlessly integrating various services
    //             under one roof. From helping you find the ideal property for rent or purchase to offering a hassle-free solution for furnishing your home with
    //             quality furniture and appliances, we cater to your every housing need. What sets Gharwala
    //             {/* <sup style={{ fontSize: "15px" }}>TM</sup> */} apart is the unique fusion of real estate brokerage fundamentals and innovative rental
    //             solutions, providing you with a one-stop destination. Our commitment goes beyond property transactions; we simplify your life by offering
    //             essential home services at your fingertips. Choose Gharwala
    //             {/* <sup style={{ fontSize: "15px" }}>TM</sup> */} for its comprehensive approach, making the entire process from finding your dream home to
    //             maintaining it, a smooth and efficient journey. Experience the convenience of a unified platform that understands and fulfills all your housing
    //             and service requirements – choose Gharwala
    //             {/* <sup style={{ fontSize: "15px" }}>TM</sup> */} today.
    //           </Typography>
    //         </div>
    //       </div>

    //       <div
    //         className='col-lg-6 col-md-6 col-xs-12 mt-0 px-0 py-0 sell-top-box-mobile'
    //         style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    //       >
    //         {/* sx={classes.padding0} item xs={12} md={6} */}
    //         <img
    //           src={homeaboutbottom}
    //           className='about-right-img'
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className='mainContainer'>
      <div className='firstSection'>
        <div className='firstSectionInner'>
          <div className='firstSectionLeft'>
            <img
              className='imageStyle'
              src={homeabout}
              alt=''
            />
          </div>
          <div className='firstSectionRight'>
            <div className='firstSectionHeading'>About Us</div>
            <div className='firstSectionSubHeading'>Discover Gharwala: Your All-in-One comprehensive Solution for Property and Home Services</div>
            <div className='firstSectionText'>
              Welcome to Gharwala, where we revolutionize your real estate and home services experience. Offering a comprehensive range of services, Gharwala is
              your go-to destination for property solutions and more. Whether you're searching for the perfect place to rent or buy, furnishing your home with
              our curated collection of furniture and appliances, or seamlessly booking essential home services like maids, electricians, and plumbers - we've
              got you covered. Our platform brings together the convenience of furniture and home appliance rentals, a diverse range of property listings, and
              an array of additional services, all designed to meet your home-related needs.
            </div>
          </div>
        </div>
      </div>
      <div className='secondSection'>
        <div className='secondSectionInner'>
          <div className='secondSectionLeft'>
            <div className='secondSectionHeading'>WHY CHOOSE US</div>
            <div className='secondSectionSubHeading'>Why Gharwala?</div>
            <div className='secondSectionText'>
              <ul>
                <li>Lowest Price in Kolkata</li>
                <li>AC on rent at lowest Price</li>
                <li>Delivery within 48hrs anywhere in Kolkata</li>
                <li>Free relocation</li>
                <li>Cancel anytime</li>
                <li>Free Maintenance</li>
                <li>Hassle free return on Delivery</li>
                <li>Top quality products</li>
                <li>Easy upgradation anytime</li>

              </ul>
            </div>
          </div>
          <div className='secondSectionRight'>
            <img
              className='imageStyle'
              src={homeaboutbottom}
              alt=''
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAbouSection;
