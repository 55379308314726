import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography, Stepper, Step, StepLabel, StepContent, StepConnector } from '@mui/material';
import { toast } from 'react-hot-toast';
import Header from '../../components/Header';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import styles from '../../styles/styles';
import { Link } from 'react-router-dom';
import AddPropertyStep1 from './components/Step1';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PanoramaFishEyeSharpIcon from '@mui/icons-material/PanoramaFishEyeSharp';
import AddPropertyStep2 from './components/Step2';
import AddPropertyStep3 from './components/Step3';
import AddPropertyStep4 from './components/Step4';
import AddPropertyStep5 from './components/Step5';
import ButtonOutlined from '../../components/Buttons/ButtonOutlined';
import ButtonContained from '../../components/Buttons/ButtonContained';
import { useNavigate } from 'react-router-dom';
import { addPropertyService } from '../../config/property';
import { useDispatch, useSelector } from 'react-redux';
import { getProperties } from '../../redux/action/property';
import classes from '../../styles/styles.js';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from 'react-responsive';
import { setCurrentUser } from '../../redux/action/auth';
import { SET_CURRENT_USER } from '../../redux/action/types.js';
import { getUserProfile } from '../../config/user.js';
const AddProperty = () => {
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [boxHeight, setboxHeight] = useState(0);
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { user } = useSelector((state) => state.auth);
  const [profile, setProfile] = useState();
  const [loading , setLoading] = useState(false);

 
  const [data, setData] = useState({
    title: '',
    bedrooms: '',
    bathrooms: '',
    kitchens: '',
    propertyType: '',
    // coverImage:'',
    wantto: '',
    furnitureDetails:[],
    usp: '',
    facilities: [],
    accessories: [],
    houseNo: '',
    apartmentSociety: '',
    locality: '',
    pinCode: '',
    landMark: '',
    rent: '',
    nearby: [],
    coverImage:'',
    propertyImages: [],
    expectedMonthlyRent: '',
    expectedSecurityDeposit: '',
    firstTimePayment: '',
    desc: '',
    photos: [],
    createdBy: user?.role,
    securityDeposit: '',
    furnishingDetails: '',
    buildupArea: '',
    availableFrom: '',
    tenant: '',
    floorNumber: '',
    propertyAge: '',
    parking: '',
    facing: '',
    flooring: '',
    address: '',
    landmark: '',
    water: '',
    bathroom: '',
    powerBackup: '',
    overlooking: '',
    amenities:'',
  });
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const handleTextChange = (name, val) => {
    setData({ ...data, [name]: val });
  };
  const boxRef = useRef();


  useEffect(() => {
    setHeaderHeight(headerRef.current.offsetHeight);
    setboxHeight(boxRef.current.offsetHeight);
  }, [headerHeight, boxHeight]);

  async function getUserDetails(){
 
      const res = await getUserProfile();
      dispatch({
        type: SET_CURRENT_USER,
        payload: res.data.user,
      });
      setProfile(res.data.user);
      setData(prevData => ({
        ...prevData,
        createdBy: res.data.user.role
      }));

  }

  useEffect(() => {
   
    getUserDetails();
  
  }, []);

  const steps = [
    {
      component: AddPropertyStep1,
      label: 'Basic Details',
      subLabel: 'Step 1',
    },
    {
      component: AddPropertyStep2,
      label: 'Property Details',
      subLabel: 'Step 2',
    },
    {
      component: AddPropertyStep3,
      label: 'Location Details',
      subLabel: 'Step 3',
    },
    {
      component: AddPropertyStep4,
      label: 'Photos',
      subLabel: 'Step 4',
    },
    {
      component: AddPropertyStep5,
      label: 'Pricing & Others',
      subLabel: 'Step 5',
    },
  ];
  const handleNext = () => {
    if (activeStep === 0) {
      if (!data.wantto) {
        toast.error('Please select one option');
        return;
      }
    }
    if (activeStep === 1) {
      if (!data.title) {
        toast.error('Property Name is required');
        return;
      }
      if (!data.propertyType) {
        toast.error('Property Type is required');
        return;
      }

      if (!data.title) {
        toast.error('Property Name is required');
        return;
      }
    }
    if (activeStep === 2) {
      if (!data.locality) {
        toast.error('Locality is required');
        return;
      }
    }

    if (activeStep === 3) {
      
      if (data.photos.length==0) {
        toast.error('Please Select Image!');
        return;
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleCheckBoxChange = (name, val) => {
    let dataArr = [...data[name]];
    let indexOfItem = dataArr.indexOf(val);
    if (indexOfItem == -1) {
      dataArr.push(val);
    } else {
      dataArr.splice(indexOfItem, 1);
    }
    setData({ ...data, [name]: dataArr });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (activeStep === 4) {
      if (!data.expectedMonthlyRent) {
        toast.error('Expected rent is required');
        return;
      }
    }
    try {
      setLoader(true);
      data.photos.push(data.coverImage);
      const obj = {
        ...data,
        propertyImages: data.photos,
        locality: JSON.stringify(data.locality),
        furnitureDetails:JSON.stringify(data.furnitureDetails),
        coverImage:''
      };
      delete obj.photos;
      const fd = new FormData();
      for (let key in obj) {
        if (Array.isArray(obj[key]) && obj[key].length > 0) {
          obj[key].forEach((i, k) => {
            fd.append(key, obj[key][k]);
          });
        } else {
          fd.append(key, obj[key]);
        }
      }
      console.log(obj)
      const res = await addPropertyService(fd);
      // console.log('res si s' , res.response.data)
      
      if(res.data.success) {
        toast.success('Property Added Successfully');
        // dispatch(getProperties(""));
        dispatch(setCurrentUser());
        navigate('/');
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
      if(err.response.data= 'Insufficient credits'){
        return toast.error('Insufficient credits')
       }
    }
    finally{
      setLoading(false);
    }
  };
  return (
    <>
      <Header innerRef={headerRef} />
      <div className='container-fluid px-0'>
        <div className='properties-container'>
          <div className='row mx-0 w-100'>
            <div className='row mx-0 w-100 px-0'>
              {!isMobile && (
                <div className='col-lg-3 col-md-3 col-xs-12 bg-white border-box h-100 px-0'>
                  <Box
                    className={`d-flex align-items-center py-3 border-bottom p-4 w-100`}
                    sx={[styles.color9A291B]}
                  >
                    <Link
                      onClick={() => navigate(-1)}
                      className='d-flex align-items-center text-decoration-none items-center'
                    >
                      <KeyboardBackspaceIcon sx={[styles.color9A291B]} />
                      <p style={{ fontWeight: 'bold', color: '#950000', marginTop:"10px" }}>Listing</p>

                      <Typography
                        sx={[styles.color9A291B, styles.fs24, styles.fbBold, styles.fontFamilyDS]}
                        className='px-1'
                      ></Typography>
                    </Link>
                  </Box>
                </div>
              )}
              <div
                className={`col-lg-9 col-md-9 col-xs-12 border-box ${!isMobile && 'border-bottom'} ${isMobile && 'd-none'} p-3`}
                style={{ borderLeft: 'none' }}
                ref={boxRef}
              ></div>
            </div>
            <div className='row mx-0 w-100 '>
              <Box className='w-100 row'>
                <div
                  className={'col-lg-3 col-md-3 col-xs-12 border-box border-top-0 py-3'}
                  style={{ borderLeft: 'none' }}
                >
                  <Stepper
                    sx={[
                      {
                        [theme.breakpoints.between('xs', 'sm')]: [
                          {
                            ...classes.dflex,
                            ...classes.jcSBetween,
                            ...classes.alignItemCenter,
                            ...{
                              width: '100%',
                              paddingLeft: 0,
                              paddingRight: '40px',
                            },
                          },
                        ],
                      },
                    ]}
                    activeStep={activeStep}
                    orientation={isMobile ? 'horizontal' : 'vertical'}
                    connector={false}
                    className='pt-4'
                  >
                    {steps.map((Item, index) => {
                      return (
                        <Step
                          key={index}
                          sx={[
                            {
                              [theme.breakpoints.between('xs', 'sm')]: [
                                {
                                  ...{ width: '100%' },
                                },
                              ],
                            },
                          ]}
                        >
                          <Box
                            sx={[
                              {
                                [theme.breakpoints.between('xs', 'sm')]: [
                                  {
                                    ...classes.dflex,
                                    ...classes.alignItemCenter,
                                    ...{ width: '100%' },
                                  },
                                ],
                              },
                            ]}
                          >
                            <StepLabel
                              icon={index > activeStep ? <PanoramaFishEyeSharpIcon /> : <CheckCircleIcon sx={[classes.color9A291B]} />}
                              tabIndex={0}
                              className='p-0'
                            >
                              <Typography
                                sx={[
                                  styles.fs20,
                                  styles.fontFamilyDS,
                                  styles.color240501,
                                  styles.fontWeight500,
                                  {
                                    [theme.breakpoints.between('xs', 'sm')]: [classes.displayNone],
                                  },
                                ]}
                              >
                                {Item.label}
                              </Typography>
                              <Typography
                                className='text-color979797'
                                sx={[
                                  styles.fs14,
                                  styles.fontFamilyDS,
                                  {
                                    [theme.breakpoints.between('xs', 'sm')]: [classes.displayNone],
                                  },
                                ]}
                              >
                                {Item.subLabel}
                              </Typography>
                            </StepLabel>
                            {steps.length !== index + 1 && <StepConnector />}
                          </Box>
                        </Step>
                      );
                    })}
                  </Stepper>
                </div>
                <div
                  className='col-lg-9 col-md-9 col-xs-12 px-4 hidden-scrollbar'
                  id='step-container'
                  // style={{
                  //   height: `calc(100vh - ${headerHeight}px - ${boxHeight}px)`,
                  //   overflowY: 'scroll',
                  // }}
                >
                  <Stepper
                    activeStep={activeStep}
                    orientation='vertical'
                    connector={false}
                  >
                    {steps.map((Item, index) => {
                      return (
                        <Step
                          key={index}
                          className='d-flex'
                        >
                          <StepContent
                            className='border-0 w-100'
                            sx={[
                              {
                                [theme.breakpoints.between('xs', 'md')]: [
                                  {
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    marginLeft: '12px',
                                  },
                                ],
                              },
                            ]}
                          >
                            <Item.component
                              setActiveStep={setActiveStep}
                              activeStep={activeStep}
                              setData={setData}
                              data={data}
                              handleTextChange={handleTextChange}
                              handleCheckBoxChange={handleCheckBoxChange}
                              totalSteps={steps.length}
                              currentStep={activeStep}
                            />
                            <Box
                              className='mt-4 d-flex w-60 pb-5'
                              sx={[
                                {
                                  [theme.breakpoints.between('xs', 'md')]: [classes.width100],
                                },
                              ]}
                            >
                              <ButtonOutlined
                                sx={[
                                  {
                                    [theme.breakpoints.between('xs', 'md')]: [{ minWidth: '100px', maxWidth: '100px' }],
                                  },
                                ]}
                                onClick={() => {
                                  console.log('hi');
                                  if (activeStep !== 0) handleBack();
                                  document.getElementById('step-container').scrollTo(0, 0);
                                }}
                              >
                                {activeStep === 0 ? 'Cancel' : 'Previous'}
                              </ButtonOutlined>
                              {loading ?
                              <ButtonContained
                             
                            >
                              Loading...
                            </ButtonContained>
                              
                              
                              : <ButtonContained
                                // onClick={() => { onSubmit() }}
                                onClick={(e) => {
                                  if (steps.length === activeStep + 1) {
                                    onSubmit(e);
                                  } else {
                                    handleNext();
                                    document.getElementById('step-container').scrollTo(0, 0);
                                  }
                                }}
                                sx={[
                                  {
                                    [theme.breakpoints.between('xs', 'md')]: [{ minWidth: '100px', maxWidth: '100px' }],
                                  },
                                ]}
                              >
                                {steps.length === activeStep + 1 ? 'Finish' : 'Next'}
                              </ButtonContained> }
                              
                            </Box>
                          </StepContent>
                        </Step>
                      );
                    })}
                  </Stepper>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProperty;
