import { WidthFull } from "@mui/icons-material";
import React from "react";
import WelcomeImage from "../../assets/images/Welcome-to-gharwala-image.png";

const WelcomeToGharwala = () => {
  return (
    <>
      <div style={{ backgroundColor: "slategray" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >

          {/* first page: */}
          <div
            style={{
              maxWidth: "52vw",
              Width: "52vw",
              height: "160vh",
            }}
          >
            <div>
              <img style={{width : "52vw"}} src={WelcomeImage} alt="img" />
            </div>
          </div>

          {/* 2nd page: */}
          <div
            style={{
              marginTop: "10px",
              maxWidth: "52vw",
              Width: "52vw",
              height: "160vh",
              paddingBlock: "40px",
              paddingInline: "20px",
              backgroundColor: "white",
            }}
          >
            <div>
              <span style={{ fontWeight: "bolder" }}>www.gharwalah.com</span> is
              primarily the trademark-applied company of Gharwala Home Solutions
              LLP. This is the user guide for easy navigation of the Gharwala
              platform. Gharwala is India's best one-stop solution platform for
              searching for property, Furniture and Home Appliances and Domestic
              Services. Welcome to Gharwala
            </div>

            <div style={{ marginTop: "20px" }}>
              <p style={{ fontWeight: "bolder" }}>Property Buying</p>
              <p>
                Buying a property is a significant milestone in one's life, and
                at Gharwala, we understand the importance of finding the perfect
                home. Our platform offers a seamless and transparent experience
                for property buyers, ensuring that they can navigate the real
                estate market with confidence and ease
              </p>

              <p>
                Comprehensive Property Listings. Explore a wide range of
                properties listed on our platform, including apartments, villas,
                independent houses, and more. Filter listings based on your
                preferences such as location, price range, size, amenities, and
                more, to find the perfect match for your needs.
              </p>

              <p>
                Detailed Property Information. Each property listing on Gharwala
                comes with detailed information, including high-quality images,
                floor plans, property specifications, amenities, neighborhood
                details, and contact information for the seller or agent. This
                allows buyers to make informed decisions and envision their
                future home.
              </p>
            </div>

            <div>
              <p style={{ fontWeight: "bolder" }}>Property Renting</p>
              <p>
                Renting a property should be a hassle-free experience, and at
                Gharwala, we strive to make it as convenient and straightforward
                as possible. Whether you're looking for a cozy apartment, a
                spacious villa, or a commercial space, our platform has you
                covered.
              </p>

              <p>
                Diverse Rental Options Browse through a diverse range of rental
                properties, including residential apartments, houses, commercial
                spaces, and more. Filter listings based on your preferences such
                as location, budget, property type, and amenities, to find the
                perfect rental home or office space.
              </p>

              <p>
                Transparent Rental Information. Each rental listing on Gharwala
                provides comprehensive information, including rental rates,
                lease terms, property features, amenities, neighborhood details,
                and contact information for the landlord or property manager.
                This allows renters to make informed decisions and find the
                ideal rental property
              </p>
            </div>
          </div>

          {/* 2nd Page */}
          <div
            style={{
              marginTop: "10px",
              maxWidth: "52vw",
              Width: "52vw",
              height: "160vh",
              paddingBlock: "40px",
              paddingInline: "20px",
              backgroundColor: "white",
            }}
          >
            <div>
              <p style={{ fontWeight: "bolder" }}>Property Viewings</p>
              <p>
                Explore rental properties at Gharwala with our property-checking
                experiences. Take a tour of rental units, inspecting the details
                of every room and space to ensure it meets your requirements and
                preferences before scheduling an in-person visit.
              </p>

              <p>
                Tenant Resources and Support. Our platform offers valuable
                resources and support for tenants, including rental guides, legal
                information, tenant rights, and FAQs. Whether you're a
                first-time renter or a seasoned tenant, our platform equips you
                with the knowledge and tools to navigate the rental process with
                confidence.
              </p>

              <p>
                Hassle-free Rental Process Gharwala streamlines the rental
                process, from property search to rental agreement. Our platform
                facilitates seamless communication between landlords and
                tenants, automates rental agreements and documentation, and
                ensures a hassle-free rental experience.
              </p>

              <p>
                Dedicated Customer Service. Our customer support team is
                available to assist you with any questions, concerns, or issues
                you may encounter during your rental journey. Whether you need
                help with property inquiries, rent agreements, or maintenance we're
                here to provide prompt and personalized assistance.
              </p>
            </div>
          </div>

          {/* 3rd page:  */}
          <div
            style={{
              marginTop: "10px",
              maxWidth: "52vw",
              Width: "52vw",
              height: "160vh",
              paddingBlock: "40px",
              paddingInline: "20px",
              backgroundColor: "white",
            }}
          >
            <div>
              <p style={{ fontWeight: "bolder" }}>
                Furniture & Home Appliances Buying
              </p>
              <p>
                At Gharwala, we believe that furnishing your home should be a
                delightful experience, which is why we offer a wide selection of
                high-quality furniture and home appliances to suit every style
                and budget. Whether you're furnishing a new home or upgrading
                your existing space, our platform has everything you need to
                create your dream living environment.
              </p>

              <p>
                Extensive Product Catalog. Explore our extensive catalog of
                furniture and home appliances, including sofas, beds, dining
                sets, wardrobes, refrigerators, washing machines, televisions,
                and more. With a diverse range of styles, designs, and brands to
                choose from, you're sure to find the perfect pieces to enhance
                your home decor.
              </p>

              <p>
                Detailed Product Descriptions. Each product listing on Gharwala
                provides detailed descriptions, specifications, dimensions,
                materials, and images, allowing you to make informed decisions
                about your purchases. Whether you're looking for contemporary
                furniture or modern appliances, our platform provides all the
                information you need to find the right products for your home.
              </p>

              <p>
                Flexible Payment Options. Gharwala offers flexible payment
                options to accommodate your budget and preferences. Choose from
                a variety of payment methods, including credit/debit cards, net
                banking, and EMI options to complete your purchase securely and
                conveniently
              </p>

              <p>
                Secure Delivery and Installation by Gharwala. Enjoy hassle-free
                delivery and installation of your furniture and appliances with
                Gharwala's reliable logistics partners. Our team ensures that
                your orders are delivered safely and on time, and provides
                professional installation services to ensure that everything is
                set up correctly and ready for use.
              </p>

              <p style={{ fontWeight: "bolder" }}>
                Furniture & Home Appliances Renting
              </p>
              <p>
                Renting furniture and home appliances is a convenient and
                cost-effective solution for temporary living arrangements,
                short-term stays, or flexible home furnishing options. At
                Gharwala, we offer a wide range of rental furniture and
                appliances to meet your needs, whether you're moving into a new
                home, hosting guests, or furnishing a temporary space
              </p>

              <p>
                Flexible Rental Plans Choose from a variety of flexible rental
                plans to suit your specific needs and duration. Whether you need
                furniture and appliances for 3M, 6M, 9M, and 12M. Gharwala offers
                rental options that fit your schedule and budget
              </p>

              <p>
                Wide Selection of Rental Items. Browse our extensive inventory
                of rental furniture and appliances, including sofas, beds,
                dining tables, refrigerators, washing machines, televisions, and
                more. With options available for every room and function, you
                can easily find the perfect items to furnish your space.
              </p>
            </div>
          </div>

          {/* 4th page: */}
          <div
            style={{
              marginTop: "10px",
              maxWidth: "52vw",
              Width: "52vw",
              height: "160vh",
              paddingBlock: "40px",
              paddingInline: "20px",
              backgroundColor: "white",
            }}
          >
            <p>
              Hassle-free Rental Process. Renting furniture and appliances from
              Gharwala is quick, easy, and hassle-free. Simply browse our online
              catalog, select the items you need, choose your rental plan and
              duration, and place your order. Our team takes care of the rest,
              delivering and setting up your rental items at your convenience.
              Go through the proper KYC verification process and easy checkout
              process.
            </p>

            <p>
              Cost-effective Solution <br></br>
              Renting furniture and appliances is a cost-effective alternative
              to purchasing, especially for temporary or short-term needs. With
              Gharwala's affordable rental rates and flexible plans, you can
              enjoy the comfort and convenience of fully furnished living spaces
              without the commitment or expense of buying.
            </p>
            <p>
              Convenient Return and Replacement. When your rental period ends or
              your needs change, simply schedule a pickup with Gharwala's
              logistics team to return your rental items. If you encounter any
              issues or require replacements during your rental period, our
              customer support team is available to assist you promptly and
              efficiently
            </p>
          </div>

          {/* 5th page: */}
          <div
            style={{
              marginTop: "10px",
              maxWidth: "52vw",
              Width: "52vw",
              height: "160vh",
              paddingBlock: "40px",
              paddingInline: "20px",
              backgroundColor: "white",
            }}
          >
            <div>
              <p style={{ fontWeight: "bolder" }}>
                Domestic Service Providers Network
              </p>
              <p>
                At Gharwala, we understand that maintaining a home requires the
                assistance of reliable domestic service providers for various
                tasks and repairs. That's why we've curated a network of trusted
                professionals, including electricians, plumbers, maids,
                painters, and more, to help you address your home maintenance
                needs quickly and efficiently
              </p>

              <div>
                <div style={{ fontWeight: "bolder" }}>
                  Comprehensive Service Directory
                </div>
                <div>
                  Explore our comprehensive directory of domestic service
                  providers, each carefully vetted and verified to ensure their
                  qualifications, expertise, and reliability. Whether you need
                  routine maintenance, repairs, or emergency services, our
                  platform connects you with skilled professionals who can
                  tackle a wide range of tasks with precision and
                  professionalism.
                </div>
              </div>

              <div>
                <div style={{ fontWeight: "bolder" }}>
                  Wide Range of Services
                </div>
                <div>
                  From electrical repairs and plumbing services to house
                  cleaning and painting, our network of service providers offers
                  a diverse range of services to meet your specific needs and
                  requirements. Whether it's fixing a leaky faucet, rewiring
                  your home, or giving your walls a fresh coat of paint, our
                  experts are equipped to handle any job, big or small.
                </div>

                <p style={{ marginTop: "10px" }}>
                  Convenient Booking Process. Booking a service provider through
                  Gharwala is quick, easy, and convenient. Simply browse our
                  directory, select the type of service you need, and choose
                  from a list of available providers in your area. You can view
                  detailed profiles, read customer reviews, and compare prices
                  before making your selection, ensuring that you find the right
                  professional for the job.
                </p>

                <p>
                  Transparent Pricing and Estimates. We believe in transparency
                  and fairness, which is why we provide upfront pricing and
                  estimates for all services listed on our platform. In the future
                  before booking a service, you'll receive a detailed breakdown
                  of costs, including labor, materials, and any additional fees,
                  so you can make informed decisions and avoid surprises.
                </p>
              </div>

              <div>
                <div style={{ fontWeight: "bolder" }}>
                  Quality Assurance and Customer Satisfaction
                </div>
                <div>
                  At Gharwala, we're committed to ensuring the highest standards
                  of quality and customer satisfaction. That's why we carefully
                  monitor the performance and feedback of our service providers,
                  continuously evaluating their workmanship and professionalism
                  to maintain our reputation for excellence. We also encourage
                  customers to share their experiences and provide feedback,
                  allowing us to continually improve and enhance our services.
                </div>
              </div>

              <div style={{ marginTop: "10px" }}>
                <div style={{ fontWeight: "bolder" }}>
                  24/7 Support and Assistance
                </div>
                <div>
                  Our dedicated customer support team is available 24/7 to
                  assist you with any questions, concerns, or issues you may
                  have. Whether you need help booking a service, resolving a
                  dispute, or seeking advice on home maintenance, our team is
                  here to provide prompt and personalized assistance, ensuring
                  that your experience with Gharwala is smooth, seamless, and
                  satisfactory.
                </div>
              </div>
            </div>
          </div>

          {/* 6th page: */}
          <div
            style={{
              marginTop: "10px",
              maxWidth: "52vw",
              Width: "52vw",
              height: "160vh",
              paddingBlock: "40px",
              paddingInline: "20px",
              backgroundColor: "white",
            }}
          >
            <p style={{ fontWeight: "bolder" }}>
              Guide for Property Owner Listing (Owner Panel)
            </p>

            <div>
              <div style={{ fontWeight: "bolder" }}>
                1. Accessing the Owner Panel:
              </div>
              <div>
                <div>Log in to your Gharwala account using your credentials.</div>
                <div>Navigate to the "Owner Panel" section from the dashboard.</div>
              </div>  
            </div>

            <div>
                <div style={{fontWeight: "bolder"}}>2. Registration Process:</div>
                <div>
                    Fill out the registration form with your business details, including your business name, contact
                    information, and business registration number.
                </div>
                <div>
                  Upload any required documents, such as proof of ownership or authorization to list properties on
                  behalf of the business.
                </div>
            </div>

            <div>
                <div style={{fontWeight: "bolder"}}>3. Property Listing:</div>
                <div>Click on the "Add Property" option to begin listing your properties.</div>
                <div>Enter the details of your property, including its address, type, size, amenities, and photographs.</div>
                <div>Specify any additional information, such as rental or selling price, terms and conditions, and availability status</div>
                <div>Review the listing details for accuracy and completeness before submitting.</div>
            </div>

            <div>
                <div style={{fontWeight: "bolder"}}>4. Managing Listings:</div>
                <div>View and manage your property listings from the Owner Panel dashboard.</div>
                <div>Edit or update existing listings as needed, such as modifying property details or adjusting pricing.</div>
                <div>Mark properties as "Available" or "Unavailable" based on their current status.</div>
                <div>Remove or deactivate listings that are no longer available or relevant.</div>
            </div>

            <div>
                <div style={{fontWeight: "bolder"}}>5. Communication and Notifications:</div>
                <div>Receive notifications for new inquiries or messages from potential tenants or buyers.</div>
                <div>Communicate directly with interested parties through the platform's messaging system.</div>
                <div>Respond promptly to inquiries and provide additional information or assistance as needed.</div>
            </div>

            <div style={{marginTop: "20px"}}>
                <p style={{fontWeight: "bolder"}}>Guide for Broker Property Listing (Broker Panel)</p>

                <div>
                    <div style={{fontWeight: "bolder"}}>1. Accessing the Broker Panel:</div>
                    <div>Log in to your Gharwala account using your credentials.</div>
                    <div>Navigate to the "Broker Panel" section from the dashboard.</div>
                </div>

                <div style={{marginTop: "10px"}}>
                    <div style={{fontWeight: "bolder"}}>2. Registration Process:</div>
                    <div>Complete the registration process by providing your personal and professional details, including your brokerage firm's information.</div>
                    <div>Upload any required documents, such as your brokerage license or identification.</div>
                </div>
            </div>
          </div>

          {/* 7th page: */}
          <div
            style={{
              marginTop: "10px",
              maxWidth: "52vw",
              Width: "52vw",
              height: "160vh",
              paddingBlock: "40px",
              paddingInline: "20px",
              backgroundColor: "white",
            }}
          >
            <div>
                <div style={{fontWeight: "bolder"}}>3. Property Listing:</div>
                <div>Click on the "List Property" option to create a new listing.</div>
                <div>Enter the property details, including its location, type, features, and photographs.</div>
                <div>Include relevant information such as pricing, terms, and availability.</div>
                <div>Review the listing before submission to ensure accuracy and completeness.</div>
            </div>

            <div style={{marginTop: "10px"}}>
                <div style={{fontWeight: "bolder"}}>4. Managing Listings:</div>
                <div>View and manage your property listings from the Broker Panel dashboard.</div>
                <div>Edit or update listings as necessary, such as making price adjustments or adding new information.</div>
                <div>Mark properties as "Active" or "Inactive" based on their availability status.</div>
                <div>Remove or archive listings that are no longer relevant or available.</div>
            </div>

            <div style={{marginTop: "10px"}}>
                <div style={{fontWeight: "bolder"}}>5. Communication and Interaction:</div>
                <div>Receive notifications for inquiries or messages from potential clients.</div>
                <div>Respond promptly to inquiries and engage with interested parties to facilitate property transactions.</div>
                <div>Use the platform's messaging system to communicate securely and efficiently with clients.</div>
            </div>

            <div style={{marginTop: "10px"}}>
                <p>Guide for Service Provider Listing (Service Panel)</p>

                <div style={{marginTop : "10px"}}>
                    <div style={{fontWeight: "bolder"}}>1. Accessing the Service Panel:</div>
                    <div>Log in to your Gharwala account using your credentials.</div>
                    <div>Navigate to the "Service Panel" section from the dashboard.</div>
                </div>

                <div style={{marginTop : "10px"}}>
                    <div style={{fontWeight: "bolder"}}>2. Registration Process:</div>
                    <div>Complete the registration process by providing your personal and business details, including your
                        service offerings.</div>
                    <div>Upload any required documents, such as licenses or certifications related to your profession.</div>
                </div>

                <div style={{marginTop : "10px"}}>
                    <div style={{fontWeight: "bolder"}}>3. Service Listing:</div>
                    <div>Click on the "List Service" option to create a new service listing.</div>
                    <div>Specify the type of service you offer, such as electrician, plumber, cleaning, or maintenance.</div>
                    <div>Provide details about your services, including pricing, availability, service area, and any specializations.</div>
                    <div>Upload photos or examples of your work to showcase your skills and expertise.</div>
                </div>

                <div style={{marginTop : "10px"}}>
                    <div style={{fontWeight: "bolder"}}>4. Managing Listings:</div>
                    <div>Access and manage your service listings from the Service Panel dashboard.</div>
                    <div>Update or modify listings as needed, such as adjusting service descriptions or pricing.</div>
                    <div>Mark services as "Available" or "Unavailable" based on your current availability.</div>
                    <div>Remove or deactivate listings for services that are no longer offered or relevant.</div>
                </div>
            </div>

          </div>

          {/* 8th page: */}
          <div
            style={{
              marginTop: "10px",
              maxWidth: "52vw",
              Width: "52vw",
              height: "160vh",
              paddingBlock: "40px",
              paddingInline: "20px",
              backgroundColor: "white",
            }}
          >
                <div style={{marginTop: "10px"}}>
                    <p style={{fontWeight: "bolder"}}>Interaction with Customers:</p>
                    <p>Receive notifications for service inquiries or booking requests from customers.</p>
                    <p>Respond promptly to inquiries and provide quotes or estimates for requested services.</p>
                    <p>Coordinate with customers to schedule service appointments and confirm bookings.</p>
                    <p>Maintain open communication with customers throughout the service process to ensure satisfaction and resolve any issues promptly.</p>
                </div>
          </div>

          {/* 9th page: */}
          <div
            style={{
              marginTop: "10px",
              maxWidth: "52vw",
              Width: "52vw",
              height: "160vh",
              paddingBlock: "40px",
              paddingInline: "20px",
              backgroundColor: "white",
            }}
          >
            <p style={{fontWeight: "bolder"}}>Property Rent Agreement Creation Process on Gharwala</p>

            <div style={{marginTop: "10px"}}>
                <div style={{fontWeight: "bolder"}}>1. Accessing the Rent Agreement Section:</div>
                <div>Log in to your Gharwala account using your credentials.</div>
                <div>Navigate to the "Rent Agreement" section from the dashboard.</div>
            </div>

            <div style={{marginTop: "10px"}}>
                <div style={{fontWeight: "bolder"}}>2. Initiating a New Rent Agreement:</div>
                <div>Click on the "Create New Agreement" option to begin drafting a rent agreement.</div>
                <div>Fill out the required details, including the landlord's and tenant's information, property details, rental terms, and duration.</div>
                <div>Review the entered information carefully to ensure accuracy.</div>
            </div>

            <div style={{marginTop: "10px"}}>
                <div>3. Customizing Agreement Terms:</div>
                <div>Customize the terms of the rent agreement based on your specific requirements, such as rent amount,
                     payment schedule, security deposit, and maintenance responsibilities.
                </div>
                <div>Add any additional clauses or provisions as needed, addressing aspects like termination conditions,
                    subletting rules, or property maintenance guidelines.
                </div>
            </div>

            <div style={{marginTop: "10px"}}>
                <div style={{fontWeight: "bolder"}}>4. Previewing and Editing:</div>
                <div>Preview the draft rent agreement to ensure all details are accurately captured.</div>
                <div>Edit or revise any information as necessary before finalizing the agreement.</div>
            </div>

            <div style={{marginTop: "10px"}}>
               <div style={{fontWeight: "bolder"}}>5. Downloading the Agreement:</div>
               <div>Once satisfied with the agreement terms, click on the "Download PDF" option to generate the rent agreement document.</div>
               <div>Save the PDF copy to your device for future reference or printing.</div>
            </div>

            <div style={{marginTop: "10px"}}>
                <div style={{fontWeight: "bolder"}}>6. Optional Hardcopy Request:</div>
                <div>If you require a hard copy of the rent agreement with stamp paper and a notary, select the appropriate option.</div>
                <div>Proceed to make the payment as per the charges specified by Gharwala for the hard-copy service.</div>
            </div>

            <div style={{marginTop: "10px"}}>
                <div style={{fontWeight: "bolder"}}>7. Delivery of Hardcopy Agreement:</div>
                <div>
                    After completing the payment, provide the necessary delivery details, including the address where
                    you want the hard-copy agreement to be delivered.
                </div>
                <div>
                    Gharwala will arrange for the printing, stamping, and notarization of the agreement and dispatch it to
                    the specified address.
                </div>
            </div>

            <div style={{marginTop: "10px"}}>
                <div style={{fontWeight: "bolder"}}>8. Confirmation and Acknowledgment:</div>
                <div>Upon receipt of the hard copy agreement, acknowledge its receipt and confirm its accuracy.</div>
                <div>Retain the hard copy agreement for your records and reference.</div>
            </div>
          </div>

          {/* 10th page: */}
          <div
            style={{
              marginTop: "10px",
              maxWidth: "52vw",
              Width: "52vw",
              height: "160vh",
              paddingBlock: "40px",
              paddingInline: "20px",
              backgroundColor: "white",
            }}
          >
            <p style={{fontWeight: "bolder"}}>Furniture Rent Agreement Process on Gharwala</p>  

            <div style={{marginTop: "10px"}}>
                <div style={{fontWeight: "bolder"}}>1. Accessing the Furniture Agreement Section:</div>
                <div>Log in to your Gharwala account using your credentials.</div>
                <div>Navigate to the "Furniture Agreement" section from the dashboard.</div>
            </div> 

            <div style={{marginTop: "10px"}}>
                <div style={{fontWeight: "bolder"}}>2. Initiating a New Furniture Agreement:</div>
                <div>Click on the "Create New Agreement" option to start drafting a furniture rental agreement.</div>
                <div>Provide the required details, including the landlord's and tenant's information, furniture details, rental terms, and duration.</div>
                <div>Ensure all information is accurately entered before proceeding.</div>
            </div>

            <div style={{marginTop: "10px"}}>
                <div style={{fontWeight: "bolder"}}>3. Customizing Agreement Terms:</div>
                <div>Customize the terms of the furniture rental agreement to suit your specific needs, such as rental period, rental charges, security deposit, and maintenance responsibilities.</div>
                <div>Include any additional clauses or provisions addressing aspects like damages, repairs, or replacement of furniture items.</div>
            </div>

            <div style={{marginTop: "10px"}}>
                <div style={{fontWeight: "bolder"}}>4. Previewing and Editing:</div>
                <div>Review the draft furniture agreement to verify the accuracy of the entered details.</div>
                <div>Make any necessary edits or revisions before finalizing the agreement.</div>
            </div>

            <div style={{marginTop: "10px"}}>
                <div style={{fontWeight: "bolder"}}>5. Downloading the Agreement:</div>
                <div>Once satisfied with the agreement terms, click on the "Download PDF" option to generate the furniture rental agreement document.</div>
                <div>Save the PDF copy for your records or printing purposes.</div>
            </div>

            <div style={{marginTop: "10px"}}>
                <div style={{fontWeight: "bolder"}}>6. Confirmation and Acknowledgment:</div>
                <div>Confirm the receipt and accuracy of the furniture rental agreement.</div>
                <div>Retain the agreement for reference throughout the rental period and beyond.</div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeToGharwala;
