
import {

    SET_SERVICES
} from '../action/types'

const initialState = {
    isAuthenticated: false,
    services: [],


}

export default function (state = initialState, action) {
    switch (action.type) {

        case SET_SERVICES:
            return {
                ...state,
                services: action.payload
            }

        default:
            return {
                ...state
            }
    }
}
