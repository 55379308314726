import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useOutletContext } from "react-router-dom";

const FormPropertyDetails = () => {
  const navigate = useNavigate();
  const [
    role,
    setRole,
    ownerData,
    setOwnerData,
    tenantData,
    setTenantData,
    propertyDetails,
    setPropertyDetails,
    contractDetails,
    setContractDetails,
    furnitureId,
    setFurnitureId,
    furnishedDetails,
    setFurnishedDetails,
  ] = useOutletContext();

  useEffect(() => {
    if (!verify(tenantData) && !verify(ownerData)) {
      navigate("/agreement/basic-details");
    }

    for (let item in tenantData) {
      if (!verify(tenantData[item])) {
        navigate("/agreement/basic-details");
      }
    }
    for (let item in ownerData) {
      if (!verify(ownerData[item])) {
        navigate("/agreement/basic-details");
      }
    }
  }, []);

  useEffect(() => {
    if (!verify(contractDetails)) {
      navigate("/agreement/contract-details");
    }

    for (let item in contractDetails) {
      if (!verify(contractDetails[item])) {
        navigate("/agreement/contract-details");
      }
    }
  }, []);

  const verify = (data) => {
    for (let key in data) {
      if (data[key] === null || data[key] === undefined || data[key] === "") {
        return false;
      }
    }

    return true;
  };

  console.log(propertyDetails);

  const handleAddOthers = (elem) => {
    // Check if the element exists in the array
    const elementExists = propertyDetails.others.includes(elem);

    // If it exists, remove it from the array
    if (elementExists) {
      const newArray = propertyDetails.others.filter((item) => item !== elem);
      setPropertyDetails((prev) => ({ ...prev, others: newArray }));
    }
    // If it doesn't exist, add it to the array
    else {
      setPropertyDetails((prev) => ({
        ...prev,
        others: [...prev.others, elem],
      }));
    }
  };

  return (
    <>
      <div className="header">Property Details</div>
      <div className="form-button-container">
        {propertyDetails.type !== "furniture" && (
          <button
            className={`form-button ${
              propertyDetails.type === "residential" && "form-button_active"
            }`}
            onClick={() =>
              setPropertyDetails((prev) => ({
                ...prev,
                type: "residential",
              }))
            }
          >
            Residential
          </button>
        )}
        <button
          className={`form-button ${
            propertyDetails.type === "non-residential" && "form-button_active"
          }`}
          onClick={() =>
            setPropertyDetails((prev) => ({
              ...prev,
              type: "non-residential",
            }))
          }
        >
          Non-Residential
        </button>
      </div>

      {propertyDetails.type === "residential" && (
        <>
          <div className="field-container">
            <div className="residence-detail">
              <div className="residence-floor">
                <label style={{ display: "block" }} htmlFor="floor">
                  Your Floor Number
                </label>
                <select
                  id="floor"
                  name="floor"
                  onChange={(e) =>
                    setPropertyDetails((prev) => ({
                      ...prev,
                      floor: e.target.value,
                    }))
                  }
                  value={propertyDetails.floor}
                  style={{ width: "auto" }}
                >
                  <option hidden selected>
                    Select
                  </option>
                  <option value="Lower Basement">Lower Basement</option>
                  <option value="Upper Basement">Upper Basement</option>
                  <option value="Ground Floor">Ground</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                  <option value="32">32</option>
                  <option value="33">33</option>
                  <option value="34">34</option>
                  <option value="35">35</option>
                  <option value="36">36</option>
                  <option value="37">37</option>
                  <option value="38">38</option>
                  <option value="39">39</option>
                  <option value="40">40</option>
                  <option value="41">41</option>
                  <option value="42">42</option>
                  <option value="43">43</option>
                  <option value="44">44</option>
                  <option value="45">45</option>
                  <option value="46">46</option>
                  <option value="47">47</option>
                  <option value="48">48</option>
                  <option value="49">49</option>
                  <option value="50">50</option>
                  <option value="51">51</option>
                  <option value="52">52</option>
                  <option value="53">53</option>
                  <option value="54">54</option>
                  <option value="55">55</option>
                  <option value="56">56</option>
                  <option value="57">57</option>
                  <option value="58">58</option>
                  <option value="59">59</option>
                  <option value="60">60</option>
                  <option value="61">61</option>
                  <option value="62">62</option>
                  <option value="63">63</option>
                  <option value="64">64</option>
                  <option value="65">65</option>
                  <option value="66">66</option>
                  <option value="67">67</option>
                  <option value="68">68</option>
                  <option value="69">69</option>
                  <option value="70">70</option>
                  <option value="71">71</option>
                  <option value="72">72</option>
                  <option value="73">73</option>
                  <option value="74">74</option>
                  <option value="75">75</option>
                  <option value="76">76</option>
                  <option value="77">77</option>
                  <option value="78">78</option>
                  <option value="79">79</option>
                  <option value="80">80</option>
                  <option value="81">81</option>
                  <option value="82">82</option>
                  <option value="83">83</option>
                  <option value="84">84</option>
                  <option value="85">85</option>
                  <option value="86">86</option>
                  <option value="87">87</option>
                  <option value="88">88</option>
                  <option value="89">89</option>
                  <option value="90">90</option>
                  <option value="91">91</option>
                  <option value="92">92</option>
                  <option value="93">93</option>
                  <option value="94">94</option>
                  <option value="95">95</option>
                  <option value="96">96</option>
                  <option value="97">97</option>
                  <option value="98">98</option>
                  <option value="99">99</option>
                  <option value="100">100</option>
                </select>
              </div>

              <div className="residence-room">
                <label style={{ display: "block" }} htmlFor="room">
                  Room Details
                </label>
                <div className="room-detail">
                  <div
                    onClick={() =>
                      setPropertyDetails((prev) => ({ ...prev, rooms: "1 RK" }))
                    }
                    className={`room-design ${
                      propertyDetails.rooms === "1 RK" && "selected-room"
                    }`}
                  >
                    1 RK
                  </div>
                  <div
                    onClick={() =>
                      setPropertyDetails((prev) => ({
                        ...prev,
                        rooms: "1 BHK",
                      }))
                    }
                    className={`room-design ${
                      propertyDetails.rooms === "1 BHK" && "selected-room"
                    }`}
                  >
                    1 BHK
                  </div>
                  <div
                    onClick={() =>
                      setPropertyDetails((prev) => ({
                        ...prev,
                        rooms: "2 BHK",
                      }))
                    }
                    className={`room-design ${
                      propertyDetails.rooms === "2 BHK" && "selected-room"
                    }`}
                  >
                    2 BHK
                  </div>
                  <div
                    onClick={() =>
                      setPropertyDetails((prev) => ({
                        ...prev,
                        rooms: "3 BHK",
                      }))
                    }
                    className={`room-design ${
                      propertyDetails.rooms === "3 BHK" && "selected-room"
                    }`}
                  >
                    3 BHK
                  </div>
                  <select
                    className="room-design"
                    id="room"
                    name="room"
                    onChange={(e) =>
                      setPropertyDetails((prev) => ({
                        ...prev,
                        rooms: e.target.value,
                      }))
                    }
                    value={propertyDetails.room}
                    style={{ width: "auto" }}
                  >
                    <option value="4 BHK">4 BHK</option>
                    <option value="5 BHK">5 BHK</option>
                    <option value="6 BHK">6 BHK</option>
                    <option value="7 BHK">7 BHK</option>
                    <option value="8 BHK">8 BHK</option>
                    <option value="9 BHK">9 BHK</option>
                    <option value="10 BHK">10 BHK</option>
                    <option value="11 BHK">11 BHK</option>
                  </select>
                </div>
              </div>

              <div className="room-sub-details">
                <div className="room-sub-details-first">
                  <div className="room-sub-details-head">Bedroom</div>
                  <div className="bedroom-num">
                    <button
                      onClick={() =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          bedroom: prev.bedroom - 1,
                        }))
                      }
                      disabled={propertyDetails.bedroom === 0}
                    >
                      -
                    </button>
                    <span className="room-sub-details-count">
                      {propertyDetails.bedroom}
                    </span>
                    <button
                      style={{marginLeft: "8px"}}
                      onClick={() =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          bedroom: prev.bedroom + 1,
                        }))
                      }
                    >
                      +
                    </button>
                  </div>
                </div>

                <div className="room-sub-details-first">
                  <div className="room-sub-details-head">Bathroom</div>
                  <div className="bathroom-num">
                    <button
                      onClick={() =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          bathroom: prev.bathroom - 1,
                        }))
                      }
                      disabled={propertyDetails.bathroom === 0}
                    >
                      -
                    </button>
                    <span className="room-sub-details-count">
                      {propertyDetails.bathroom}
                    </span>
                    <button
                      style={{marginLeft: "8px"}}
                      onClick={() =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          bathroom: prev.bathroom + 1,
                        }))
                      }
                    >
                      +
                    </button>
                  </div>
                </div>

                <div className="room-sub-details-first">
                  <div className="room-sub-details-head">Balcony</div>
                  <div className="balcony-num">
                    <button
                      onClick={() =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          balcony: prev.balcony - 1,
                        }))
                      }
                      disabled={propertyDetails.balcony === 0}
                    >
                      -
                    </button>
                    <span className="room-sub-details-count">
                      {propertyDetails.balcony}
                    </span>
                    <button
                      style={{marginLeft: "8px"}}
                      onClick={() =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          balcony: prev.balcony + 1,
                        }))
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              <div className="other-rooms">
                <div className="other-rooms-head">Other Rooms</div>
                <div className="room-type">
                  <button
                    onClick={() => handleAddOthers("Servant Room")}
                    className="each-room"
                    style={{
                      backgroundColor:
                        propertyDetails.others.includes("Servant Room") &&
                        "#9A291B",
                    }}
                  >
                    Servant Room
                  </button>
                  <button
                    onClick={() => handleAddOthers("Store Room")}
                    className="each-room"
                    style={{
                      backgroundColor:
                        propertyDetails.others.includes("Store Room") &&
                        "#9A291B",
                    }}
                  >
                    Store Room
                  </button>
                  <button
                    onClick={() => handleAddOthers("Pooja Room")}
                    className="each-room"
                    style={{
                      backgroundColor:
                        propertyDetails.others.includes("Pooja Room") &&
                        "#9A291B",
                    }}
                  >
                    Pooja Room
                  </button>
                  <br></br>
                  <button
                    onClick={() => handleAddOthers("Study Room")}
                    className="each-room"
                    style={{
                      backgroundColor:
                        propertyDetails.others.includes("Study Room") &&
                        "#9A291B",
                    }}
                  >
                    Study Room
                  </button>
                  <button
                    onClick={() => handleAddOthers("Others")}
                    className="each-room"
                    style={{
                      backgroundColor:
                        propertyDetails.others.includes("Others") && "#9A291B",
                    }}
                  >
                    Others
                  </button>
                </div>
              </div>

              <div className="property-square-area">
                <input
                  className="property-square-input"
                  placeholder="Property Area (In Sqft) (Optional)"
                  value={propertyDetails.area}
                  onChange={(e) =>
                    setPropertyDetails((prev) => ({
                      ...prev,
                      area: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="parking-space">
                <div className="parking-space-head">Parking Space</div>

                <div className="parking-space-option">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      type="radio"
                      name="parking"
                      checked={propertyDetails.parking}
                      onChange={() =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          parking: true,
                        }))
                      }
                      id="parking_yes"
                    />
                    <label htmlFor="parking_yes">Yes</label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      type="radio"
                      name="parking"
                      checked={!propertyDetails.parking}
                      onChange={() =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          parking: false,
                        }))
                      }
                      id="parking_no"
                    />
                    <label htmlFor="parking_no">No</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <input
              type="checkbox"
              checked={propertyDetails.addressSame}
              onChange={() =>
                setPropertyDetails((prev) => ({
                  ...prev,
                  addressSame: !prev.addressSame,
                }))
              }
            />
             <span style={{marginLeft:"10px"}}>Same as Landlord's Address</span>
          </div>

          {!propertyDetails.addressSame && (
            <div className="rented-property">
              <div className="rented-property-address">
                <input
                  placeholder="Address of Rented Property"
                  value={propertyDetails.address}
                  onChange={(e) =>
                    setPropertyDetails((prev) => ({
                      ...prev,
                      address: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="rented-property-address-two">
                <div className="rented-property-pincode">
                  <input
                    placeholder="Pincode"
                    value={propertyDetails.pincode}
                    onChange={(e) =>
                      setPropertyDetails((prev) => ({
                        ...prev,
                        pincode: e.target.value,
                      }))
                    }
                  />
                </div>

                <div className="rented-property-city-state">
                  <input
                    placeholder="City"
                    value={propertyDetails.city}
                    onChange={(e) =>
                      setPropertyDetails((prev) => ({
                        ...prev,
                        city: e.target.value,
                      }))
                    }
                  />
                  <input
                    placeholder="State"
                    value={propertyDetails.state}
                    onChange={(e) =>
                      setPropertyDetails((prev) => ({
                        ...prev,
                        state: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {propertyDetails.type === "non-residential" && (
        <>
          <div className="field-container">
            <div className="residence-detail">
              <div className="residence-floor">
                <label style={{ display: "block" }} htmlFor="floor">
                  Your Floor Number
                </label>
                <select
                  id="floor"
                  name="floor"
                  onChange={(e) =>
                    setPropertyDetails((prev) => ({
                      ...prev,
                      floor: e.target.value,
                    }))
                  }
                  value={propertyDetails.floor}
                  style={{ width: "auto" }}
                >
                  <option hidden selected>
                    Select
                  </option>
                  <option value="Lower Basement">Lower Basement</option>
                  <option value="Upper Basement">Upper Basement</option>
                  <option value="Ground Floor">Ground</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                  <option value="32">32</option>
                  <option value="33">33</option>
                  <option value="34">34</option>
                  <option value="35">35</option>
                  <option value="36">36</option>
                  <option value="37">37</option>
                  <option value="38">38</option>
                  <option value="39">39</option>
                  <option value="40">40</option>
                  <option value="41">41</option>
                  <option value="42">42</option>
                  <option value="43">43</option>
                  <option value="44">44</option>
                  <option value="45">45</option>
                  <option value="46">46</option>
                  <option value="47">47</option>
                  <option value="48">48</option>
                  <option value="49">49</option>
                  <option value="50">50</option>
                  <option value="51">51</option>
                  <option value="52">52</option>
                  <option value="53">53</option>
                  <option value="54">54</option>
                  <option value="55">55</option>
                  <option value="56">56</option>
                  <option value="57">57</option>
                  <option value="58">58</option>
                  <option value="59">59</option>
                  <option value="60">60</option>
                  <option value="61">61</option>
                  <option value="62">62</option>
                  <option value="63">63</option>
                  <option value="64">64</option>
                  <option value="65">65</option>
                  <option value="66">66</option>
                  <option value="67">67</option>
                  <option value="68">68</option>
                  <option value="69">69</option>
                  <option value="70">70</option>
                  <option value="71">71</option>
                  <option value="72">72</option>
                  <option value="73">73</option>
                  <option value="74">74</option>
                  <option value="75">75</option>
                  <option value="76">76</option>
                  <option value="77">77</option>
                  <option value="78">78</option>
                  <option value="79">79</option>
                  <option value="80">80</option>
                  <option value="81">81</option>
                  <option value="82">82</option>
                  <option value="83">83</option>
                  <option value="84">84</option>
                  <option value="85">85</option>
                  <option value="86">86</option>
                  <option value="87">87</option>
                  <option value="88">88</option>
                  <option value="89">89</option>
                  <option value="90">90</option>
                  <option value="91">91</option>
                  <option value="92">92</option>
                  <option value="93">93</option>
                  <option value="94">94</option>
                  <option value="95">95</option>
                  <option value="96">96</option>
                  <option value="97">97</option>
                  <option value="98">98</option>
                  <option value="99">99</option>
                  <option value="100">100</option>
                </select>
              </div>

              <div className="residence-room">
                <label style={{ display: "block" }} htmlFor="room">
                  Room Details
                </label>
                <div className="room-detail">
                  <div
                    onClick={() =>
                      setPropertyDetails((prev) => ({
                        ...prev,
                        rooms: "1 Rooms",
                      }))
                    }
                    className={`room-design ${
                      propertyDetails.rooms === "1 Rooms" && "selected-room"
                    }`}
                  >
                    1 Rooms
                  </div>
                  <div
                    onClick={() =>
                      setPropertyDetails((prev) => ({
                        ...prev,
                        rooms: "2 Rooms",
                      }))
                    }
                    className={`room-design ${
                      propertyDetails.rooms === "2 Rooms" && "selected-room"
                    }`}
                  >
                    2 Rooms
                  </div>
                  <div
                    onClick={() =>
                      setPropertyDetails((prev) => ({
                        ...prev,
                        rooms: "3 Rooms",
                      }))
                    }
                    className={`room-design ${
                      propertyDetails.rooms === "3 Rooms" && "selected-room"
                    }`}
                  >
                    3 Rooms
                  </div>
                  <select
                    className="room-design"
                    id="room"
                    name="room"
                    onChange={(e) =>
                      setPropertyDetails((prev) => ({
                        ...prev,
                        rooms: e.target.value,
                      }))
                    }
                    value={contractDetails.rentPaymentDate}
                    style={{ width: "auto" }}
                  >
                    <option value="4BHK">4 Rooms</option>
                    <option value="5bhk">5 Rooms</option>
                    <option value="6bhk">6 Rooms</option>
                    <option value="7bhk">7 Rooms</option>
                    <option value="8bhk">8 Rooms</option>
                    <option value="9bhk">9 Rooms</option>
                    <option value="10bhk">10 Rooms</option>
                  </select>
                </div>
              </div>

              <div className="room-sub-details">
                <div className="room-sub-details-first">
                  <div className="room-sub-details-head">Bathroom</div>
                  <div className="bathroom-num">
                    <button>-</button>
                    <span className="room-sub-details-count">3</span>
                    <button>+</button>
                  </div>
                </div>

                <div className="room-sub-details-first">
                  <div className="room-sub-details-head">Balcony</div>
                  <div className="balcony-num">
                    <button>-</button>
                    <span className="room-sub-details-count">3</span>
                    <button>+</button>
                  </div>
                </div>
              </div>

              <div className="other-rooms">
                <div className="other-rooms-head">Other Rooms</div>
                <div className="room-type">
                  <button
                    onClick={() => handleAddOthers("Store Room")}
                    className="each-room"
                    style={{
                      backgroundColor:
                        propertyDetails.others.includes("Store Room") &&
                        "#9A291B",
                    }}
                  >
                    Store Room
                  </button>
                  <button
                    onClick={() => handleAddOthers("Others")}
                    className="each-room"
                    style={{
                      backgroundColor:
                        propertyDetails.others.includes("Others") && "#9A291B",
                    }}
                  >
                    Others
                  </button>
                </div>
              </div>

              <div className="property-square-area">
                <input
                  className="property-square-input"
                  placeholder="Property Area (In Sqft) (Optional)"
                  value={propertyDetails.area}
                  onChange={(e) =>
                    setPropertyDetails((prev) => ({
                      ...prev,
                      area: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="parking-space">
                <div className="parking-space-head">Parking Space</div>

                <div className="parking-space-option">
                  <div parking-space-each-option>
                    <input
                      type="radio"
                      name="parking"
                      checked={propertyDetails.parking}
                      onChange={() =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          parking: true,
                        }))
                      }
                    />
                    Yes
                  </div>
                  <div parking-space-each-option>
                    <input
                      type="radio"
                      name="parking"
                      checked={!propertyDetails.parking}
                      onChange={() =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          parking: false,
                        }))
                      }
                    />
                    No
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <input
              type="checkbox"
              checked={propertyDetails.addressSame}
              onChange={() =>
                setPropertyDetails((prev) => ({
                  ...prev,
                  addressSame: !prev.addressSame,
                }))
              }
            />
            <span style={{marginLeft:"10px"}}>Same as Landlord's Address</span>
          </div>

          {!propertyDetails.addressSame && (
            <div className="rented-property">
              <div className="rented-property-address">
                <input
                  placeholder="Address of Rented Property"
                  value={propertyDetails.address}
                  onChange={(e) =>
                    setPropertyDetails((prev) => ({
                      ...prev,
                      address: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="rented-property-address-two">
                <div className="rented-property-pincode">
                  <input
                    placeholder="Pincode"
                    value={propertyDetails.pincode}
                    onChange={(e) =>
                      setPropertyDetails((prev) => ({
                        ...prev,
                        pincode: e.target.value,
                      }))
                    }
                  />
                </div>

                <div className="rented-property-city-state">
                  <input
                    placeholder="City"
                    value={propertyDetails.city}
                    onChange={(e) =>
                      setPropertyDetails((prev) => ({
                        ...prev,
                        city: e.target.value,
                      }))
                    }
                  />
                  <input
                    placeholder="State"
                    value={propertyDetails.state}
                    onChange={(e) =>
                      setPropertyDetails((prev) => ({
                        ...prev,
                        state: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default FormPropertyDetails;
