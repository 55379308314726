import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/Header';
import { privateReq } from '../../config/axiosConfig';
import ButtonContained from '../../components/Buttons/ButtonContained';
import OutlinedFormInput from '../../components/Input/OutlinedInput';
import axios from 'axios';
import { useSelector } from "react-redux";
import PageNotFound from "../../components/PageNotFound";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

const ReferScheme = () => {
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);

  const [showPage, setShowPage] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user !== null) {
      if (user?.role === "admin") {
        setShowPage(true);
      }
    }
  }, []);

  const [referScheme, setReferScheme] = useState({
    referrerPoints: 0,
    referredPoints: 0,
  });
  useEffect(() => {
    // setHeaderHeight(headerRef.current.offsetHeight);
  }, [headerHeight]);
  const getReferScheme = async () => {
    await axios.get("https://gharwala-5466fddd6458.herokuapp.com/api/get-refer-scheme").then((res) =>{
      // console.log(res);
      const { refer_scheme } =res.data;
      setReferScheme({
        referrerPoints:refer_scheme.referrer_points,
        referredPoints:refer_scheme.referred_points
      })


    }).catch((err) => {
     console.log(err);
    })
  }

  useEffect(()=> {
    getReferScheme();
  },[]);
  const EditReferScheme = (e) => {
    const { name, value } = e.target;
    setReferScheme((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddReferScheme = async () => {
   await axios.post("https://gharwala-5466fddd6458.herokuapp.com/api/update-refer-scheme" ,
{
    referrer_points:referScheme.referrerPoints, referred_points:referScheme.referredPoints
} ).then((res) => {
    toast.success('Data submitted');
}).catch((err) => {
    console.log(err);
    toast.error(err.response.message);
})
    
  
  }

  return showPage ? (
    <>
      <Header innerRef={headerRef} />
      <div style={{ marginTop: '100px', padding: '0 50px' }}>
        <div
          style={{
            fontSize: '30px',
            fontFamily: 'Montserrat',
          }}
        >
          Edit Refer Scheme
        </div>
        <div>
          <div>
            <div style={{ marginLeft: '7px' }}>
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <label>this will be credit to the person who is referring </label>
                <OutlinedFormInput
                  style={{
                    width: '300px',
                  }}
                  type='text'
                  name='referrerPoints'
                  value={referScheme.referrerPoints}
                  onChange={EditReferScheme}
                  placeholder='Referred points'
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <label>this will be credit to the person being reffered </label>
                <OutlinedFormInput
                  style={{
                    width: '300px',
                  }}
                  type='text'
                  name='referredPoints'
                  value={referScheme.referredPoints}
                  onChange={EditReferScheme}
                  placeholder='Referral points'
                />
              </div>
            </div>

            <ButtonContained
              style={{
                width: '150px',
              }}
              onClick={ handleAddReferScheme }
            >
            SUBMIT 
            </ButtonContained>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh"}}>
      <PageNotFound />
      <Link to="/">
        <button>Go To Home</button>
      </Link>
    </div>
  );
};

export default ReferScheme;
