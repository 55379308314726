import React, { useEffect, useState } from "react";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import Carousel from "react-simply-carousel";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { flushSync } from "react-dom";
import toast from "react-hot-toast";
import axios from "axios";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Testimonials = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(
          "https://gharwala-5466fddd6458.herokuapp.com/api/get-all-testimonials"
        );
        console.log(response.data.testimonials);
        setData(response.data.testimonials);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };
    fetchTestimonials();
  }, []);

  return (
    <div
      style={{
        padding: isMobile ? "50px 0" : "50px 100px",
      }}
    >
      <div
        style={{
          fontFamily: "Montserrat",
          textAlign: "center",
          fontSize: isMobile ? "20px" : "30px",
        }}
      >
        Our Happy Customers
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Carousel
          containerProps={{
            style: {
              width: isMobile ? "100%" : "90%",
              justifyContent: "center",
              alignItems: "center",
              userSelect: "none",
              position: "relative",
            },
          }}
          preventScrollOnSwipe
          swipeTreshold={100}
          activeSlideIndex={activeSlide}
          onRequestChange={setActiveSlide}
          forwardBtnProps={{
            children: (
              <FaAngleRight
                style={{
                  fontSize: "40px",
                  color: "white",
                }}
              />
            ),
            style: {
              width: 60,
              height: 60,
              minWidth: 60,
              alignSelf: "center",
              position: "absolute",
              right: "0",
              top: "40%",
              transform: "translateY(-30%)",
              backgroundColor: "lightgray",
              border: "none",
              borderRadius: "50%",
              zIndex: "10",
              display: isMobile ? "none" : "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          }}
          backwardBtnProps={{
            children: (
              <FaAngleLeft
                style={{
                  fontSize: "40px",
                  color: "white",
                }}
              />
            ),
            style: {
              width: 60,
              height: 60,
              minWidth: 60,
              alignSelf: "center",
              position: "absolute",
              left: "0",
              top: "40%",
              transform: "translateY(-30%)",
              backgroundColor: "lightgray",
              border: "none",
              borderRadius: "50%",
              zIndex: "10",
              display: isMobile ? "none" : "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          }}
          dotsNav={{
            show: true,
            itemBtnProps: {
              style: {
                display: !isMobile ? "inline-block" : "none",
                height: 10,
                width: 10,
                borderRadius: "50%",
                border: 0,
                margin: "0 5px",
              },
            },
            activeItemBtnProps: {
              style: {
                display: !isMobile ? "inline-block" : "none",
                height: 10,
                width: 10,
                borderRadius: "50%",
                border: 0,
                background: "lightgray",
                margin: "0 5px",
              },
            },
          }}
          itemsToShow={1}
          speed={400}
          centerMode
        >
          {data.map((item, index) =>
            item.pincode ? null : (
              <>
                <div
                  style={{
                    border: !isMobile ? "10px solid white" : "2px solid white",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    overflowY: "scroll",
                  }}
                >
                  <div
                    key={index}
                    style={{
                      width: isMobile ? "300px" : "800px",
                      padding: "30px",
                      border: "1px solid #9A291B",
                      borderRadius: "15px",
                      position: "relative",
                      margin: "20px",
                      display: "flex",
                      justifyContent: "",
                      flexDirection: "column",
                    }}
                  >
                    <ImQuotesLeft
                      style={{
                        color: "#9A291B",
                        height: "30px",
                        width: "30px",
                        position: "absolute",
                        left: "20px",
                        top: "20px",
                      }}
                    />
                    <div
                      style={{
                        marginTop: "10px",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          marginBottom: "10px",
                        }}
                      >
                        {/* {item.title} */}
                      </div>
                      <div>{item.description}</div>

                      {/* {item.pincode !=== "" && (<div>{item.pincode}</div>)} */}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: isMobile ? "16px" : "22px",
                        marginTop: "20px",
                        fontWeight: "600",
                      }}
                    >
                      {item.name}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "22px",
                        marginTop: "20px",
                        fontWeight: "600",
                      }}
                    >
                      {item.pincode}
                    </div>
                    <ImQuotesRight
                      style={{
                        color: "#9A291B",
                        height: "30px",
                        width: "30px",
                        position: "absolute",
                        right: "20px",
                        bottom: "60px",
                      }}
                    />
                  </div>
                </div>
              </>
            )
          )}
        </Carousel>
      </div>
    </div>
  );
};

export default Testimonials;
