import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { registerService, updateRole } from "../../config/user";
import { privateReq } from "../../config/axiosConfig";

export default function Profession({ user }) {
  const [role, setRole] = useState("broker");
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");

  const updateService = () => {
    console.log("hi");
    privateReq
      .put("/update-service-type", {
        id: user.id,
        serviceType: selectedService,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    privateReq
      .get("/admin-get-services")
      .then((res) => {
        setServices(res.data.services);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [service_type, setService_type] = useState("");
  const [servicesArr, setServicesArr] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await privateReq.get("/admin-get-services");
      setServicesArr(data.services);
    })();
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    let validate = true;
    updateService();
    if (!role) {
      toast("Please select role");
      return;
    } else {
      try {
        const res = await updateRole({
          role,
          service_type,
        });

        if (res.data.status) {
          setRole("");
          setSelectedService("");
          toast.success(res.data.message);
          let timeout = setTimeout(() => {
            // window.location.reload();
            window.location.href = "/add-property";
            clearTimeout(timeout);
          }, 1000);
        }
      } catch (err) {
        console.log("error is ", err);
      }
    }
  };

  return (
    <div style={{ minHeight: "50vh" }} className="container">
      <div className="row g-3 align-items-center">
        <form onSubmit={onSubmit}>
          <div className="col-lg-12 col-md-12 col-sm-12 form-group mb-3">
            <label className="form-label auth-labels">Register As</label>
            <select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="form-control w-100"
            >
              <option value="broker">Broker</option>
              <option value="owner">Owner</option>
              <option value="developer">Developer</option>
              {/* <option value="serviceman">Service Provider</option> */}
            </select>
          </div>

          {role === "serviceman" && (
            <div className="col-lg-12 col-md-12 col-sm-12 form-group mb-3">
              <label className="form-label auth-labels">Service Type</label>
              <select
                value={service_type}
                onChange={(e) => setService_type(e.target.value)}
                className="form-control w-100"
              >
                <option value="">Select Type</option>
                {servicesArr.map((item) => {
                  return (
                    <>
                      <option value={item.service_name}>
                        {item.service_name}
                      </option>
                    </>
                  );
                })}
              </select>
            </div>
          )}
          <div class="col-lg-12 col-md-12 col-sm-12 form-group mb-2">
            <button className="btn-auth-signup ">update</button>
          </div>
        </form>
      </div>
    </div>
  );
}
