import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  Grid,
  TextField,
  FormControlLabel,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../../../styles/styles";
import axios from "axios";
import OutlinedFormInput from "../../../components/Input/OutlinedInput";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material/styles";
import toast from "react-hot-toast";
import { TableRowsTwoTone } from "@mui/icons-material";
import { privateReq } from "../../../config/axiosConfig";
import ButtonContained from "../../../components/Buttons/ButtonContained";

import './style.css';

const AddFurnitureStep2 = ({ handleTextChange, setData, data }) => {
  const [customRoom, setCustomRoom] = useState({
    bed: "",
    bath: "",
    kitchen: "",
  });

  //////
  const [inputFields, setInputFields] = useState([{ key: "", value: "" }]);

  const addInput = () => {
    setInputFields([...inputFields, { key: "", value: "" }]);
  };



  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
  };

  const saveKeyValue = () => {
    handleTextChange("keyValue", inputFields);
    toast.success("Saved Successfully");
  };

  // console.log('type',data.wantto)
  const [categories, setCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [customCategory, setCustomCategory] = useState("");
  const [showCustomType, setShowCustomType] = useState(false);
  const [ShowCustomSubCategory, setShowCustomSubCategory] = useState(false);
  const [categoryType, setCategoryType] = useState("");
  // const fetchCategoryId =  async () =>{
  //   axios.
  // }
  const [ShowCustomCategory, setShowCustomCategory] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [addCustomSubCategoryState, setAddCustomSubCategory] = useState("");
  const theme = useTheme();

  const addCustomCategory = async (name) => {
    const res = await privateReq.post("add-category", {
      name: name,
      category: data.categoryType,
    });
    toast.success("Category Added");
    fetchCategories();
  };

  const addCustomSubcategory = async (name) => {
    const res = await privateReq.post("add-subcategory", {
      name: name,
      category: selectedCategory._id,
    });
    toast.success("Subcategory Added");
    fetchSubCategories(selectedCategory);
  };

  const fetchCategories = async () => {
    axios
      .get(
        `https://gharwala-5466fddd6458.herokuapp.com/api/get-category?type=${
          data.wantto === "rent" ? "rent" : "buy"
        }`
      )
      .then((res) => {
        setCategories(res.data.category);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchSubCategories = async (category) => {
    // console.log("category id", category._id);
    axios
      .post("https://gharwala-5466fddd6458.herokuapp.com/api/get-subcategory", {
        id: category._id,
      })
      .then((res) => {
        setSubCategories(res.data.getSubcategory);
        // console.log(subCategories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log(selectedCategory._id);
  }, [selectedCategory]);

  return (
    <Box
      sx={[
        { marginLeft: "12px", paddingLeft: "20px", paddigRight: "8px" },
        {
          [theme.breakpoints.between("xs", "md")]: [
            { marginLeft: 0, paddingLeft: 0, paddigRight: 0 },
          ],
        },
      ]}
    >
      <Typography
        sx={[
          styles.fs30,
          styles.fontFamilySGB,
          styles.fbBold,
          styles.color240501,
        ]}
      >
        Basic Details
      </Typography>
      <Box sx={[styles.mt40]}>
        <Box className="w-60">
          <Box>
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2"
            >
              Furniture Name
            </Typography>
            <OutlinedFormInput
              placeholder={"Sofa for Sale"}
              onChange={(e) =>
                handleTextChange("furnitureName", e.target.value)
              }
              sx={[
                { height: "initial" },
                {
                  [theme.breakpoints.between("xs", "sm")]: [
                    {
                      maxWidth: "300px",
                      minWidth: "300px",
                    },
                  ],
                },
              ]}
            />
          </Box>
          <Box className="mt-3">
            <Typography
              variant="subtitle1"
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2"
            >
              Category Type
            </Typography>
            <RadioGroup
              value={categoryType}
              onChange={(e) => setCategoryType(e.target.value)}
              name="categoryType"
            >
              {categories.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.name}
                  label={item.name}
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": styles.color9A291B,
                      }}
                    />
                  }
                  onChange={(e) => {
                    handleTextChange("categoryType", e.target.value);
                    fetchSubCategories(item);
                    setSelectedCategory(item);
                  }}
                />
              ))}
              <FormControlLabel
                value="custom"
                label={
                  <Button
                    className="text-capitalize"
                    sx={[styles.fontFamilyDS, styles.fs16, styles.color9A291B]}
                    startIcon={<AddIcon />}
                    variant="text"
                    onClick={() => setShowCustomCategory(!ShowCustomCategory)}
                  >
                    Custom
                  </Button>
                }
                control={<Radio />}
              />
            </RadioGroup>
            {ShowCustomCategory && (
              <Box className="d-flex">
                <Box className="px-2">
                  <Typography
                    sx={[styles.fs14, styles.fontFamilyDS, styles.color240501]}
                    className="mb-2"
                  >
                    category
                  </Typography>
                  <div className="inline-input">
                  <OutlinedFormInput
                    placeholder={"subcategory"}
                    onChange={(e) => {
                      setCustomCategory(e.target.value);
                      handleTextChange("categoryType", e.target.value);
                    }}
                   
                  />
                  <ButtonContained
                    variant="contained"
                   
                    onClick={() => {
                      // handleTextChange("subcategoryType",addCustomSubCategoryState );
                      addCustomCategory(customCategory);
                    }}
                  >
                    Add Category
                  </ButtonContained>
                  </div>
                
                </Box>
              </Box>
            )}

            

            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2"
            >
              Sub Category Type
            </Typography>

            <RadioGroup
              defaultValue=""
              name="sub-category-radio-buttons-group"
              // onChange={(e) => handleTextChange("subCategoryType", e.target.value)}
            >
              {subCategories?.map((item, index) => {
                return (
                  <RadioGroup defaultValue="female" name="radio-buttons-group">
                    <FormControlLabel
                      value={item.name}
                      label={
                        <Typography
                          variant="body2"
                          sx={[
                            styles.fontFamilyDS,
                            styles.fs16,
                            styles.color240501,
                          ]}
                        >
                          {item.name}
                        </Typography>
                      }
                      control={
                        <Radio
                          onChange={(e) => {
                            handleTextChange("subcategoryType", e.target.value);
                            // console.log(data);
                            console.log(data)
                            // fetchSubCategories(item);
                          }}
                          sx={{
                            "&.Mui-checked": styles.color9A291B,
                          }}
                        />
                      }
                    />
                  </RadioGroup>
                );
              })}
              <FormControlLabel
                value="custom"
                label={
                  <Button
                    className="text-capitalize"
                    sx={[styles.fontFamilyDS, styles.fs16, styles.color9A291B]}
                    startIcon={<AddIcon />}
                    variant="text"
                    onClick={() =>
                      setShowCustomSubCategory(!ShowCustomSubCategory)
                    }
                  >
                    Custom
                  </Button>
                }
                control={<Radio />}
              />
            </RadioGroup>

            <Box>
              {ShowCustomSubCategory && (
                <Box className="d-flex">
                  <Box className="px-2">
                    <Typography
                      sx={[
                        styles.fs14,
                        styles.fontFamilyDS,
                        styles.color240501,
                      ]}
                      className="mb-2"
                    >
                      Sub category
                    </Typography>
                    <div className="inline-input">
                      <OutlinedFormInput
                        placeholder={"subcategory"}
                        onChange={(e) => {
                          setAddCustomSubCategory(e.target.value);
                          handleTextChange("subcategoryType", e.target.value);
                        }}
                        onClick={() => {
                          // handleTextChange("subcategoryType", "");
                        }}
                      />

                      <ButtonContained
                        onClick={() => {
                          addCustomSubcategory(addCustomSubCategoryState);
                        }}
                      >
                        Add Subcategory
                      </ButtonContained>
                    </div>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          {/* <Box className="mt-3">
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2"
            >
              Furniture Type
            </Typography>
            <RadioGroup defaultValue="female" name="radio-buttons-group" >
              <FormControlLabel
                value="sofa"
                label={
                  <Typography
                    variant="body2"
                    sx={[styles.fontFamilyDS, styles.fs16, styles.color240501]}
                  >
                    Sofa
                  </Typography>
                }
                control={
                  <Radio
                    onChange={(e) =>
                      handleTextChange("furnitureType", e.target.value)
                    }
                    sx={{
                      "&.Mui-checked": styles.color9A291B,
                    }}
                  />
                }
              />
              <FormControlLabel
                value="bed"
                label={
                  <Typography
                    variant="body2"
                    sx={[styles.fontFamilyDS, styles.fs16, styles.color240501]}
                  >
                    Bed
                  </Typography>
                }
                control={
                  <Radio
                    onChange={(e) =>
                      handleTextChange("furnitureType", e.target.value)
                    }
                    sx={{
                      "&.Mui-checked": styles.color9A291B,
                    }}
                  />
                }
              />
              <FormControlLabel
                value="fridge"
                label={
                  <Typography
                    variant="body2"
                    sx={[styles.fontFamilyDS, styles.fs16, styles.color240501]}
                  >
                    Fridge
                  </Typography>
                }
                control={
                  <Radio
                    onChange={(e) =>
                      handleTextChange("furnitureType", e.target.value)
                    }
                    sx={{
                      "&.Mui-checked": styles.color9A291B,
                    }}
                  />
                }
              />
              <FormControlLabel
                value="washing-machine"
                label={
                  <Typography
                    variant="body2"
                    sx={[styles.fontFamilyDS, styles.fs16, styles.color240501]}
                  >
                    Washing Machine
                  </Typography>
                }
                control={
                  <Radio
                    onChange={(e) =>
                      handleTextChange("furnitureType", e.target.value)
                    }
                    sx={{
                      "&.Mui-checked": styles.color9A291B,
                    }}
                  />
                }
              />
              <FormControlLabel
                value="microwave"
                label={
                  <Typography
                    variant="body2"
                    sx={[styles.fontFamilyDS, styles.fs16, styles.color240501]}
                  >
                    Microwave
                  </Typography>
                }
                control={
                  <Radio
                    onChange={(e) =>
                      handleTextChange("furnitureType", e.target.value)
                    }
                    sx={{
                      "&.Mui-checked": styles.color9A291B,
                    }}
                  />
                }
              />
            </RadioGroup>
            <Box></Box>
            <Button
              className="text-capitalize mb-2"
              sx={[styles.fontFamilyDS, styles.fs16, styles.color9A291B]}
              startIcon={<AddIcon />}
              variant="text"
              onClick={() => setShowCustomType(!showCustomType)}
            >
              Custom
            </Button>
            {showCustomType && (
              <>
                <Typography
                  sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
                  className="mb-2"
                >
                  Furniture 
                </Typography>
                <Box className="d-flex">
                  <Box className="px-2">
                    <Typography
                      sx={[
                        styles.fs14,
                        styles.fontFamilyDS,
                        styles.color240501,
                      ]}
                      className="mb-2"
                    >
                      Type
                    </Typography>
                    <OutlinedFormInput
                      placeholder={"2 ft"}
                      onChange={(e) => {
               
                        handleTextChange("furnitureType", e.target.value);
                      }}
                    />
                  </Box>
                  <Box className="px-2">
                    <Typography
                      sx={[
                        styles.fs14,
                        styles.fontFamilyDS,
                        styles.color240501,
                      ]}
                      className="mb-2"
                    >
                      Length
                    </Typography>
                    <OutlinedFormInput
                      placeholder={"2 ft"}
                      onChange={(e) => {
               
                        handleTextChange("furnitureLength", e.target.value);
                      }}
                    />
                  </Box>
                  <Box className="px-2">
                    <Typography
                      sx={[
                        styles.fs14,
                        styles.fontFamilyDS,
                        styles.color240501,
                      ]}
                      className="mb-2"
                    >
                      Breadth
                    </Typography>
                    <OutlinedFormInput
                      placeholder={"2 ft"}
                      onChange={(e) => {
                   
                        handleTextChange("furnitureWidth",e.target.value);
                      }}
                    />
                  </Box>
                  <Box className="px-2">
                    <Typography
                      sx={[
                        styles.fs14,
                        styles.fontFamilyDS,
                        styles.color240501,
                      ]}
                      className="mb-2"
                    >
                      Height
                    </Typography>
                    <OutlinedFormInput
                      placeholder={"2 ft"}
                      onChange={(e) => {
             
                        handleTextChange("furnitureHeight", e.target.value);
                      }}
                    />
                  </Box>
                </Box>
              </>
            )}
          </Box> */}
        </Box>

        {/* <Box className="mt-3">
          <Typography
            sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
            className="mb-2"
          >
            Color Availablity
          </Typography>
          <OutlinedFormInput
            placeholder={"brown, black, white"}
            onChange={(e) => handleTextChange("color", e.target.value)}
            sx={[
              { height: "initial" },
              {
                [theme.breakpoints.between("xs", "sm")]: [
                  {
                    maxWidth: "300px",
                    minWidth: "300px",
                  },
                ],
              },
            ]}
          />
        </Box>
        <Box className="mt-3">
          <Typography
            sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
            className="mb-2"
          >
            Material
          </Typography>
          <OutlinedFormInput
            placeholder={"material"}
            onChange={(e) => handleTextChange("material", e.target.value)}
            sx={[
              { height: "initial" },
              {
                [theme.breakpoints.between("xs", "sm")]: [
                  {
                    maxWidth: "300px",
                    minWidth: "300px",
                  },
                ],
              },
            ]}
          />
        </Box>

        <Box className="mt-3">
          <Typography
            sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
            className="mb-2"
          >
            Feature & Specs
          </Typography>
          <OutlinedFormInput
            placeholder="write here.."
            // sx={[{ height: "initial" }]}
            rows={4}
            multiline={true}
            onChange={(e) => handleTextChange("features", e.target.value)}
            sx={[
              { height: "initial" },
              {
                [theme.breakpoints.between("xs", "sm")]: [
                  {
                    maxWidth: "300px",
                    minWidth: "300px",
                  },
                ],
              },
            ]}
          />
        </Box> */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "12" }}>
          <Typography gutterBottom>
            Key Value Pairs
          </Typography>
          {inputFields.map((input, index) => (
            <Grid key={index} container spacing={2} alignItems="center">
              <Grid item>
                <TextField
                className="mt-10"
                  name="key"
                  placeholder="Item Name"
                  value={input.key}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </Grid>
              <Grid item>
                <TextField
                className="mt-10"
                  name="value"
                  placeholder="Item Value"
                  value={input.value}
                  type="string"
                  onChange={(e) => handleInputChange(index, e)}
                />
              </Grid>
            </Grid>
          ))}
          <Box
            style={{
              display: "flex",
              width: "40%",
              justifyContent: "space-between",
            }}
          >
            <Button variant="contained" className="mt-10" onClick={addInput}>
              Add
            </Button>
            <Button variant="contained" color="success" className="mt-10" onClick={saveKeyValue}>
              Save
            </Button>
          </Box>
        </Box>
     
      </Box>
    </Box>
  );
};

export default AddFurnitureStep2;
