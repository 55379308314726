  import { useEffect, useState } from "react";
  import Header from "../../components/Header";
  import {
  addWishlistService,
  getMyWishlistService,
    removeFromWishlistService
  } from "../../config/furniture";
  import { addToCartService } from "../../config/furniture";
  import ButtonContained from "../../components/Buttons/ButtonContained";
  import { buyPhonePe } from "../../config/user";
  import toast from "react-hot-toast";
  import { FaTrash } from "react-icons/fa";
  import { useMediaQuery } from "react-responsive";
  import { privateReq } from "../../config/axiosConfig";
  import { useSelector } from "react-redux";
  import { BottomSheet } from "react-spring-bottom-sheet";
  import SignUp from "../../components/Signup";
  import ModalSignUp from "../../components/ModalSignup";
  import Login from "../../components/Login";
  import ModalLogin from "../../components/ModalLogin";
  import FurnitureAddress from "../checkout/FurnitureAddress";
  import publicReq from "../../config/axiosConfig";
import NotFound from "./not_found";

  const Wishlist = ({ user }) => {
    const userData = useSelector((state) => state.auth);
    const [items, setItems] = useState([]);
    const [totalAmount, setTotalAmount] = useState();
    const [loader, setLoader] = useState(false);
    const [cost,setCost ] = useState(0);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [usePoints, setUsePoints] = useState(false);
    const [furnitureDetails,setFurnitureDetails] =  useState([]);
    const [updatedReferralPoints, setUpdatedReferralPoints] = useState(
      user?.referral_points
    );
    const [deliveryAddress, setDeliveryAddress] = useState({
      address: "",
      house_no: "",
      street: "",
      city: "",
      state: "",
      pinCode: "",
    });
    const [carryingCost,setCarryingCost]= useState([]);
    const [deliveryAddressModal, setDeliveryAddressModal] = useState(false);
    const [closeModal, setCloseModal] = useState(false);
    const [closeLoginModal, setCloseLoginModal] = useState(false);

    const [monthlyRent, setMonthlyRent] = useState(0)
    const [securityDeposit, setSecurityDeposit] = useState(0)

    useEffect(() => {
      checkCart();
      calcTotalAmount();
    }, [userData?.user]);

    useEffect(() => {
      calcTotalAmount();
    }, [items]);


  //   const onCreateBooking = async () => {
  //     try {
  //       // if (user === null) {
  //       //   setCloseLoginModal(true);
  //       //   return;
  //       // }
  //       if (furnitureDetails?.sellingOptions === "rent" && !bookingPeriod) {
  //         toast.error("Please select rental period", {
  //           position: "top-right",
  //         });
  //         return;
  //       }

  //       if (user === null) {
  //         let data = {
  //           _id: null,
  //           expired: false,
  //           periodNeeded: bookingPeriod,
  //           created: new Date(),
  //           user: null,
  //           furniture: furnitureDetails,
  //           image: furnitureDetails?.furnitureImages[0],
  //         };

  //         let cartData = localStorage.getItem("cartData");
  //         if (cartData) {
  //           let mainData = [...JSON.parse(cartData), data];
  //           localStorage.setItem("cartData", JSON.stringify(mainData));
  //         } else {
  //           localStorage.setItem("cartData", JSON.stringify([data]));
  //         }
  //         toast.success("Added to cart!");

  //         return;
  //       }

  //       const res = await addToCartService({
  //         furnitureId: furnitureDetails?._id,
  //         period: bookingPeriod,
  //       });
  //       if (res.data.status) {
  //         toast.success("Added to cart!");
  //         // setBookingPeriod("");
  //         // setHideBookingDialog(true);
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };
  

    useEffect(() => {
      if(items.length== 0){
        setCost(0);
      }
      if (items.length > 0 && items.length <= 5) {
    
      setCost(carryingCost[`product${items.length}`])
      //  console.log(carryingCost[`product${items.length}`]);
      }
      else if(items.length >5){
        setCost(350);
      }

    }, [items]);

    const checkCart = async () => {
      if (!userData?.user) {
        setItems(
          JSON.parse(localStorage.getItem("cartData"))
            ? JSON.parse(localStorage.getItem("cartData"))
            : []
        );
      } else {
        let cartItem = JSON.parse(localStorage.getItem("cartData"))
          ? JSON.parse(localStorage.getItem("cartData"))
          : [];

        let itemsToAdd = cartItem.map((item) => ({
          furnitureId: item.furniture._id,
          period: item.periodNeeded,
        }));

        console.log("itemsToAdd total ", itemsToAdd);
        if (itemsToAdd.length !== 0) {
          await addWishlistService(itemsToAdd);
        }
        getItems();
      }
    };

    const getItems = async () => {
      // console.log('hi there')
      const { data } = await getMyWishlistService();
      console.log('data is ' , data)
      setItems(data.bookings);
      
      console.log(data.bookings);
      localStorage.removeItem("cartData");
    };

    const handleRemove = async (id, index) => {

        try {
        const data =  await removeFromWishlistService(`?furnitureId=${id}`);
          console.log(data,'hi')
   
        } catch (error) {
          console.log(error);
        }
        finally {
          getItems();
        }
      
    };

    const calcTotalAmount = () => {
      const parseAndRound = (value) => {
        return parseFloat(parseFloat(value).toFixed(2));
      };

      setMonthlyRent(items.reduce((acc, it) => acc + parseAndRound(it.furniture.threeMonthPayment.threeMonthPayment), 0));

      setSecurityDeposit(items.reduce((acc, it) => acc + parseAndRound(it.furniture.expectedSecurityDeposit), 0));

      setTotalAmount(() =>
        items.reduce(
          (acc, it) =>
            // acc +
            // it.furniture.firstTimePayment +
            // it.furniture.expectedSecurityDeposit,
            acc +
            parseAndRound(it.furniture.expectedSecurityDeposit) +
            parseAndRound(it.furniture.threeMonthPayment.threeMonthPayment),
          0
        )
      );
    };

    const getCost =()=>{
      publicReq.get('/carryingCost').then((res)=>{
          console.log(res);
          const {__v,_id,...rest} = res.data;
          setCarryingCost(rest);
      }).catch((err)=>{
          console.log(err);
      })
  }

  useEffect(()=>{
      getCost();
  },[])

    const updateReferral = () => {
      privateReq
        .post("/update-referal-points", {
          points: updatedReferralPoints,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    function calculateAmount(usePoints, referralPoints) {
      let calculatedAmount;

      if (usePoints) {
        calculatedAmount =
          totalAmount + totalAmount * 0.18 <= referralPoints
            ? 0
            : totalAmount + totalAmount * 0.18 - referralPoints;
      } else {
        calculatedAmount = totalAmount + totalAmount * 0.18;
      }
      // console.log(calculatedAmount -cost ,'cost ');
      return calculatedAmount + cost ;
    }

    const createSession = async () => {
      if (!userData?.user) {
        setCloseLoginModal(true);
        return;
      }
      updateReferral();
      try {
        setLoader(true);

        const res = await buyPhonePe({
          amount:
            calculateAmount(usePoints, user?.referral_points) === 0
              ? 1
              : calculateAmount(usePoints, user?.referral_points),
          MUID: "MUID" + Date.now(),
          furnitureId: items?.map((i) => {
            return {
              _id: i.furniture._id,
              period: i.periodNeeded,
            };
          }),
          transactionId: "T" + Date.now(),
          address: deliveryAddress,
        });

        if (res.data.status) {
          window.location.href = res.data.url;
          setLoader(false);
        }
      } catch (e) {
        console.log(e);
        toast.error("Sorry, something went wrong!");
        setLoader(false);
      }
    };
    

    return (
      <div>
        <Header />
    {
      items.length === 0? (<NotFound item={'Wishlisted items'} showText={true}/>):(    <div
        style={{
          width: "90%",
          margin: "100px auto 0 auto",
        }}
      >
        <div
          style={{
            fontFamily: "Montserrat",
            fontSize: "40px",
            color: "#9A291B",
          }}
        >
          Wishlist
        </div>
        <div
          style={{
            display: !isMobile && "flex",
            alignItems: "start",
            gap: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              flex: "1",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <div
              style={{
                fontSize: "24px",
              }}
            >
              Items
            </div>
            {items?.map((it, index) => (
              <div
                style={{
                  border: "1px solid lightgray",
                  padding: "10px",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    display: !isMobile && "flex",
                    justifyContent: "start",
                    gap: "10px",
                  }}
                >
                  <img
                    style={{
                      width: isMobile ? "100%" : "200px",
                      borderRadius: "20px",
                      height: "150px",
                    }}
                    src={it.image}
                  />
                  <div
                    style={{
                      marginLeft: !isMobile && "20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "22px",
                        fontWeight: "700",
                      }}
                    >
                      {it.furniture.furnitureName}
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        display: !isMobile && "flex",
                        justifyContent: "center",
                        alignItems: "start",
                        gap: "30px",
                      }}
                    >
                      {/* <div
                        style={{
                          border: "1px solid lightgray",
                          padding: "10px",
                          flex: "1",
                          borderRadius: "20px",
                          marginBottom: isMobile && "10px",
                        }}
                      >
                        <div>Payable Now: ₹{totalAmount}</div>
                        <div>
                          Product Type:{" "}
                          {it.furniture.expectedSecurityDeposit
                            ? "Rent"
                            : "Buy"}
                        </div>
                      </div> */}

                      {/* {it.furniture.expectedSecurityDeposit && (
                        <div
                          style={{
                            border: "1px solid lightgray",
                            padding: "10px",
                            flex: "1",
                            borderRadius: "20px",
                          }}
                        >
                          <div>
                            Security Deposit: ₹
                            {it.furniture.expectedSecurityDeposit}
                          </div>
                          <div>
                            {it.periodNeeded === 9
                              ? "Nine Month"
                              : it.periodNeeded === 6
                                ? "Six Month"
                                : it.periodNeeded === 3
                                  ? "Monthly"
                                  : "Twelve Month"}{" "}
                            Rent: ₹
                            {it.periodNeeded === 9
                              ? it.furniture.nineMonthPayment.nineMonthPayment
                              : it.periodNeeded === 6
                                ? it.furniture.sixMonthPayment.sixMonthPayment
                                : it.periodNeeded === 3
                                  ? it.furniture.threeMonthPayment.threeMonthPayment
                                  : it.furniture.twelveMonthPayment
                                    .twelveMonthPayment}
                          </div>
                          <div
                            style={{
                              margin: "5px 0",
                              height: "1px",
                              width: "100%",
                              backgroundColor: "lightgray",
                            }}
                          />
                          <div>
                            ** Not to be paid now. Pay pre usage every month.
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginRight: "10px",
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                  }}
                >
                  <ButtonContained
                    sx={[
                      {
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      },
                    ]}
                    onClick={() => handleRemove(it.furniture._id, index)}
                  >
                    <FaTrash />
                    Remove
                  </ButtonContained>
                  <ButtonContained
                    sx={[
                      {
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      },
                    ]}
                    onClick={() => handleRemove(it.furniture._id, index)}
                  >
                    <FaTrash />
                    Add to Cart
                  </ButtonContained>
                </div>
              </div>
            ))}
          </div>

        </div>
      </div>)
    }

      </div>
    );
  };

  export default Wishlist;
