import Modal from "react-modal";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import toast from "react-hot-toast";
import { Cancel } from "@mui/icons-material";

export default function FurnitureAddress({
  modalVisible,
  setModalVisible,
  deliveryAddress,
  setDeliveryAddress,
  onSubmit = () => {},
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleDeliveryAddressChange = (e) => {
    const { name, value } = e.target;
    setDeliveryAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const onSubmitKyc = async (e) => {
    e.preventDefault();
    if (!deliveryAddress.address) {
      toast.error("Please enter address");
    } else if (!deliveryAddress.house_no) {
      toast.error("Please enter house no.");
    } else if (!deliveryAddress.street) {
      toast.error("Please enter street");
    } else if (!deliveryAddress.city) {
      toast.error("Please enter city");
    } else if (!deliveryAddress.state) {
      toast.error("Please enter state");
    } else if (!deliveryAddress.pinCode) {
      toast.error("Please enter pinCode");
    } else {
      setModalVisible(false);
      onSubmit();
    }
  };

  return (
    <Modal
      isOpen={modalVisible}
      style={!isMobile ? customStyles : customStylesMobile}
      contentClassName="custom-modal"
    >
      <div className="row g-3 align-items-center">
        <div style={{
          display:"flex",
          justifyContent:"space-between"
        }}>
        <h2
          style={{
            color: "#240501",
            fontWeight: "bold",
            fontSize: "30px",
            textAlign: "center",
          }}
        >
          Delivery Address
        </h2>
        <p style={{cursor:"pointer", fontWeight:"bold"}} onClick={()=>setModalVisible(false)}>
          <Cancel />
        </p>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <form onSubmit={onSubmitKyc}>
            <div className="col-sm-12 form-group div-profile-modal mb-3">
              <div className={!isMobile ? "col-2" : "col-sm-12"}>
                <label
                  className="col-form-label profile-labels-modal"
                  htmlFor="address"
                >
                  Address
                </label>
              </div>
              <div className={!isMobile ? "col-8" : "col-sm-12"}>
                <input
                  id="address"
                  className="form-control form-control-profile"
                  name="address"
                  placeholder="Address"
                  value={deliveryAddress.address}
                  onChange={handleDeliveryAddressChange}
                />
              </div>
            </div>
            <div className="col-sm-12 form-group div-profile-modal mb-3">
              <div className={!isMobile ? "col-2" : "col-sm-12"}>
                <label
                  className="col-form-label profile-labels-modal"
                  htmlFor="house_no"
                >
                  House No.
                </label>
              </div>
              <div className={!isMobile ? "col-8" : "col-sm-12"}>
                <input
                  id="house_no"
                  className="form-control form-control-profile"
                  name="house_no"
                  placeholder="House No."
                  value={deliveryAddress.house_no}
                  onChange={handleDeliveryAddressChange}
                />
              </div>
            </div>
            <div className="col-sm-12 form-group div-profile-modal mb-3">
              <div className={!isMobile ? "col-2" : "col-sm-12"}>
                <label
                  className="col-form-label profile-labels-modal"
                  htmlFor="house_no"
                >
                  Street
                </label>
              </div>
              <div className={!isMobile ? "col-8" : "col-sm-12"}>
                <input
                  id="house_no"
                  className="form-control form-control-profile"
                  name="street"
                  placeholder="Street"
                  value={deliveryAddress.street}
                  onChange={handleDeliveryAddressChange}
                />
              </div>
            </div>

            <div className="col-sm-12 form-group div-profile-modal mb-3">
              <div className={!isMobile ? "col-2" : "col-sm-12"}>
                <label
                  className="col-form-label profile-labels-modal"
                  htmlFor="house_no"
                >
                  City
                </label>
              </div>
              <div className={!isMobile ? "col-8" : "col-sm-12"}>
                <input
                  id="city"
                  className="form-control form-control-profile"
                  name="city"
                  placeholder="City"
                  value={deliveryAddress.city}
                  onChange={handleDeliveryAddressChange}
                />
              </div>
            </div>

            <div className="col-sm-12 form-group div-profile-modal mb-3">
              <div className={!isMobile ? "col-2" : "col-sm-12"}>
                <label
                  className="col-form-label profile-labels-modal"
                  htmlFor="house_no"
                >
                  State
                </label>
              </div>
              <div className={!isMobile ? "col-8" : "col-sm-12"}>
                <input
                  id="state"
                  className="form-control form-control-profile"
                  name="state"
                  placeholder="State"
                  value={deliveryAddress.state}
                  onChange={handleDeliveryAddressChange}
                />
              </div>
            </div>

            <div className="col-sm-12 form-group div-profile-modal mb-3">
              <div className={!isMobile ? "col-2" : "col-sm-12"}>
                <label
                  className="col-form-label profile-labels-modal"
                  htmlFor="house_no"
                >
                  PinCode
                </label>
              </div>
              <div className={!isMobile ? "col-8" : "col-sm-12"}>
                <input
                  id="pinCode"
                  className="form-control form-control-profile"
                  name="pinCode"
                  placeholder="PinCode"
                  value={deliveryAddress.pinCode}
                  onChange={handleDeliveryAddressChange}
                />
              </div>
            </div>

            {/* <div className="col-sm-12 form-group div-profile-modal mb-3">
              <div className={!isMobile ? "col-2" : "col-sm-12"}>
                <label
                  className="col-form-label profile-labels-modal"
                  htmlFor="aadhar"
                >
                  Aadhar Number
                </label>
              </div>
              <div className={!isMobile ? "col-8" : "col-sm-12"}>
                <input
                  id="aadhar"
                  className="form-control form-control-profile"
                  name="aadhar"
                  placeholder="Aadhar Number"
                  value={deliveryAddress.aadhar}
                  onChange={handleDeliveryAddressChange}
                />
              </div>
            </div> */}

            <button
              type="submit"
              style={{
                width: "100%",
                marginTop: "20px",
                padding: "10px 0",
                borderRadius: "20px",
                border: "none",
                backgroundColor: "#9A291B",
                color: "white",
                fontWeight: "700",
                fontSize: "20px",
              }}
            >
              {"Submit"}
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
}

const customStyles = {
  Modal: {
    borderRadis: "25px",
    overlay: "hidden",
  },
  customModal: {
    border: "none",
  },
  overlay: {
    position: "fixed",
    inset: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 9999,
    left: 0,
    alignItems: "center",
    display: "flex",
  },
  content: {
    maxWidth: "30%",
    minWidth: "30%",
    height: "65%",
    margin: "0 auto",
    borderRadius: "20px",
  },
};
const customStylesMobile = {
  ReactModal__Content: {
    border: "none",
  },
  customModal: {
    border: "none",
    marginTop: "100px",
  },
  overlay: {
    position: "fixed",
    inset: 0,
    backgroundColor: "#FFFFFF",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 9999,
    left: 0,
  },
  content: {
    maxWidth: "100%",
    border: "none",
    margin: "0 auto",
    marginTop: "75px",
  },
};
