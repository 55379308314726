// import { makeStyles } from '@mui/styles';

export default {
  toolBar: {
    height: '10vh',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    backgroundColor: 'white',
  },
  logo: {
    color: 'blue',
    cursor: 'pointer',
  },
  link: {
    color: '#000',
  },
  menuIcon: {
    color: '#000',
  },
  formContainer: {
    flexGrow: 1,
    padding: '10px',
    maxWidth: '700px',
    margin: '30px auto',
    // [theme.breakpoints.between('xs', 'sm')]: {
    //     width: '100%',
    // },
  },
  form: {
    marginTop: '30px',
  },
  formHeading: {
    textAlign: 'center',
  },
  heroBox: {
    width: '100%',
    display: 'flex',
    minHeight: '600px',
    // minHeight: '500px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F4F4F4',
    margin: 'auto',
    marginTop: '100px',
    flex: 1,
  },
  heroBoxMobile: {
    width: '95%',
    display: 'flex',
    minHeight: '600px',
    // minHeight: '500px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F4F4F4',
    margin: 'auto',
    borderRadius: '32px',
    marginTop: '100px',
    flex: 1,
    paddingBottom: '15px',
  },
  aboutBox: {
    width: '97%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '600px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    margin: 'auto',
    borderRadius: '32px',
    marginTop: '80px',
  },
  gridContainer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '1300px',
    padding: '20px 0',
    // marginTop: '-72px'1
  },
  gridContainerMobile: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '1300px',
    padding: '57px 13px 8px 13px',
    marginTop: '-10px!imporatnt',
    marginLeft: '0px!important',
  },
  aboutUsContainer: {
    width: '100%',
    display: 'flex',
    minHeight: '400px',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '30px 0px 50px 0px',
  },
  aboutUsSubtitle: {
    opacity: '0.7',
    paddingBottom: '30px',
    fontSize: '18px',
  },
  title: {
    paddingBottom: '15px',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '3rem',
    display: 'flex',
    alignItems: 'flex-end',
    letterSpacing: '-1.5px',
    color: '#fff',
  },
  titleSmXs: {
    fontSize: '29px',
    padding: '5px 0px',
    lineHeight: '40px',
  },
  subtitle: {
    opacity: '1',
    paddingBottom: '30px',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '26px',
    display: 'flex',
    alignItems: 'flex-end',
    color: '#fff',
  },
  subtitlesmXs: {
    fontSize: '17px',
    padding: '5px 0px',
    opacity: 1,
    lineHeight: '19px',
  },
  titleAbout: {
    textTransform: 'uppercase',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '3rem',
    color: '#9A291B',
    letterSpacing: '3px',
  },
  titleAboutSmXs: {
    fontSize: '15px',
  },
  subtitleAbout: {
    // opacity: '0.4',
    paddingBottom: '30px',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '40px',
    lineHeight: '3rem',
    color: '#240501',
  },
  subtitleAboutXsSm: {
    fontSize: '18px',
    lineHeight: '1.5rem',
  },
  displayNone: {
    display: 'none',
  },

  descAbout: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '147.1%',
    color: ' #5B5B5B',
  },
  descAboutSmXS: {
    fontSize: '12px',
    lineHeight: '1.25rem',
  },
  padding0: {
    padding: '0px!important',
  },
  aboutRightItem: {
    paddingTop: '0px',
  },
  largeImage: {
    width: '100%',
  },
  sectionGridContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: '500px',
  },
  sectionGridItem: {
    backgroundColor: '#f2f0f1',
    textAlign: 'center',
    padding: '30px',
    width: '200px',
    borderRadius: '10px',
    margin: '10px ',
  },
  inputField: {
    marginBottom: '20px ',
  },
  textArea: {
    width: '100%',
    marginBottom: '20px',
    fontSize: '16px',
    padding: '10px',
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    miHeight: '10vh',
    padding: '20px',
    justifyContent: 'center',
    backgroundColor: '#f2f0f1',
    flexDirection: 'column',
  },
  footerText: {
    paddingBottom: '10px',
  },
  footerDate: {
    opacity: '0.4',
  },
  testimonialCard: {
    backgroundColor: '#fff',
    padding: '10px',
    minHeight: '200px',
    display: 'flex',
    alignItems: 'center',
  },
  testimonialStatement: {
    paddingBottom: '25px',
  },
  avatar: {
    marginRight: '10px',
  },
  testimonialPosition: {
    fontSize: '14px',
    opacity: '0.6',
  },
  searchBarContainer: {
    margin: '0 auto',
    width: '100%',
    marginTop: '0px!important',
    // padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '0 16px 16px 16px',
    padding: '20px',
    height: '120px',
    '@media(minWidth: 768px)': {
      height: '1px',
      display: 'flex',
    },
  },
  searchBarContainerMobile: {
    margin: 0,
    width: '100%',
    marginTop: '0px!important',
    // padding: '10px',
    // backgroundColor: "#fff",
    borderRadius: '0',
    paddingLeft: '0px!important',
    paddingRight: '0px!important',
    '@media(minWidth: 768px)': {
      height: '1px',
    },
  },
  searchBarHeadings: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '31px',
    display: 'flex',
    alignItems: 'center',
    color: '#9A291B',
  },
  selectContainer: {
    // width: '15%',
    height: '5%',
    textTransform: 'capitalize',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '31px',
    display: 'flex',
    alignItems: 'center',
    color: '#9A291B',
    '&:hover': {
      backgroundColor: 'rgba(154, 41, 27, 0.1)',
    },
  },
  selectContainer: {
    // width: '15%',
    height: '5%',
    textTransform: 'capitalize',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '20px',
    lineSeight: '31px',
    display: 'flex',
    alignItems: 'center',
    color: '#9A291B',
    '&:hover': {
      backgroundColor: 'rgba(154, 41, 27, 0.1)',
    },
  },
  selectContainerSm: {
    // width: '15%',
    height: '5%',

    fontSize: '15px',

    '&:hover': {
      backgroundColor: 'rgba(154, 41, 27, 0.1)',
    },
  },
  selectContainerESm: {
    // width: '15%',
    height: '5%',

    fontSize: '11px',

    '&:hover': {
      backgroundColor: 'rgba(154, 41, 27, 0.1)',
    },
  },
  selectContainerMobile: {
    // width: '15%',
    height: '5%',
    textTransform: 'capitalize',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '15px',
    lineSeight: '31px',
    display: 'flex',
    alignItems: 'center',
    color: '#9A291B',
    '&:hover': {
      backgroundColor: 'rgba(154, 41, 27, 0.1)',
    },
  },
  homeSelectSearch: {
    color: 'rgba(0, 0, 0, 0.87)',
    '.MuiOutlinedInput-notchedOutline': { border: 0 },
    '.MuiOutlinedInput-input': {
      padding: '0 6px',
      color: '#979797',
      fontSize: '16px',
      fontFamily: 'Montserrat',
      marginLeft: '3px',
    },
  },
  defualtMarronButton: {
    display: 'inline-block',
    margin: '7px',
    backgroundColor: '#9A291B',
    borderRadius: '8.25px',
    width: '50px',
    height: '50px',
    '&:hover': {
      backgroundColor: 'rgba(154, 41, 27, 0.8)',
    },
  },
  defualtMarronButtonSMMD: {
    display: 'inline-block',
    margin: '7px',
    backgroundColor: '#9A291B',
    borderRadius: '8.25px',
    width: '30px',
    height: '30px',
    '&:hover': {
      backgroundColor: 'rgba(154, 41, 27, 0.8)',
    },
  },
  defualtMarronButtonESM: {
    display: 'inline-block',
    margin: '7px',
    backgroundColor: '#9A291B',
    borderRadius: '8.25px',
    width: '50px',
    height: '50px',
    '&:hover': {
      backgroundColor: 'rgba(154, 41, 27, 0.8)',
    },
  },
  searcButtons: {
    backgroundColor: '#9A291B',
    marginBottom: 0,
    padding: '15px',
    textTransform: 'capitalize',
    fontFamily: 'Montserrat',
    fontSize: '24px',
    color: '#fff',
    borderRadius: '0px',
    '&:hover': {
      backgroundColor: '#9A291B',
      color: '#fff',

      // border: '2px solid #fff',
      // borderColor: "green",
    },
    // "&:focus": {
    //   border: "1px solid #000000",
    // },
  },

  searcButtonsEsm: {
    fontSize: '15px',
    padding: '10px',
  },
  searcButtonsMobiles: {
    backgroundColor: '#9A291B',
    marginBottom: 0,

    padding: '15px',
    width: '50%',
    textTransform: 'capitalize',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#9A291B',
      color: '#fff',
      border: '2px solid #fff',
    },
    // "&:focus": {
    //   border: "1px solid #000000",
    // },
  },
  searcButtonsProperties: {
    backgroundColor: '#fff',
    color: 'rgba(151, 151, 151, 1)',
    // borderColor: '1px solid #fff',
  },
  outlineInput: {
    borderRadius: '12px',
    border: 'none',
    height: '40px',
    fontFamily: 'Montserrat-Regular',
    fontSize: '16px',
    width: '100%',
  },
  outlineTextArea: {
    borderRadius: '12px',
    fontFamily: 'Montserrat-Regular',
    fontSize: '16px',
    width: '100%',
    padding: 5,
  },
  searcButtonsPropertiesMobile: {
    backgroundColor: '#fff',
    color: 'rgba(151, 151, 151, 1)',
    // borderColor: '1px solid #fff',
  },
  getListedBtn: {
    width: ' 140px',
    height: '48px',
  },
  bg9A291B: {
    backgroundColor: '#9A291B',
  },
  bgWhite: {
    backgroundColor: '#fff',
  },
  borderColorADB0B6: {
    borderColor: '#ADB0B6',
  },
  borderRadius0: {
    borderRadius: '0px',
  },
  borderRadius5: {
    borderRadius: '5px',
  },
  borderRadius12: {
    borderRadius: '12px',
  },
  borderRadius24: {
    borderRadius: '24px',
  },
  borderRadius7: {
    borderRadius: '7px',
  },
  colorWhite: {
    color: '#FFFFFF',
  },
  lh165: {
    lineHeight: '1.65px',
  },
  mt0: {
    marginTop: '0px',
  },
  mt15: {
    marginTop: '15px',
  },
  mt22: {
    marginTop: '22px',
  },
  mt40: {
    marginTop: '40px',
  },

  mt40: {
    marginTop: '40px',
  },
  mt80: {
    marginTop: '80px',
  },

  textSortBy: {
    marginRight: '5px',
    width: '100%',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineSeight: '24px',
    display: 'flex',
    alignItems: 'center',
    color: '#979797',
  },
  filterSelect: {},
  containedButton: {
    display: 'inline-block',
    margin: '7px',
    backgroundColor: '#9A291B',
    borderRadius: '8.25px',
    width: '100%',
    fontFamily: 'Montserrat',
    textTransform: 'capitalize',
    boxShadow: 'none',

    // height: "50px",
    '&:hover': {
      backgroundColor: '#fff!important',
      color: '#9A291B',
      //   borderColor: "#9A291B",
      //   border: "1px solid",
      boxShadow: '0px 0px 0px 1px #9A291B inset',
    },
  },
  outlineButton: {
    display: 'inline-block',
    margin: '7px',
    borderColor: '#9A291B',
    borderRadius: '8.25px',
    fontFamily: 'Montserrat',
    width: '100%',
    color: '#9A291B',
    textTransform: 'capitalize',
    // height: "50px",
    '&:hover': {
      //   backgroundColor: "rgba(255,255,255,0.8)!important",
      borderColor: '#9A291B',
      backgroundColor: '#9A291B!important',
      color: '#fff',
    },
  },
  /* global styles */
  fontFamilySGB: {
    fontFamily: 'Montserrat',
  },
  fontFamilySG: {
    fontFamily: 'Montserrat',
  },
  fontFamilySGR: {
    fontFamily: 'Montserrat-Regular',
  },
  fontFamilyDS: {
    fontFamily: 'Montserrat',
  },
  fs6: {
    fontSize: '6px',
  },
  fs7: {
    fontSize: '7px',
  },
  fs8: {
    fontSize: '8px',
  },
  fs9: {
    fontSize: '9px',
  },
  fs12: {
    fontSize: '12px',
  },
  fs17: {
    fontSize: '17px',
  },
  fs24: {
    fontSize: '24px',
  },
  fs20: {
    fontSize: '20px',
  },
  fs30: {
    fontSize: '30px',
  },
  fs16: {
    fontSize: '16px',
  },
  fs10: {
    fontSize: '10px',
  },
  fs14: {
    fontSize: '14px',
  },
  fontWeight400: {
    fontWeight: 400,
  },
  fontWeight500: {
    fontWeight: 500,
  },
  fontWeight700: {
    fontWeight: 700,
  },
  fbBold: {
    fontWeight: 'bold',
  },
  dflex: {
    display: 'flex',
  },
  dBlock: {
    display: 'block',
  },
  alignItemCenter: {
    alignItems: 'center',
  },
  jcSBetween: {
    justifyContent: 'space-between',
  },
  jcSCenter: {
    justifyContent: 'center',
  },
  marginAuto: {
    margin: 'auto',
  },
  jcSSAround: {
    justifyContent: 'space-around',
  },
  color240501: {
    color: '#240501',
  },
  textTransformCapitalize: {
    textTransform: 'capitalize',
  },
  btnBgGreen: {
    backgroundColor: '#008000',
  },
  colorADB0B6: {
    color: '#ADB0B6',
  },
  textDecorationUL: {
    textDecoration: 'underline',
  },
  p6: {
    padding: '6px',
  },
  p7: {
    padding: '7px',
  },
  p8: {
    padding: '8px',
  },
  p10: {
    padding: '10px',
  },
  p13: {
    padding: '13px',
  },
  px5: {
    paddingRight: '5px',
    paddingLeft: '5px',
  },
  px10: {
    paddingRight: '10px',
    paddingLeft: '10px',
  },
  px11: {
    paddingRight: '11px',
    paddingLeft: '11px',
  },
  px14: {
    paddingRight: '14px',
    paddingLeft: '14px',
  },
  pl10: {
    paddingLeft: '10px',
  },
  p5: {
    padding: '5px',
  },
  fdc: {
    flexDirection: 'column',
  },
  fdr: {
    flexDirection: 'column',
  },
  fdrow: {
    flexDirection: 'row',
  },
  color9A291B: {
    color: '#9A291B',
  },
  lh24: {
    lineHeight: '24px',
  },
  lh0: {
    lineHeight: '0px',
  },
  margin4: {
    margin: '4px',
  },
  width20: {
    width: '20%',
  },
  width30: {
    width: '30%',
  },
  width40: {
    width: '40%',
  },
  width50: {
    width: '50%',
  },
  width100px: {
    width: '100px',
  },
  width100: {
    width: '100%',
  },
  width90: {
    width: '90%',
  },
  width95: {
    width: '95%',
  },
  height20: {
    height: '20%',
  },
  height30: {
    height: '30%',
  },
  height40: {
    height: '40%',
  },
  height50: {
    height: '50%',
  },
  height100: {
    height: '100%',
  },

  mt50: {
    marginTop: '50px',
  },
  searchGridItem: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  searchGridItemMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  textCenter: {
    textAlign: 'center',
  },
  margin0: {
    margin: 0,
  },
  marginTop1: {
    marginTop: '1px',
  },
  height25px: {
    height: '25px',
  },
  containedPropertyDetailButton1: {
    backgroundColor: '#008000',
    borderColor: '#008000',
    borderRadius: '5px',
    marginRight: '10px',
    color: '#fff',
    fontSize: '12px',
    fontWeight: 500,
  },
  containedPropertyDetailButton2: {
    backgroundColor: '#e2b4af',
    borderColor: '#9A291B',
    border: 1,
    fontFamily: 'Montserrat',
    marginRight: '10px',
    borderRadius: '5px',
    color: '#240501',
    fontSize: '12px',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#e2b4af',
    },
  },
  MuiMenuList: {
    borderRadius: '20px',
  },
  border: {
    border: '1px solid #ADB0B6',
  },
  maxWidth170: {
    maxWidth: '170px',
  },
  minWidth170: {
    minWidth: '170px',
  },
  maxWidth200: {
    maxWidth: '200px',
  },
  minWidth200: {
    minWidth: '200px',
  },
  minHeight30: {
    minHeight: '40px',
  },
  maxHeight30: {
    maxHeight: '40px',
  },
  fs15: {
    fontSize: '15px',
  },

  fs16: {
    fontSize: '16px',
  },
  color979797: {
    color: '#979797',
  },
  shadowProps: {
    boxShadow: '10px 5px 5px #000000',
  },
};
// };

// const useStyles = makeStyles(styles);
// export default styles;
