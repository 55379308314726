import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useMediaQuery } from "react-responsive";
import StickyFooterMobile from "../../components/FooterMobile";
import StickyFooter from "../../components/Footer";
const ContactUs = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <>
      <Header />
      <div className="container" style={{ paddingTop: "150px" }}>
        <h2 className="h3-about-tems" style={{ marginBottom: "15px" }}>
          Contact Us
        </h2>{" "}
        <br />
        <br />
        <p className="para-about-terms">
          {" "}
          For any inquiries or assistance related to returns and refunds, please
          don't hesitate to reach out to our customer support team. We are
          committed to providing prompt and efficient support.
          <br />
          gharwalah.com strives to ensure a seamless and customer-centric
          experience with our furniture and home appliances rental services.
          Your satisfaction is our priority, and we are dedicated to resolving
          any issues or facilitating returns and refunds in accordance with this
          policy.
        </p>{" "}
        <br />
        <h4 className="h4-about-tems">Need Help? </h4>
        <br />
        <p className="para-about-terms">
          {" "}
          If you have any questions, concerns or require assistance with any
          aspect of our services, please don't hesitate to contact us. We're
          here to help.{" "}
        </p>
        <br />
        <h4 className="h4-about-tems"> Address: </h4>
        <p className="para-about-terms">
          {" "}
          Gharwala{/* <sup style={{ fontSize: "15px" }}>TM</sup> */} Home
          Solutions LLP
          <br /> 2 New Tollygunge Purba Putiary <br />
          Kolkata- 700093
        </p>{" "}
        <h4 className="h4-about-tems"> Email: </h4>
        <p className="para-about-terms">
          {" "}
          <a href="mailto:support@gharwalah.com" className="text-black">
            support@gharwalah.com
          </a>
        </p>{" "}
        <h4 className="h4-about-tems"> Phone No.: </h4>
        <p className="para-about-terms"> +91+91 8240444587</p> <br />
      </div>
      <div>{isMobile ? <StickyFooterMobile /> : <StickyFooter />}</div>
    </>
  );
};

export default ContactUs;
