import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "../../../styles/styles";
import OutlinedFormInput from "../../../components/Input/OutlinedInput";
import classes from "../../../styles/styles.js";
import { useTheme } from "@mui/material/styles";

const AddPropertyStep5 = ({ handleTextChange, data }) => {
  const theme = useTheme();
  return (
    <Box
      sx={[
        { marginLeft: "12px", paddingLeft: "20px", paddigRight: "8px" },
        {
          [theme.breakpoints.between("xs", "md")]: [
            { marginLeft: 0, paddingLeft: 0, paddigRight: 0 },
          ],
        },
      ]}
    >
      <Typography
        sx={[
          styles.fs30,
          styles.fontFamilySGB,
          styles.fbBold,
          styles.color240501,
        ]}
      >
        Pricing & Others
      </Typography>
      <Box sx={[styles.mt40]}>
        <Box className="w-60">
          <Box>
            <Typography
              sx={[
                styles.fs16,
                styles.fontFamilySG,
                styles.color240501,
                {
                  [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                },
              ]}
              className="mb-2"
            >
               {data?.wantto === "sell" ? "Price" : "Monthly Rent"}
            </Typography>
            <OutlinedFormInput
              placeholder={"20000"}
              onChange={(e) => {
                handleTextChange("rent", e.target.value);
                handleTextChange("expectedMonthlyRent", e.target.value);
              }}
              sx={[
                { height: "initial" },
                {
                  [theme.breakpoints.between("xs", "sm")]: [
                    {
                      maxWidth: "300px",
                      minWidth: "300px",
                    },
                  ],
                },
              ]}
            />
          </Box>
          {data?.wantto !== "sell" && (
            <>
              <Box className="mt-3">
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  Security Deposit
                </Typography>
                <OutlinedFormInput
                  disabled={data.propertyType === "land"}
                  placeholder={"2000"}
                  onChange={(e) =>
                    handleTextChange("expectedSecurityDeposit", e.target.value)
                  }
                  sx={[
                    { height: "initial" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
              </Box>
              {/* <Box className="mt-3">
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  First time payment
                </Typography>
                <OutlinedFormInput
                  disabled={data.propertyType === "land"}
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("firstTimePayment", e.target.value)
                  }
                  sx={[
                    { height: "initial" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
              </Box> */}
            </>
          )}
          <Box className="mt-3">
            <Typography
              sx={[
                styles.fs16,
                styles.fontFamilySG,
                styles.color240501,
                {
                  [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                },
              ]}
              className="mb-2"
            >
              Description of the property
            </Typography>
            <OutlinedFormInput
              placeholder="Write under 300 words...."
              rows={8}
              multiline={true}
              inputProps={{
                maxLength: 300,
              }}
              sx={[
                { height: "initial" },
                {
                  [theme.breakpoints.between("xs", "sm")]: [
                    {
                      maxWidth: "300px",
                      minWidth: "300px",
                    },
                  ],
                },
              ]}
              onChange={(e) => handleTextChange("desc", e.target.value)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddPropertyStep5;
