import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { privateReq } from '../../config/axiosConfig';
import NotFound from '../wishlist/not_found';
import Header from '../../components/Header';
import StickyFooter from '../../components/Footer';

const ProductList = ({user}) => {
  const [showDetailsIndex, setShowDetailsIndex] = useState(null);
  const [productList, setProductList] = useState([]);

  const getDetails = () => {

    console.log(user)
    privateReq.post('/get-user-seen-products',{id:user?.id ,role:user?.role})
      .then((res) => {
        // console.log(res);
        setProductList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDetails();
  }, []);
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const toggleDetails = (index) => {
    setShowDetailsIndex(showDetailsIndex === index ? null : index);
  };

  return (
  <>
  <Header/>
 <div style={{margin:'100px 0 0 0' ,fontWeight:'bold' }}>
  Viewed property details
 </div>
  {
    productList.length===0 ? (<NotFound item={'Viewed properties'} showText={false}/>): (  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
      {productList?.map((product, index) => (
        <div key={index} style={{ width: '300px', margin: '20px', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', backgroundColor: '#fff' }}>
          <div style={{ marginBottom: '10px' }}>
            <strong>User Details:</strong>
            <ul>
              <li>
                <strong>Name:</strong> {product.users.name}
              </li>
              <li>
                <strong>Phone:</strong> {product.users.phone}
              </li>
              <li>
                <strong>Email:</strong> {product.users.email}
              </li>
              <li>
                <strong>Last Clicked </strong> {formatDate(product.lastClickTimeStamp)}
                </li>
            </ul>

          </div>
          <Link to={`/properties/${product.product}`}>
          <button style={{background:'#9A291B'}}  onClick={() => toggleDetails(index)}>View Product Details</button>
          </Link>
    
        </div>
      ))}
    </div>)
  }
  <StickyFooter/>
  </>
  );
};

export default ProductList;
