import {
  SET_CURRENT_USER,
  // REMOVE_CURRENT_USER,
  SET_CURRENT_LOCATION,
  SET_CONTACTS,
  SET_USERS,
  SET_ACCESSORIES,
} from './types';
import { getAllUsersService, getUserProfile } from '../../config/user';
import { getAllAccessoriesService } from '../../config/accessory';
export const setCurrentUser = (data, cb) => async (dispatch) => {
  try {
    const res = await getUserProfile();
    dispatch({
      type: SET_CURRENT_USER,
      payload: res.data.user,
    });
    cb();
  } catch (e) {
    console.log(e);
  }
};

export const setCurrentLocation = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_CURRENT_LOCATION,
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setLogOut = () => async (dispatch) => {
  try {
    sessionStorage.removeItem('token');
    dispatch({
      type: SET_CURRENT_USER,
      payload: null,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getAllUsers = (data) => async (dispatch) => {
  try {
    const res = await getAllUsersService(data);

    dispatch({
      type: SET_USERS,
      payload: { users: res.data.users, total: res.data.total },
    });
  } catch (e) {
    console.log(e);
  }
};
export const getAllAccessories = () => async (dispatch) => {
  try {
    const res = await getAllAccessoriesService();

    dispatch({
      type: SET_ACCESSORIES,
      payload: res.data.accessories,
    });
  } catch (e) {
    console.log(e);
  }
};
