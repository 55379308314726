import React, { useEffect, useState } from "react";
import DetailHeader from "../../components/DetailHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import classes from "../../styles/styles.js";
import Button from "@mui/material/Button";
import ButtonContained from "../../components/Buttons/ButtonContained";
import ButtonOutlined from "../../components/Buttons/ButtonOutlined";
import { useTheme } from "@mui/material/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  IconButton,
  Chip,
  Avatar,
  Rating,
  TextareaAutosize,
  Typography,
  Input,
} from "@mui/material";
import {
  addFurnitureRatingService,
  addToCartService,
  addToWishlistService,
  approveFurnitureService,
  checkPinCodeApi,
  createFurnitureBookingService,
  onGetFurnitureById,
  viewFurnitureContactService,
} from "../../config/furniture";
import StarIcon from "@mui/icons-material/Star";
import styles from "../../styles/styles.js";
import StickyFooter from "../../components/Footer";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useSelector } from "react-redux";
import BusSvg from "../../assets/images/bus.svg";
import { assetUrl, privateReq } from "../../config/axiosConfig";
import Header from "../../components/Header";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import { toast } from "react-hot-toast";
import { useMediaQuery } from "react-responsive";
import ModalSignUp from "../../components/ModalSignup";
import SignUp from "../../components/Signup";
import ModalLogin from "../../components/ModalLogin";
import Login from "../../components/Login";
import { BottomSheet } from "react-spring-bottom-sheet";
import { buyPhonePe, createBuySessionService } from "../../config/user.js";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Faq from "../../components/Faq";
import ChoosePlan from "./ChoosePlan.js";
import { Watermark } from "@hirohe/react-watermark";
import "./index.css";

const FurnitureDetails = ({ sx, setOpenPopUp }) => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();

  const [furnitureDetails, setFurnitureDetails] = useState(null);
  const [loader, setLoader] = useState(false);
  const [bookingPeriod, setBookingPeriod] = useState("12");
  const { user } = useSelector((state) => state.auth);
  const { furnitures } = useSelector((state) => state.furniture);
  const [hideBookingDialog, setHideBookingDialog] = useState(false);
  const [hideBookingDialog1, setHideBookingDialog1] = useState(false);
  const [disableWishlist, setWishlist] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [reviewGiven, setreviewGiven] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [myRating, setMyRating] = useState({
    rate: 0,
    desc: "",
  });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [closeModal, setCloseModal] = useState(false);
  const [closeLoginModal, setCloseLoginModal] = useState(false);
  const [pincode, setPincode] = useState();
  const [allPincodes, setAllPincodes] = useState();
  const [isPincodeAvailable, setIsPincodeAvailable] = useState(false);
  const [display, setDisplay] = useState(false);
  const [planModal, setPlanModal] = useState(false);
  useEffect(() => {
    setBookingPeriod(bookingPeriod);
    console.log(bookingPeriod);
  }, [bookingPeriod]);

  useEffect(() => {
    GetFurnitureById();
  }, []);

  const checkPincode = async () => {
    try {
      const res = await privateReq.get(`/get-pincodes?pincode=${pincode}`);
      console.log("this is my pincode", res.data.data.length);
      if (res.data.data.length > 0) {
        // toast.success('We are Available at your Pincode');
        setIsPincodeAvailable(true);
        setPincode("");
      } else {
        // toast.error('Sorry We are not available at your location.');
        setIsPincodeAvailable(false);
        setDisplay(true);
        setPincode("");
      }
    } catch (err) {}
  };

  const GetFurnitureById = async () => {
    const filterQuery = params.id;
    let res = await onGetFurnitureById(filterQuery);
    if (res.data) {
      console.log("res.datares.data", res.data);
      setFurnitureDetails(res.data.furniture);
      setShowContact(res.data.showContact);
      setreviewGiven(res.data.reviewGiven);
      setReviews(res.data.reviews);
      setLoader(false);
    }
  };

  const onViewContact = async (e) => {
    e.preventDefault();
    try {
      if (user === null) {
        setCloseLoginModal(true);
        return;
      }
      const res = await viewFurnitureContactService({
        furnitureId: params.id,
      });
      if (res.data.status) {
        setShowContact(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onAddReview = async (e) => {
    e.preventDefault();
    try {
      const res = await addFurnitureRatingService({
        ...myRating,
        id: params.id,
      });
      if (res.data.status) {
        toast.success("Review added successfully!");
        setMyRating({
          rate: 0,
          desc: "",
        });
        await GetFurnitureById();
        setreviewGiven(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleTextChange = (name, val) => {
    setMyRating({ ...myRating, [name]: val });
  };
  console.log("furnitureDetails", furnitureDetails);

  const onCreateBooking = async () => {
    try {
      // if (user === null) {
      //   setCloseLoginModal(true);
      //   return;
      // }
      if (furnitureDetails?.sellingOptions === "rent" && !bookingPeriod) {
        toast.error("Please select rental period", {
          position: "top-right",
        });
        return;
      }

      if (user === null) {
        let data = {
          _id: null,
          expired: false,
          periodNeeded: bookingPeriod,
          created: new Date(),
          user: null,
          furniture: furnitureDetails,
          image: furnitureDetails?.furnitureImages[0],
        };

        let cartData = localStorage.getItem("cartData");
        if (cartData) {
          let mainData = [...JSON.parse(cartData), data];
          localStorage.setItem("cartData", JSON.stringify(mainData));
        } else {
          localStorage.setItem("cartData", JSON.stringify([data]));
        }
        toast.success("Added to cart!");

        return;
      }

      const res = await addToCartService({
        furnitureId: furnitureDetails?._id,
        period: bookingPeriod,
      });
      if (res.data.status) {
        toast.success("Added to cart!");
        setBookingPeriod("");
        setHideBookingDialog(true);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onCreateBooking1 = async () => {
    try {
      // if (user === null) {
      //   setCloseLoginModal(true);
      //   return;
      // }
      if (furnitureDetails?.sellingOptions === "rent" && !bookingPeriod) {
        toast.error("Please select rental period", {
          position: "top-right",
        });
        return;
      }

      if (user === null) {
        setCloseLoginModal(true);
        let data = {
          _id: null,
          expired: false,
          periodNeeded: bookingPeriod,
          created: new Date(),
          user: null,
          furniture: furnitureDetails,
          image: furnitureDetails?.furnitureImages[0],
        };

        let cartData = localStorage.getItem("wishlistData");
        if (cartData) {
          let mainData = [...JSON.parse(cartData), data];
          localStorage.setItem("wishlistData", JSON.stringify(mainData));
        } else {
          localStorage.setItem("wishlistData", JSON.stringify([data]));
        }
        // toast.success("Added to wishlist!");

        return;
      }

      const res = await addToWishlistService({
        furnitureId: furnitureDetails?._id,
        period: bookingPeriod,
      });
      if (res.data.status) {
        toast.success("Added to wishlist!");
        setBookingPeriod("");
        setHideBookingDialog1(true);
        setWishlist(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onBooking = async () => {
    try {
      if (user === null) {
        setPlanModal(false);
        setCloseLoginModal(true);
        return;
      }
      await onCreateBooking();
      navigate("/checkout");
    } catch (e) {
      console.log(e);
    }
  };

  const onUpdatefurnitureDetailstatus = async (status) => {
    try {
      const res = await approveFurnitureService({
        id: furnitureDetails?._id,
        active: !furnitureDetails.status,
      });
      if (res.data.success) {
        toast.success(
          `Furniture ${status ? "Deactivated" : "Activated"} Successfully`,
          {
            position: "top-right",
          }
        );
        GetFurnitureById();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setOpenImage = (index) => {
    setSelectedImage(index);
    if (index > 2) {
      setOpen(true);
    }
  };

  // FAQ:
  const [faqs, setFaqs] = useState([
    {
      question: "Q: How can I rent furniture from gharwalah.com?",
      answer: (
        <div>
          Renting furniture and AC from gharwalah.com is a 2-step process:{" "}
          <br />
          1.Choose the products listed on the website, pick a tenure period of
          3,6,9 or 12 months, and call us at +91-+91 8240444587 to make an
          informed decision. Also, as all the photos of our products match the
          actual products that we deliver, feel free to place an online order
          without making any phone call <br />
          2.Submit the necessary documents and data to complete the KYC (know
          your customer) process, and we will deliver the products within the
          next 48 hours anywhere in Kolkata <br />
          (we will embed a screen record video of placing an order, on the
          website)
        </div>
      ),
      open: false,
    },
    {
      question: "Q: Why should I rent furniture & AC from gharwalah.com",
      answer: (
        <div>
          7 reasons: <br />
          2.Consistently happy customers. Check our Google reviews @
          https://g.co/kgs/E1J2d63 <br />
          3.Guaranteed delivery of product within 48 hours anywhere in Kolkata{" "}
          <br />
          4.WhatsApp/phone call support from 9am to 9pm <br />
          5.You can visit our go-downs at designated pincodes, to check the
          products before you decide to rent <br />
          6.FREE maintenance 9 months onwards. However, in case of manufacturing
          defects in any product for any tenure, we repair it within 48 hours{" "}
          <br />
          7.For AC installation, we provide 10 ft. copper pipe and 20 ft.
          drainage pipe for FREE <br />
          8.Least documentation and most hassle-free onboarding process <br />
        </div>
      ),
      open: false,
    },
    {
      question:
        "Q: Are there any hidden costs involved in renting furniture & AC?",
      answer:
        "No, there are no hidden costs. All prices are clearly mentioned on the website, namely, the rental amount (monthly), discount, security deposit, relocation cost (one-time), installation cost (only for AC), and (GST only on rental amount). ",
      open: false,
    },
    {
      question:
        "Q: Will I be charged any fee if I return the product before my minimum tenure ends?",
      answer:
        "There are standard pre-closure rules applicable to all rented furniture & appliances. Firstly, pre-closure is not allowed for rental products of 3 months tenure. You are required to pay the rent for all 3 months even if you handover the products after the first month of the tenure. Minimum lock-in period for all products is 3 months. For rental products of 6 months, 9 months, and 12 months tenure, pre-closure is not allowed before the fourth month of the tenure. And, 1 month rental amount is chargeable as pre-closure fees. For example, for a 6-month tenure that ends in August, if you want pre-closure in June, you are required to pay the rental amount of July. After we pick up the products and check them, we will decide about adjusting the remaining amount with the security deposit amount.",
      open: false,
    },

    {
      question: "Q: Can the security deposit amount be waved off?",
      answer:
        "Ofcourse, we provide easy payment terms suitable to your needs. You need not pay security deposit if you pay the entire rental amount up-front. ",
      open: false,
    },

    {
      question:
        "Q: When I rent an AC, how do you ensure the delivery & installation?",
      answer:
        "We deliver AC within 48 hours anywhere in Kolkata. We provide 10 ft. copper pipe and 20 ft. drainage pipe for FREE. In order to install the AC, you must ensure the electrical connection is in place before our installation team arrives at your desired location. Also, we do not carry a ladder. Please arrange one before the installation",
      open: false,
    },

    {
      question: "Q: What all do you cover in free maintenance?",
      answer:
        "FREE maintenance is provided for all items. In case of manufacturing defects in any product for any tenure, we repair it within 48 hours, for FREE. However, we do not cover repairs on intentional damage",
      open: false,
    },

    {
      question: "Q: Are there any charges for cancellation?",
      answer:
        "Yes, users will be charged INR 100 /- in cases of user requested cancellations post verification, after KYC process is completed. However, cancellation is not allowed after delivery of product. You are required to make payment for full tenure, in order to handover the products, if you choose to cancel the order.",
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
      />
      <div
        className="container property-details"
        style={{ paddingBottom: "75px" }}
      >
        {user?.role === "admin" && isMobile && (
          <div>
            <ButtonContained
              sx={[
                styles.fontFamilySGB,
                !furnitureDetails?.status && styles.btnBgGreen,
                styles.p10,
                styles.borderRadius12,
              ]}
              className="m-0 my-2 mx-1"
              onClick={(e) => {
                e.preventDefault();
                onUpdatefurnitureDetailstatus(furnitureDetails?.status);
              }}
            >
              {furnitureDetails?.status ? "Deactivate" : "Activate"}
            </ButtonContained>
          </div>
        )}
        <div className="row">
          <div className="col-md-8 col-xs-12">
            {selectedImage !== null ? (
              <div>
                <Watermark
                  gutter={150}
                  rotate={0}
                  textSize={50}
                  multiline={false}
                  text="Gharwalah.com"
                >
                  <img
                    src={furnitureDetails?.furnitureImages[selectedImage]}
                    style={{
                      width: "100%",
                      aspectRatio: 2,
                      height: undefined,
                      borderRadius: "10px",
                      objectFit: "cover",
                    }}
                  />
                </Watermark>
              </div>
            ) : (
              <div>
                <Watermark
                  gutter={150}
                  rotate={0}
                  textSize={50}
                  multiline={false}
                  text="Gharwalah.com"
                >
                  <img
                    src={furnitureDetails?.furnitureImages[0]}
                    style={{
                      width: "100%",
                      aspectRatio: 2,
                      height: undefined,
                      borderRadius: "10px",
                      objectFit: "cover",
                    }}
                  />
                </Watermark>
              </div>
            )}

            <Lightbox
              plugins={[Thumbnails]}
              open={open}
              close={() => setOpen(false)}
              slides={furnitureDetails?.furnitureImages.map((i) => {
                return {
                  src: i,
                };
              })}
              initialSlide={selectedImage}
            />

            <div className="row">
              {furnitureDetails?.furnitureImages
                .slice(0, 4)
                .map((image, index) => (
                  <div className="col-3">
                    {index === 3 ? (
                      <span
                        className="d-block w-100 h-100"
                        style={{
                          zIndex: 1,
                          position: "relative",
                          backgroundColor: "green",
                          marginTop: "15px",
                          borderRadius: "10px",
                        }}
                      >
                        <img
                          src={image}
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "10px",
                          }}
                        />
                        <span
                          onClick={() => setOpenImage(index)}
                          className="d-flex align-items-center justify-content-center slider-title"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            width: "100%",
                            zIndex: 3,
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.75)",
                            borderRadius: "10px",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          +{furnitureDetails?.furnitureImages.length - 5} photos
                        </span>
                      </span>
                    ) : (
                      <img
                        onClick={() => setOpenImage(index)}
                        src={image}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                          marginTop: "15px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                ))}
            </div>
            <div className="property-detail-bottom-left">
              <div className="property-detail-title">
                <span className="property-detail-title">
                  {furnitureDetails?.furnitureName}
                </span>
              </div>
              <div className="property-detail-pricing">
                <span className="property-detail-price">
                  {furnitureDetails &&
                  furnitureDetails?.sellingOptions &&
                  furnitureDetails?.sellingOptions === "buy"
                    ? "₹" + (furnitureDetails?.firstTimePayment || "")
                    : "₹ " +
                      (furnitureDetails?.expectedMonthlyRent || "") +
                      "/month"}
                </span>
              </div>
            </div>
            {/* <div className="desc-stars">
              <div className="stars">
                <p
                  style={{
                    color: "#9A291B",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {furnitureDetails?.avgRating}
                </p>
                <span style={{ marginTop: "2px", paddingLeft: "5px" }}>
                  <Rating
                    value={parseInt(furnitureDetails?.avgRating)}
                    defaultValue={parseInt(furnitureDetails?.avgRating)}
                    readOnly
                    sx={{ color: "#9a291b" }}
                  />
                </span>
              </div>
            </div> */}
            <div
              className="row furniture-features-cards"
              style={{ marginTop: "20px" }}
            >
              {furnitureDetails?.keyValue.map((e) => (
                <div className="col-md-6 col-xs-12 furniture-card-right">
                  <div class="card">
                    <div class="card-body">
                      <div className="furnture-sub-title">
                        <h3>{e.key}</h3>
                        <span className="furnture-span">{e.value}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <h4 className="amenties-heading" style={{ marginTop: "35px" }}>
              Description
            </h4>
            <p>{furnitureDetails?.desc}</p>

            <>
              {/* {!isMobile && (
                <>
                  <h4
                    className="amenties-heading"
                    style={{ marginTop: "35px" }}
                  >
                    Reviews
                  </h4>
                  <div>
                    {reviews.length > 0 ? (
                      reviews.map((item) => (
                        <>
                          <div className="reviews-section-1">
                            <div className="review-user-section">
                              <Avatar
                                src={assetUrl + item.user?.avatarImage}
                                className="review-image"
                                alt={item.user.name}
                              />
                            </div>
                            <div className="review-stars-section">
                              <div className="review-user-name">
                                <div>
                                  <span>{item.user.name}</span>
                                </div>
                                <Rating
                                  value={item.rating}
                                  sx={{
                                    color: "#9A291B",
                                  }}
                                  readOnly
                                  size="small"
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <p className="review-sub-title">{item.desc}</p>
                          </div>
                        </>
                      ))
                    ) : (
                      <span className="nearby-list-address">
                        No reviews yet
                      </span>
                    )}
                  </div>
                </>
              )} */}

              {!isMobile && (
                <div className="App">
                  <div className="faqs">
                    {faqs.map((faq, index) => (
                      <Faq
                        faq={faq}
                        index={index}
                        key={index}
                        toggleFAQ={toggleFAQ}
                      />
                    ))}
                  </div>
                </div>
              )}
            </>
            {showContact && !reviewGiven && (
              <div>
                <h4 className="amenties-heading" style={{ marginTop: "35px" }}>
                  Add a review
                </h4>
                <Rating
                  value={myRating.rate}
                  sx={{
                    color: "#9A291B",
                  }}
                  onChange={(e) =>
                    handleTextChange("rate", parseInt(e.target.value))
                  }
                  size="large"
                />
                <TextareaAutosize
                  style={styles.outlineTextArea}
                  className="w-100 border"
                  minRows={5}
                  placeholder="Write your review..."
                  value={myRating.desc}
                  onChange={(e) => handleTextChange("desc", e.target.value)}
                />
                <ButtonContained onClick={onAddReview}>Submit</ButtonContained>
              </div>
            )}
          </div>

          <div className="col-md-4 col-xs-12">
            {user?.role === "admin" && !isMobile && (
              <div>
                <ButtonContained
                  sx={[
                    styles.fontFamilySGB,
                    !furnitureDetails?.status && styles.btnBgGreen,
                    styles.p10,
                    styles.borderRadius12,
                  ]}
                  className="m-0 my-2 mx-1"
                  onClick={(e) => {
                    e.preventDefault();
                    onUpdatefurnitureDetailstatus(furnitureDetails?.status);
                  }}
                >
                  {furnitureDetails?.status ? "Deactivate" : "Activate"}
                </ButtonContained>
              </div>
            )}

            <div className="mt-2">
              <div class="card card-property-details">
                <div class="card-body">
                  <div className="furnture-sub-title">
                    <h3>{furnitureDetails?.furnitureName}</h3>
                  </div>
                  <div className="border"></div>

                  {furnitureDetails?.sellingOptions == "rent" && (
                    <>
                      <div className="mt-3">
                        <Typography sx={[styles.fontFamilySGB, styles.fs20]}>
                          Select Rental Period
                        </Typography>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        {["3", "6", "9", "12"].map((i) => {
                          const isSelected = i === bookingPeriod;
                          return (
                            <Chip
                              className="chip"
                              onClick={() => setBookingPeriod(i)}
                              sx={[
                                classes.borderRadius12,
                                classes.fontWeight500,
                                classes.bgWhite,
                                classes.border,
                                classes.fontFamilyDS,
                                classes.colorADB0B6,
                                classes.borderColorADB0B6,
                                classes.fs12,
                                // i === bookingPeriod && classes.btnBgGreen,
                                // i === bookingPeriod && classes.colorWhite,
                                isSelected && classes.btnBgGreen,
                                isSelected && classes.colorWhite,
                                {
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                },
                                {
                                  [theme.breakpoints.between("xs", "sm", "md")]:
                                    {
                                      ...classes.fs9,
                                      ...classes.textCenter,
                                      ...classes.margin0,
                                      ...classes.height25px,
                                      ...(isSelected && classes.btnBgGreen),
                                      ...(isSelected && classes.colorWhite),
                                    },
                                },
                              ]}
                              label={i + "M"}
                            />
                          );
                        })}
                      </div>
                    </>
                  )}
                  <div className="mt-3">
                    <Typography
                      sx={[
                        classes.fontFamilySGB,
                        styles.color9A291B,
                        {
                          fontSize: "16px",
                        },
                      ]}
                    >
                      {furnitureDetails &&
                      furnitureDetails?.sellingOptions &&
                      furnitureDetails?.sellingOptions === "buy" ? (
                        "₹" + (furnitureDetails?.firstTimePayment || "")
                      ) : (
                        <>
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                width: "50%",
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                                paddingBlock: "6px",
                                border: "1px solid #ced4da",
                                textAlign: "center",
                              }}
                            >
                              <div>
                                {bookingPeriod === "3" &&
                                  "₹" +
                                    furnitureDetails?.threeMonthPayment
                                      .threeMonthPayment +
                                    "/month"}

                                {bookingPeriod === "6" &&
                                  "₹" +
                                    furnitureDetails?.sixMonthPayment
                                      .sixMonthPayment +
                                    "/month"}

                                {bookingPeriod === "9" &&
                                  "₹" +
                                    furnitureDetails?.nineMonthPayment
                                      .nineMonthPayment +
                                    "/month"}

                                {bookingPeriod === "12" &&
                                  "₹" +
                                    furnitureDetails?.twelveMonthPayment
                                      .twelveMonthPayment +
                                    "/month"}
                              </div>

                              <div>
                                <Typography
                                  sx={[
                                    classes.fontFamilySGB,
                                    styles.fs16,
                                    styles.color240501,
                                  ]}
                                >
                                  {furnitureDetails?.sellingOptions == "rent"
                                    ? "Monthly Rent"
                                    : "Price"}
                                </Typography>
                              </div>
                            </div>
                            <div
                              className="justify-content-between"
                              style={{
                                width: "50%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px solid #ced4da",
                                paddingBlock: "6px",
                              }}
                            >
                              <div style={{ fontSize: "16px" }}>
                                {"₹" +
                                  furnitureDetails?.expectedSecurityDeposit}
                              </div>

                              <div
                                style={{
                                  fontSize: "16px",
                                  textAlign: "center",
                                  color: "black",
                                }}
                              >
                                Refundable Deposit:
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              fontSize: "14px",
                              border: "1px solid #ced4da",
                              paddingInline: "6px",
                              paddingBlock: "10px",
                              color: "black",
                              fontWeight: "200",
                              marginBottom: "10px",
                            }}
                          >
                            <div style={{ display: "flex", gap: "4px" }}>
                              <CheckCircleIcon fontSize="small" />
                              Free relocation
                            </div>
                            <div style={{ display: "flex", gap: "4px" }}>
                              <CheckCircleIcon fontSize="small" />
                              Free upgrades
                            </div>
                            <div
                              style={{ color: "#1dbdc0", cursor: "pointer" }}
                              onClick={() => setPlanModal(true)}
                            >
                              View All
                            </div>
                          </div>

                          <ButtonContained onClick={() => setPlanModal(true)}>
                            Select Rental Period
                          </ButtonContained>
                        </>
                      )}
                    </Typography>
                  </div>
                  <div className="border my-3"></div>

                  <div style={{ margin: "0 7px 10px 7px" }}>Check Pincode:</div>
                  <input
                    type="number"
                    placeholder="Enter Pincode"
                    style={{
                      outline: "none",
                      padding: "5px 10px",
                      margin: "0 7px 10px 7px",
                      width: "100%",
                    }}
                    name="pincode"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                  />

                  {isPincodeAvailable && (
                    <div
                      style={{
                        color: "green",
                        textAlign: "center",
                        fontWeight: "500",
                        width: "100%",
                      }}
                    >
                      Available at your location <CheckBoxIcon />
                    </div>
                  )}

                  {!isPincodeAvailable && display === true && (
                    <div
                      style={{
                        color: "red",
                        textAlign: "center",
                        fontWeight: "500",
                        width: "100%",
                      }}
                    >
                      Coming soon at your location.
                    </div>
                  )}

                  <ButtonContained onClick={checkPincode}>
                    Check
                  </ButtonContained>

                  <div className="border my-3"></div>
                  <ButtonContained
                    disabled={hideBookingDialog}
                    onClick={onCreateBooking}
                  >
                    {hideBookingDialog ? "Added To Cart" : "Add To Cart"}
                  </ButtonContained>
                  <ButtonContained
                    disabled={disableWishlist}
                    onClick={onCreateBooking1}
                  >
                    {hideBookingDialog1
                      ? "Added To wishlist"
                      : "Add To Wishlist"}
                  </ButtonContained>

                  {furnitureDetails?.sellingOptions == "buy" ? (
                    <ButtonContained onClick={onBooking}>
                      Buy Now
                    </ButtonContained>
                  ) : (
                    <ButtonContained onClick={onBooking}>
                      Rent Now
                    </ButtonContained>
                  )}
                </div>
              </div>
            </div>

            {/* Furniture you might need: */}

            {/* {!hideBookingDialog && ( */}

            {/* )} */}
          </div>
        </div>

        {isMobile && (
          <div className="App">
            <div className="faqs">
              {faqs.map((faq, index) => (
                <Faq
                  faq={faq}
                  index={index}
                  key={index}
                  toggleFAQ={toggleFAQ}
                />
              ))}
            </div>
          </div>
        )}

        {!isMobile && (
          <div
            className="mt-4"
            //   style={{ marginTop: "20px", border: "1px solid #ccc" }}
          >
            <h4 className="nearby-title">Furniture you might need</h4>

            <div
              className="furniture-you-might-need"
              style={{ display: "flex", flexWrap: "wrap", gap: "30px" }}
            >
              {furnitures?.data?.map((card, index) =>
                card._id === furnitureDetails?._id ? null : (
                  <div
                    className="border card-container-furniture mt-2"
                    style={{
                      width: "410px",
                      borderRadius: 12,
                    }}
                  >
                    <div
                      ref={(el) =>
                        el &&
                        el.style.setProperty(
                          "border-radius",
                          "12px",
                          "important"
                        )
                      }
                      key={index}
                      className="card card-furniture"
                      style={{
                        marginRight: 0,
                        width: "100%",
                      }}
                    >
                      <Watermark
                        gutter={150}
                        rotate={0}
                        text="Gharwalah.com"
                        textSize={50}
                        multiline={false}
                      >
                        <div
                          className="card-image"
                          style={{
                            backgroundImage: `url(${card.image})`,
                            backgroundSize: "100%",
                            backgroundRepeat: "no-repeat",
                            backgroundClip: "content-box",
                            backgroundPosition: "center",
                          }}
                        >
                          <div className="d-flex flex-column justify-content-between h-100">
                            <div className="d-flex justify-content-end">
                              {/* <IconButton className="bg-white m-2">
                             <BookmarkBorderIcon sx={{ color: "#000" }} />
                          </IconButton> */}
                            </div>
                            <div className="d-flex justify-content-start align-items-center">
                              {/* <Chip
                              sx={[
                                classes.borderRadius5,
                                classes.bgWhite,
                                classes.fontWeight700,
                                classes.fs12,
                                {
                                  bottom: "0px",
                                  margin: "10px",
                                  color: "#9A291B!important",
                                },
                                {
                                  [theme.breakpoints.between("xs", "sm")]: {
                                    ...classes.fs9,
                                    ...classes.height25px,
                                  },
                                },
                              ]}
                              label="4.2"
                              onDelete={() => {}}
                              deleteIcon={
                                <StarIcon
                                  sx={{
                                    color: "#9A291B!important",
                                    alignSelf: "center",
                                    marginTop: "1px",
                                  }}
                                />
                              }
                              variant="contained"
                            />  */}

                              {/* <Chip
                            sx={[
                              classes.borderRadius5,
                              classes.bgWhite,
                              classes.colorWhite,
                              classes.fontWeight500,
                              classes.fs12,
                              {
                                bottom: "0px",
                                margin: "10px",
                                backgroundColor: "#008000",
                              },
                              {
                                [theme.breakpoints.between("xs", "sm")]: {
                                  ...classes.fs9,
                                  ...classes.textCenter,
                                  ...classes.margin0,
                                  ...classes.height25px,
                                },
                              },
                            ]}
                            label="Best Seller"
                             /> */}
                            </div>
                          </div>
                          {/* <a href="#" download="new-filename" className="card-image-icon d-flex justify-content-end p-2"><img src={SaveSvg} /></a> */}
                        </div>
                      </Watermark>
                      {/* <div class="image-icon">

                                    </div> */}
                      {/* </div> */}
                      <div className="text-info text-info-furniture">
                        <div className="slider-card-header">
                          <h2
                            className="slider-title slider-title-furniture"
                            style={{ textTransform: "capitalize" }}
                          >
                            {card.furnitureName}
                          </h2>
                        </div>
                        {/* <p className="property-desc">{card.description}</p> */}
                        <div>
                          {/* <span style={{color: "#9a291b",}}>For</span>{' '}  */}
                          <span
                            style={{
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {card.sellingOptions}
                          </span>
                        </div>
                        <div
                          style={{
                            color: "#9a291b",
                            fontSize: "20px",
                            fontWeight: "700",
                          }}
                          className="price-text-furniture propety-icons justify-content-between"
                        >
                          ₹ {card.firstTimePayment + "/mo."}
                        </div>
                      </div>
                      <div className="property-slider-buttons">
                        {/* <button className="btn-connect btn-plan">Book your plan </button>
                                    <button className="btn-connect btn-details">View Detail </button> */}
                        <ButtonContained
                          onClick={() => {
                            navigate(`/furniture/${card._id}`);
                            window.location.reload();
                          }}
                          sx={[
                            classes.borderRadius12,
                            {
                              [theme.breakpoints.between("xs", "sm")]: {
                                ...classes.fs9,
                                ...classes.margin4,
                                ...classes.borderRadius7,
                              },
                            },
                          ]}
                        >
                          Book your plan
                        </ButtonContained>
                        <ButtonOutlined
                          onClick={() => {
                            navigate(`/furniture/${card._id}`);
                            window.location.reload();
                          }}
                          sx={[
                            classes.borderRadius12,
                            {
                              [theme.breakpoints.between("xs", "sm")]: {
                                ...classes.fs9,
                                ...classes.margin4,
                                ...classes.borderRadius7,
                              },
                            },
                          ]}
                        >
                          View Detail
                        </ButtonOutlined>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>

      <ModalSignUp
        state={planModal}
        stateFunc={setPlanModal}
        // title="Choose Plan"
      >
        <ChoosePlan
          isPropertyFree={planModal}
          furnitureDetails={furnitureDetails}
          bookingPeriod={bookingPeriod}
          setCloseModal={() => setPlanModal(false)}
          setBookingPeriod={setBookingPeriod}
          setPlanModal={setPlanModal}
          onContinue={(e) => {
            onBooking();
          }}
        />
      </ModalSignUp>

      <StickyFooter />
      {isMobile ? (
        <BottomSheet
          snapPoints={({ minHeight }) => minHeight}
          open={closeModal}
          onDismiss={() => setCloseModal(false)}
        >
          <SignUp setCloseModal={setCloseModal} />
        </BottomSheet>
      ) : (
        <ModalSignUp
          state={closeModal}
          stateFunc={setCloseModal}
          title="Please fill all the Details"
        >
          <SignUp setCloseModal={setCloseModal} />
        </ModalSignUp>
      )}
      {isMobile ? (
        <BottomSheet
          snapPoints={({ minHeight }) => minHeight}
          open={closeLoginModal}
          onDismiss={() => setCloseLoginModal(false)}
        >
          <Login
            setSignupModal={setCloseModal}
            setCloseModal={setCloseLoginModal}
          />
        </BottomSheet>
      ) : (
        <ModalLogin
          state={closeLoginModal}
          stateFunc={setCloseLoginModal}
          title="Login"
        >
          <Login setCloseModal={setCloseLoginModal} />
        </ModalLogin>
      )}
    </>
  );
};

export default FurnitureDetails;
