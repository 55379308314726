import { Box, Typography, IconButton } from "@mui/material";
import React, { useState } from "react";
import styles from "../../../styles/styles";
import ButtonContained from "../../../components/Buttons/ButtonContained";
import ButtonOutlined from "../../../components/Buttons/ButtonOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from '@mui/material/styles';
const AddFurnitureStep3 = ({ data, setData, handleTextChange }) => {
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const [imagePaths, setImagePaths] = useState([]);
  const inputRef = React.useRef(null);
  const theme = useTheme();
  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  function handleDelete(file, index) {
    let paths = [...imagePaths];
    let images = [...data.photos];
    paths = paths.filter((path) => path !== file);
    images = images.filter((image, imgIndex) => {
      return imgIndex !== index;
    });
    setImagePaths(paths);
    setData({ ...data, photos: images });
  }
  function handleFile(files) {
    if (data.photos.length === 10) {
      alert("Only 10 files are allowed max.");
      return;
    }
    const arr = [...data.photos];
    let path = [...imagePaths];
    Array.from(files).forEach((file) => {
      arr.push(file);
      path.push(URL.createObjectURL(file));
    });
    setData({ ...data, photos: arr });
    setImagePaths(path);
    inputRef.current.value = "";
  }
  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (
      e.dataTransfer.files &&
      e.dataTransfer.files[0]
      //   e.dataTransfer.files[0].type === "application/pdf"
    ) {
      handleFile(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <Box sx={[{ width: '100%', marginLeft: '12px', paddingLeft: '20px', paddigRight: '8px' }, {
      [theme.breakpoints.between('xs', 'md')]: [{ marginLeft: 0, paddingLeft: 0, paddigRight: 0 }]
    }]}>
      <Typography
        sx={[
          styles.fs30,
          styles.fontFamilySGB,
          styles.fbBold,
          styles.color240501,
        ]}
      >
        Photos
      </Typography>
      <Box sx={[styles.mt40]}>
        <Box className="w-60">
          <Box sx={[{ height: "initial" },
          {
            [theme.breakpoints.between('xs', 'sm')]: [{
              maxWidth: '300px',
              minWidth: '300px'
            }]
          }]}>
            <form
              id="form-file-upload"
              onDragEnter={handleDrag}
              onSubmit={(e) => e.preventDefault()}
              enctype="multipart/form-data"
            >
              <input
                ref={inputRef}
                type="file"
                accept="image/*,*.png,*.jpeg,*.jpg"
                id="input-file-upload"
                multiple={true}
                onChange={handleChange}
              />
              <label
                id="label-file-upload"
                htmlFor="input-file-upload"
                className={dragActive ? "drag-active" : ""}
              >
                <Box
                  gap={1}
                  className="d-flex align-items-center justify-content-center flex-column"
                >
                  <CloudUploadIcon fontSize="large" sx={[styles.color9A291B]} />
                  <Typography sx={[styles.fontFamilySGR]} className="px-3">
                    {"Click and Drag to upload photos"}
                  </Typography>
                  <Typography
                    className="text-color979797"
                    sx={[styles.fs14, styles.fontFamilyDS]}
                  >
                    (Max 10 photos)
                  </Typography>
                  {/* <ContainedButton className="bg-green" onClick={onButtonClick}>Carica file</ContainedButton> */}
                </Box>
              </label>
              {dragActive && (
                <div
                  id="drag-file-element"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </form>
          </Box>
          <Box className="d-flex flex-wrap my-4">
            {imagePaths.map((photo, index) => (
              <div
                key={photo.name}
                className="mx-3 my-2 d-flex justify-content-end align-items-start m-2"
                style={{
                  backgroundImage: `url(${photo})`,
                  backgroundSize: "100%",
                  height: "62px",
                  width: "80px",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  borderRadius: "8px",
                }}
              >
                <IconButton
                  className="py-0 px-0 p-4"
                  onClick={() => handleDelete(photo, index)}
                >
                  <CloseIcon
                    fontSize="small"
                    sx={{
                      color: "#000",
                      backgroundColor: "#F5F5F5",
                      borderRadius: "20px",
                    }}
                  />
                </IconButton>
              </div>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddFurnitureStep3;
