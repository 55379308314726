import React from "react";
import Header from "../../components/Header";
import { Link } from "react-router-dom";

const SuccessfullOrder = () => {
  return (
    <div>
      <Header />
      <h3 style={{ color: "green", marginTop: "150px", textAlign: "center" }}>
        YOUR ORDER HAS BEEN PLACED SUCCESSFULLY
      </h3>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "100px",
          paddingLeft:"100px",
          
        }}
      >
        <div>
          <h4 style={{ fontWeight: "bold", color: "#9a291b" }}>
            You Need to Complete These Checks
          </h4>
          <div style={{ marginTop: "50px" }}>
            <h2 style={{ fontWeight: "bold", color: "#9a291b" }}>KYC</h2>
            <p style={{ width: "400px" }}>
              You need to complete the KYC process in order to get the
              agreement.
              
              <br></br><br></br>

               After KYC verification you will get a free agreement
              from Gharwala for your purchase.{" "}
            </p>

            <p style={{color: "#9a291b"}}>Note: If KYC already done leave it. You will get your agreement on your email.</p>
            <Link to="/profile" >
            <button
              style={{
                border: "none",
                borderRadius: "10px",
                padding: "7px 20px",
                backgroundColor: "#9a291b",
                color: "white",
                marginTop:"20px"
              }}
            >
              Go To KYC
            </button>
            </Link>
          </div>
        </div>
        <div>
          <img style={{ height: "500px" }} src="/assests/images/POSE2.png" />
        </div>
      </div>
    </div>
  );
};

export default SuccessfullOrder;
