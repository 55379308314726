import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import axios from "axios";

const HappyClients = ({sx, closeLoginModal, setCloseLoginModal, setOpenPopUp}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(
          "https://gharwala-5466fddd6458.herokuapp.com/api/get-all-testimonials"
        );
        console.log(response.data.testimonials);
        setData(response.data.testimonials);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };
    fetchTestimonials();
  }, []);

  return (
    <>
      <Header closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp} />
      <h3 style={{
        marginTop:"120px",
        textAlign:"center"
      }}>Testimonials based on Location</h3>
     

     <div style={{
      maxWidth: "1200px",
      margin:"auto",
      gap:"20px",
      flexWrap:"wrap",
      justifyContent:"center"
     }} className="flex">
     {data.map((item, index) =>
        item.pincode && (
          <div
            style={{
              marginTop: "40px",
              display: "flex",
              justifyContent: "center",
             
            }}
          >
            <div
              style={{
                width: "600px",
                overflow:"auto",
                padding: "20px",
                borderRadius:"10px",
                boxShadow:"2px 2px 10px 5px #cacaca"
              }}
            >
              

             
              <div style={{ marginTop: "0px" }}>
                <div
                  style={{
                    color:"#9a291b",
                    borderRadius: "4px",
                    padding: "4px",
                    fontSize:"20px",
                    fontWeight:"bold"
                  }}
                >
                  Pincode : {item.pincode}
                </div>
                <div
                  style={{
                    marginTop: "6px",
                    borderRadius: "6px",
                    padding: "4px",
                  }}
                >
                  {item.description}
                </div>
              </div>
            </div>
          </div>
        )
      )}
     </div>
    </>
  );
};
export default HappyClients;
