import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const location = useLocation();

  return (
    <div
      style={{ display: location.pathname.includes('final') ? 'none' : 'flex' }}
      className='sidebar'
    >
      <Link
        to='/agreement/basic-details'
        className={`sidebar-link ${location.pathname.includes('basic') && 'sidebar-link_active'}`}
      >
        <div className='sidebar-link-dot'></div>Basic Details
      </Link>
      {/* <Link
        to='/agreement/property-details'
        className={`sidebar-link ${location.pathname.includes('property') && 'sidebar-link_active'}`}
      >
        <div className='sidebar-link-dot'></div>Property Details
      </Link> */}
      <Link
        to='/agreement/contract-details'
        className={`sidebar-link ${location.pathname.includes('contract') && 'sidebar-link_active'}`}
      >
        <span className='sidebar-link-dot'></span>Contract Details
      </Link>

      <Link
        to='/agreement/property-details'
        className={`sidebar-link ${location.pathname.includes('property') && 'sidebar-link_active'}`}
      >
        <span className='sidebar-link-dot'></span>Property Details
      </Link>

      <Link
        to='/agreement/furnishing-details'
        className={`sidebar-link ${location.pathname.includes('furnishing') && 'sidebar-link_active'}`}
      >
        <span className='sidebar-link-dot'></span>Furnishing Details(Optional)
      </Link>
    </div>
  );
};

export default Sidebar;
