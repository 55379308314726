import React, { useRef, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Checkbox,
  IconButton,
  Typography,
  Slider,
  MenuItem,
  Chip,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import toast from "react-hot-toast";
import BusSvg from "../../assets/images/bus.svg";
import styles from "../../styles/styles.js";
import { SliderThumb } from "@mui/material/Slider";
import Select from "@mui/material/Select";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import classes from "../../styles/styles.js";
import ButtonContained from "../../components/Buttons/ButtonContained";
import ButtonOutlined from "../../components/Buttons/ButtonOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { API_KEY, assetUrl, privateReq } from "../../config/axiosConfig";
import { getFurnitures } from "../../redux/action/furniture";
import { useMediaQuery } from "react-responsive";
import { Watermark } from '@hirohe/react-watermark';

import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { checkTabsStatus } from "../../config/user.js";

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: "rgba(154, 41, 27, 1)",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    // border: "1px solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));




const rentPeriod = [
  {
    value: 3,
    label: "3M",
  },
  {
    value: 6,
    label: "6M",
  },
  {
    value: 9,
    label: "9M",
  },
  {
    value: 12,
    label: "12M",
  },
];

function SliderThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <FiberManualRecordIcon sx={{ fontSize: "10px" }} />
    </SliderThumb>
  );
}

const Furniture = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  const headerRef = useRef();
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [rentPeriodVal, setRentPeriodVal] = useState(6);
  const { furnitures } = useSelector((state) => state.furniture);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [productType, setProductType] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [showFilter, setShowFilter] = useState(false);
  const [locality, setLocality] = useState({});
  const [wantTo, setWantTo] = useState([]);
  const [buyOrRent, setBuyOrRent] = useState("rent");
  const [furnitureSell ,setFurnitureSell] = useState(false);
const [furnitureBuy,setFurnitureBuy] = useState(false)
  const [categoryData, setCategoryData] = useState({
    Category: [],
    SubCategory: [],
  });

  const getFurnitureStatus = async (  )=>{
    const results = await checkTabsStatus({propertyName:'FURNITURESELL'})
    const results1 = await checkTabsStatus({propertyName:'FURNITUREBUY'})
    console.log(results1);
    setFurnitureSell(results.data.message.enabled)
    setFurnitureBuy(results1.data.message.enabled)
  }

  useEffect(() => {
    setHeaderHeight(headerRef.current.offsetHeight);
  }, [headerHeight]);

  useEffect(() => {
    onGetCategory();
    getFurnitureStatus()
  }, []);

  const onGetCategory = async () => {
    try {
      const res = await privateReq.get("get-category");
      const rentData = res?.data?.category.filter(
        (item) => item.type.toLowerCase() === buyOrRent.toLowerCase()
      );
      setCategoryData((prevState) => ({
        ...prevState,
        Category: rentData,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const onGetSubCategory = async (id) => {
    try {
      const res = await privateReq.post(`get-subcategory`, {
        id,
      });
      setCategoryData((prevState) => ({
        ...prevState,
        SubCategory: res?.data?.getSubcategory,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const onCheck = (state, val, setState, type, item) => {
    if (type === "category") {
      const isSelected = categoryId.includes(item._id);
      if (isSelected) {
        setCategoryId(categoryId.filter((id) => id !== item._id));
      } else {
        setCategoryId([...categoryId, item._id]);
      }
      onGetSubCategory([...categoryId, item._id]);
    }
    const arr = [...state];
    if (arr.includes(val)) {
      let idx = arr.findIndex((i) => i === val);
      arr.splice(idx, 1);
    } else {
      arr.push(val);
    }
    setState(arr);
  };

  useEffect(() => {
    console.log("buyrendt", buyOrRent);
  }, [buyOrRent]);

  useEffect(() => {
    if (location.search) {
      let params = new URLSearchParams(location.search);
      if (params.get("category")) {
        setCategory([params.get("category")]);
      }
      if (params.get("wantto")) {
        setWantTo([params.get("wantto")]);
      }
      if (params.get("productType")) {
        setProductType([params.get("productType")]);
        setBuyOrRent(params.get("productType"));
      }
      let timeout = setTimeout(() => {
        let query = `?category=${[[params.get("category")]]}`;
        dispatch(getFurnitures(query));
        clearTimeout(timeout);
      }, 500);
    } else {
      onApplyFilter();
    }
  }, []);

  useEffect(() => {
    (async function () { })();
  }, [buyOrRent]);

  function onResetFilter(e) {
    e.preventDefault();
    setCategory([]);
    setWantTo([]);
    setProductType([]);
    dispatch(getFurnitures(""));
    setShowFilter(false);
  }

  const onApplyFilter = async () => {
    // event.preventDefault();
    try {
      const data = {
        category,
        productType,
      };
      let loc =
        locality.lng && locality.lat ? locality : await getLocLatLng(locality);
      if (loc?.lng && loc?.lat) {
        data.longitude = loc?.lng;
        data.latitude = loc?.lat;
      }
      let query = `?`;
      Object.keys(data).map((key, idx) => {
        if (Array.isArray(data[key])) {
          data[key].forEach((item, index) => {
            query += `${idx > 0 || index > 0 ? "&" : ""}${key}=${item}`;
          });
        } else {
          query += `${idx > 0 ? "&" : ""}${key}=${data[key]}`;
        }
      });

      dispatch(getFurnitures(query));
      setShowFilter(false);
    } catch (e) {
      console.log(e);
    }
  };
  function deleteFurniture(id) {

    privateReq.delete(`/delete-furniture/${id}`).then((res) => {
      if (res.status === 200) {
        toast.success('furniture deleted')
      }
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      dispatch(getFurnitures(""));
    })

  }


  const getLocLatLng = (district) => {
    return geocodeByAddress(district?.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log("Successfully got latitude and longitude", { lat, lng });
        return { lat, lng };
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        innerRef={headerRef}
      />
      <div style={{marginTop: "40px"}} className="container-fluid px-0">
        <div className="properties-container bgF4F4F4">
          <div className="row mx-0  w-100">
            {isMobile && (
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
                sx={[
                  classes.colorADB0B6,
                  classes.fs20,
                  classes.fontFamilyDS,
                  classes.fbBold,
                  classes.textDecorationUL,
                  {
                    cursor: "pointer",
                    zIndex: 9,
                    marginLeft: "auto",
                    textAlign: "right",
                  },
                  {
                    [theme.breakpoints.between("xs", "md")]: {
                      ...classes.fs14,
                      ...classes.fbBold,
                      marginLeft: "auto",
                    },
                  },
                ]}
              >
                Show Filters
              </Typography>
            )}
            {(isMobile && showFilter) || !isMobile ? (
              <div
                className="col-lg-3 col-md-3 col-xs-12 bg-white p-4 container-scrollbar"
                style={{
                  height: isMobile ? "auto" : `calc(100vh - ${headerHeight}px)`,
                  overflowY: "scroll",
                }}
              >
                <Box
                  sx={[
                    classes.p10,
                    classes.dflex,
                    classes.jcSBetween,
                    classes.alignItemCenter,
                  ]}
                  className="mt-2"
                >
                  <Typography
                    component="div"
                    variant="h5"
                    sx={[
                      classes.fontFamilySGB,
                      classes.fbBold,
                      classes.fs24,
                      classes.dflex,
                      classes.color240501,
                      {
                        [theme.breakpoints.between("xs", "md")]: {
                          ...classes.fs17,
                        },
                      },
                    ]}
                  >
                    Filters
                  </Typography>
                  <Typography
                    onClick={onResetFilter}
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    sx={[
                      classes.colorADB0B6,
                      classes.fs20,
                      classes.fontFamilyDS,
                      classes.fbBold,
                      classes.textDecorationUL,
                      {
                        cursor: "pointer",
                      },
                      {
                        [theme.breakpoints.between("xs", "md")]: {
                          ...classes.fs14,
                          ...classes.fbBold,
                        },
                      },
                    ]}
                  >
                    Reset Filters
                  </Typography>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    marginBottom: "20px",
                  }}
                >
     {    furnitureBuy &&         <button
                    style={{
                      flex: "1",
                      backgroundColor:
                        buyOrRent === "buy" ? "#9A291B" : "white",
                      border: `1px solid ${buyOrRent === "buy" ? "white" : "#9A291B"
                        }`,
                      color: buyOrRent === "buy" ? "white" : "#9A291B",
                      padding: "10px",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                      fontWeight: "bold",
                    }}
                    onClick={(e) => setBuyOrRent("buy")}
                  >
                    Buy
                  </button>}
            { furnitureSell &&     <button
                    style={{
                      flex: "1",
                      backgroundColor:
                        buyOrRent === "rent" ? "#9A291B" : "white",
                      border: `1px solid ${buyOrRent === "rent" ? "white" : "#9A291B"
                        }`,
                      color: buyOrRent === "rent" ? "white" : "#9A291B",
                      padding: "10px",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                      fontWeight: "bold",
                    }}
                    onClick={(e) => setBuyOrRent("rent")}
                  >
                    Rent
                  </button>}
                </div>
                <GooglePlacesAutocomplete
                  apiKey={API_KEY}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: ["in"],
                    },
                  }}
                  selectProps={{
                    district: locality,
                    onChange: setLocality,
                    placeholder: "Enter City, Locality",
                  }}
                />
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                  sx={[
                    classes.colorADB0B6,
                    classes.fs20,
                    classes.fontFamilyDS,
                    classes.fbBold,
                    classes.p10,
                    {
                      [theme.breakpoints.between("xs", "md")]: {
                        ...classes.fs17,
                      },
                    },
                  ]}
                >
                  Category
                </Typography>
                {/* <Box
                  className='d-flex justify-content-between w-100 align-items-center'
                  sx={classes.px10}
                >
                  <Typography
                    sx={[
                      classes.fs16,
                      classes.color240501,
                      classes.fontFamilyDS,
                      {
                        [theme.breakpoints.between('xs', 'md')]: {
                          ...classes.fs14,
                        },
                      },
                    ]}
                  >
                    Buy
                  </Typography>
                  <Checkbox
                    value='sell'
                    onChange={(e) => onCheck(wantTo, e.target.value, setWantTo)}
                    checked={wantTo.includes('sell')}
                    sx={{
                      '&.Mui-checked': classes.color9A291B,
                    }}
                  />
                </Box>
                <Box
                  className='d-flex justify-content-between w-100 align-items-center'
                  sx={classes.px10}
                >
                  <Typography
                    sx={[
                      classes.fs16,
                      classes.color240501,
                      classes.fontFamilyDS,
                      {
                        [theme.breakpoints.between('xs', 'md')]: {
                          ...classes.fs14,
                        },
                      },
                    ]}
                  >
                    Rent
                  </Typography>
                  <Checkbox
                    value={'rent'}
                    onChange={(e) => onCheck(wantTo, e.target.value, setWantTo)}
                    checked={wantTo.includes('rent')}
                    sx={{
                      '&.Mui-checked': classes.color9A291B,
                    }}
                  />
                </Box> */}

                {categoryData?.Category?.map((item) => {
                  return (
                    <Box
                      className="d-flex justify-content-between w-100 align-items-center"
                      sx={classes.px10}
                    >
                      <Typography
                        sx={[
                          classes.fs16,
                          classes.color240501,
                          classes.fontFamilyDS,
                          {
                            [theme.breakpoints.between("xs", "md")]: {
                              ...classes.fs14,
                            },
                          },
                        ]}
                      >
                        {item?.name}
                      </Typography>
                      <Checkbox
                        value={item?.name}
                        onChange={(e) =>
                          onCheck(
                            category,
                            e.target.value,
                            setCategory,
                            "category",
                            item
                          )
                        }
                        checked={category.includes(item?.name)}
                        sx={{
                          "&.Mui-checked": classes.color9A291B,
                        }}
                      />
                    </Box>
                  );
                })}

                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                  sx={[
                    classes.colorADB0B6,
                    classes.fs20,
                    classes.fontFamilyDS,
                    classes.fbBold,
                    classes.p10,
                    {
                      [theme.breakpoints.between("xs", "md")]: {
                        ...classes.fs17,
                      },
                    },
                  ]}
                >
                  Sub Category
                </Typography>

                {categoryData?.SubCategory?.map((item) => {
                  return (
                    <Box
                      className="d-flex justify-content-between w-100 align-items-center"
                      sx={classes.px10}
                    >
                      <Typography
                        sx={[
                          classes.fs16,
                          classes.color240501,
                          classes.fontFamilyDS,
                          {
                            [theme.breakpoints.between("xs", "md")]: {
                              ...classes.fs14,
                            },
                          },
                        ]}
                      >
                        {item?.name}
                      </Typography>
                      <Checkbox
                        value={item?.name}
                        onChange={(e) =>
                          onCheck(productType, e.target.value, setProductType)
                        }
                        checked={productType.includes(item?.name)}
                        sx={{
                          "&.Mui-checked": classes.color9A291B,
                        }}
                      />
                    </Box>
                  );
                })}

                <Box>
                  <ButtonOutlined
                    onClick={onApplyFilter}
                    sx={[
                      classes.width95,
                      classes.fbBold,
                      classes.fontFamilySGB,
                      classes.fs16,
                      classes.borderRadius12,
                      classes.mt50,
                    ]}
                  >
                    Apply Filter
                  </ButtonOutlined>
                </Box>
              </div>
            ) : null}
            <div
              className="col-lg-9 col-md-9 col-xs-12"
            // style={{
            //   height: `calc(100vh - ${headerHeight}px)`,
            //   overflowY: "scroll",
            // }}
            >
              <div className="row properties-list mx-0 w-100">
                <div style={{marginBottom: "10px"}} className="properties-header mt-5 px-3">
                  <div>
                    <span>{`${user && user?.role === "admin"
                      ? "All furnitures"
                      : "Our recommendations"
                      }`}</span>
                  </div>
                  <div
                    className="properties-filter-select"
                    style={{ marginRight: "1.25rem" }}
                  ></div>
                </div>
                <div className="d-flex flex-wrap">
                  {furnitures?.data?.map(
                    (card, index) =>
                      ((buyOrRent === "buy" && card.sellingOptions === "buy") ||
                        (buyOrRent === "rent" &&
                          card.sellingOptions === "rent") ||
                        buyOrRent === "") && (
                        <div
                          className={`col-lg-4 col-md-4 col-sm-12 ${isMobile && " w-100"
                            }`}
                          key={index}
                        >
                          <div className="card-container-furniture bg-transparent mb-2">
                            <div className="card card-furniture">
                              <Watermark gutter={60} rotate={0}  textSize={30} text="Gharwalah.com"  multiline={false}>
                              <div
                                className="card-image"
                                onClick={() =>
                                  navigate(`/furniture/${card._id}`)
                                }
                                style={{
                                  backgroundImage: `url(${card.image})`,
                                  backgroundSize: "100%",
                                  backgroundRepeat: "no-repeat",
                                  backgroundClip: "content-box",
                                  backgroundPosition: "center",
                                }}
                              >
                                {/* <div className="d-flex flex-column justify-content-between h-100">
                                  <div className="d-flex justify-content-end"></div>
                                  <div className="d-flex justify-content-start align-items-center">
                                    <Chip
                                      sx={{
                                        borderRadius: "5px",
                                        backgroundColor: "#FFF",
                                        bottom: "0px",
                                        margin: "10px",
                                        color: "#9A291B!important",
                                        fontWeight: 700,
                                        fontSie: "12px",
                                      }}
                                      label={card.avgRating}
                                      onDelete={() => { }}
                                      deleteIcon={
                                        <StarIcon
                                          sx={{
                                            color: "#9A291B!important",
                                            alignSelf: "center",
                                          }}
                                        />
                                      }
                                      variant="contained"
                                    />
                                  </div>
                                </div> */}
                              </div>
                              </Watermark>
                              <div className="text-info text-info-furniture">
                                <div className="slider-card-header">
                                  <h2 className="slider-title slider-title-furniture">
                                    {card.furnitureName}
                                  </h2>
                                </div>
                                <div style={{
                                  height:"40px"
                                }}>
                                   {/* <span style={{color: "#9a291b",}}>For</span>{' '}  */}
                                   <span style={{color: "black", paddingInline: "0px", borderRadius: "8px", textTransform: "capitalize"}}>For {card.sellingOptions}</span>
                                </div>
                                <div className="propety-icons justify-content-between w-full">
                                  <div style={{
                                    width:"100%"
                                  }} className="property-icon">
                                    <div style={{
                                      width:"100%"
                                    }} className="slider-title price-text-furniture">
                                      {card.sellingOptions === "buy" ? (
                                        "₹" + card.firstTimePayment
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "10px",
                                            width:"100%"
                                          }}
                                        >
                                          <div>
                                            {"₹" +
                                              card.expectedMonthlyRent +
                                              "/mo."}
                                          </div>
                                          <div
                                            className="justify-content-between"
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              width:"100%",
                                              flexGrow:"1"
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontSize: "12px",
                                                color: "black",
                                              }}
                                            >
                                              Security Deposit:
                                            </div>
                                            <div style={{ fontSize: "14px" }}>
                                              {"₹" +
                                                card.expectedSecurityDeposit}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="btn-group">
                                <div className="btn-group-vertical mx-2 w-50" role="group" aria-label="Vertical button group">
                                  <ButtonContained
                                    onClick={() =>
                                      navigate(`/furniture/${card._id}`)
                                    }
                                    sx={[
                                      styles.fontFamilySGB,
                                      styles.p10,
                                      styles.borderRadius12,
                                      classes.fs12,
                                      classes.margin4,
                                      classes.p10,
                                      {
                                        [theme.breakpoints.between("xs", "md")]: {
                                          ...classes.fs8,
                                          ...classes.margin4,
                                          ...classes.borderRadius7,
                                          ...classes.p9,
                                        },
                                      },
                                    ]}
                                    className="m-0 mx-1 my-2"
                                  >
                                    Book your plan
                                  </ButtonContained>
                                  {
                                    user?.role === 'admin' ? (
                                      <ButtonOutlined
                                        onClick={() =>
                                          navigate(`/edit-furniture/${card._id}`)
                                        }
                                        sx={[
                                          styles.fontFamilySGB,
                                          styles.p10,
                                          styles.borderRadius12,
                                          classes.fs12,
                                          classes.margin4,
                                          classes.p10,
                                          {
                                            [theme.breakpoints.between("xs", "md")]: {
                                              ...classes.fs9,
                                              ...classes.margin4,
                                              ...classes.borderRadius7,
                                              ...classes.p7,
                                            },
                                          },
                                        ]}
                                        className="m-0 mx-1 my-2"
                                      >
                                        Edit
                                      </ButtonOutlined>
                                    ) : ('')
                                  }


                                </div>


                                <div className="btn-group-vertical mx-2 w-50">
                                
                                  <div className="btn-group-vertical" role="group" aria-label="Vertical button group">

                                    <ButtonOutlined
                                      onClick={() =>
                                        navigate(`/furniture/${card._id}`)
                                      }
                                      sx={[
                                        styles.fontFamilySGB,
                                        styles.p10,
                                        styles.borderRadius12,
                                        classes.fs12,
                                        classes.margin4,
                                        classes.p10,
                                        {
                                          [theme.breakpoints.between("xs", "md")]: {
                                            ...classes.fs9,
                                            ...classes.margin4,
                                            ...classes.borderRadius7,
                                            ...classes.p7,
                                          },
                                        },
                                      ]}
                                      className="m-0 mx-1 my-2"
                                      style={isMobile ? { width: '35vw' } : { width: '10vw' }}
                                    >
                                      View Detail
                                    </ButtonOutlined>

                                    {
                                      user?.role === 'admin' ? (
                                        <ButtonOutlined
                                          onClick={() =>
                                            deleteFurniture(card._id)
                                          }
                                          sx={[
                                            styles.fontFamilySGB,
                                            styles.p10,
                                            styles.borderRadius12,
                                            classes.fs12,
                                            classes.margin4,
                                            classes.p10,
                                            {
                                              [theme.breakpoints.between("xs", "md")]: {
                                                ...classes.fs9,
                                                ...classes.margin4,
                                                ...classes.borderRadius7,
                                                ...classes.p7,
                                              },
                                            },
                                          ]}
                                          className="m-0 mx-1 my-2"
                                          style={isMobile ? { width: '35vw' } : { width: '10vw' }}
                                        >
                                          Delete
                                        </ButtonOutlined>
                                      ) : ('')
                                    }
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <StickyFooter /> */}
      <Footer />
    </>
  );
};

export default Furniture;
