import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "../../../styles/styles";
import OutlinedFormInput from "../../../components/Input/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import { usePlacesWidget } from "react-google-autocomplete";
import { API_KEY } from "../../../config/axiosConfig";

const AddPropertyStep3 = ({
  setActiveStep,
  activeStep,
  setData,
  data,
  handleTextChange,
}) => {
  const { ref } = usePlacesWidget({
    apiKey: API_KEY,
    options: {
      componentRestrictions: { country: "in" },
      types: ["(regions)"],
    },
    onPlaceSelected: (place) => {
      console.log('place',place)
      setData({
        ...data,
        locality: [
          place.geometry.location.lng(),
          place.geometry.location.lat(),
          place.formatted_address
        ],
      });
    },
  });

  const theme = useTheme();
  return (
    <Box
      sx={[
        {
          width: "100%",
          marginLeft: "12px",
          paddingLeft: "20px",
          paddigRight: "8px",
        },
        {
          [theme.breakpoints.between("xs", "md")]: [
            { marginLeft: 0, paddingLeft: 0, paddigRight: 0 },
          ],
        },
      ]}
    >
      <Typography
        sx={[
          styles.fs30,
          styles.fontFamilySGB,
          styles.fbBold,
          styles.color240501,
        ]}
      >
        Location Details
      </Typography>
      <Box sx={[styles.mt40]}>
        <Box className="w-60">
          

          <Box className="mt-3">
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2"
            >
              Locality *
            </Typography>
            <OutlinedFormInput
              placeholder={"Bidhhannagar"}
              inputRef={ref}
              // value={data.locality}
              // onChange={(e) => {
              //   handleTextChange("locality", e.target.value);
              // }}
              sx={[
                { height: "initial" },
                {
                  [theme.breakpoints.between("xs", "sm")]: [
                    {
                      maxWidth: "300px",
                      minWidth: "300px",
                    },
                  ],
                },
              ]}
            />
          </Box>

          <Box className="mt-3">
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2"
            >
              Area
            </Typography>
            <OutlinedFormInput
              placeholder={"Bidhhannagar"}
              
              // value={data.locality}
              // onChange={(e) => {
              //   handleTextChange("locality", e.target.value);
              // }}
              sx={[
                { height: "initial" },
                {
                  [theme.breakpoints.between("xs", "sm")]: [
                    {
                      maxWidth: "300px",
                      minWidth: "300px",
                    },
                  ],
                },
              ]}
            />
          </Box>

          <Box className="mt-3">
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2"
            >
              PIN Code
            </Typography>
            <OutlinedFormInput
              value={data.pinCode}
              placeholder={"751003"}
              onChange={(e) => handleTextChange("pinCode", e.target.value)}
              sx={[
                { height: "initial" },
                {
                  [theme.breakpoints.between("xs", "sm")]: [
                    {
                      maxWidth: "300px",
                      minWidth: "300px",
                    },
                  ],
                },
              ]}
            />
          </Box>

          <Box className="mt-3">
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2"
            >
              Apartment/Society
            </Typography>
            <OutlinedFormInput
              // disabled={data.propertyType === "land"}
              value={data.apartmentSociety}
              placeholder={"Manjula"}
              onChange={(e) =>
                handleTextChange("apartmentSociety", e.target.value)
              }
              sx={[
                { height: "initial" },
                {
                  [theme.breakpoints.between("xs", "sm")]: [
                    {
                      maxWidth: "300px",
                      minWidth: "300px",
                    },
                  ],
                },
              ]}
            />
          </Box>
          
          <Box className="mt-3">
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2"
            >
              Landmark
            </Typography>
            <OutlinedFormInput
              placeholder={"Near Sani Thakur Mandir"}
              value={data.landMark}
              onChange={(e) => handleTextChange("landMark", e.target.value)}
              sx={[
                { height: "initial" },
                {
                  [theme.breakpoints.between("xs", "sm")]: [
                    {
                      maxWidth: "300px",
                      minWidth: "300px",
                    },
                  ],
                },
              ]}
            />
          </Box>

          
        </Box>
      </Box>
    </Box>
  );
};

export default AddPropertyStep3;
