import React from "react";
import { useNavigate } from "react-router-dom";
import OrderFail from "../../assets/images/RED-CROSS-GIF.gif";

const UnsuccessfulOrder = () => {
    const navigate = useNavigate();

    const handleTryAgain = () => {
        navigate("/checkout");
    };

    return (
        <div>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "80vh", overflow: "-moz-hidden-unscrollable"}}>
            <img style={{width: "200px"}} src={OrderFail} alt="fail-img"/>
            <div style={{fontWeight: "700", fontSize: "50px"}}>Order unsuccessful</div>
            <button onClick={handleTryAgain} style={{backgroundColor: "green", marginTop: "20px"}}>Try Again</button>
          </div>
        </div>
    )
}

export default UnsuccessfulOrder;