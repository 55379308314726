import React from "react";
import Header from "../../../components/Header";
import { useMediaQuery } from "react-responsive";
import StickyFooterMobile from "../../../components/FooterMobile";
import StickyFooter from "../../../components/Footer";

const MainTerms = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{
        height: "100vh",
      }}
    >
      <Header />
      <div className="container" style={{ paddingTop: "125px" }}>
        <h2 className="h3-about-tems" style={{ marginBottom: "15px" }}>
          Terms and Conditions of Gharwalah.com
        </h2>{" "}
        <br />
        <p className="para-about-terms">
          {" "}
          1. Acceptance of Terms:<br></br> By accessing or using Gharwalah.com,
          you agree to abide by these Terms and Conditions. <br></br>
          <br></br>2. User Responsibilities:<br></br> Users are responsible for
          the accuracy of the information provided during registration and use
          of the platform. Users must not engage in any unlawful activities or
          misuse the services provided by Gharwalah.com. <br></br>
          <br></br>3. Property Listings:
          <br></br> Property listings must be accurate, complete, and adhere to
          all relevant laws and regulations. Gharwalah.com reserves the right to
          remove any listing that violates its policies. <br></br>
          <br></br>4. Property Renting:<br></br> Users engaging in property
          renting must comply with the terms agreed upon in the rental
          agreement. Gharwalah.com is not responsible for any disputes arising
          from property renting transactions. <br></br>
          <br></br>5. Property Buying:
          <br></br> Users engaging in property buying must adhere to the terms
          of the purchase agreement. Gharwalah.com does not guarantee the
          accuracy of property information and recommends verification.{" "}
          <br></br>
          <br></br>6. Property Selling:<br></br> Users listing properties for
          sale must provide accurate details and adhere to legal obligations.
          Gharwalah.com is not involved in the negotiation or execution of
          property sale agreements. <br></br>
          <br></br>7. Furniture Renting:
          <br></br> Gharwalah.com provides furniture renting services with its
          inventory. Users renting furniture must agree to the terms
          specified in the furniture rental agreement. <br></br>
          <br></br>8. Home Maintenance Services:<br></br> Gharwalah.com
          aggregates third-party vendors for home maintenance services. Users
          booking home maintenance services must follow the terms set by the
          respective service providers. <br></br>
          <br></br>9. Payment and Fees:<br></br> Payments for property
          transactions, furniture renting, and service bookings are subject to
          the agreed terms between parties. Gharwalah.com may charge fees for
          premium services, and users will be notified in advance. <br></br>
          <br></br>10. Intellectual Property:<br></br> All content and materials
          on the platform are the intellectual property of Gharwalah.com. Users
          must not reproduce, distribute, or use any content without explicit
          permission. <br></br>
          <br></br>11. Privacy Policy:<br></br> User data is handled by
           the Privacy Policy of Gharwalah.com. By using the
          platform, users consent to the collection and use of their
          information. <br></br>
          <br></br>12. Termination of Services:
          <br></br> Gharwalah.com reserves the right to terminate or suspend
          services to any user violating the Terms and Conditions. Users can
          terminate their accounts by following the specified procedures.{" "}
          <br></br>
          <br></br>13. Liability:<br></br> Gharwalah.com is not liable for any
          direct or indirect damages resulting from the use of the platform.
          Users engage with the platform at their own risk. <br></br>
          <br></br>
          14. Amendments to Terms:<br></br> Gharwalah.com may update or modify
          these Terms and Conditions at any time. Users will be notified of any
          changes, and continued use implies acceptance of the amended terms.{" "}
          <br></br>
          <br></br>15. Governing Law:<br></br> These Terms and Conditions are
          governed by the laws of the jurisdiction of the state of West Bengal. Any
          disputes arising will be subject to the exclusive jurisdiction of the
          courts in the state of West Bengal. By using Gharwalah.com, you
          acknowledge that you have read, understood, and agreed to these Terms
          and Conditions. If you do not agree, please refrain from using the
          platform. <br></br>
          <br></br>Last Updated:
          <b> 20-01-2024</b>
        </p>
      </div>
      <div>{isMobile ? <StickyFooterMobile /> : <StickyFooter />}</div>
    </div>
  );
};

export default MainTerms;
