import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { authLoginService, verifyOtpService } from '../../config/user';
import { validateField } from '../../util/json/helpers';
import { setCurrentUser, setCurrentLocation } from '../../redux/action/auth';
import { useMediaQuery } from "react-responsive";
import "./index.css"

export default function Login({ setCloseModal, setSignupModal }) {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [loader, setLoader] = useState(false);
  const [password, setPassword] = useState('');
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState('');
  const [hashedOtp, setHashedOtp] = useState('');
  const [sessionId, setSessionId] = useState('');
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();
  // const [toast, addToast] = useState(0);
  // const toaster = useRef();
  const dispatch = useDispatch();

  const emailInputRef = useRef(null);
  const otpInputRef = useRef(null);

  useEffect(() => {
    if (showOtp) {
      otpInputRef.current?.blur(); // Prevent focusing on OTP input
    } else {
      emailInputRef.current?.blur(); // Prevent focusing on email input
    }
  }, [showOtp]);

  const getPosition = (loc) => {
    if (loc.coords !== undefined) {
      dispatch(setCurrentLocation(loc.coords));
    }
  };
  const sendOtp = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await authLoginService({
        phone: email,
        // password,
      });
      if (res.data.status) {
        toast.success(res.data.message, {
          position: 'top-right',
        });
        setLoader(false);
        setShowOtp(true);
        // setHashedOtp(res.data.hashedOtp);
        setSessionId(res.data.details);
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          style: {
            zIndex: 99994,
          },
        });
        setLoader(false);
      }
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    let validate = true;
    let phoneReg = /^([+]\d{2})?\d{10}$/;
    if (email.trim() === '') {
      validateField('email', 'Mobile no. is required');
      validate = false;
    }
    if (!phoneReg.test(email)) {
      validateField('email', 'Invalid Mobile no.');
      validate = false;
    }

    if (email.trim().length != 10) {
      validateField('email', 'Mobile no. should be of 10 numbers only');
      validate = false;
    }

    if (password.trim() === '') {
      validateField('password', 'OTP is required');
      validate = false;
    }

    if (password.trim().length < 6 || password.trim().length > 6) {
      validateField('password', 'Invalid OTP');
      validate = false;
    }

    if (!validate) {
      //   toast("Please fill required fields")
      return;
    }
    try {
      setLoader(true);
      const res = await verifyOtpService({
        phone: email,
        otp: password,
        details: sessionId,
        // otp,
        // password,
      });
      if (res.data.token) {
        if (res.data.status) {
          
          localStorage.setItem('token', res.data.token);
          

          dispatch(
            setCurrentUser(res.data.token, () => {
              toast.success('Login Succesfully');
              let redirect = new URLSearchParams(window.location.search).get('redirect');
              if (redirect) {
                navigate(redirect, { replace: true });
              }
              setLoader(false);
              // setIsLogin(true)
            })
          );

          if(window.innerWidth<900){
            window.location.href="/";
           }
           else{
            setCloseModal(false);
           }
        }
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          style: {
            zIndex: 99994,
          },
        });
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      if (err?.response?.data?.message) {
        toast(err.response.data.message);
      } else {
        console.log(err);
      }
    }
  };

  return (
    <div 
      className='container mt-2 phone-height'
    >
      <div class='row g-3 align-items-center'>
        {/* <div className="col-6 user-icon-auth">
          <img src="/assests/images/user.png" />
        </div> */}
        <p className='text-center'>
          {showOtp ? 'Enter OTP' : 'Welcome to Gharwala'}
          {/* <sup style={{ fontSize: "15px" }}>TM</sup> */}
        </p>
        <form
          onSubmit={(e) => {
            if (showOtp) onSubmit(e);
            else sendOtp(e);
          }}
        >
          {!showOtp && (
            <div class='col-12 form-group'>
              <label
              style={
                {
                  fontFamily:"Montserrat"
                }
              }
                type='text'
                class='col-form-label auth-labels'
                placeholder='Enter your full name'
              >
                Enter your mobile number to Login
              </label>

              <input
                ref={otpInputRef}
                type='number'
                class='form-control'
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                autocomplete='off'
                name='email'
              />
            </div>
          )}

          {showOtp && (
            <div class='col-12 form-group'>
              <label
                for='inputEmail'
                class='col-form-label auth-labels text-3xl'
              >
                <p
                  style={{
                    fontSize: '30px',
                  }}
                >
                  OTP
                </p>
              </label>

              <input
                ref={otpInputRef}
                placeholder='Enter OTP'
                type='text'
                id='inputEmail'
                class='form-control'
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                autocomplete='off'
                name='password'
              />
            </div>
          )}
          <div className='mt-4'>
            {!showOtp && (
              <p>
                By continuing, you agree to <strong>Gharwala{/* <sup style={{ fontSize: "15px" }}>TM</sup> */}’s</strong> Terms of Service; Opens a new tab and
                acknowledge you've read our Privacy Policy.
              </p>
            )}
          </div>
          <div className='col-12 mt-5'>
            <button
              disabled={loader}
              className='btn-auth-signup mb-2'
            >
              {loader ? 'Please wait...' : 'Continue'}
            </button>

            {/* <Link 
            to="#"
            onClick={() => {
              setCloseModal(false);
              setSignupModal(true);
            }}
            >
             <button
               disabled={loader}
               className='btn-auth-signup mb-2'
             >
               {loader ? 'Please wait...' : 'Continue'}
             </button>
            </Link> */}

            <Link
              className='auth-labels'
              to='#'
              onClick={() => {
                setSignupModal(true);
                setCloseModal(false);
                
              }}
            >
              Don't have an account? Signup
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
