import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { checkPaymentStatus } from "../config/user";

const CheckPaymentStatus = () => {
  useEffect(async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const transactionId = urlSearchParams.get("transactionId") || "";
    const furnitures = urlSearchParams.get("furnitures") || "";
    const period = parseInt(urlSearchParams.get("period")) || 0;

    const res = await checkPaymentStatus({
      transactionId,
      furnitures,
      period,
    });
    if (res.data) {
      if (res.data.data.success) {
        window.location.href = "/furniture/order";
      } else {
        window.location.href = "/";
      }
    }
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6 text-center">
          {/* Loader */}
          <div
            className="loader"
            style={{
              border: "8px solid #f3f3f3",
              borderTop: "8px solid #3498db",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              animation: "spin 1s linear infinite",
              margin: "20px auto", // Adjust margin to center the loader vertically
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default CheckPaymentStatus;
