import React from 'react';
import cash from '../../../assets/images/cash.png';
import logo from '../../../assets/images/logofull.png';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import Roboto from '../../../assets/fonts/Roboto-Regular.ttf';
import RobotoBold from '../../../assets/fonts/Roboto-Bold.ttf';

Font.register({
  family: 'Roboto',
  fonts: [{ src: Roboto }, { src: RobotoBold, fontWeight: 'bold' }],
});

const MyDocument = ({ ownerData, tenantData, propertyDetails, contractDetails, furnishedDetails }) => {
  const date = new Date();
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      padding: 40,
      backgroundColor: 'white',
      fontSize: 15,
      fontFamily: 'Roboto',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
      textAlign: 'justify',
    },
    field: {
      fontWeight: 'bold',
    },
  });

  console.log(furnishedDetails);

  return (
    <Document>
      <Page
        size='A4'
        style={styles.page}
      >
        <View style={styles.section}>
          
        </View>
         <View style={{ marginTop: 300 }}></View>
        <View style={styles.section}>
          <Text>
            This agreement made on this {date.getDate()}nd day of {months[date.getMonth()]}, {date.getFullYear()} between
            <Text style={styles.field}>{ownerData.name === null ? '__________' : `${ownerData.initial} ${ownerData.name}`}</Text> , with{' '}
            <Text style={styles.field}>{ownerData.number === null ? '__________' : `${ownerData.number}`}</Text> as Mobile Number, residing at{' '}
            <Text style={styles.field}>{ownerData.address === null ? '__________' : `${ownerData.address}`}</Text>,{' '}
            <Text style={styles.field}>{ownerData.city === null ? '__________' : `${ownerData.city}`}</Text>,{' '}
            <Text style={styles.field}>{ownerData.state === null ? '__________' : `${ownerData.state}`}</Text>,{' '}
            <Text style={styles.field}>{ownerData.pincode === null ? '__________' : `${ownerData.pincode}`}</Text>, hereinafter referred to as the 'LESSOR' of
            the One Part AND <Text style={styles.field}>{tenantData[0].name === null ? '__________' : `${tenantData[0].initial} ${tenantData[0].name}`}</Text>,
            with <Text style={styles.field}>{tenantData[0].number === null ? '__________' : `${tenantData[0].number}`}</Text> as Mobile Number, residing at
            <Text style={styles.field}>{tenantData[0].address === null ? '__________' : `${tenantData[0].address}`}</Text>,{' '}
            <Text style={styles.field}>{tenantData[0].city === null ? '__________' : `${tenantData[0].city}`}</Text>,{' '}
            <Text style={styles.field}>{tenantData[0].state === null ? '__________' : `${tenantData[0].state}`}</Text>,{' '}
            <Text style={styles.field}>{tenantData[0].pincode === null ? '__________' : `${tenantData[0].pincode}`}</Text> ,
            {tenantData.length >= 2 && (
              <>
                <Text style={styles.field}>{tenantData[1].name === null ? '__________' : `${tenantData[1].initial} ${tenantData[1].name}`}</Text>, with{' '}
                <Text style={styles.field}>{tenantData[1].number === null ? '__________' : `${tenantData[1].number}`}</Text> as Mobile Number, residing at
                <Text style={styles.field}>{tenantData[1].address === null ? '__________' : `${tenantData[1].address}`}</Text>,{' '}
                <Text style={styles.field}>{tenantData[1].city === null ? '__________' : `${tenantData[1].city}`}</Text>,{' '}
                <Text style={styles.field}>{tenantData[1].state === null ? '__________' : `${tenantData[1].state}`}</Text>,{' '}
                <Text style={styles.field}>{tenantData[1].pincode === null ? '__________' : `${tenantData[1].pincode}`}</Text>
              </>
            )}
            ,{' '}
            {tenantData.length >= 3 && (
              <>
                <Text style={styles.field}>{tenantData[2].name === null ? '__________' : `${tenantData[2].initial} ${tenantData[1].name}`}</Text>, with{' '}
                <Text style={styles.field}>{tenantData[2].number === null ? '__________' : `${tenantData[2].number}`}</Text> as Mobile Number, residing at
                <Text style={styles.field}>{tenantData[2].address === null ? '__________' : `${tenantData[2].address}`}</Text>,{' '}
                <Text style={styles.field}>{tenantData[2].city === null ? '__________' : `${tenantData[2].city}`}</Text>,{' '}
                <Text style={styles.field}>{tenantData[2].state === null ? '__________' : `${tenantData[2].state}`}</Text>,{' '}
                <Text style={styles.field}>{tenantData[2].pincode === null ? '__________' : `${tenantData[2].pincode}`}</Text>
              </>
            )}
            hereinafter referred to as the 'LESSEE(s)' of the other Part; {'\n'}
            {'\n'}
            WHEREAS the Lessor is the lawful owner of, and otherwise well sufficiently entitled to,
            {propertyDetails.addressSame ? (
              <>
                {' '}
                <Text style={styles.field}>{ownerData.address === null ? '__________' : ownerData.address}</Text>,{' '}
                <Text style={styles.field}>{ownerData.city === null ? '__________' : ownerData.city}</Text> ,{' '}
                <Text style={styles.field}>{ownerData.state === null ? '__________' : ownerData.state}</Text>,{' '}
                <Text style={styles.field}>{ownerData.pincode === null ? '__________' : ownerData.pincode}</Text>
              </>
            ) : (
              <>
                <Text style={styles.field}>{propertyDetails.address === null ? '__________' : propertyDetails.address}</Text>,{' '}
                <Text style={styles.field}>{propertyDetails.city === null ? '__________' : propertyDetails.city}</Text> ,{' '}
                <Text style={styles.field}>{propertyDetails.state === null ? '__________' : propertyDetails.state}</Text>,{' '}
                <Text style={styles.field}>{propertyDetails.pincode === null ? '__________' : propertyDetails.pincode}</Text>
              </>
            )}
            <Text style={styles.field}>{propertyDetails.rooms === null ? '__________' : propertyDetails.rooms}</Text> including{' '}
            <Text style={styles.field}>{propertyDetails.bedroom !== 0 && propertyDetails.bedroom + ' Bedroom, '} </Text>
            <Text style={styles.field}>{propertyDetails.bathroom !== 0 && propertyDetails.bathroom + ' Bathroom, '}</Text>
            <Text style={styles.field}>{propertyDetails.balcony !== 0 && propertyDetails.balcony + ' Balcony, '} </Text>
            {propertyDetails.others.length !== 0 && propertyDetails.others.map((o) => <Text style={styles.field}>{o},</Text>)} present in Floor{' '}
            <Text style={styles.field}>{propertyDetails.floor === null ? '__________' : propertyDetails.floor}</Text>,{' '}
            <Text style={styles.field}>{propertyDetails.parking ? 'with' : 'without'}</Text> Parking{' '}
            {propertyDetails.area !== '' && (
              <>
                with an area of <Text style={styles.field}>{propertyDetails.area} Square Feet</Text>
              </>
            )}{' '}
            hereinafter referred to as the 'said premises'.{'\n'}
            {'\n'}
            AND WHEREAS at the request of the Lessee, the Lessor has agreed to let the said premises to the tenant for a term of
            <Text style={styles.field}>{contractDetails.duration === null ? '__________' : `${contractDetails.duration} Months`}</Text> commencing from
            2024-02-22 in the manner hereinafter appearing. NOW THIS AGREEMENT WITNESSETH AND IT IS HEREBY AGREED BY AND BETWEEN THE PARTIES AS UNDER:
          </Text>
          <View style={{ flexDirection: 'column', marginTop: 20 }}>
            <View style={{ flexDirection: 'row', marginBottom: 4 }}>
              <Text style={{ marginHorizontal: 8 }}>•</Text>
              <Text style={{ marginRight: 20 }}>
                That the Lessor hereby grant to the Lessee, the right to enter and use and remain in the said premises along with the existing fixtures and
                fittings listed in Annexure 1 to this Agreement and that the Lessee shall be entitled to peacefully possess and enjoy possession of the said
                premises for <Text style={styles.field}>{propertyDetails.type === null ? '__________' : propertyDetails.type}</Text> use, and the other rights
                herein.
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 4 }}>
              <Text style={{ marginHorizontal: 8 }}>•</Text>
              <Text style={{ marginRight: 20 }}>
                That the lease hereby granted shall, unless cancelled earlier under any provision of this Agreement, remain in force for a period of{' '}
                {<Text style={styles.field}>{contractDetails.duration === null ? '__________' : `${contractDetails.duration} Months`}</Text>}.
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 4 }}>
              <Text style={{ marginHorizontal: 8 }}>•</Text>
              <Text style={{ marginRight: 20 }}>
                That the Lessee will have the option to terminate this lease by giving in{' '}
                {<Text style={styles.field}>{contractDetails.noticePeriod === null ? '__________' : `${contractDetails.noticePeriod} Months`}</Text>} writing to
                the Lessor.
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 4 }}>
              <Text style={{ marginHorizontal: 8 }}>•</Text>
              <Text style={{ marginRight: 20 }}>
                That the Lessee shall have no right to create any sub-lease or assign or transfer in any manner the lease or give to any one the possession of
                the said premises or any part thereof.
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 4 }}>
              <Text style={{ marginHorizontal: 8 }}>•</Text>
              <Text style={{ marginRight: 20 }}>That the Lessee shall use the said premises only for residential purposes.</Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 4 }}>
              <Text style={{ marginHorizontal: 8 }}>•</Text>
              <Text style={{ marginRight: 20 }}>
                That the Lessor shall, before handing over the said premises, ensure the working of sanitary, electrical and water supply connections and other
                fittings pertaining to the said premises. It is agreed that it shall be the responsibility of the Lessor for their return in the working
                condition at the time of re-possession of the said premises, subject to normal wear and tear.
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 4 }}>
              <Text style={{ marginHorizontal: 8 }}>•</Text>
              <Text style={{ marginRight: 20 }}>That the Lessee is not authorized to make any alteration in the construction of the said premises.</Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 4 }}>
              <Text style={{ marginHorizontal: 8 }}>•</Text>
              <Text style={{ marginRight: 20 }}>
                That the day-to-day repair jobs shall be affected by the Lessee at his own cost, and any major repairs, either structural or to the electrical
                or water connection, plumbing leaks, water seepage shall be attended to by the Lessor. In the event of the Lessor failing to carry out the
                repairs on receiving notice from the Lessee, the Lessee shall undertake the necessary repairs and the Lessor will be liable to immediately
                reimburse costs incurred by the Lessee.
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 4 }}>
              <Text style={{ marginHorizontal: 8 }}>•</Text>
              <Text style={{ marginRight: 20 }}>
                That the Lessor or its duly authorized agent shall have the right to enter or upon the said premises or any part thereof at a mutually arranged
                convenient time for the purpose of inspection.
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 4 }}>
              <Text style={{ marginHorizontal: 8 }}>•</Text>
              <Text style={{ marginRight: 20 }}>
                That in consideration of use of the said premises the Lessee agrees that he shall pay to the Lessor during the period of this agreement, a
                monthly rent at the rate of ₹<Text style={styles.field}>{contractDetails.rentAmount === null ? '__________' : contractDetails.rentAmount}</Text>
                . The amount will be paid in advance on or before the date of <span></span>. That the Lessee shall pay to the Landlord a monthly maintenance
                charge of ₹{<Text style={styles.field}>{contractDetails.maintenanceCharges}</Text>}
                towards maintenance of property.
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 4 }}>
              <Text style={{ marginHorizontal: 8 }}>•</Text>
              <Text style={{ marginRight: 20 }}>
                It is hereby agreed that in the event of default in payment of the rent for a consecutive period of three months the lessor shall be entitled to
                terminate the lease.
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 4 }}>
              <Text style={{ marginHorizontal: 8 }}>•</Text>
              <Text style={{ marginRight: 20 }}>
                That the Lessee has paid to the Lessor a sum of ₹
                <Text style={styles.field}>{contractDetails.securityDeposit === null ? '__________' : contractDetails.securityDeposit}</Text> as deposit, free
                of interest. The said deposit shall be returned to the Lessee simultaneously with the Lessee vacating the said premises. In the event of failure
                on the part of the Lessor to refund the said deposit amount to the Lessee as aforesaid, the Lessee shall be entitled to continue to use and
                occupy the said premises without payment of any rent until the Lessor refunds the said amount.
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 4 }}>
              <Text style={{ marginHorizontal: 8 }}>•</Text>
              <Text style={{ marginRight: 20 }}>
                That the Lessor shall be responsible for the payment of all taxes and levies pertaining to the said premises including but not limited to House
                Tax, Property Tax, other cesses, if any, and any other statutory taxes, levied by the Government or Governmental Departments. During the term of
                this Agreement, the Lessor shall comply with all rules, regulations and requirements of any statutory authority, local, state, and central
                government, and governmental departments in relation to the said premises.
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 4 }}>
              <Text style={{ marginHorizontal: 8 }}>•</Text>
            </View>
          </View>
          <View>
            <Text
              style={{
                textAlign: 'center',
              }}
            >
              ANNEXURE I
            </Text>
            <Text>
              List of fixtures and fittings provided in
              <Text style={styles.field}>{propertyDetails.address + ', '}</Text>
              <Text style={styles.field}>{propertyDetails.city + ', '}</Text>
              <Text style={styles.field}> {propertyDetails.state + ', '}</Text>
              <Text style={styles.field}> {propertyDetails.pincode + ', '}</Text>
            </Text>
          </View>
          <View
            style={{
              margin: '10px 0',
            }}
          >
            <View
              style={{
                fontWeight: 'bold',
              }}
            >
              <Text style={styles.field}>{furnishedDetails?.type?.charAt(0).toUpperCase() + furnishedDetails?.type?.slice(1)}</Text>
            </View>
            <View>
              {Object.keys(furnishedDetails)?.map(
                (key) =>
                  key !== 'type' &&
                  furnishedDetails[key] !== 0 && (
                    <Text>
                      {key.charAt(0).toUpperCase() + key.slice(1)}&nbsp;&nbsp;-&nbsp;&nbsp;
                      {furnishedDetails[key]}
                    </Text>
                  )
              )}
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                border: '1px solid black',
                width: 15,
                height: 15,
                marginRight: 10,
              }}
            ></View>
            <Text>Signature is not required because this is a electronically generated agreement.</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                border: '1px solid black',
                width: 15,
                height: 15,
                marginRight: 10,
              }}
            ></View>
            <Text>You agreed to submit the details and the details are true as per your knowledge according to you.</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
