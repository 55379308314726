import { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import { privateReq } from "../../config/axiosConfig";
import ButtonContained from "../../components/Buttons/ButtonContained";
import OutlinedFormInput from "../../components/Input/OutlinedInput";
import { FileX, Settings } from "lucide-react";
import { BellDot } from "lucide-react";
import { User } from "lucide-react";
import { useSelector } from "react-redux";
import PageNotFound from "../../components/PageNotFound";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import toast from "react-hot-toast";

const Pincodes = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [pincodes, setPincodes] = useState();

  const [addPincode, setAddPincode] = useState({
    pincode: "",
    state: "",
    country: "",
    countryCode: "",
  });
  const [deletePincode, setDeletePincode] = useState(false);

  // useEffect(() => {
  //   setHeaderHeight(headerRef.current.offsetHeight);
  // }, [headerHeight]);

  //for 404 auth
  const [showPage, setShowPage] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user !== null) {
      if (user?.role === "admin") {
        setShowPage(true);
      }
    }
  }, []);


  useEffect(() => {
    (async function () {
      const res = await privateReq.get("/get-pincodes");
      setPincodes(res.data.data);
    })();
  }, [ , deletePincode ]);

  const onChangeAddPincode = (e) => {
    const { name, value } = e.target;
    setAddPincode((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddPincode = async () => {
    try {
      const res = await privateReq.post("/add-pincode", addPincode);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeletePincode = async (id) => {
    try {
      const res = await privateReq.delete("/delete-pincode/" + id);
      toast.success("Deleted Successfully");
      setDeletePincode(!deletePincode);
    } catch (err) {
      console.log(err);
    }
  };

  return showPage ? (
    <>
      <Header innerRef={headerRef} />
      <div
        style={{
          marginTop: "100px",
          padding: "0 50px",
        }}
      >
        <div
          style={{
            fontSize: "30px",
            fontFamily: "Montserrat",
          }}
        >
          Pin Codes
        </div>
        <div>
          <div>
            <div style={{ marginLeft: "7px", marginBottom: "10px" }}>
              Add Pin code
            </div>
            <div style={{ marginLeft: "7px" }}>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginBottom: "10px",
                }}
              >
                <OutlinedFormInput
                  style={{
                    width: "300px",
                  }}
                  type="text"
                  name="pincode"
                  value={addPincode.pincode}
                  onChange={onChangeAddPincode}
                  placeholder="Pin code"
                />
                <OutlinedFormInput
                  style={{
                    width: "300px",
                  }}
                  type="text"
                  name="state"
                  value={addPincode.state}
                  onChange={onChangeAddPincode}
                  placeholder="State"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginBottom: "10px",
                }}
              >
                <OutlinedFormInput
                  style={{
                    width: "300px",
                  }}
                  type="text"
                  name="country"
                  value={addPincode.country}
                  onChange={onChangeAddPincode}
                  placeholder="Country"
                />
                <OutlinedFormInput
                  style={{
                    width: "300px",
                  }}
                  type="text"
                  name="countryCode"
                  value={addPincode.countryCode}
                  onChange={onChangeAddPincode}
                  placeholder="Country Code"
                />
              </div>
            </div>

            <ButtonContained
              style={{
                width: "150px",
              }}
              onClick={handleAddPincode}
            >
              Add Pin code
            </ButtonContained>
          </div>
        </div>
        <div
          style={{
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid gray",
            }}
          >
            <div
              style={{
                fontSize: "30px",
                fontFamily: "Montserrat",
              }}
            >
              Pin code list:
            </div>

            {/* <div style={{display: "flex", gap: "20px", color: "#9a291b", alignItems: "center"}}>
              <div style={{color: "#3498db", cursor: "pointer"}}><Settings /></div>
              <div style={{color: "#3498db", cursor: "pointer"}}><BellDot /></div>
              <div style={{color: "#3498db", cursor: "pointer"}}><User /></div>
            </div> */}
          </div>

          {/* <div style={{marginTop: "10px", display: "flex", justifyContent: "space-between",}}>
            <div style={{display: "flex", gap: "10px"}}>
              <input style={{paddingBlock: "2px", paddingInline: "20px"}} placeholder='Search' />
              <div style={{paddingBlock: "2px", paddingInline: "20px", border: "1px solid black"}}>Filter</div>
            </div>
          </div> */}

          <div
            style={{
              display: "flex",
              marginTop: "20px",
              marginBottom: "20px",
              maxWidth: "100%",
            }}
          >

            <div className="container text-center">
              <div className="row align-items-start">
                <div className="col" style={isMobile ? { width: "10vw", fontWeight: "bold" } : { width: "5vw", fontWeight: "bold" }}>Pin code</div>
                <div className="col" style={isMobile ? { width: "10vw", fontWeight: "bold", marginLeft: "5vw" } :{ width: "300px", fontWeight: "bold" }}>State</div>
                <div className="col" style={isMobile ? { width: "10vw", fontWeight: "bold" } :{ width: "300px", fontWeight: "bold" }}>Country</div>
                <div className="col" style={isMobile ? { width: "10vw", fontWeight: "bold" } :{ width: "300px", fontWeight: "bold" }}>
                  Country Code
                </div>
                {isMobile ? null  : <div style={{ width: "300px", fontWeight: "bold" }}>Action</div>}
                {/* <div style={{ width: "300px", fontWeight: "bold" }}>Action</div> */}
              </div>
            </div>
          </div>
          {pincodes?.map((pincode) => (
            <ul className="list-group list-group-flush">
              <div
              // style={{
              //   display: "flex",
              //   justifyItems: "center",
              //   alignItems: "center",
              //   borderBottom: "1px solid gray",
              //   maxWidth: "100%",
              // }}
              >
                <li className="list-group-item">
                  <div className="container text-center">
                    <div className="row align-items-start">
                      <div className="col" style={{ width: "300px" }}>{pincode.pincode}</div>
                      <div className="col" style={{ width: "300px" }}>{pincode.state}</div>
                      <div className="col" style={{ width: "300px" }}>{pincode.country}</div>
                      <div className="col" style={{ width: "300px" }}>{pincode.countryCode}</div>
                      <div className="col" style={{ width: "300px" }}>
                        <ButtonContained
                          style={{
                            width: "100px",
                          }}
                          onClick={() => handleDeletePincode(pincode._id)}
                        >
                          Delete
                        </ButtonContained>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            </ul>
          ))}
        </div>
      </div>
    </>
  ) : (
    // <h1 style={{ margin: " 250px 10px", textAlign: "center" }}>404 ERROR</h1>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <PageNotFound />
      <Link to="/">
        <button>Go To Home</button>
      </Link>
    </div>
  );
};

export default Pincodes;
