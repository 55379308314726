import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

export const EmailForm = () => {

  const [message, setMessage] = useState({
    name: '',
    email: '',
    message: '',
  });

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    alert('Referral sent successfully!');

    setMessage({ name: '', email: '', message: '' });
    emailjs
      .sendForm('service_aklcf56', 'template_r5fwarg', form.current, {
        publicKey: 'DJaTpvcOKgdrnV79E',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input style={{ width: "25vw", borderRadius: "8px", border: "2px solid #ececec", padding: "10px" }} type="text" name="user_name" onChange={(e) => setMessage({ ...message, name: e.target.value })} value={message.name} />
      <label>Email</label>
      <input style={{ width: "25vw", borderRadius: "8px", border: "2px solid #ececec", padding: "10px" }} type="email" name="user_email" onChange={(e) => setMessage({ ...message, email: e.target.value })} value={message.email} required />
      <label>Message</label>
      <textarea style={{ width: "25vw", borderRadius: "8px", border: "2px solid #ececec", padding: "10px" }} name="message" onChange={(e) => setMessage({ ...message, message: e.target.value })} value={message.message} required />
      <input className='submit-btn mt-3' style={{ width: "25vw", borderRadius: "8px", border: "2px solid #ececec", padding: "10px", backgroundColor: "#9B291B", color: "white" }} type="submit" value="Send" />
    </form>
  );
};