import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Stack, Grid, Paper, Box } from '@mui/material';
import fbSvg from '../assets/images/fb.svg';
import instaSvg from '../assets/images/insta.svg';
import twitterSvg from '../assets/images/twitter.svg';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo.jpg';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  // color: theme.palette.text.secondary,
}));

export default function StickyFooterMobile() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        minHeight: '30vh',
        padding: '20px 0',
        // justifyContent: 'center'
      }}
    >
      <Box
        component='footer'
        sx={{
          // py: 3,
          // px: 2,
          // mt: 'auto',
          backgroundColor: '#FFF',
        }}
      >
        <div className='container mobile-footer-container'>
          <div className='row'>
            <div className='col-8'>
              <div
                class='brand-logo'
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}
              >
                <img
                  src={Logo}
                  style={{
                    height: '30px',
                    width: '30px',
                    // marginLeft: "15px",
                  }}
                />
                <h3
                  className='ms-2'
                  style={{
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                  }}
                >
                  Ghar
                  <span style={{ color: '#9A291B' }}>Wala</span>
                  <sup style={{ fontSize: '15px' }}>TM</sup>
                </h3>
              </div>
            </div>
            <div className='col-4'>
              <div className='footer-social-icons'>
              <a target='_blank'
                href='https://www.facebook.com/profile.php?id=61558672864335'>
                <img src={fbSvg} />
                </a>
                <a
                  target='_blank'
                  href='https://www.instagram.com/gharwala_solutions'
                >
                  <img src={instaSvg} />
                </a>
                <a
                  target='_blank'
                  href='https://www.youtube.com/@gharwala2847'
                >
                  <img src={twitterSvg} />
                </a>
                {/* <img src={emailSvg} /> */}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-6'>
              <div className='footer-nav'>
                <ul>
                  <h6>Support</h6>

                  <li>
                    {' '}
                    <Link to='/privacy'>Privacy Policy</Link>{' '}
                  </li>

                  <li>
                    {' '}
                    <Link to='/return-policy'>Return Policy</Link>{' '}
                  </li>
                  <li>
                    {' '}
                    <Link to='/data-security'>Data Security</Link>{' '}
                  </li>
                  <li>
                    {' '}
                    <Link to='/security-deposit'>Security Deposit</Link>{' '}
                  </li>
                  <li>
                    {' '}
                    <Link to='/blogs'>Blog</Link>{' '}
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-6'>
              <div className='footer-nav'>
                <ul>
                  <h6>Service</h6>
                  <li>
                    {' '}
                    <Link to='/buying-property'>Buying a Property</Link>{' '}
                  </li>
                  <li>
                    {' '}
                    <Link to='/selling-property'>Selling a Property</Link>{' '}
                  </li>
                  <li>
                    {' '}
                    <Link to='/renting-property'>Renting a Property</Link>{' '}
                  </li>

                  <li>
                    {' '}
                    <Link to='/data-security'>Data Security</Link>{' '}
                  </li>
                  <li>
                    {' '}
                    <Link to='/covid19'>Covid 19</Link>{' '}
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-12'>
              <div className='footer-nav'>
                <ul>
                  <h6>About</h6>
                  <li>
                    {' '}
                    <Link to='/about-us'>About Us</Link>{' '}
                  </li>

                  <li>
                    {' '}
                    <Link to='/contact-us'>Contact Us</Link>{' '}
                  </li>
                  <li>
                    {' '}
                    <Link to='/terms'>Terms & Conditions</Link>{' '}
                  </li>
                  <li>
                    {' '}
                    <Link to='/rental-terms'>Furniture Rental Terms</Link>{' '}
                  </li>
                  <li>
                    {' '}
                    <Link to='/referral-terms'>Referral Terms</Link>{' '}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
}
