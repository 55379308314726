import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";
import { useMediaQuery } from "react-responsive";
import StickyFooterMobile from "../../components/FooterMobile";
import StickyFooter from "../../components/Footer";
import { privateReq } from "../../config/axiosConfig";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const BlogCard = ({ user, setBlogId }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();
  const getBlogs = () => {
    axios
      .get(
        // 'http://localhost:5000/api/blogs/allBlogs'
        "https://gharwala-5466fddd6458.herokuapp.com/api/blogs/allBlogs"
      )
      .then((res) => {
        console.log(res);
        setBlogs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteBlog = (id) => {
    privateReq
      .post(`/delete-blogs/${id}`)
      .then((res) => {
        console.log(res);
        if (res.data.message === "Blog removed successfully") {
          toast.success("blog removed successfully");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        getBlogs();
      });
  };

  useEffect(() => {
    getBlogs();
  }, []);

  return (
    <>
      <Header />
      <div
        className="blog-layout"
        style={{ maxWidth: "1200px", margin: "0 auto" }}
      >
        <div className="upper-section">
          <img
            src="https://i.ibb.co/JF65SQk/blogs.png"
            alt="Cover"
            className="cover-image"
            style={isMobile ? { marginTop: "5vw", marginLeft: "5vw", width: "85%", height: "auto" } : { width: "100%", height: "auto" }}
          />
        </div>
        <div style={{marginTop:"30px"}} className="lower-section">
          <h2 style={{textAlign:"center", fontSize:"40px", fontWeight:"bold"}}>BLOGS</h2>
          <div className="navbar">{/* Navbar content goes here */}</div>
          <div
            className="blog-cards"
            style={isMobile ? 
              { width: "83%",
                marginLeft: "5vw", 
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                gap: "20px", } : 
              {display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
              gap: "20px",
            }}
          >
            
            {blogs.map((item, index) => (
              <div
                className="blog-card"
                style={{
                  marginBottom: "20px",
                  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                  transition: "0.3s",
                  borderRadius: "5px",
                  height: "420px",
                }}
              >
                <img
                  src={item.imageUrl}
                  alt="Blog"
                  className="blog-card-image"
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "fill",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                  }}
                />
                <div className="blog-card-content" style={{ padding: "20px" }}>
                  <p
                    className="blog-card-title"
                    style={{ marginBottom: "10px", fontWeight:"bold" }}
                  >
                    {item.title}
                  </p>
                  <p
                    className="blog-card-description"
                    style={{ fontSize: "14px", marginBottom: "10px" }}
                  >{`${item.shortDescription.substring(0, 50)}...`}</p>
                  <Link to={`${item._id}`} style={{ textDecoration: "none" }}>
                    <p
                      className="read-more-link"
                      style={isMobile ? { position: "sticky", color: "#007bff", cursor: "pointer" } : { color: "#007bff", cursor: "pointer" }}
                    >
                      Read more
                    </p>
                  </Link>
                  {user?.role === "admin" && (
                    <div>
                      <button
                        onClick={() => {
                          setBlogId(item._id);
                          navigate("/blogs/editblog");
                        }}
                        style={isMobile ? { position: "sticky", backgroundColor: "#dc3545", color: "#fff", border: "none", padding: "5px 10px", borderRadius: "3px", marginTop: "10px", cursor: "pointer" } : {
                          backgroundColor: "#dc3545",
                          color: "#fff",
                          border: "none",
                          padding: "5px 10px",
                          borderRadius: "3px",
                          marginTop: "10px",
                          cursor: "pointer",
                        }}
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => {
                          deleteBlog(item._id);
                        }}
                        style={isMobile ? { position: "sticky", backgroundColor: "#dc3545", color: "#fff", border: "none", padding: "5px 10px", borderRadius: "3px", marginTop: "10px", cursor: "pointer" } : {
                          backgroundColor: "#dc3545",
                          color: "#fff",
                          border: "none",
                          padding: "5px 10px",
                          borderRadius: "3px",
                          marginTop: "10px",
                          cursor: "pointer",
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>
              // </Link>
            ))}
          </div>
        </div>
      </div>

      <div>{isMobile ? <StickyFooterMobile /> : <StickyFooter />}</div>
    </>
  );
};

export default BlogCard;
