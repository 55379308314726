import React from "react";
import Header from "../../components/Header";
import { useMediaQuery } from "react-responsive";
import StickyFooterMobile from "../../components/FooterMobile";
import StickyFooter from "../../components/Footer";
const SellProperty = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{
        height: "100vh",
      }}
    >
      <Header />
      <div className="container" style={{ paddingTop: "125px" }}>
        <h2 className="h3-about-tems" style={{ marginBottom: "15px" }}>
          Sell a property
        </h2>{" "}
        <br />
        <p className="para-about-terms">
          {" "}
          Introducing Gharwala Property Solutions – Your Gateway to Effortless
          Real Estate Transactions. Whether you're an owner or a broker, our
          online platform simplifies property selling like never before. List
          your property on our website, and let us take care of the verification
          process, ensuring authenticity and trust. Potential buyers can easily
          explore your property details on our user-friendly interface. Gharwala
          Property Solutions combines efficiency with transparency, offering a
          seamless online experience for both sellers and buyers. Step into the
          future of real estate transactions with Gharwala – where your property
          meets its perfect match.
        </p>
      </div>
      <div>{isMobile ? <StickyFooterMobile /> : <StickyFooter />}</div>
    </div>
  );
};

export default SellProperty;
