import React, { useEffect, useState } from 'react';
import OutlinedFormInput from '../../components/Input/OutlinedInput';
import ButtonContained from '../../components/Buttons/ButtonContained';
import { Public } from '@mui/icons-material';
import publicReq from '../../config/axiosConfig';
import toast from 'react-hot-toast';
import Header from '../../components/Header';
import StickyFooter from '../../components/Footer';
const Index = () => {
  const [carryingCost, setCarryingCost] = useState({

  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCarryingCost(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

 const getCost =()=>{
    publicReq.get('/carryingCost').then((res)=>{
        console.log(res);
        const {__v,_id,...rest} = res.data;
        setCarryingCost(rest);
    }).catch((err)=>{
        console.log(err);
    })
 }

 useEffect(()=>{
    getCost();
 },[])

  const handleSubmit = () => {
    // Here you can submit the carryingCost object to your backend API
    console.log(carryingCost);

    publicReq.put('/carryingCost',carryingCost).then((res)=>{
        console.log(res);
        if(res.data.message='Carrying costs updated successfully'){
            toast.success('Carrying cost updated successfully');
        }
    }).catch((err)=>{
        console.log(err);
    })
  };

  return (
    <>
    <div style={{ marginTop: '100px', padding: '0 50px' }}>
      <Header />
      <div
        style={{
          fontSize: '30px',
          fontFamily: 'Montserrat',
        }}
      >
        Carrying Cost
      </div>
      <div>
        <div>
          <div style={{ marginLeft: '7px' }}>
            {[1, 2, 3, 4, 5].map((number) => (
              <div key={number}>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <label>{`Product ${number}`}</label>
                  <OutlinedFormInput
                    style={{
                      width: '300px',
                    }}
                    type='text'
                    name={`product${number}`} 
                    value={carryingCost[`product${number}`]} 
                    onChange={handleChange} 
                    placeholder={`Product ${number} carrying cost`}
                  />
                </div>
              </div>
            ))}
          </div>

          <ButtonContained
            style={{
              width: '150px',
            }}
            onClick={handleSubmit}
          >
            SUBMIT 
          </ButtonContained>
        </div>
      </div>
     
    </div>
    <div style={{marginTop:"100px"}}></div>
     <StickyFooter />
    </>
  );
};

export default Index;
