import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Avatar,
  Badge,
  Container,
  Input,
  Select,
  MenuItem,
} from "@mui/material";
import classes from "../../styles/styles.js";
import { useTheme } from "@mui/material/styles";
import Header from "../../components/Header";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import ButtonContained from "../../components/Buttons/ButtonContained";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import EditSvg from "../../assets/images/edit.svg";
import OutlinedFormInput from "../../components/Input/OutlinedInput";
import { servicesArr, validateField } from "../../util/json/helpers";
import { toast } from "react-hot-toast";
import { addOtherServicesApi } from "../../config/otherservices";
import { useSelector } from "react-redux";
import { usePlacesWidget } from "react-google-autocomplete";
import { API_KEY } from "../../config/axiosConfig.js";
import publicReq from "../../config/axiosConfig.js";
import "./style.css";
const AddOtherService = ({ closeLoginModal, setCloseLoginModal,  setOpenPopUp}) => {
  const inputRef = useRef();
  const [data, setData] = useState({
    photo: {},
  });
  const { user } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [phone, setPhone] = useState("");
  const [pinCode, setPincode] = useState("");
  const [locality, setLocality] = useState("");
  const [language, setLanguage] = useState("");
  const [adhaarCard, setAdhaarCard] = useState("");
  const [images, setImages] = useState([]);
  const navigate = useNavigate();
  const headerRef = useRef();

  const theme = useTheme();
  const { ref } = usePlacesWidget({
    apiKey: API_KEY,
    options: {
      componentRestrictions: { country: "in" },
      types: ["(regions)"],
    },
    onPlaceSelected: (place) => {
      // handleTextChange("locality", [
      //   place.geometry.location.lng(),
      //   place.geometry.location.lat(),
      // ]);
      setLocality([
        place.geometry.location.lng(),
        place.geometry.location.lat(),
      ]);
    },
  });
  const onSubmit = async (e) => {
    e.preventDefault();
    let validate = true;
    if (serviceName.trim() === "") {
      toast("Service name is required");
      validate = false;
    }
    if (user?.role === "admin") {
      if (name.trim() === "") {
        toast("Name is required");
        validate = false;
      }

      if (phone.trim() === "") {
        toast("Phone is required");
        validate = false;
      }
      if (phone.trim().length != 10) {
        toast("Phone Number should be 10 numbers only");
        validate = false;
      }
    }
    if (locality.length === 0) {
      toast("Locality is required");
      validate = false;
      return;
    }

    if (!validate) {
      toast("Please fill required fields");
      return;
    }

    try {
      if (images.length === 0) {
        toast.error("ID Proof images are required");
        return;
      }
      setLoader(true);
      const obj = {
        name: user?.role === "admin" ? name : user?.name,
        phone: user?.role === "admin" ? phone : user?.phone,
        pinCode,
        language,
        adhaarCard,
        serviceName,
        profilePic: data.photo,
        images,
        locality: JSON.stringify(locality),
      };
      const fd = new FormData();
      for (let key in obj) {
        if (Array.isArray(obj[key])) {
          obj[key].map((i) => {
            fd.append(key, i);
          });
        } else fd.append(key, obj[key]);
      }
      const res = await addOtherServicesApi(fd);

      if (res.data.success) {
        setLoader(false);
        setPincode("");
        setName("");
        setPhone("");
        setLanguage("");
        setAdhaarCard("");
        setServiceName("");

        toast.success("Service Added Successfully");
        navigate("/");
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  return (
    <>
      <Header
        closeLoginModal={closeLoginModal}
        setCloseLoginModal={setCloseLoginModal}
        setOpenPopUp={setOpenPopUp}
        innerRef={headerRef}
      />
      <div className="container-fluid px-0">
        <div className="properties-container" style={{ paddingTop: "170px" }}>
          <div className="w-100">
            <div className="border-box px-0">
              <Box
                className="d-flex align-items-center py-3 border-bottom p-4 w-100"
                sx={[styles.color9A291B]}
              >
                <Link
                  onClick={() => navigate(-1)}
                  className="d-flex align-items-center text-decoration-none"
                >
                  <KeyboardBackspaceIcon sx={[styles.color9A291B]} />
                  <p style={{fontWeight:"bold", color:"#950000" , marginTop:"10px"}}>Listing</p>

                  <Typography
                    sx={[
                      styles.color9A291B,
                      styles.fs24,
                      styles.fbBold,
                      styles.fontFamilyDS,
                    ]}
                    className="px-1"
                  >
                    
                  </Typography>
                </Link>
              </Box>
            </div>
            <div className="mt-4 mb-5">
              <Container
                className="add-new-service border w-50 p-4"
                sx={[styles.borderRadius24]}
              >
                <Box className="add-new-service-text text-center">
                  <Typography sx={[styles.fontFamilySGB, styles.fs30]}>
                    Add New Service
                  </Typography>
                </Box>
                <Box
                  sx={[styles.mt40]}
                  className="d-flex flex-column justify-content-center align-items-center"
                >
                  <input
                    ref={inputRef}
                    type="file"
                    className="d-none"
                    accept="image/*"
                    onChange={(e) =>
                      setData({ ...data, photo: e.target.files[0] })
                    }
                  />
                  <Badge
                    overlap="circular"
                    className="d-flex justify-content-center align-items-center"
                    onClick={() => inputRef.current.click()}
                    sx={{
                      width: "90px",
                      height: "90px",
                      border: "1px solid #ADB0B6",
                      borderRadius: "45px",
                    }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={<img src={EditSvg} />}
                  >
                    {data.photo.name ? (
                      <Avatar
                        alt="Profile image"
                        src={URL.createObjectURL(data.photo)}
                        className="h-100 w-100"
                      />
                    ) : (
                      <Person2OutlinedIcon
                        sx={{ color: "#ADB0B6" }}
                        fontSize="large"
                      />
                    )}
                  </Badge>
                  <Box className="w-100 mt-4">
                    {user?.role === "admin" && (
                      <Box className="d-flex">
                        <Box className="col-lg-6 col-sm-12 col-md-6 px-2">
                          <Typography
                            sx={[
                              styles.fs16,
                              styles.fontFamilySG,
                              styles.color240501,
                              {
                                [theme.breakpoints.between("xs", "md")]: {
                                  ...classes.fs14,
                                  ...classes.margin4,
                                  ...classes.borderRadius7,
                                },
                              },
                            ]}
                            className="mb-2"
                          >
                            Full Name
                          </Typography>
                          <OutlinedFormInput
                            placeholder={"Anil Kumar"}
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            autocomplete="off"
                            name="name"
                          />
                        </Box>
                        <Box className="col-lg-6 col-sm-12 col-md-6 px-2">
                          <Typography
                            sx={[
                              styles.fs16,
                              styles.fontFamilySG,
                              styles.color240501,
                              {
                                [theme.breakpoints.between("xs", "md")]: {
                                  ...classes.fs14,
                                  ...classes.margin4,
                                  ...classes.borderRadius7,
                                },
                              },
                            ]}
                            className="mb-2"
                          >
                            Phone Number
                          </Typography>
                          <OutlinedFormInput
                            type="number"
                            placeholder={"9087654321"}
                            inputProps={{
                              type: "number",
                            }}
                            value={phone}
                            onChange={(e) => {
                              setPhone(e.target.value);
                            }}
                            autocomplete="off"
                            name="phone"
                          />
                        </Box>
                      </Box>
                    )}
                    <Box className="w-100 mt-4">
                      <Box className="d-flex">
                        <Box className="service-name col-lg-6 col-sm-12 col-md-6 px-2">
                          <Typography
                            sx={[
                              styles.fs16,
                              styles.fontFamilySG,
                              styles.color240501,
                              {
                                [theme.breakpoints.between("xs", "md")]: {
                                  ...classes.fs14,
                                  ...classes.margin4,
                                  ...classes.borderRadius7,
                                },
                              },
                            ]}
                            className="mb-2"
                          >
                            Service Name
                          </Typography>
                          {/* <OutlinedFormInput
                            placeholder={"Plumber"}
                            value={serviceName}
                            onChange={(e) => {
                              setServiceName(e.target.value);
                            }}
                            autocomplete="off"
                            name="serviceName"
                          /> */}
                          <Select
                            sx={[styles.filterSelect]}
                            className="w-100"
                            placeholder="Plumber"
                            value={serviceName}
                            onChange={(e) => {
                              setServiceName(e.target.value);
                            }}
                          >
                            <MenuItem value="">Select</MenuItem>
                            {servicesArr.map((item) => (
                              <MenuItem
                                className="text-capitalize"
                                value={item}
                              >
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                        <Box className="service-pin-code col-lg-6 col-sm-12 col-md-6 px-2">
                          <Typography
                            sx={[
                              styles.fs16,
                              styles.fontFamilySG,
                              styles.color240501,
                              {
                                [theme.breakpoints.between("xs", "md")]: {
                                  ...classes.fs14,
                                  ...classes.margin4,
                                  ...classes.borderRadius7,
                                },
                              },
                            ]}
                            className="mb-2"
                          >
                            PIN Code
                          </Typography>
                          <OutlinedFormInput
                            type="number"
                            placeholder={"700001"}
                            inputProps={{
                              type: "number",
                            }}
                            value={pinCode}
                            onChange={(e) => {
                              setPincode(e.target.value);
                            }}
                            autocomplete="off"
                            name="pinCode"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box className="w-100 mt-4">
                      <Box className="d-flex">
                        <Box className="col-lg-6 col-sm-12 col-md-6 px-2">
                          <Typography
                            sx={[
                              styles.fs16,
                              styles.fontFamilySG,
                              styles.color240501,
                              {
                                [theme.breakpoints.between("xs", "md")]: {
                                  ...classes.fs14,
                                  ...classes.margin4,
                                  ...classes.borderRadius7,
                                },
                              },
                            ]}
                            className="mb-2"
                          >
                            Lanugage
                          </Typography>
                          <OutlinedFormInput
                            placeholder={"Hindi, Bengali"}
                            value={language}
                            onChange={(e) => {
                              setLanguage(e.target.value);
                            }}
                            autocomplete="off"
                            name="language"
                          />
                        </Box>
                        <Box className="col-lg-6 col-sm-12 col-md-6 px-2">
                          <Typography
                            sx={[
                              styles.fs16,
                              styles.fontFamilySG,
                              styles.color240501,
                              {
                                [theme.breakpoints.between("xs", "md")]: {
                                  ...classes.fs14,
                                  ...classes.margin4,
                                  ...classes.borderRadius7,
                                },
                              },
                            ]}
                            className="mb-2"
                          >
                            ID Number
                          </Typography>
                          <OutlinedFormInput
                            placeholder={"write..."}
                            value={adhaarCard}
                            onChange={(e) => {
                              setAdhaarCard(e.target.value);
                            }}
                            autocomplete="off"
                            name="adhaarCard"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box className="mt-4 col-lg-6 col-sm-12 col-md-6 px-2">
                      <Typography
                        sx={[
                          styles.fs16,
                          styles.fontFamilySG,
                          styles.color240501,
                          {
                            [theme.breakpoints.between("xs", "md")]: {
                              ...classes.fs14,
                              ...classes.margin4,
                              ...classes.borderRadius7,
                            },
                          },
                        ]}
                        className="mb-2"
                      >
                        ID Proof*
                      </Typography>
                      <Input
                        inputProps={{
                          type: "file",
                          multiple: true,
                        }}
                        type="file"
                        multiple
                        // value={images}
                        onChange={(e) => {
                          let filesArray = [];
                          for (var i = 0; i < e.target.files.length; i++) {
                            filesArray.push(e.target.files[i]);
                          }
                          setImages(filesArray);
                        }}
                        name="images"
                      />
                    </Box>
                    <Box className="mt-4 col-lg-6 col-sm-12 col-md-6 px-2">
                      <Typography
                        sx={[
                          styles.fs16,
                          styles.fontFamilySG,
                          styles.color240501,
                        ]}
                        className="mb-2"
                      >
                        Locality *
                      </Typography>
                      <OutlinedFormInput
                        placeholder={"Bidhhannagar"}
                        inputRef={ref}
                        name="locality"
                        // value={data.locality}
                        // onChange={(e) => {
                        //   handleTextChange("locality", e.target.value);
                        // }}
                        sx={[
                          { height: "initial" },
                          {
                            [theme.breakpoints.between("xs", "sm")]: [
                              {
                                maxWidth: "300px",
                                minWidth: "300px",
                              },
                            ],
                          },
                        ]}
                      />
                    </Box>
                  </Box>
                  <Box className="w-50 mt-3">
                    <ButtonContained
                      sx={[
                        styles.fs16,
                        styles.borderRadius12,
                        { padding: "0.75rem" },
                      ]}
                      onClick={onSubmit}
                    >
                      Submit
                    </ButtonContained>
                  </Box>
                </Box>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOtherService;
