import React, { useEffect, useState } from "react";
import Home from "./home";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
  useLocation,
} from "react-router-dom";
import Properties from "./properties";
import Furniture from "./furniture";
import AddProperty from "./add-property";
import AddFurniture from "./add-furniture";
import AddBroker from "./add-broker";
import Owners from "./owners";
import Brokers from "./brokers";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAccessories,
  setCurrentLocation,
  setCurrentUser,
} from "../redux/action/auth";
import Login from "../screens/LoginScreen";
import { getProperties } from "../redux/action/property";
import { getFurnitures } from "../redux/action/furniture";
import PropertyDetails from "./property-detail";
import FurnitureDetails from "./furniture-detail";
import ProductList from "./viewed-products";
import Profile from "./profile";
import UserDetails from "./user-details";
import AddAccessories from "./add-accessories";
import AddOtherService from "./other-service";
import OtherServices from "./other-service/list";
import FurnitureOrder from "./furniture-order";
import AboutUs from "./about";
import RentalTerms from "./terms/rental";
import RefferalTerms from "./terms/referral";
import Privacy from "./privacy";
import ContactUs from "./contact";
import BuyingProperty from "./buying-property";
import SellProperty from "./sell-property";
import RentingProperty from "./renting-property";
import SecurityDep from "./security-deposit";
import Wishlist from "./wishlist";
import Services from "./services-text";
import ReturnPolicy from "./return-policy";
import DataSecurity from "./data-security";
import toast from "react-hot-toast";
import Covid19 from "./covid";
import Benefits from "./benefits";
import MainTerms from "./terms/main";
import CheckPaymentStatus from "../components/CheckPaymentStatus";
import Agreement from "./agreement";
import AgreementForm from "./agreement-form/AgreementForm";
import FormBasicDetails from "./agreement-form/forms/FormBasicDetails";
import FormPropertyDetails from "./agreement-form/forms/FormPropertyDetails";
import FormContractDetails from "./agreement-form/forms/FormContractDetails";
import AgreementContent from "./agreement-form/components/AgreementContent";
import FinalAgreement from "./agreement-form/components/FinalAgreement";
import AgreementPaymentStatus from "../components/AgreementPaymentStatus";
import { checkTabsStatus } from "../config/user";
import AdminLogin from "./admin-login";
import TabsAccess from "./tabs";
import Index from "./carrying-cost";
import Shortlist from "./shortlist";
import Pincodes from "./pincodes";
import Notification from "./notification/Notification";
import Admin from "./admin";
import SuccessfullOrder from "./succssfullOrder";
import AdminCategory from "./admin-category";
import EditServices from "./edit-services";
import ImageUploader from "../components/BlogUpload/Blog";
import SingleBlogPage from "../components/BlogUpload/SingleBlog";
import BlogForm from "../components/BlogUpload/UploadBlog";
import Checkout from "./checkout";
import ReferScheme from "./refer-scheme";
import AddTestimonial from "./add-testimonial";
import FurnitureRenting from "./furniture-home-renting";
import BuyingFurniture from "./furniture-home-renting/buying";
import ContactUsPage from "./contact-us";
import Testimonials from "./testimonials";
import PincodeTestimonial from "./pincode-testimonial";
import GetContact from "./get-contact";
import BlogCard from "../components/BlogUpload/Blog";
import EditBlog from "../components/BlogUpload/EditBlog";
import HappyClients from "./happy-clients";
import WelcomeToGharwala from "./welcome-to-gharwala";
import AgreementPage from "./agreement-page";
import EditFurniture from "./edit-furniture/editFurniture";
import FormFurnishingDetails from "./agreement-form/forms/FormFurnishingDetails";
import RecentProperties from "./recent-properties";
import ReferAndEarn from "./refer-earn";
import ManageServices from "./manage-services";
import ManageProperties from "./manage-properties";
import ModalCredit from "../components/ModalCredits/ModalCredit";
import UnsuccessfulOrder from "./unsuccessfulOrder";
import UpdateProperty from "./edit-property";
import UserList from "./users";

const RootStackScreen = ({
  closeLoginModal,
  setCloseLoginModal,
  setOpenPopUp,
}) => {
  const [isLogin, setIsLogin] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const [blogId, setBlogId] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  console.log("user", user);
  // const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  React.useEffect(() => {
    getUser();
    fetchDataEveryMinute();
    // onGetAllProperties();
  }, []);

  const fetchDataEveryMinute = async () => {
    let enabled = true;
    if (user?.role === "BROKERS") {
      let res = await checkTabsStatus({ propertyName: "BROKERS" });
      enabled = res.message.enabled;
    } else if (user?.role === "OWNERS") {
      let res = await checkTabsStatus({ propertyName: "OWNERS" });
      enabled = res.message.enabled;
    } else if (user?.role === "SERVICES") {
      let res = await checkTabsStatus({ propertyName: "SERVICES" });
      enabled = res.message.enabled;
    }
    if (!enabled) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }
  };

  useEffect(() => {
    const intervalId = setInterval(fetchDataEveryMinute, 60000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        dispatch(
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
        );
        onGetAllProperties(
          `?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}`
        );
        onGetAllFurnitures();
      },
      () => {
        onGetAllProperties(``);
        onGetAllFurnitures();
      }
    );
  }, []);

  const onGetAllProperties = (query = "") => {
    // dispatch(getProperties(query));
    dispatch(getAllAccessories());
  };
  const onGetAllFurnitures = () => {
    let filterQuery = "";
    dispatch(getFurnitures(filterQuery));
  };

  const getUser = () => {
    let token = localStorage.getItem("token");
    if (token) {
      dispatch(
        setCurrentUser(token, () => {
          setIsLogin(true);
          setLoader(false);
        })
      );
    } else {
      setIsLogin(false);
      setLoader(false);
    }
  };
  return (
    <Routes>
      <Route path="/login" element={<Login setIsLogin={setIsLogin} />}></Route>
      <Route
        path="/"
        element={
          <Home
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      ></Route>
      <Route
        path="/checkout"
        element={
          <Checkout
            user={user}
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route path="/furniture-renting" element={<FurnitureRenting />} />
      <Route path="/furniture-buying" element={<BuyingFurniture />} />
      <Route path="/agreement" element={<Agreement />}></Route>
      <Route path="/agreement" element={<AgreementForm />}>
        <Route path="basic-details" element={<FormBasicDetails />} />
        <Route path="property-details" element={<FormPropertyDetails />} />
        <Route path="contract-details" element={<FormContractDetails />} />
        <Route path="furnishing-details" element={<FormFurnishingDetails />} />
        <Route path="final-agreement" element={<FinalAgreement />} />
      </Route>

      <Route path="/carrying-cost" element={<Index />} />
      <Route
        path="/properties"
        element={
          <Properties
            user={user}
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      ></Route>
      <Route
        path="/properties/:id"
        element={
          <PropertyDetails
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      ></Route>
      <Route
        path="/furniture"
        element={
          <Furniture
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route path="/owners" element={<Owners />} />
      <Route path="/owners/:id" element={<UserDetails />} />
      <Route path="/all-users"  element={<UserList user_loggedin={user} />} />
      <Route path="/successfullOrder" element={<SuccessfullOrder />} />
      <Route path="/unsuccessfulOrder" element={<UnsuccessfulOrder />} />
      <Route path="/edit-properties/:id" element={<UpdateProperty/>} />
      <Route path="/brokers" element={<Brokers />} />
      <Route path="/brokers" element={<Brokers />} />
      <Route path="/brokers" element={<Brokers />} />
      <Route path="/pincodes" element={<Pincodes />} />
      <Route path="/notification" element={<Notification />} />
      <Route path="/admin-category" element={<AdminCategory />} />

      <Route path="/add-property" element={<AddProperty />} />
      <Route path="/add-furniture" element={<AddFurniture />} />
      <Route
        path="/furniture/:id"
        element={
          <FurnitureDetails
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route path="/edit-furniture/:id" element={<EditFurniture />} />
      <Route path="/check-status" element={<CheckPaymentStatus />} />
      <Route path="/agreement-status" element={<AgreementPaymentStatus />} />
      <Route
        path="/blogs"
        element={<BlogCard user={user} setBlogId={setBlogId} />}
      />

      <Route path="/blogs/:blogId" element={<SingleBlogPage />} />
      <Route path="/blogs/addBlog" element={<BlogForm />} />
      <Route path="/blogs/editBlog" element={<EditBlog blogId={blogId} />} />

      <Route path="/furniture/order" element={<FurnitureOrder />} />
      <Route path="/add-broker" element={<AddBroker />} />
      <Route path="/brokers/:id" element={<UserDetails />} />
      <Route path="/add-accessories" element={<AddAccessories />} />
      <Route path="/add-other-service" element={<AddOtherService />} />
      <Route path="/other-services" element={<OtherServices setOpenPopUp={setOpenPopUp} />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/rental-terms" element={<RentalTerms />} />
      <Route path="/referral-terms" element={<RefferalTerms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route
        path="/profile"
        element={
          <Profile
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route path="/buying-property" element={<BuyingProperty />} />
      <Route path="/wishlist" element={<Wishlist />} />
      <Route path="/shortlist" element={<Shortlist />} />
      <Route path="/selling-property" element={<SellProperty />} />
      <Route path="/renting-property" element={<RentingProperty />} />
      <Route path="/security-deposit" element={<SecurityDep />} />
      <Route path="/services" element={<Services />} />
      <Route path="/return-policy" element={<ReturnPolicy />} />
      <Route path="/data-security" element={<DataSecurity />} />
      <Route path="/covid19" element={<Covid19 />} />
      <Route path="/benefits" element={<Benefits />} />
      <Route path="/terms" element={<MainTerms />} />
      <Route path="/admin-login" element={<AdminLogin />} />
      <Route path="/tabs-access" element={<TabsAccess />} />
      <Route path="/manage-services" element={<ManageServices />} />
      <Route path="/manage-properties" element={<ManageProperties />} />

      <Route path="/admin" element={<Admin />} />
      <Route path="/recent-properties" element={<RecentProperties />} />

      <Route path="/edit-services" element={<EditServices />} />
      <Route path="/contact" element={<ContactUsPage />} />
      <Route path="/add-testimonial" element={<AddTestimonial />} />
      <Route path="/pincode-testimonial" element={<PincodeTestimonial />} />
      <Route path="/get-testimonial" element={<Testimonials />} />
      <Route
        path="/happy-clients"
        element={
          <HappyClients
            closeLoginModal={closeLoginModal}
            setCloseLoginModal={setCloseLoginModal}
            setOpenPopUp={setOpenPopUp}
          />
        }
      />
      <Route path="/get-contact" element={<GetContact />} />
      <Route path="/refer-scheme" element={<ReferScheme />} />
      <Route path="/refer-now" element={<ReferAndEarn />} />

      <Route
        path="/viewed-properties-all"
        element={<ProductList user={user} />}
      />
      <Route path="/viewed-properties" element={<ProductList user={user} />} />
      <Route path="/welcome-to-gharwala" element={<WelcomeToGharwala />} />
      <Route path="/agreement-page" element={<AgreementPage />} />
      <Route path="/buy-credits" element={<ModalCredit />} />
    </Routes>
  );
};

export default RootStackScreen;
