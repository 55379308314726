import { useLocation } from "react-router-dom";
import cash from "../../../assets/images/cash.svg";
import { PDFViewer } from "@react-pdf/renderer";
import MyDocument from "./MyDocument";

const AgreementContent = ({
  ownerData,
  tenantData,
  propertyDetails,
  setPropertyDetails,
  contractDetails,
  setContractDetails,
  setFurnitureId,
  furnitureId,
  furnishedDetails,
  setFurnishedDetails,
}) => {
  const date = new Date();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const { pathname } = useLocation();

  return (
    <div
      className="agreement-container"
      style={{
        display: pathname.includes("final") ? "none" : "block",
        width: pathname.includes("final") ? "100%" : "30%",
        marginTop: !pathname.includes("final") && "100px",
      }}
    >
      {/* <div className='agreement-header'>Agreement Sample:</div> */}
      <div className="cash-img-container">
        <img className="cash-img" src={cash} />
      </div>
      <div className='agreement-content'>
        <p style={{fontWeight: "bold", textAlign: "center"}}>RENT AGREEMENT</p>
        This agreement is made on this{' '}
        {/* <span>{date.getDate()}nd</span> day of <span>{months[date.getMonth()]}</span>, <span>{date.getFullYear()}</span> {' '} */}
        <span>{contractDetails.startDate === null ? '__________' : `${contractDetails.startDate}`}</span>,{' '}
        of Christian Era.

        <p style={{fontWeight: "bold", textAlign: "center"}}>BETWEEN</p>

        <span>{ownerData.name === null ? '__________' : `${ownerData.initial} ${ownerData.name}`}</span>,{' '}
        <span>{ownerData.parentName === null  ? '__________' : `${ownerData.relation} ${ownerData.parentName}`}</span>, {' '}
        <span>{ownerData.docNumber === null ? '__________' : `${ownerData.chooseDoc} ${ownerData.docNumber}`}</span>, {' '}
        Mobile Number <span>{ownerData.number === null ? '__________' : `${ownerData.number}`},</span> {' '}
        By faith- <span>{ownerData.faith === null ? '__________' : `${ownerData.faith}`}</span>,{' '}
        By Profession <span>{ownerData.profession === null ? '__________' : `${ownerData.profession}`}</span>,{' '}
        residing at <span>{ownerData.address === null ? '__________' : `${ownerData.address}`}</span>,{' '}
        and Rent Permises address is <span>{ownerData.city === null ? '__________' : `${ownerData.city}`}</span>,{' '}
        <span>{ownerData.state === null ? '__________' : `${ownerData.state}`}</span>,{' '}
        <span>{ownerData.pincode === null ? '__________' : `${ownerData.pincode}`}</span>, {' '}
        (WB) hereinafter called the 'LESSOR' (which expression shall mean & include his legal heirs, successors, administrator, representatives, executors and assigns) on the ONE PART.

        <p style={{fontWeight: "bolder", textAlign: "center"}}>AND</p>
        
        <span>{tenantData[0].name === null ? '__________' : `${tenantData[0].initial} ${tenantData[0].name}`}</span>, {' '}
        <span>{tenantData[0].parentName === null ? '__________' : `${tenantData[0].relation} ${tenantData[0].parentName}`}</span>, {''}
        <span>{tenantData[0].docNumber === null ? '__________' : `${tenantData[0].chooseDoc} ${tenantData[0].docNumber}`}</span>, {' '}
        Mobile Number {' '} <span>{tenantData[0].number === null ? '__________' : `${tenantData[0].number}`}</span>,{' '} 
        By faith- <span>{tenantData[0].faith === null ? '__________' : `${tenantData[0].faith}`}</span>,{' '} 
        By occupation <span>{tenantData[0].profession === null ? '__________' : `${tenantData[0].profession}`}</span>,{' '}
        residing at {' '}
        <span>{tenantData[0].address === null ? '__________' : `${tenantData[0].address}`}</span>,{' '}
        <span>{tenantData[0].city === null ? '__________' : `${tenantData[0].city}`}</span>,{' '}
        <span>{tenantData[0].state === null ? '__________' : `${tenantData[0].state}`}</span>,{' '}
        <span>{tenantData[0].pincode === null ? '__________' : `${tenantData[0].pincode}`}</span>, {' '}

        {tenantData.length >= 2 && (
          <>
            <p style={{fontWeight: "bolder", textAlign: "center"}}>AND</p>

            {/* <span>{tenantData[1].name === null ? '__________' : `${tenantData[1].initial} ${tenantData[1].name}`}</span>, with{' '}
            <span>{tenantData[1].number === null ? '__________' : `${tenantData[1].number}`}</span> as Mobile Number, residing at
            <span>{tenantData[1].address === null ? '__________' : `${tenantData[1].address}`}</span>,{' '}
            <span>{tenantData[1].city === null ? '__________' : `${tenantData[1].city}`}</span>,{' '}
            <span>{tenantData[1].state === null ? '__________' : `${tenantData[1].state}`}</span>,{' '}
            <span>{tenantData[1].pincode === null ? '__________' : `${tenantData[1].pincode}`}</span> */}

            <span>{tenantData[1].name === null ? '__________' : `${tenantData[1].initial} ${tenantData[1].name}`}</span>, {' '}
            <span>{tenantData[1].parentName === null ? '__________' : `${tenantData[1].relation} ${tenantData[1].parentName}`}</span>, {''}
            <span>{tenantData[1].docNumber === null ? '__________' : `${tenantData[1].chooseDoc} ${tenantData[1].docNumber}`}</span>, {' '}

            Mobile Number {' '} <span>{tenantData[1].number === null ? '__________' : `${tenantData[1].number}`}</span>{' '} 
            By faith- <span>{tenantData[1].faith === null ? '__________' : `${tenantData[1].faith}`}</span>,{' '} 
            By occupation <span>{tenantData[1].profession === null ? '__________' : `${tenantData[1].profession}`}</span>,{' '}
            residing at {' '}
            <span>{tenantData[1].address === null ? '__________' : `${tenantData[1].address}`}</span>,{' '}
            <span>{tenantData[1].city === null ? '__________' : `${tenantData[1].city}`}</span>,{' '}
            <span>{tenantData[1].state === null ? '__________' : `${tenantData[1].state}`}</span>,{' '}
            <span>{tenantData[1].pincode === null ? '__________' : `${tenantData[1].pincode}`}</span> ,
          </>
        )}{' '}

        {tenantData.length >= 3 && (
          <>
            <p style={{fontWeight: "bolder", textAlign: "center"}}>AND</p>

            {/* <span>{tenantData[2].name === null ? '__________' : `${tenantData[2].initial} ${tenantData[1].name}`}</span>, with{' '}
            <span>{tenantData[2].number === null ? '__________' : `${tenantData[2].number}`}</span> as Mobile Number, residing at
            <span>{tenantData[2].address === null ? '__________' : `${tenantData[2].address}`}</span>,{' '}
            <span>{tenantData[2].city === null ? '__________' : `${tenantData[2].city}`}</span>,{' '}
            <span>{tenantData[2].state === null ? '__________' : `${tenantData[2].state}`}</span>,{' '}
            <span>{tenantData[2].pincode === null ? '__________' : `${tenantData[2].pincode}`}</span> */}

            <span>{tenantData[2].name === null ? '__________' : `${tenantData[2].initial} ${tenantData[2].name}`}</span>, {' '}
            <span>{tenantData[2].parentName === null ? '__________' : `${tenantData[2].relation} ${tenantData[2].parentName}`}</span>, {''}
            <span>{tenantData[2].docNumber === null ? '__________' : `${tenantData[2].chooseDoc} ${tenantData[2].docNumber}`}</span>, {' '}

            Mobile Number {' '} <span>{tenantData[2].number === null ? '__________' : `${tenantData[2].number}`}</span>{' '}
            By faith- <span>{tenantData[2].faith === null ? '__________' : `${tenantData[2].faith}`}</span>,{' '} 
            By occupation <span>{tenantData[2].profession === null ? '__________' : `${tenantData[2].profession}`}</span>,{' '} 
            residing at {' '}
            <span>{tenantData[2].address === null ? '__________' : `${tenantData[2].address}`}</span>,{' '}
            <span>{tenantData[2].city === null ? '__________' : `${tenantData[2].city}`}</span>,{' '}
            <span>{tenantData[2].state === null ? '__________' : `${tenantData[2].state}`}</span>,{' '}
            <span>{tenantData[2].pincode === null ? '__________' : `${tenantData[2].pincode}`}</span>, {' '}
          </>
        )}{' '}
        hereinafter referred to as the “LESSEE” (which expression shall mean & include his legal heirs, successors, administrator, representatives, executors and assigns) in the OTHER PART. <br></br> WHEREAS the lease agreement shall be valid for a period of {' '} 

        <span>{contractDetails.duration === null ? '__________' : `${contractDetails.duration} months`}</span> {' '}
        commencing from {' '}
        {/* <span>{date.getDate()}</span> {' '}
        <span>{months[date.getMonth()]}</span> {' '}
        <span>{date.getFullYear()}</span> {' '}, */}
        <span>{contractDetails.startDate === null ? '__________' : `${contractDetails.startDate}`}</span>,{' '}
        <span>& expiring on {' '}<span>{contractDetails.endDate === null ? '__________' : `${contractDetails.endDate}`}</span></span> {' '} 
        on the terms & conditions hereinafter as appearing.


        {/* {propertyDetails.addressSame ? (
          <>
            {' '}
            <span>{ownerData.address === null ? '__________' : ownerData.address}</span>, <span>{ownerData.city === null ? '__________' : ownerData.city}</span>{' '}
            , <span>{ownerData.state === null ? '__________' : ownerData.state}</span>,{' '}
            <span>{ownerData.pincode === null ? '__________' : ownerData.pincode}</span>
          </>
        ) : (
          <>
            <span>{propertyDetails.address === null ? '__________' : propertyDetails.address}</span>,{' '}
            <span>{propertyDetails.city === null ? '__________' : propertyDetails.city}</span> ,{' '}
            <span>{propertyDetails.state === null ? '__________' : propertyDetails.state}</span>,{' '}
            <span>{propertyDetails.pincode === null ? '__________' : propertyDetails.pincode}</span>
          </>
        )}
        and comprising of <span>{propertyDetails.rooms === null ? '__________' : propertyDetails.rooms}</span> including{' '}
        <span>{propertyDetails.bedroom !== 0 && propertyDetails.bedroom + ' Bedroom, '} </span>
        <span>{propertyDetails.bathroom !== 0 && propertyDetails.bathroom + ' Bathroom, '} </span>
        <span>{propertyDetails.balcony !== 0 && propertyDetails.balcony + ' Balcony, '} </span>
        {propertyDetails.others.length !== 0 && propertyDetails.others.map((o) => <span>{o},</span>)} present in Floor{' '}
        <span>{propertyDetails.floor === null ? '__________' : propertyDetails.floor}</span>, <span>{propertyDetails.parking ? 'with' : 'without'}</span>{' '}
        Parking{' '}
        {propertyDetails.area !== '' && (
          <>
            with an area of <span>{propertyDetails.area} Square Feet</span>
          </>
        )}{' '}
        hereinafter referred to as the 'said premises'. <br />
        <br />
        AND WHEREAS at the request of the Lessee, the Lessor has agreed to let the said premises to the tenant for a term of
        <span>{contractDetails.duration === null ? '__________' : `${contractDetails.duration} Months`}</span> commencing from 2024-02-22 in the manner
        hereinafter appearing. NOW THIS AGREEMENT WITNESSETH AND IT IS HEREBY AGREED BY AND BETWEEN THE PARTIES AS UNDER: */}

        <p style={{fontWeight: "bolder", marginTop: "20px"}}>NOW IT IS HEREBY EXPRESSLY AGREED AND DECLARED BY AND BETWEEN THE PARTIES AS FOLLOWS:</p>

        <ol>
          <li>
             The Lessor hereby grants to the Lessee and the Lessee hereby accepts from the Lessor the licence to occupy & use the said flat mentioned in the schedule below with effect from {' '}
             {/* <span>{date.getDate()}nd</span> {' '}
             <span>{months[date.getMonth()]}</span> {' '}
             <span>{date.getFullYear()}</span> {' '}, */}
             <span>{contractDetails.startDate === null ? '__________' : `${contractDetails.startDate}`}</span>,{' '}
             <span>for a period of </span>
             {<span>{contractDetails.duration === null ? '__________' : `${contractDetails.duration}`}</span>} {' '} months.
          </li>

          <li>
             The Lessee shall in consideration of such lease accommodation as stated above pay to the Lessor sum of {' '}
             <span>₹ {' '}{contractDetails.rentAmount === null ? '__________' : contractDetails.rentAmount}</span> {' '}
             - (Rupees <span>{contractDetails.rentAmountInWords === null ? '__________' : contractDetails.rentAmountInWords}</  span> {' '} Only) per month towards lease rent for such accommodation {' '}
             <span>{contractDetails.maintenanceChoice === null ? '__________' : contractDetails.maintenanceChoice}</span> {' '}
              society & maintenance charges {' '}
              <span>₹ {' '}{contractDetails.maintenanceCharges === null ? '__________' : contractDetails.maintenanceCharges}</span> {' '}
              per month per English calendar month with effect from {' '}
              <span>{contractDetails.startDate === null ? '__________' : `${contractDetails.startDate}`}</span>,{' '} for {' '} 
              {<span>{contractDetails.duration === null ? '__________' : `${contractDetails.duration}`}</span>} {' '}
               Months with escalation prices/charges @
              <span>{contractDetails.escalationPrice === null ? '__________' : contractDetails.escalationPrice}</span>
              % or {' '} 
              <span>₹ {' '}{contractDetails.escalationPriceAmount === null ? '__________' : contractDetails.escalationPriceAmount}</span> {' '}
              after {' '}<span>{contractDetails.duration === null ? '__________' : contractDetails.duration}</span> {' '}
              months” which sum shall be paid by the lessee before the {' '}
              <span>{contractDetails.rentPaymentDate === null ? '__________' : contractDetails.rentPaymentDate}</span> {' '}
              as per English Calendar without delay. 
          </li>

          <li>
            The Lessor will keep the security deposit of {' '}
            <span>₹ {' '}{contractDetails.securityDeposit === null ? '__________' : contractDetails.securityDeposit}</span> {' '}
            /- (Rupees {' '}<span>{contractDetails.securityDepositInWords === null ? '__________' : contractDetails.securityDepositInWords}</span> {' '} Only) 
            as interest free security deposit. The interest free security deposit will be refunded on the revocation and/or vacation or expiry of the lease rent agreement however the Lessor will have the right to recover the cost of damage if any, caused by the Lessee to the property from the said security deposit.
          </li>

          <li>
            The Lessee shall not have any right to make any modification, addition, alteration to the said flat, nor shall be entitled to use the same for pursuing any purpose other than residential accommodation. He should not be allowed to make any storage of articles other than those necessary habitation purpose. Further the lessee shall not let out, co-share or part with the possession of the said portion of the premises or any part thereof to any person/organisation. 
          </li>

          <li>
            That all the charges as per Bills & Meter reading for consumption of Electricity in the said flat shall be borne by the Lessee.(as per reading of the Electric meter or applicable charges as may be as and when installed)
          </li>

          <li>
            The lessee shall keep the said flat in good condition & if any damages, breakages are caused to the said flat, the lessee shall make good the loss caused to the lessors on account of such damages & breakages. Any minor repair of arising due to usage of the said flat would be borne the lessee.
          </li>

          <li>
            The lessee shall not cause any nuisance & shall refrain from doing any act which might be objectionable to the owner or the neighbours and for this purpose the lessors shall have right to enter & inspect the premise at any time suitable to them.
          </li>

          <li>
            That the lessee shall not carry any illegal business, or activities which would cause damage to the said flat/ premises and shall strongly observe the rules & regulations of the society, municipal corporation, Gram Panchayat and Police Department.
          </li>

          <li>
            The lessor shall be entitled to revoke the licence at any time during the subsistence of the lease period for any breach committed by the lessee by sending one calendar month notice in writing revoking the said licence.
          </li>

          <li>
            The lessor has agreed that he shall bear and continue to bear all liabilities obligations, costs and responsibilities, which he bears as owner of the aforesaid premises and he shall pay all rates, taxes, assessments, ceases and other outgoings whether municipal or otherwise which ore now payable or during the period of occupation of the flat by the lessee in respect of the said flat.
          </li>

          <li>
            The lessee shall also be entitled to vacate the property at any time during the lease period by serving the lessor one calendar month notice in writing or by paying one calendar month accommodation charges in lieu of the notice.
          </li>

          <li>
            The lease agreement shall be automatically terminated on expiry of the lease period {' '}
            {<span>{contractDetails.duration === null ? '__________' : `${contractDetails.duration}`}</span>} {' '} months
            and immediately after that the lessee shall vacate and handover peaceful possession of the said premises to the lessors in good condition. The lease may however be mutually renewed before expiry of current lease agreement. 
          </li>
          
          <li>
            That the lessee does hereby agree/undertake he, his family member/ his roommate/ visitors, shall take all reasonable care of, all and singular, the portion and the premises, and shall indemnify the lessors from and against any damages/loss by reason of normal use/occupation thereof and he shall always keep the portion & the premises a clean, habitable decent/ sanitary, condition from waste/rubbish.
          </li>

          <li>
            Rent will be payable to {' '}         
            <span>{ownerData.name === null ? '__________' : `${ownerData.initial} ${ownerData.name}`}</span>,{' '}
             in the Account Or cash Or UPI PAYMENT, Before Due Date.
          </li>

          <li>
            That this Agreement shall be governed by: <br></br>
            (a) Indian Contract Act, 1982 and <br></br>
            (b) In case of breach or in the matter of taking legal proceedings, if any will be the jurisdiction of the court of Kolkata.
          </li>

          <p style={{fontWeight: "bolder", textAlign: "center", marginTop: "10px"}}>SCHEDULE</p>

          he said flat consists of No. of bed rooms {' '}
          <span>{propertyDetails.bedroom === null ? '__________' : propertyDetails.bedroom}</span>, {' '}
          <span>{propertyDetails.bathroom !== 0 && propertyDetails.bathroom + ' Bathroom, '} </span>
          <span>{propertyDetails.balcony !== 0 && propertyDetails.balcony + ' Balcony, '} </span>
          {propertyDetails.others.length !== 0 && propertyDetails.others.map((o) => <span>{o}, {' '}</span>)} present in Floor{' '}
          <span>{propertyDetails.floor === null ? '__________' : propertyDetails.floor}</span>, <span>{propertyDetails.parking ? 'with' : 'without'}</span>{' '}
          Parking{' '}
          {propertyDetails.area !== '' && (
            <>
              with an area of <span>{propertyDetails.area} Square Feet</span>
            </>
          )},{' '}
          Address {' '}
          <span>{propertyDetails.address === null ? '__________' : propertyDetails.address}</span>,{' '}
          <span>{propertyDetails.city === null ? '__________' : propertyDetails.city}</span> ,{' '}
          <span>{propertyDetails.state === null ? '__________' : propertyDetails.state}</span>,{' '}
          <span>{propertyDetails.pincode === null ? '__________' : propertyDetails.pincode}</span>


          <p style={{marginTop: "20px"}}>
            IN WITNESS WHEREOF we the parties of this agreement set & subscribed our hands and seals on the day, month and year first above written.
          </p>

          <p style={{fontWeight: "bolder", textAlign: "center"}}>WITNESS</p>

          <div style={{display: "flex", justifyContent: "space-between"}}>
            <span>1.</span>
            <div style={{marginTop: "20px"}}>
              <div style={{borderBottom: "1px solid black"}}></div>
              <span>Signature of the Licensor/Lessor</span>
            </div>
          </div>

          <div style={{marginTop: "20px", display: "flex", justifyContent: "space-between"}}>
            <span>2.</span>
            <div style={{marginTop: "20px"}}>
              <div style={{borderBottom: "1px solid black"}}></div>
              <span>Signature of the Licensee/Lessee</span>
            </div>
          </div>


          {/* 
          <li>
            That the Lessor hereby grant to the Lessee, the right to enter and use and remain in the said premises along with the existing fixtures and fittings
            listed in Annexure 1 to this Agreement and that the Lessee shall be entitled to peacefully possess and enjoy possession of the said premises for{' '}
            <span>{propertyDetails.type === null ? '__________' : propertyDetails.type}</span> use, and the other rights herein.
          </li>

          <li>
            That the lease hereby granted shall, unless cancelled earlier under any provision of this Agreement, remain in force for a period of{' '}
            {<span>{contractDetails.duration === null ? '__________' : `${contractDetails.duration} Months`}</span>}.
          </li>

          <li>
            That the Lessee will have the option to terminate this lease by giving in{' '}
            {<span>{contractDetails.noticePeriod === null ? '__________' : `${contractDetails.noticePeriod} Months`}</span>} writing to the Lessor.
          </li>

          <li>
            That the Lessee shall have no right to create any sub-lease or assign or transfer in any manner the lease or give to any one the possession of the
            said premises or any part thereof.
          </li>

          <li>That the Lessee shall use the said premises only for residential purposes.</li>

          <li>
            That the Lessor shall, before handing over the said premises, ensure the working of sanitary, electrical and water supply connections and other
            fittings pertaining to the said premises. It is agreed that it shall be the responsibility of the Lessor for their return in the working condition
            at the time of re-possession of the said premises, subject to normal wear and tear.
          </li>

          <li>That the Lessee is not authorized to make any alteration in the construction of the said premises.</li>

          <li>
            That the day-to-day repair jobs shall be affected by the Lessee at his own cost, and any major repairs, either structural or to the electrical or
            water connection, plumbing leaks, water seepage shall be attended to by the Lessor. In the event of the Lessor failing to carry out the repairs on
            receiving notice from the Lessee, the Lessee shall undertake the necessary repairs and the Lessor will be liable to immediately reimburse costs
            incurred by the Lessee.
          </li>

          <li>
            That the Lessor or its duly authorized agent shall have the right to enter or upon the said premises or any part thereof at a mutually arranged
            convenient time for the purpose of inspection.
          </li>
          
          <li>
            That in consideration of use of the said premises the Lessee agrees that he shall pay to the Lessor during the period of this agreement, a monthly
            rent at the rate of ₹<span>{contractDetails.rentAmount === null ? '__________' : contractDetails.rentAmount}</span>. The amount will be paid in
            advance on or before the date of <span></span>. That the Lessee shall pay to the Landlord a monthly maintenance charge of ₹
            {<span>{contractDetails.mainte}</span>}
            towards maintenance of property.
          </li>
          <li>
            It is hereby agreed that in the event of default in payment of the rent for a consecutive period of three months the lessor shall be entitled to
            terminate the lease.
          </li>
          <li>
            That the Lessee has paid to the Lessor a sum of ₹
            <span>{contractDetails.securityDeposit === null ? '__________' : contractDetails.securityDeposit}</span> as deposit, free of interest. The said
            deposit shall be returned to the Lessee simultaneously with the Lessee vacating the said premises. In the event of failure on the part of the Lessor
            to refund the said deposit amount to the Lessee as aforesaid, the Lessee shall be entitled to continue to use and occupy the said premises without
            payment of any rent until the Lessor refunds the said amount.
          </li>
          <li>
            That the Lessor shall be responsible for the payment of all taxes and levies pertaining to the said premises including but not limited to House Tax,
            Property Tax, other cesses, if any, and any other statutory taxes, levied by the Government or Governmental Departments. During the term of this
            Agreement, the Lessor shall comply with all rules, regulations and requirements of any statutory authority, local, state, and central government,
            and governmental departments in relation to the said premises.
          </li> */}
        </ol>

        <div>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            ANNEXURE I
          </div>
          <div>
            List of fixtures and fittings provided in {propertyDetails.address}, {propertyDetails.city}, {propertyDetails.state}, {propertyDetails.pincode}
          </div>
        </div>

        {/* furnishing details: */}
        <div
          style={{
            margin: '10px 0',
          }}
        >
          <div
            style={{
              fontWeight: 'bold',
            }}
          >
            {furnishedDetails.type.charAt(0).toUpperCase() + furnishedDetails.type.slice(1)}
          </div>
          <ol>
            {Object.keys(furnishedDetails).map(
              (key) =>
                key !== 'type' &&
                furnishedDetails[key] !== 0 && (
                  <li>
                    <span>{key.charAt(0).toUpperCase() + key.slice(1)}</span>&nbsp;&nbsp;-&nbsp;&nbsp;
                    <span
                      style={{
                        fontWeight: 'normal',
                      }}
                    >
                      {furnishedDetails[key]}
                    </span>
                  </li>
                )
            )}
          </ol>
        </div>
      </div>

    </div>
  );
};

export default AgreementContent;
