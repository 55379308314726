import React, { useEffect, useState } from 'react';
import StickyFooter from '../../components/Footer';
import Header from '../../components/Header';
import HomeHeroSection from '../../components/HomeHeroSection/HomeHeroSection';
import Ribbon from "../../components/Ribbon/Ribbon";
import SearchBar from '../../components/SearchBar/SearcBar';
import SearchBarMobile from '../../components/SearchBarMobile/SearchBarMobile';
import HomeAbouSection from '../../components/HomeAboutSection/HomeAboutSection';
import PropertySlider from '../../components/Slider/Slider';
import HomeBottomSection from '../../components/HomeBottomSections/HomeBottomSection';
import FurnitureSlider from '../../components/FurnitureSlider/FurnitureSlider';
// import SearchBarMobile from "../../components/SearchBarMobile/SearchBarMobile";
import HomeHeroSectionMobile from '../../components/HomeHeroSectionMobile/HomeHeroSectionMobile';
import StickyFooterMobile from '../../components/FooterMobile';
import { useMediaQuery } from 'react-responsive';
import toast from 'react-hot-toast';
import { updateTransactionService } from '../../config/user';
import Testimonials from '../../components/testimonials';
import publicReq from '../../config/axiosConfig';
import ScrollToTop from '../../components/ScrollToTop';

const Home = ({ closeLoginModal, setCloseLoginModal, setOpenPopUp }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const showToastTransactionAlert = async () => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('success') === 'true') {
      await updateTransaction(params.get('id'), params.get('furniture'));
    } else if (params.get('success') === 'false') {
      toast.error('Sorry something went wrong!');
      let timeout = setTimeout(() => {
        window.location.href = '/';
        clearTimeout(timeout);
      }, 1000);
    }
  };

  console.log(screenWidth);

  const updateTransaction = async (id, furniture) => {
    try {
      const res = await updateTransactionService({
        id,
        furniture,
      });
      if (res.data.status) {
        toast.success(`Payment done successfully!\n${furniture && furniture === 'true' ? 'Order placed' : 'Credits added to account!'}`);
        let timeout = setTimeout(() => {
          window.location.href = '/';
          clearTimeout(timeout);
        }, 1000);
      } else {
        toast.error(res.data.message);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    let timeout = setTimeout(() => {
      showToastTransactionAlert();
    }, 800);
    return () => clearTimeout(timeout);
  }, []);

  const [showProperty, setShowProperty] = useState(false);

  async function checkTabS() {
    try {
      const result = await publicReq.post('/get-access-for-basic-features', {
        propertyName: 'PROPERTY',
      });

      // const results = await Promise.all(promises);
      console.log('tab', result);
      setShowProperty(result.data.message.enabled);

    } catch (error) {
      toast.error('something went wrong');
      console.log(error);
    }
  }


  useEffect(() => {
    checkTabS();
  }, []);

  return (
    <>
      <ScrollToTop />
      <Header closeLoginModal={closeLoginModal} setCloseLoginModal={setCloseLoginModal} setOpenPopUp={setOpenPopUp} />
      {screenWidth > 700 ? (
        <>
          <HomeHeroSection />
          <Ribbon />
          <SearchBar />
        </>
      ) : (
        <>
          <HomeHeroSection />
          <SearchBarMobile />
        </>
      )}
      {showProperty && <PropertySlider />}
      <FurnitureSlider />
      <HomeAbouSection />
      <HomeBottomSection />
      <Testimonials />
      {isMobile ? <StickyFooterMobile /> : <StickyFooter />}
    </>
  );
};

export default Home;
