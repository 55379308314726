import { Autocomplete, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "../../../styles/styles";
import OutlinedFormInput from "../../../components/Input/OutlinedInput";
import ButtonContained from "../../../components/Buttons/ButtonContained";
import ButtonOutlined from "../../../components/Buttons/ButtonOutlined";
import { useTheme } from "@mui/material/styles";
import { usePlacesWidget } from "react-google-autocomplete";
import { API_KEY } from "../../../config/axiosConfig";

const AddFurnitureStep4 = ({
  handleTextChange,
  setData,
  data,
  sellingOptions,
  setSellingOptions,
}) => {
  const theme = useTheme();
  const { ref } = usePlacesWidget({
    apiKey: API_KEY,
    options: {
      componentRestrictions: { country: "in" },
      types: ["(regions)"],
    },
    onPlaceSelected: (place) => {
      setData({
        ...data,
        locality: [
          place.geometry.location.lng(),
          place.geometry.location.lat(),
        ],
      });
    },
  });

  return (
    <Box
      sx={[
        { marginLeft: "12px", paddingLeft: "20px", paddigRight: "8px" },
        {
          [theme.breakpoints.between("xs", "md")]: [
            { marginLeft: 0, paddingLeft: 0, paddigRight: 0 },
          ],
        },
      ]}
    >
      <Typography
        sx={[
          styles.fs30,
          styles.fontFamilySGB,
          styles.fbBold,
          styles.color240501,
        ]}
      >
        Pricing & Others
      </Typography>
      <Box sx={[styles.mt40]}>
        <Box className="w-60">
          <Box>
            <Typography
              sx={[
                styles.fs16,
                styles.fontFamilySG,
                styles.color240501,
                {
                  [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                },
              ]}
              className="mb-2"
            >
              Locality
            </Typography>
            <OutlinedFormInput
              inputRef={ref}
              placeholder={"Kolkata"}
              // onChange={(e) => handleTextChange("locality", e.target.value)}
              sx={[
                { height: "45px" },
                {
                  [theme.breakpoints.between("xs", "sm")]: [
                    {
                      maxWidth: "300px",
                      minWidth: "300px",
                    },
                  ],
                },
              ]}
            />
          </Box>

          {sellingOptions === "rent" ? (
            <>
              <Box className="mt-3">
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  Expected Monthly Rent
                </Typography>
                <OutlinedFormInput
                  placeholder={"20000"}
                  onChange={(e) =>
                    handleTextChange("expectedMonthlyRent", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
              </Box>

              <Box className="mt-3">
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  Expected Security Deposit
                </Typography>
                <OutlinedFormInput
                  placeholder={"2000"}
                  onChange={(e) =>
                    handleTextChange("expectedSecurityDeposit", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
              </Box>

              {/* <Box className="mt-3">
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  First Time Payment
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("firstTimePayment", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
              </Box> */}
                                  <Box className="mt-3">
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  Three Month Payment
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("threeMonthPayment","threeMonthPay", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  Three Month monthlyRentalSavings
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("threeMonthPayment","monthlyRentalSavings", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  Three Month earlyClosureCharge
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("threeMonthPayment","earlyClosureCharge", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  Three Month freeAllocation
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("threeMonthPayment","freeAllocation", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  Three Month freeUpgrade
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("threeMonthPayment","freeUpgrade", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
              </Box>
              <Box className="mt-3">
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  Six Month Payment
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("sixMonthPayment","sixMonthPay", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />

<Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  sixMonthPayment monthlyRentalSavings
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("sixMonthPayment","monthlyRentalSavings", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  sixMonthPayment earlyClosureCharge
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("sixMonthPayment","earlyClosureCharge", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  sixMonthPayment freeAllocation
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("sixMonthPayment","freeAllocation", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  sixMonthPayment freeUpgrade
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("sixMonthPayment","freeUpgrade", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
              </Box>
              <Box className="mt-3">
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  Nine Month Payment
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("nineMonthPayment","nineMonthPay", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />

                
<Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  nineMonthPayment monthlyRentalSavings
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("nineMonthPayment","monthlyRentalSavings", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  nineMonthPayment earlyClosureCharge
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("nineMonthPayment","earlyClosureCharge", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  nineMonthPayment freeAllocation
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("nineMonthPayment","freeAllocation", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  nineMonthPayment freeUpgrade
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("nineMonthPayment","freeUpgrade", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
              </Box>
              <Box className="mt-3">
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  Twelve Month Payment
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("twelveMonthPayment","twelveMonthPay", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />

                
<Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                twelveMonthPayment monthlyRentalSavings
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("twelveMonthPayment","monthlyRentalSavings", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  twelveMonthPayment earlyClosureCharge
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("twelveMonthPayment","earlyClosureCharge", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  twelveMonthPayment freeAllocation
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("twelveMonthPayment","freeAllocation", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
                <Typography
                  sx={[
                    styles.fs16,
                    styles.fontFamilySG,
                    styles.color240501,
                    {
                      [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                    },
                  ]}
                  className="mb-2"
                >
                  twelveMonthPayment freeUpgrade
                </Typography>
                <OutlinedFormInput
                  placeholder={"40000"}
                  onChange={(e) =>
                    handleTextChange("twelveMonthPayment","freeUpgrade", e.target.value)
                  }
                  sx={[
                    { height: "45px" },
                    {
                      [theme.breakpoints.between("xs", "sm")]: [
                        {
                          maxWidth: "300px",
                          minWidth: "300px",
                        },
                      ],
                    },
                  ]}
                />
              </Box>
            </>
          ) : (
            <Box className="mt-3">
              <Typography
                sx={[
                  styles.fs16,
                  styles.fontFamilySG,
                  styles.color240501,
                  {
                    [theme.breakpoints.between("xs", "sm")]: [styles.fs12],
                  },
                ]}
                className="mb-2"
              >
                Price
              </Typography>
              <OutlinedFormInput
                placeholder={"40000"}
                onChange={(e) =>
                  handleTextChange("firstTimePayment", e.target.value)
                }
                sx={[
                  { height: "45px" },
                  {
                    [theme.breakpoints.between("xs", "sm")]: [
                      {
                        maxWidth: "300px",
                        minWidth: "300px",
                      },
                    ],
                  },
                ]}
              />
            </Box>
          )}

          <Box className="mt-3">
            <Typography
              sx={[
                styles.fs16,
                styles.fontFamilySG,
                styles.color240501,
                {
                  [theme.breakpoints.between("xs", "md")]: [styles.fs12],
                },
              ]}
              className="mb-2"
            >
              Description of the Furniture
            </Typography>
            <OutlinedFormInput
              placeholder="Write under 300 words...."
              // sx={[{ height: "initial" }]}
              rows={8}
              multiline={true}
              inputProps={{
                maxLength: 300,
              }}
              onChange={(e) => handleTextChange("desc", e.target.value)}
              sx={[
                { height: "initial" },
                {
                  [theme.breakpoints.between("xs", "sm")]: [
                    {
                      maxWidth: "300px",
                      minWidth: "300px",
                    },
                  ],
                },
              ]}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddFurnitureStep4;
