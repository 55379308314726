import {
    SET_PROPERTIES,
} from "../action/types";

const initialState = {
    properties: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_PROPERTIES: {
            return {
                ...state,
                properties: action.payload
            }
        }
        default:
            return {
                ...state
            }
    }
}