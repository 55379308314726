import publicReq, { privateReq, imageReq } from "./axiosConfig";

export const authLoginService = (data) => publicReq.post("/login", data);
export const authAdminLoginService = (data) =>
  publicReq.post("/login-admin-by-email", data);
export const verifyOtpService = (data) => publicReq.post("/verify-login", data);
export const registerService = (data) => publicReq.post("/register", data);
export const updateRole = (data) => privateReq.post("/update-role", data);

export const updateProfileService = (data) =>
  imageReq.post("/profile/edit", data);

export const updateKyc = (data) => privateReq.post("/add-kyc", data);

export const getKyc = (data) => privateReq.get("/get-kyc");

export const uploadImage = (data) => imageReq.post("/upload", data);

export const addBrokerService = (data) => imageReq.post("/add-broker", data);
export const getUserProfile = () => privateReq.get("/get-profile");
export const getUserService = (_id) => privateReq.get("/get-user/" + _id);
export const getAllUsersService = (data) => privateReq.get("/get-users" + data);
export const updateUserStatusService = (data) =>
  privateReq.post("/update-user-status", data);
export const ChangePasswordService = (data) =>
  privateReq.post("/change-password", data);
export const createSessionService = (data) =>
  privateReq.post("/checkout-session", data);
export const createCartSessionService = (data) =>
  privateReq.post("/cart-checkout-session", data);

export const createBuySessionService = (data) =>
  privateReq.post("/buy-checkout-session", data);

export const buyPhonePe = (data) => privateReq.post("/buy-phonePe", data);
export const checkPaymentStatus = (data) =>
  privateReq.post("/payment-checkStatus", data);

export const updateTransactionService = (data) =>
  privateReq.patch("/transaction-status", data);

export const checkTabsStatus = (data) =>
  privateReq.post("/get-access-for-basic-features", data);

export const updateTabStatus = (data) =>
  privateReq.put("/update-access-for-basic-features", data);
