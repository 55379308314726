import React, { useState } from "react";
import HomeIcon from '@mui/icons-material/Home';
import GroupsIcon from '@mui/icons-material/Groups';
import KeyIcon from '@mui/icons-material/Key';
import { Home } from 'lucide-react';
import { Users } from 'lucide-react';
import { Key } from 'lucide-react';
import { Sofa } from 'lucide-react';

import "./Ribbon.css";

function Ribbon() {
    return (
        <>
          <div className="ribbon-container">
            <div className="house-rented text-color">
                <div style={{color: "#FFDB58"}}>
                   <Home size={40} />
                </div>
                <div>
                  <div className="set-size-num">500+</div>
                  <div className="set-size-text">House Rented</div>
                </div>
            </div>

            {/* <div className="furniture-ac-rented text-color">
                <div style={{color: "#FFDB58"}}>
                  <Sofa size={40} />
                </div>
                <div>
                  <div className="set-size-num">300+</div>
                  <div className="set-size-text">Furniture & AC Rented</div>
                </div>
            </div> */}

            <div className="happy-customer text-color">
                <div style={{color: "#FFDB58"}}>
                  <Users size={40} />
                </div>
                <div>
                  <div className="set-size-num">1200+</div>
                  <div className="set-size-text">Happy Customers</div>
                </div>
            </div>

            <div className="trusted-owner text-color">
                <div style={{color: "#FFDB58"}}>
                   <Key size={40} />
                </div>
                <div>
                  <div className="set-size-num">200+</div>
                  <div className="set-size-text">Trusted Owners</div>
                </div>
            </div>
          </div>
        </>
    );
}

export default Ribbon;