import axios from "axios";
export const assetUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/uploads/"
    : "https://gharwalah.com/uploads/";

export const API_KEY = "AIzaSyBkX4L3NKvRWF1R2c9ga5AQZR6Qj5nWHx4";

const baseURL =
  process.env.NODE_ENV === "development"
    ? "https://gharwala-5466fddd6458.herokuapp.com/api"
    : // ? 'http://localhost:5000/api'

      // : "https://gharwalah.com/api";
      "https://gharwala-5466fddd6458.herokuapp.com/api";


const publicReq = axios.create({
  baseURL,
});

const privateReq = axios.create({
  baseURL,
});

const imageReq = axios.create({
  baseURL,
});
privateReq.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
imageReq.defaults.headers.common["Content-Type"] = "multipart/form-data";
imageReq.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
export { privateReq, imageReq };
export default publicReq;
