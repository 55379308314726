import React from 'react';

const NotFound = ({item ,showText}) => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f4f4f4',
      color: '#333',
      textAlign: 'center',
      padding: '20px',
    },
    title: {
      fontSize: '3rem',
      color: '#9A291B',
      marginBottom: '20px',
    },
    description: {
      fontSize: '1.5rem',
      marginBottom: '40px',
      maxWidth: '600px',
    },
    homeLink: {
      display: 'inline-block',
      padding: '10px 20px',
      backgroundColor: '#9A291B',
      color: '#fff',
      textDecoration: 'none',
      borderRadius: '5px',
      transition: 'background-color 0.3s ease',
    },
    homeLinkHover: {
      backgroundColor: '#7c2115',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>{item} Not Found</h1>
{
  showText &&       <p style={styles.description}>
  WOW such empty , try adding some items to your {item}
      </p>
}
      <a
        href="/"
        style={styles.homeLink}
        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.homeLinkHover.backgroundColor}
        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = styles.homeLink.backgroundColor}
      >
        Go Back to Home
      </a>
    </div>
  );
};

export default NotFound;
