import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import BedSvg from "../../assets/images/bed.svg";
import BathSvg from "../../assets/images/bed.svg";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ButtonContained from "../Buttons/ButtonContained";
import ButtonOutlined from "../Buttons/ButtonOutlined";
import classes from "../../styles/styles.js";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { assetUrl } from "../../config/axiosConfig";
import { useNavigate, Link } from "react-router-dom";
import { getProperties } from "../../redux/action/property";
import { Watermark } from "@hirohe/react-watermark";
import Carousel from "react-multi-carousel";
import { useMediaQuery } from "react-responsive";
import { Button, IconButton } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ArrowRight } from "lucide-react";

const PropertySlider = () => {
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 3 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };
  const { properties } = useSelector((state) => state.property);
  const [showAllProperties, setShowAllProperties] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sliderSettings = {
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    centerMode: false,
    draggable: true,
    autoplay: false,
    speed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  function removeLocations(inputString) {
    if (inputString) {
      let modifiedString = inputString
        .replace(/West Bengal,?\s?|India,?\s?|\d{6}/g, "")
        .trim();

      modifiedString = modifiedString
        .replace(/,\s*,/g, ", ")
        .replace(/,\s*$/, "")
        .trim();

      return modifiedString;
    }
    return inputString;
  }

  const getPropertiesInitial = () => {
    try {
      const data = {
        priceRange: [1, 10000000],
      };

      let query = `?`;
      Object.keys(data).forEach((key, idx) => {
        if (Array.isArray(data[key])) {
          data[key].forEach((item, index) => {
            query += `${idx > 0 || index > 0 ? "&" : ""}${key}=${item}`;
          });
        } else {
          query += `${idx > 0 ? "&" : ""}${key}=${data[key]}`;
        }
      });

      dispatch(getProperties(query));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getPropertiesInitial();
  }, []);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleViewAllClick = () => {
    navigate("/properties");
    // You can also perform other actions here, like fetching properties from an API
  };

  return (
    <div
      style={{
        marginTop: isMobile ? "20px" : "100px",
      }}
    >
      <div className="controls content pt-2">
        <div className="slider-header-text-container">
          <div
            className="slider-header "
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>New Listed Properties</span>
            {!isMobile && (
              <div
                className="view-all-hover"
                onClick={handleViewAllClick}
                label="view all properties"
                style={{
                  marginLeft: "auto",
                  cursor: "pointer",
                }}
              >
                <span style={{ fontSize: "20px" }}>
                  view all properties <ArrowRight />
                </span>
              </div>
            )}
          </div>

          <div className="slider-sub-header">
            <span>Find all new listed properties here.</span>
          </div>
        </div>
      </div>
      {showAllProperties && properties?.length > 0 && (
        <div>
          <h2>All Properties</h2>
          <div className="property-list">
            {properties?.map((card, index) => (
              <div className="card-container mb-5 mx-3" key={index}>
                <div className="card">
                  <Watermark
                    gutter={80}
                    rotate={0}
                    text="Gharwalah.com"
                    textSize={30}
                    multiline={false}
                  >
                    <div
                      className="card-image"
                      onClick={() => navigate(`/properties/${card._id}`)}
                      style={{
                        backgroundImage: `url(${card.image})`,
                        backgroundSize: "100%",
                        backgroundRepeat: "no-repeat",
                        backgroundClip: "content-box",
                        backgroundPosition: "center",
                      }}
                    >
                      <div className="d-flex justify-content-end"></div>
                    </div>
                  </Watermark>
                  <div className="text-info">
                    <div style={{ display: "flex", gap: "5px" }}>
                      <div className="text-black property-icon-text">
                        {card.bedrooms}
                      </div>
                      <div className="text-black property-icon-text">BHK</div>
                      <div
                        style={{ textTransform: "capitalize" }}
                        className="text-black property-icon-text"
                      >
                        {card.propertyType} for {card?.wantto}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        paddingBlock: "4px",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: "20px",
                          color: "#9a291b",
                        }}
                      >
                        ₹{" "}
                        {Intl.NumberFormat("en-IN").format(
                          card?.expectedMonthlyRent
                        )}
                      </span>
                      <span
                        style={{ color: "#9a291b" }}
                        className="property-icon-text"
                      >
                        │
                      </span>
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: "20px",
                          color: "#9a291b",
                        }}
                      >
                        {card.buildupArea} Sq. Ft.
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="text-black property-icon-text">
                        {removeLocations(card.locality.coordinates[2])}
                      </div>
                    </div>
                  </div>
                  <div className="property-slider-buttons">
                    <ButtonContained
                      onClick={() => navigate(`/properties/${card._id}`)}
                      sx={[
                        classes.borderRadius12,
                        classes.fs12,
                        classes.margin4,
                        classes.p10,
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs9,
                            ...classes.margin4,
                            ...classes.borderRadius7,
                          },
                        },
                      ]}
                    >
                      Contact
                    </ButtonContained>
                    <ButtonOutlined
                      onClick={() => {
                        navigate(`/properties/${card._id}`);
                        window.location.reload();
                      }}
                      sx={[
                        classes.borderRadius12,
                        classes.fs12,
                        classes.margin4,
                        classes.p10,
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs8,
                            ...classes.margin4,
                            ...classes.borderRadius7,
                            ...classes.p6,
                            ...classes.px5,
                          },
                        },
                      ]}
                    >
                      View Detail
                    </ButtonOutlined>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {!showAllProperties && properties?.length > 0 && (
        <Carousel
          responsive={responsive}
          removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
          infinite={true}
          showDots={false}
          autoPlay={true}
          autoPlaySpeed={3500}
        >
          {properties?.slice(0, 15).map((card, index) => (
            <div className="card-container mb-5 mx-3" key={index}>
              <div className="card">
                <Watermark
                  gutter={80}
                  rotate={0}
                  text="Gharwalah.com"
                  textSize={30}
                  multiline={false}
                >
                  <div
                    className="card-image"
                    onClick={() => navigate(`/properties/${card._id}`)}
                    style={{
                      backgroundImage: `url(${card.image})`,
                      backgroundSize: "100%",
                      backgroundRepeat: "no-repeat",
                      backgroundClip: "content-box",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="d-flex justify-content-end"></div>
                  </div>
                </Watermark>
                <div className="text-info">
                  <div style={{ display: "flex", gap: "5px" }}>
                    <div className="text-black property-icon-text">
                      {card.bedrooms}
                    </div>
                    <div className="text-black property-icon-text">BHK</div>
                    <div
                      style={{ textTransform: "capitalize" }}
                      className="text-black property-icon-text"
                    >
                      {card.propertyType} for {card?.wantto}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      paddingBlock: "4px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bolder",
                        fontSize: "20px",
                        color: "#9a291b",
                      }}
                    >
                      ₹{" "}
                      {Intl.NumberFormat("en-IN").format(
                        card?.expectedMonthlyRent
                      )}
                    </span>
                    <span
                      style={{ color: "#9a291b" }}
                      className="property-icon-text"
                    >
                      │
                    </span>
                    <span
                      style={{
                        fontWeight: "bolder",
                        fontSize: "20px",
                        color: "#9a291b",
                      }}
                    >
                      {card.buildupArea} Sq. Ft.
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <div className="text-black property-icon-text">
                      {removeLocations(card.locality.coordinates[2])}
                    </div>
                  </div>
                </div>
                <div className="property-slider-buttons">
                  <ButtonContained
                    onClick={() => navigate(`/properties/${card._id}`)}
                    sx={[
                      classes.borderRadius12,
                      classes.fs12,
                      classes.margin4,
                      classes.p10,
                      {
                        [theme.breakpoints.between("xs", "md")]: {
                          ...classes.fs9,
                          ...classes.margin4,
                          ...classes.borderRadius7,
                        },
                      },
                    ]}
                  >
                    Contact
                  </ButtonContained>
                  <ButtonOutlined
                    onClick={() => {
                      navigate(`/properties/${card._id}`);
                      window.location.reload();
                    }}
                    sx={[
                      classes.borderRadius12,
                      classes.fs12,
                      classes.margin4,
                      classes.p10,
                      {
                        [theme.breakpoints.between("xs", "md")]: {
                          ...classes.fs8,
                          ...classes.margin4,
                          ...classes.borderRadius7,
                          ...classes.p6,
                          ...classes.px5,
                        },
                      },
                    ]}
                  >
                    View Detail
                  </ButtonOutlined>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default PropertySlider;
