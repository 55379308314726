import React from "react";
import Header from "../../components/Header";
import { useMediaQuery } from "react-responsive";
import StickyFooterMobile from "../../components/FooterMobile";
import StickyFooter from "../../components/Footer";
const SecurityDep = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{
        height: "100vh",
      }}
    >
      <Header />
      <div className="container" style={{ paddingTop: "125px" }}>
        <h2 className="h3-about-tems" style={{ marginBottom: "15px" }}>
          Security Deposit - Refundable Based on Terms and condition
        </h2>{" "}
        <br />
        <p className="para-about-terms">
          {" "}
          At Gharwalah.com, we understand the need for security and trust when
          renting furniture and home appliances. To ensure a secure and smooth
          rental process, we require a refundable security deposit. Here's how
          it works: Purpose of the Security Deposit: The security deposit is
          designed to protect both parties. It serves as a financial guarantee
          in case of damage or non-compliance with the rental agreement. It
          ensures that you, as a renter, are accountable for the items in your
          possession. Transparency: We maintain complete transparency regarding
          the deposit amount and the terms and conditions governing its refund.
          The deposit amount is communicated during the rental process,
          and the terms are outlined in the rental agreement. Refundable Nature:
          The security deposit is entirely refundable, provided the rented items
          are returned in the same condition as when they were delivered, and
          the terms and conditions of the rental agreement have been adhered to.
          Assessment of Items: Upon the return of the rented items, our team
          conducts a thorough assessment to check for any damage or
          discrepancies. If the items are in the same condition as when
          delivered and the terms are met, the deposit is promptly refunded.
          Protection for All Parties: The security deposit provides peace of
          mind to both renters and Gharwalah.com. It ensures that the items
          remain in good condition and encourages responsible handling of the
          rented products. Refund Process: We aim to make the deposit refund
          process as seamless as possible. Upon the successful assessment of the
          items, the deposit is returned to you through the same payment method
          used during the initial transaction. This transparent and refundable
          security deposit system is designed to safeguard the interests of both
          renters and Gharwalah.com, ensuring a reliable and secure rental
          experience. Transferring Your Rental Subscription At Gharwalah.com, we
          understand that your living arrangements can change, and we want to
          provide the flexibility you need. If you're living with roommates,
          flatmates, or individuals in the same building and need to pass on
          your subscription, our straightforward process ensures a smooth
          transition: Simple Documentation: We've streamlined the subscription
          transfer process. All it takes is straightforward documentation to
          make the transfer official. This means minimal hassle and paperwork
          for you and the new subscriber. Multiple Names on a Subscription: Our
          system allows for multiple names on a single subscription. This
          flexibility means you and your roommates can jointly manage the
          subscription with ease. Convenient Handover: The transfer process
          includes a straightforward handover of the rented items to the new
          subscriber. Our team will coordinate with all parties involved to
          ensure a seamless transition. Documentation Verification: To protect
          both parties and ensure transparency, we'll verify the documentation
          and details of the new subscriber. This step is essential for
          maintaining the security and integrity of the rental. Continued
          Convenience: For you, the transfer process is hassle-free, and for the
          new subscriber, it offers a convenient solution for furnishing their
          space without the need for an entirely new rental agreement.
          Flexibility: Our system is designed to adapt to your living situation.
          If you have new flatmates, simply update the subscription with their
          details to keep the rental in the same place. Transparency and
          Accountability: The documentation process ensures transparency and
          accountability for all parties involved, fostering trust and
          confidence in the transfer. This feature allows you to share the
          benefits of your subscription with those you live with, making it easy
          to maintain a comfortable and well-furnished living space. Our goal
          is to provide convenience and adaptability to accommodate your dynamic
          living arrangements.
        </p>
      </div>
      <div>{isMobile ? <StickyFooterMobile /> : <StickyFooter />}</div>
    </div>
  );
};

export default SecurityDep;
