import { useLocation, useNavigate } from 'react-router-dom';
import { CiCircleChevLeft } from 'react-icons/ci';
import toast from 'react-hot-toast';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { Button, Menu, MenuItem } from '@mui/material';
import { Fragment } from 'react';

const Bottombar = ({
  ownerData,
  tenantData,
  propertyDetails,
  furnishingDetails,
  setFurnishingDetails,
  setPropertyDetails,
  contractDetails,
  setContractDetails,
  setFurnitureId,
  furnitureId,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const verify = (data) => {
    for (let key in data) {
      if (data[key] === null || data[key] === undefined) {
        return false;
      }
    }

    return true;
  };

  function validateEmail(email) {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  }

  const prevFunction = () => {
    if (location.pathname.includes('basic')) {
      navigate('/agreement');
    } else if (location.pathname.includes('contract')) {
      navigate('/agreement/basic-details');
    } else if (location.pathname.includes('property')) {
      navigate('/agreement/contract-details');
    } else if (location.pathname.includes('furnishing')) {
      navigate('/agreement/property-details');
    } else if (location.pathname.includes('final')) {
      navigate('/agreement/furnishing-details');
    }
  };

  const nextFunction = () => {
    if (location.pathname.includes('basic')) {
      if ((propertyDetails.type !== 'furniture' && !verify(ownerData)) || !verify(propertyDetails)) {
        toast.error('All fields necessary.');
        return;
      }
      if (propertyDetails.type !== 'furniture' && !validateEmail(ownerData.email)) {
        toast.error('Owner email invalid');
        return;
      }
      for (let item in tenantData) {
        if (!verify(tenantData[item])) {
          toast.error('All fields necessary.');
          return;
        }
        if (!validateEmail(tenantData[item].email)) {
          toast.error('Tenant email invalid');
          return;
        }
      }
      navigate('/agreement/contract-details');
    } else if (location.pathname.includes('contract')) {
      if (!verify(contractDetails)) {
        toast.error('All fields necessary.');
        return;
      }
      if (propertyDetails?.type === 'furniture' && !furnitureId) {
        toast.error('Select furniture');
        return;
      }

      navigate('/agreement/property-details');
    } else if (location.pathname.includes('property')) {
      if (!verify(propertyDetails)) {
        toast.error('All fields necessary.');
        return;
      }
      if (propertyDetails?.type === 'furniture' && !furnitureId) {
        toast.error('Select furniture');
        return;
      }

      navigate('/agreement/furnishing-details');
    } else if (location.pathname.includes('furnishing')) {
      if (!verify(furnishingDetails)) {
        toast.error('All fields necessary.');
        return;
      }
      if (furnishingDetails?.type === 'furniture' && !furnitureId) {
        toast.error('Select furniture');
        return;
      }

      navigate('/agreement/final-agreement');
    }
  };

  const downloadFree = () => {};
  const downloadPaid = () => {};

  return (
    <div
      style={{
        display: location.pathname.includes('final') && 'none',
      }}
      className='bottombar'
    >
      <div className='bottombar-nav'>
        <button
          className='bottombar-nav-prev'
          onClick={prevFunction}
        >
          <CiCircleChevLeft
            style={{
              fontSize: '50px',
            }}
          />
        </button>
        {location.pathname.includes('final') ? (
          // <div style={{ display: 'flex', gap: '10px' }}>
          //   <button
          //     className='bottombar-nav-next'
          //     onClick={nextFunction}
          //   >
          //     Download Free
          //   </button>
          //   <button
          //     className='bottombar-nav-next'
          //     onClick={nextFunction}
          //   >
          //     Download Paid
          //   </button>
          // </div>
          <PopupState
            variant='popover'
            popupId='demo-popup-menu'
          >
            {(popupState) => (
              <Fragment>
                <Button
                  style={{
                    backgroundColor: '#9a291b',
                    borderRadius: '1000px',
                  }}
                  variant='contained'
                  {...bindTrigger(popupState)}
                >
                  Download
                </Button>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem onClick={popupState.close}>Free</MenuItem>
                  <MenuItem onClick={popupState.close}>Paid</MenuItem>
                </Menu>
              </Fragment>
            )}
          </PopupState>
        ) : (
          <button
            className='bottombar-nav-next'
            onClick={nextFunction}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default Bottombar;
