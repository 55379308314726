import React, { useEffect, useState } from "react";
import Header from "../Header";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IconButton } from "@mui/material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import ButtonContained from "../Buttons/ButtonContained";
import ButtonOutlined from "../Buttons/ButtonOutlined";
import BedSvg from "../../assets/images/bed.svg";
import BathSvg from "../../assets/images/bath.svg";
import FloorSvg from "../../assets/images/floor.svg";
import AreaSvg from "../../assets/images/area.svg";
import { Link, useNavigate } from "react-router-dom";
import classes from "../../styles/styles.js";
import { assetUrl } from "../../config/axiosConfig";
import { useTheme } from "@mui/material/styles";
import { getPropertyContactViewdervice } from "../../config/property";

const PropertyContactViewed = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [list, setList] = useState([]);

  useEffect(() => {
    onGetAllRatings();
  }, []);
  const onGetAllRatings = async () => {
    let res = await getPropertyContactViewdervice("");
    if (res.data.status) {
      setList(res.data.list);
    }
  };
  return (
    <>
      {/* <Header /> */}
      <div className="container-fluid">
        <div className="row">
          {list?.map((card, index) => (
            <div className="col-lg-4 col-md-4 col-xs-12">
              <div className="card-container" style={{ marginBottom: "10px" }}>
                <div key={index} className="card">
                  <div
                    className="card-image"
                    style={{
                      backgroundImage: `url(${
                        card.property.propertyImages[0]
                      })`,
                      backgroundSize: "100%",
                      backgroundRepeat: "no-repeat",
                      backgroundClip: "content-box",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="d-flex justify-content-end"></div>
                  </div>
                  <div className="text-info">
                    <div className="slider-card-header">
                      <h2
                        className="slider-title"
                        style={{ textTransform: "capitalize" }}
                      >
                        {card.property.title}
                      </h2>
                      <span className="slider-title price-text">
                        {card.property.expectedMonthlyRent}
                      </span>
                    </div>
                    <p className="property-desc">{card.description}</p>
                    <div className="propety-icons">
                      <div className="property-icon">
                        <img src={BedSvg} />
                        <span className="text-color979797 property-icon-text">
                          {card.property.bedrooms}
                        </span>
                      </div>
                      {card.property.bathrooms && (
                        <div className="property-icon">
                          <img src={BathSvg} />
                          <span className="property-icon-text text-color979797">
                            {card.bathrooms}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="property-slider-buttons">
                    {/* <button className="btn-connect">Connect </button> */}
                    <ButtonContained
                      onClick={() =>
                        navigate("/properties/" + card.property._id)
                      }
                      sx={[
                        classes.borderRadius12,
                        {
                          [theme.breakpoints.between("xs", "sm")]: {
                            ...classes.fs9,
                            ...classes.margin4,
                            ...classes.borderRadius7,
                          },
                        },
                      ]}
                    >
                      Contact
                    </ButtonContained>
                    <ButtonOutlined
                      onClick={() => {
                        navigate(`/properties/${card.property._id}`);
                      }}
                      sx={[
                        classes.borderRadius12,
                        {
                          [theme.breakpoints.between("xs", "sm")]: {
                            ...classes.fs7,
                            ...classes.margin4,
                            ...classes.borderRadius7,
                          },
                        },
                      ]}
                    >
                      View Detail
                    </ButtonOutlined>

                    {/* <button className="btn-connect btn-details">View Detail </button> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PropertyContactViewed;
