import {
  ADD_PROPERTY,
  SET_AD_DETAILS,
  SET_MY_PROPERTIES,
  SET_PROPERTIES,
  SET_SEARCH_PROPERTIES,
  SET_ALL_PROPERTIES,
} from "./types";

import {
  addPropertyService,
  getAllPropertieService,
} from "../../config/property";

export const addProperty = (data, cb) => async (dispatch) => {
  try {
    const res = await addPropertyService(data);
    dispatch({
      type: ADD_PROPERTY,
      payload: res.data,
    });

    cb(res.data);
  } catch (e) {
    cb(e);

    console.log(e);
  }
};

export const getProperties = (data) => async (dispatch) => {
  try {
    const res = await getAllPropertieService(data);
    dispatch({
      type: SET_PROPERTIES,
      payload: res.data,
    });
    console.log(res, "filter");
  } catch (e) {
    console.log(e);
  }
};
