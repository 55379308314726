// auth flow
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_ADMIN = "SET_CURRENT_ADMIN";
export const REMOVE_CURRENT_USER = "REMOVE_CURRENT_USER";
export const SET_PROFILE = "SET_PROFILE";
export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";
export const SET_TOKEN_EXPIRED = "SET_TOKEN_EXPIRED";
export const SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION";
export const SET_BROKERS = "SET_BROKERS";
export const SET_USERS = "SET_USERS";

// property types

export const ADD_PROPERTY = "ADD_PROPERTY";
export const SET_AD_DETAILS = "SET_AD_DETAILS";
export const SET_MY_PROPERTIES = "SET_MY_PROPERTIES";
export const SET_PROPERTIES = "SET_PROPERTIES";
export const SET_SEARCH_PROPERTIES = "SET_SEARCH_PROPERTIES";
export const SET_ALL_PROPERTIES = "SET_ALL_PROPERTIES";

// furniture types
export const ADD_FURNITURE = "ADD_FURNITURE";
export const SET_FURNITURES = "SET_FURNITURES";
export const SET_FURNITURE_DETAILS = "SET_FURNITURE_DETAILS";

//accessory Types
export const SET_ACCESSORIES = "SET_ACCESSORIES";

// other services
export const SET_SERVICES = "SET_SERVICES";
