import {
  SET_CURRENT_USER,
  REMOVE_CURRENT_USER,
  SET_PROFILE,
  SET_NEW_PASSWORD,
  SET_TOKEN_EXPIRED,
  SET_CURRENT_LOCATION,
  // SET_CONTACTS,
  SET_USERS,
  SET_BROKERS,
  SET_ACCESSORIES,
} from "../action/types";

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  newPassword: "",
  tokenExpired: false,
  contacts: [],
  allUsers: null,
  totalUsers: null,
  accessories: [],
  location: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_CURRENT_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case SET_USERS:
      let { users, total } = action.payload;
      return {
        ...state,
        allUsers: users,
        totalUsers: total ? total : 0,
      };
    case SET_BROKERS:
      return {
        ...state,
        location: action.payload,
      };
    case REMOVE_CURRENT_USER:
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
        tokenExpired: false,
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };

    case SET_NEW_PASSWORD:
      return {
        ...state,
        newPassword: action.payload,
      };
    case SET_ACCESSORIES:
      return {
        ...state,
        accessories: action.payload,
      };
    case SET_TOKEN_EXPIRED:
      return {
        ...state,
        tokenExpired: action.payload,
      };
    // case SET_CONTACTS:
    //     return {
    //         ...state,
    //         contacts: action.payload
    //     }
    default:
      return {
        ...state,
      };
  }
}
