import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
const customStyles = {
  Modal: {
    borderRadis: "25px",
    overlay: "hidden",
  },
  customModal: {
    border: "none",
  },
  overlay: {
    position: "fixed",
    inset: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 9999,
    left: 0,
  },
  content: {
    maxWidth: "50%",
    margin: "0 auto",
    borderRadius: "20px",
  },
};
const customStylesMobile = {
  ReactModal__Content: {
    border: "none",
  },
  customModal: {
    border: "none",
    marginTop: "100px",
  },
  overlay: {
    position: "fixed",
    inset: 0,
    backgroundColor: "#FFFFFF",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 9999,
    left: 0,
  },
  content: {
    maxWidth: "100%",
    border: "none",
    margin: "0 auto",
    marginTop: "75px",
  },
};
export default function GHModal({
  children,
  state,
  stateFunc,
  title,
  handleClose,
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Modal
      isOpen={state}
      style={!isMobile ? customStyles : customStylesMobile}
      //   contentLabel="Example Modal"
      contentClassName="custom-modal"
    >
      <h2
        style={{
          color: "#240501",
          fontWeight: "bold",
          fontSize: "30px",
          textAlign: "center",
        }}
      >
        {title}
      </h2>
      <a
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          display: "inline-block",
          fontSize: 30,
          color: "#000",
        }}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          stateFunc(false);
        }}
      >
        <CloseRoundedIcon fontSize="25px" />
        {/* <i class="fa fa-times" aria-hidden="true"></i> */}
      </a>
      <div>{children}</div>
    </Modal>
  );
}
