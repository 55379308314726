import React, { useEffect, useState } from "react";
import { Avatar, IconButton, Typography } from "@mui/material";
import ButtonContained from "../Buttons/ButtonContained";
import ButtonOutlined from "../Buttons/ButtonOutlined";
import { Link, useNavigate } from "react-router-dom";
import { assetUrl } from "../../config/axiosConfig";
import { useTheme } from "@mui/material/styles";
import {
  getMyCartService,
  removeFromCartService,
} from "../../config/furniture";
import { useMediaQuery } from "react-responsive";
import styles from "../../styles/styles.js";
import { useSelector } from "react-redux";
// import moment from "moment";
import { buyPhonePe, createCartSessionService } from "../../config/user";
import toast from "react-hot-toast";

const CartModal = ({ setTotalItemsInCart }) => {
  const { user } = useSelector((state) => state.auth);
  const theme = useTheme();
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  
  console.log('usessssr' ,user)
   
  useEffect(() => {
    onGetAllRatings();
  }, []);
  const onGetAllRatings = async () => {
    let res = await getMyCartService();
    if (res.data.status) {
      setList(res.data.bookings);
      setTotalItemsInCart(res.data.bookings.length);
    }
  };
  const getPrice = () => {
    return list
      .map(
        (i) =>
          (i.furniture?.expectedMonthlyRent * +i.periodNeeded || 0) +
          (i.furniture?.firstTimePayment || 0)
      )
      .reduce((a, b) => a + b);
  };
  const createSession = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);

      const res = await buyPhonePe({
        amount: getPrice(),
        MUID: "MUID" + Date.now(),
        furnitureId: list.map((i) => {
          return {
            _id: i.furniture._id,
            period: i.periodNeeded,
          };
        }),
        transactionId: "T" + Date.now(),
      });

      if (res.data.status) {
        window.location.href = res.data.url;
        setLoader(false);
      }
    } catch (e) {
      console.log(e);
      toast.error("Sorry, something went wrong!");
      setLoader(false);
    }
  };

  const onRemoveFromCart = async (id) => {
    try {
      const res = await removeFromCartService(`?furnitureId=${id}`);
      if (res.data.status) {
        onGetAllRatings();
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      {/* <Header /> */}
      <div className="container-fluid d-flex flex-column justify-content-between h-100">
        <div className="">
          {list?.map((card, index) => (
            <div
              key={index}
              className="card card-container mt-2"
              style={{
                minWidth: "97%",
                maxWidth: "97%",
              }}
            >
              <div className="py-2 row furniture-order-content">
                <div className={!isMobile ? "col-4" : "col-sm-12"}>
                  <img
                    className="card-image"
                    src={card.image}
                    style={{
                      aspectRatio: !isMobile ? 2.5 : 0.5,
                      width: !isMobile ? "50%" : "100%",
                      margin: "auto",
                    }}
                  />
                </div>
                <div
                  className={
                    !isMobile
                      ? "col-8 pe-0 ps-0 d-flex flex-column justify-content-between"
                      : "col-sm-12 pe-0 ps-3 d-flex flex-column justify-content-between"
                  }
                >
                  <Typography
                    sx={[
                      { textTransform: "capitalize" },
                      styles.fs22,
                      styles.fontFamilyDS,
                      styles.fbBold,
                      styles.color9A291B,
                    ]}
                  >
                    {card.furniture.furnitureName}
                  </Typography>
                  <div className="row mt-2">
                    <div className={isMobile ? "col-12" : "col-3"}>
                      <Typography
                        sx={[
                          styles.fs9,
                          styles.fontFamilyDS,
                          styles.colorADB0B6,
                        ]}
                      >
                        Order By
                      </Typography>
                      <div className="row mt-2 ">
                        <div className={isMobile ? "col-2" : "col-4"}>
                          <Avatar
                            src={assetUrl + card?.user?.avatarImage}
                            alt={card?.user?.name}
                            sx={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "25px",
                              marginRight: "5px",
                            }}
                          />
                        </div>
                        <div className={!isMobile ? "col-8" : "col-9"}>
                          <Typography
                            sx={[
                              { textTransform: "capitalize" },
                              styles.fs17,
                              styles.color240501,
                              styles.fontFamilyDS,
                              styles.fontWeight500,
                            ]}
                          >
                            {card?.user?.name}
                          </Typography>
                          <Typography
                            sx={[
                              styles.fs15,
                              styles.color979797,
                              styles.fontFamilyDS,
                              styles.fontWeight500,
                            ]}
                          >
                            {card?.user?.phone}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        !isMobile
                          ? "col-6 order-duration"
                          : "col-sm-12 order-duration"
                      }
                    >
                      <Typography
                        sx={[
                          styles.fs15,
                          styles.fontFamilyDS,
                          styles.colorADB0B6,
                        ]}
                      >
                        Duration
                      </Typography>

                      <Typography
                        className="mt-2"
                        sx={[
                          styles.fs14,
                          styles.color240501,
                          styles.fontFamilyDS,
                          styles.fontWeight500,
                          {
                            [theme.breakpoints.between("xs", "md")]: {
                              paddingBottom: "8px",
                              marginLeft: "5px",
                            },
                          },
                        ]}
                      >
                        {card?.periodNeeded}
                      </Typography>
                    </div>

                    <div
                      className={"row " + (!isMobile ? "col-6" : "col-sm-12")}
                    >
                      <Typography
                        sx={[
                          styles.fs9,
                          styles.fontFamilyDS,
                          styles.colorADB0B6,
                          {
                            [theme.breakpoints.between("xs", "md")]: {
                              ...styles.fs15,
                            },
                          },
                        ]}
                      >
                        Location
                      </Typography>

                      <Typography
                        className="mt-2"
                        sx={[
                          styles.fs14,
                          styles.color240501,
                          styles.fontFamilyDS,
                          styles.fontWeight500,
                          {
                            [theme.breakpoints.between("xs", "md")]: {
                              ...styles.fs17,
                            },
                          },
                        ]}
                      >
                        {card?.user?.address}
                      </Typography>
                    </div>
                  </div>

                  <div
                    className={`property-slider-buttons ${
                      isMobile && "flex-column"
                    }`}
                    style={{
                      display: "flex",
                    }}
                  >
                    <ButtonContained
                      onClick={() => onRemoveFromCart(card.furniture._id)}
                      sx={[
                        styles.fontFamilySGB,
                        styles.p10,
                        styles.borderRadius12,
                        { width: isMobile ? "100%" : "50%" },
                      ]}
                      className={`m-0 my-2 ${!isMobile && "ms-4 me-1"} ${
                        isMobile && "me-3"
                      }`}
                    >
                      Remove from Cart
                    </ButtonContained>

                    


                    <ButtonOutlined
                      onClick={() =>
                        navigate(`/furniture/${card.furniture._id}`)
                      }
                      sx={[
                        styles.fontFamilySGB,
                        styles.p10,
                        styles.borderRadius12,
                        { width: isMobile ? "100%" : "50%" },
                      ]}
                      className="m-0 me-4 ms-1 my-2"
                    >
                      View Details
                    </ButtonOutlined>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          ))}

          {list.length === 0 && (
            <Typography
              className="text-center mt-5"
              sx={[
                { textTransform: "capitalize" },
                styles.fs22,
                styles.fontFamilyDS,
                styles.fbBold,
                styles.color9A291B,
              ]}
            >
              Your cart is empty!
            </Typography>
          )}
        </div>
        {list.length > 0 && (
          <ButtonOutlined
            disabled={loader}
            onClick={createSession}
            sx={[
              {
                alignSelf: "center",
                marginBottom: "5%",
                width: isMobile ? "80%" : "50%",
                marginTop: "3%",
              },
            ]}
          >
            {loader ? "Please wait.." : "Place order"}
          </ButtonOutlined>
        )}
      </div>
    </>
  );
};

export default CartModal;
