import React, { useState } from 'react';
import { Button } from '@mui/material';
import classes from '../../styles/styles.js';
import ButtonContained from '../Buttons/ButtonContained';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import RatingModal from '../ModalRating';
import CartModal from '../Cart';
import SignUp from '../Signup';
import ModalSignUp from '../ModalSignup';
import group from '../../assets/images/group.svg';
import './style.css';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import Profession from '../Profession/Profession.js';

const HomeBottomSection = () => {
  const theme = useTheme();
  const [closeModal, setCloseModal] = useState(false);
  const [firstPropty, setFirstPropty] = useState(false);
  const [isPropertyFree, setIsPropertyFree] = useState(false);
  const { user } = useSelector((state) => state.auth);

  function freeListing(){


    setIsPropertyFree(true);
		if (user === null) {
			setCloseModal(true);
		} else if (user?.role === "guest") {
			// toast("Please select List my Property on Navbar");
      setFirstPropty(true);
		} else {
			window.location.href = "/add-property";
		}

    
    // setIsPropertyFree(true);
    //         setCloseModal(true);
  }

  return (
    <div className='cont'>
      <div className='imageCont'>
        <img
          className='houseImage'
          src={group}
          alt=''
        />
      </div>
      <div className='contentCont'>
        <div className='title-10'>LIST your property to rent or sell, as owner or broker, for FREE</div>
        <div className='description'>
          {/* Unlock a World of Opportunities - Click, Register, list and Elevate Your Property Ventures with Gharwala as Owner or Broker! */}
        </div>
        <button
          className='getListedButton'
          onClick={() => {
            freeListing();
          }}
        >
          List for Free
        </button>
      </div>
      <ModalSignUp
        state={closeModal}
        stateFunc={setCloseModal}
        title='Please fill all the Details'
      >
        <SignUp
          isPropertyFree={isPropertyFree}
          setCloseModal={setCloseModal}
        />
      </ModalSignUp>


      <ModalSignUp
				state={firstPropty}
				stateFunc={() => setFirstPropty(false)}
				title="Choose profession"
			>
				<Profession
					user={user}
					isPropertyFree={firstPropty}
					setCloseModal={() => setFirstPropty(false)}
				/>
			</ModalSignUp>
    </div>
  );
};

export default HomeBottomSection;
