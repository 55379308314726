import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Checkbox,
  Grid,
  TextField,
} from "@mui/material";
// import * as React from 'react';
// import Box from '@mui/material/Box';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import React, { useState } from "react";
import styles from "../../../styles/styles";
import OutlinedFormInput from "../../../components/Input/OutlinedInput";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import classes from "../../../styles/styles.js";
import { useTheme } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const AddPropertyStep2 = ({ handleTextChange, handleCheckBoxChange, data }) => {
  const { accessories } = useSelector((state) => state.auth);
  const [furnitureDetails, setFurnitureDetails] = useState([
    { key: "", value: "" },
  ]);


  const handleFurnitureChange = (index, event) => {
    const values = [...furnitureDetails];
    values[index][event.target.name] = event.target.value;
    setFurnitureDetails(values);
  };
  const addFurnitureDetail = () => {
    setFurnitureDetails([...furnitureDetails, { key: "", value: "" }]);
  };

  const saveFurnitureDetails = () => {
    handleTextChange("furnitureDetails", furnitureDetails);
    toast.success("Saved Successfully");
    console.log(data);
  };
  const [showCustomRoom, setCustomRoomShow] = useState(false);
  const [furnishDetails, setFurnishDetails] = useState(false);
  const [parking, setParking] = useState(false);

  const theme = useTheme();

  return (
    <div
      sx={[
        {
          width: "100%",
          marginLeft: "12px",
          paddingLeft: "20px",
          paddigRight: "8px",
        },
        {
          [theme.breakpoints.between("xs", "md")]: [
            { marginLeft: 0, paddingLeft: 0, paddigRight: 0 },
          ],
        },
      ]}
    >
      <Typography
        sx={[
          styles.fs30,
          styles.fontFamilySGB,
          styles.fbBold,
          styles.color240501,
        ]}
      >
        Property Details
      </Typography>

      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <Typography
            sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
            className="mb-2 mt-4"
          >
            Property Details
          </Typography>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={data.propertyDetails}
            onChange={(e) =>
              handleTextChange("propertyDetails", e.target.value)
            }
          >
            <MenuItem value={10}>Commercial</MenuItem>
            <MenuItem value={20}>Residential</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box sx={[styles.mt40]}>
        <Box className="w-100">
          <Box>
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2"
            >
              Society Name/Building Name
            </Typography>
            <OutlinedFormInput
              placeholder={"4BHK Flat for Sale"}
              value={data.title}
              onChange={(e) => handleTextChange("title", e.target.value)}
            />
          </Box>
          <Box className="mt-3">
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2"
            >
              Property Type
            </Typography>
            <RadioGroup defaultValue="female" name="radio-buttons-group">
              <FormControlLabel
                value="flat"
                label={
                  <Typography
                    variant="body2"
                    sx={[styles.fontFamilyDS, styles.fs16, styles.color240501]}
                  >
                    Flat
                  </Typography>
                }
                control={
                  <Radio
                    checked={data.propertyType === "flat"}
                    sx={{
                      "&.Mui-checked": styles.color9A291B,
                    }}
                    onChange={(e) =>
                      handleTextChange("propertyType", e.target.value)
                    }
                  />
                }
              />
              <FormControlLabel
                value="house"
                label={
                  <Typography
                    variant="body2"
                    sx={[styles.fontFamilyDS, styles.fs16, styles.color240501]}
                  >
                    House
                  </Typography>
                }
                control={
                  <Radio
                    checked={data.propertyType === "house"}
                    sx={{
                      "&.Mui-checked": styles.color9A291B,
                    }}
                    onChange={(e) =>
                      handleTextChange("propertyType", e.target.value)
                    }
                  />
                }
              />
              <FormControlLabel
                value="villa"
                label={
                  <Typography
                    variant="body2"
                    sx={[styles.fontFamilyDS, styles.fs16, styles.color240501]}
                  >
                    Villa
                  </Typography>
                }
                control={
                  <Radio
                    checked={data.propertyType === "villa"}
                    sx={{
                      "&.Mui-checked": styles.color9A291B,
                    }}
                    onChange={(e) =>
                      handleTextChange("propertyType", e.target.value)
                    }
                  />
                }
              />
              <FormControlLabel
                value="commercial space"
                label={
                  <Typography
                    variant="body2"
                    sx={[styles.fontFamilyDS, styles.fs16, styles.color240501]}
                  >
                    Commercial Space
                  </Typography>
                }
                control={
                  <Radio
                    checked={data.propertyType === "commercial space"}
                    sx={{
                      "&.Mui-checked": styles.color9A291B,
                    }}
                    onChange={(e) =>
                      handleTextChange("propertyType", e.target.value)
                    }
                  />
                }
              />
              <FormControlLabel
                value="land"
                label={
                  <Typography
                    variant="body2"
                    sx={[styles.fontFamilyDS, styles.fs16, styles.color240501]}
                  >
                    Land
                  </Typography>
                }
                control={
                  <Radio
                    checked={data.propertyType === "land"}
                    sx={{
                      "&.Mui-checked": styles.color9A291B,
                    }}
                    onChange={(e) =>
                      handleTextChange("propertyType", e.target.value)
                    }
                  />
                }
              />
              {data.wantto === "rent" && (
                <>
                  <FormControlLabel
                    value="pg"
                    label={
                      <Typography
                        variant="body2"
                        sx={[
                          styles.fontFamilyDS,
                          styles.fs16,
                          styles.color240501,
                        ]}
                      >
                        PG
                      </Typography>
                    }
                    control={
                      <Radio
                        checked={data.propertyType === "pg"}
                        sx={{
                          "&.Mui-checked": styles.color9A291B,
                        }}
                        onChange={(e) =>
                          handleTextChange("propertyType", e.target.value)
                        }
                      />
                    }
                  />

                  <FormControlLabel
                    value="roommate"
                    label={
                      <Typography
                        variant="body2"
                        sx={[
                          styles.fontFamilyDS,
                          styles.fs16,
                          styles.color240501,
                        ]}
                      >
                        Room-mate
                      </Typography>
                    }
                    control={
                      <Radio
                        checked={data.propertyType === "roommate"}
                        sx={{
                          "&.Mui-checked": styles.color9A291B,
                        }}
                        onChange={(e) =>
                          handleTextChange("propertyType", e.target.value)
                        }
                      />
                    }
                  />
                </>
              )}
            </RadioGroup>
          </Box>
          <Box className="mt-3">
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2"
            >
              Room Details
            </Typography>
            <RadioGroup defaultValue="female" name="radio-buttons-group">
              <FormControlLabel
                value="1"
                label={
                  <Typography
                    variant="body2"
                    sx={[styles.fontFamilyDS, styles.fs16, styles.color240501]}
                  >
                    1 BHK
                  </Typography>
                }
                control={
                  <Radio
                    disabled={data.propertyType === "land"}
                    checked={data.bedrooms == "1"}
                    sx={{
                      "&.Mui-checked": styles.color9A291B,
                    }}
                    onChange={(e) =>
                      handleTextChange("bedrooms", e.target.value)
                    }
                  />
                }
              />
              <FormControlLabel
                value="2"
                label={
                  <Typography
                    variant="body2"
                    sx={[styles.fontFamilyDS, styles.fs16, styles.color240501]}
                  >
                    2 BHK
                  </Typography>
                }
                control={
                  <Radio
                    disabled={data.propertyType === "land"}
                    checked={data.bedrooms == "2"}
                    sx={{
                      "&.Mui-checked": styles.color9A291B,
                    }}
                    onChange={(e) =>
                      handleTextChange("bedrooms", e.target.value)
                    }
                  />
                }
              />
              <FormControlLabel
                value="3"
                label={
                  <Typography
                    variant="body2"
                    sx={[styles.fontFamilyDS, styles.fs16, styles.color240501]}
                  >
                    3 BHK
                  </Typography>
                }
                control={
                  <Radio
                    disabled={data.propertyType === "land"}
                    checked={data.bedrooms == "3"}
                    sx={{
                      "&.Mui-checked": styles.color9A291B,
                    }}
                    onChange={(e) =>
                      handleTextChange("bedrooms", e.target.value)
                    }
                  />
                }
              />
              <FormControlLabel
                value="4"
                label={
                  <Typography
                    variant="body2"
                    sx={[styles.fontFamilyDS, styles.fs16, styles.color240501]}
                  >
                    4 BHK
                  </Typography>
                }
                control={
                  <Radio
                    disabled={data.propertyType === "land"}
                    checked={data.bedrooms == "4"}
                    sx={{
                      "&.Mui-checked": styles.color9A291B,
                    }}
                    onChange={(e) =>
                      handleTextChange("bedrooms", e.target.value)
                    }
                  />
                }
              />
              <FormControlLabel
                value="5"
                label={
                  <Typography
                    variant="body2"
                    sx={[styles.fontFamilyDS, styles.fs16, styles.color240501]}
                  >
                    5 BHK
                  </Typography>
                }
                control={
                  <Radio
                    disabled={data.propertyType === "land"}
                    checked={data.bedrooms == "5"}
                    sx={{
                      "&.Mui-checked": styles.color9A291B,
                    }}
                    onChange={(e) =>
                      handleTextChange("bedrooms", e.target.value)
                    }
                  />
                }
              />
            </RadioGroup>
            <Box>
              <Button
                className="text-capitalize mb-2"
                disabled={data.propertyType === "land"}
                sx={[styles.fontFamilyDS, styles.fs16, styles.color9A291B]}
                startIcon={<AddIcon />}
                variant="text"
                onClick={() => {
                  setCustomRoomShow(!showCustomRoom);
                  handleTextChange("bedrooms", "");
                }}
              >
                Custom
              </Button>
              {showCustomRoom && (
                <Box className="d-flex">
                  <Box className="px-2">
                    <Typography
                      sx={[
                        styles.fs14,
                        styles.fontFamilyDS,
                        styles.color240501,
                      ]}
                      className="mb-2"
                    >
                      No. of Bedroom
                    </Typography>
                    <OutlinedFormInput
                      placeholder={"2"}
                      onChange={(e) =>
                        handleTextChange("bedrooms", e.target.value)
                      }
                    />
                  </Box>
                  <Box className="px-2">
                    <Typography
                      sx={[
                        styles.fs14,
                        styles.fontFamilyDS,
                        styles.color240501,
                      ]}
                      className="mb-2"
                    >
                      No. of Bathroom
                    </Typography>
                    <OutlinedFormInput
                      placeholder={"2"}
                      onChange={(e) =>
                        handleTextChange("bathrooms", e.target.value)
                      }
                    />
                  </Box>
                  <Box className="px-2">
                    <Typography
                      sx={[
                        styles.fs14,
                        styles.fontFamilyDS,
                        styles.color240501,
                      ]}
                      className="mb-2"
                    >
                      No. of Kitchen
                    </Typography>
                    <OutlinedFormInput
                      placeholder={"2"}
                      onChange={(e) =>
                        handleTextChange("kitchens", e.target.value)
                      }
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "12", marginTop: "20px" }}>
            <Typography gutterBottom>
              Furniture Details
            </Typography>
            {furnitureDetails.map((detail, index) => (
              <Grid key={index} container spacing={2} alignItems="center">
                <Grid item>
                  <TextField
                    className="mt-10"
                    name="key"
                    placeholder="Property Name"
                    value={detail.key}
                    onChange={(e) => handleFurnitureChange(index, e)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    className="mt-10"
                    name="value"
                    placeholder="Property Value"
                    value={detail.value}
                    onChange={(e) => handleFurnitureChange(index, e)}
                  />
                </Grid>
              </Grid>
            ))}
            <Box
              style={{
                display: "flex",
                width: "40%",
                justifyContent: "space-between",
              }}
            >
              <Button variant="contained" className="mt-10" onClick={addFurnitureDetail}>
                Add
              </Button>
              <Button variant="contained" color="success" className="mt-10" onClick={saveFurnitureDetails}>
                Save
              </Button>
            </Box>
          </Box> */}

          {data.wantto === "rent" && (
            <Box className="mt-3">
              <Typography
                sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
                className="mb-2"
              >
                USP
              </Typography>
              <Box
                sx={[
                  classes.jcSBetween,
                  classes.dflex,
                  {
                    [theme.breakpoints.between("xs", "sm")]: [
                      classes.dBlock,
                      classes.jcSBetween,
                    ],
                  },
                ]}
              >
                <FormControlLabel
                  value="couple friendly"
                  label={
                    <Typography
                      variant="body2"
                      sx={[
                        styles.fontFamilyDS,
                        styles.fs16,
                        styles.color240501,
                      ]}
                    >
                      Couple Friendly
                    </Typography>
                  }
                  control={
                    <Checkbox
                      disabled={data.propertyType === "land"}
                      sx={{
                        "&.Mui-checked": styles.color9A291B,
                      }}
                      checked={data.usp.includes("couple friendly")}
                      onChange={(e) =>
                        handleCheckBoxChange("usp", e.target.value)
                      }
                    />
                  }
                />
                <FormControlLabel
                  value="pet friendly"
                  label={
                    <Typography
                      variant="body2"
                      sx={[
                        styles.fontFamilyDS,
                        styles.fs16,
                        styles.color240501,
                      ]}
                    >
                      Pet Friendly
                    </Typography>
                  }
                  control={
                    <Checkbox
                      disabled={data.propertyType === "land"}
                      checked={data.usp.includes("pet friendly")}
                      sx={{
                        "&.Mui-checked": styles.color9A291B,
                      }}
                      onChange={(e) =>
                        handleCheckBoxChange("usp", e.target.value)
                      }
                    />
                  }
                />
                <FormControlLabel
                  value="restriction free"
                  label={
                    <Typography
                      variant="body2"
                      sx={[
                        styles.fontFamilyDS,
                        styles.fs16,
                        styles.color240501,
                      ]}
                    >
                      Restriction Free
                    </Typography>
                  }
                  control={
                    <Checkbox
                      disabled={data.propertyType === "land"}
                      checked={data.usp.includes("restriction free")}
                      sx={{
                        "&.Mui-checked": styles.color9A291B,
                      }}
                      onChange={(e) =>
                        handleCheckBoxChange("usp", e.target.value)
                      }
                    />
                  }
                />
              </Box>
            </Box>
          )}

          <Box className="mt-3">
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2"
            >
              Society Amenities
            </Typography>
            <Box className="d-flex flex-wrap">
              {accessories.map((item) => (
                <FormControlLabel
                  value={item._id}
                  label={
                    <Typography
                      variant="body2"
                      sx={[
                        styles.fontFamilyDS,
                        styles.fs16,
                        styles.color240501,
                      ]}
                    >
                      {item.accessoryName}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      disabled={data.propertyType === "land"}
                      checked={data.accessories.includes(item._id)}
                      sx={{
                        "&.Mui-checked": styles.color9A291B,
                      }}
                      onChange={(e) =>
                        handleCheckBoxChange("accessories", e.target.value)
                      }
                    />
                  }
                />
              ))}
            </Box>
          </Box>

          {/* <Box>
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2 mt-4"
            >
              Security Deposit
            </Typography>
            <OutlinedFormInput
              placeholder={"4000"}
              value={data.securityDeposit}
              onChange={(e) =>
                handleTextChange("securityDeposit", e.target.value)
              }
            />
          </Box> */}

          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <Typography
                sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
                className="mb-2 mt-4"
              >
                Property Condition
              </Typography>

              {data.wantto === "rent" ? (
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={data.propertyCondition}
                  onChange={(e) =>
                    handleTextChange("propertyCondition", e.target.value)
                  }
                >
                  <MenuItem value={10}>New</MenuItem>
                  <MenuItem value={20}>Old</MenuItem>
                </Select>
              ) : (
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={data.propertyCondition}
                  onChange={(e) =>
                    handleTextChange("propertyCondition", e.target.value)
                  }
                >
                  <MenuItem value={10}>New</MenuItem>
                  <MenuItem value={20}>Resale</MenuItem>
                  <MenuItem value={30}>Under Construction</MenuItem>
                </Select>
              )}
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <Typography
                sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
                className="mb-2 mt-4"
              >
                Furnishing Details
              </Typography>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.furnishingDetails}
                onChange={(e) =>
                  handleTextChange("furnishingDetails", e.target.value)
                }
              >
                <MenuItem
                  onClick={() => {
                    setFurnishDetails(true);
                  }}
                  value={1}
                >
                  Furnished
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setFurnishDetails(true);
                  }}
                  value={2}
                >
                  Semi Furnished
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setFurnishDetails(false);
                  }}
                  value={3}
                >
                  UnFurnished
                </MenuItem>
              </Select>
              {/* <OutlinedFormInput
              placeholder={"Details"}
              value={data.furnishingDetails}
              onChange={(e) =>
                handleTextChange("furnishingDetails", e.target.value)
              }
            /> */}
            </FormControl>
          </Box>

          <ToastContainer />
          {furnishDetails && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12",
                marginTop: "20px",
              }}
            >
              <Typography gutterBottom>Furnish Details</Typography>
              <Grid  container spacing={2} alignItems="center">
                  <Grid item>
                    <input
                    style={{
                      border:"none",
                      color:"black",
                      outline: "none"
                    }}
                      className="mt-10 border-none"
                      name="key"
                      placeholder="Equipment Name"
                      value={"Equipment Name"}
                      
                    />
                  </Grid>
                  <Grid item>
                    <input
                    style={{
                      border:"none",
                      color:"black",
                      outline: "none",
                      paddingLeft:"30px",
                    }}
                      className="mt-10 border-none"
                      name="value"
                      placeholder="Equipment Quantity"
                      value={"Equipment Quantity"}
                     
                    />
                  </Grid>
                </Grid>
              {furnitureDetails.map((detail, index) => (
                <Grid key={index} container spacing={2} alignItems="center">
                  <Grid item>
                    <TextField
                      className="mt-10"
                      name="key"
                      placeholder="Equipment Name"
                      value={detail.key}
                      onChange={(e) => handleFurnitureChange(index, e)}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      className="mt-10"
                      name="value"
                      placeholder="Equipment Quantity"
                      value={detail.value}
                      type="number"
                      onChange={(e) => handleFurnitureChange(index, e)}
                    />
                  </Grid>
                </Grid>
              ))}
              <Box
                style={{
                  display: "flex",
                  width: "40%",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="contained"
                  className="mt-10"
                  onClick={addFurnitureDetail}
                >
                  Add
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  className="mt-10"
                  onClick={saveFurnitureDetails}
                >
                  Save
                </Button>
              </Box>
            </Box>
          )}

          <Box>
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2 mt-4"
            >
              Build Up Area
            </Typography>
            <OutlinedFormInput
            type="number"
              placeholder={"3,000 sq.ft"}
              value={data.buildupArea}
              onChange={(e) => handleTextChange("buildupArea", e.target.value)}
            />
          </Box>

          <Box>
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2 mt-4"
            >
              Available From
            </Typography>
            <OutlinedFormInput
              type="date"
              placeholder={"Available"}
              value={data.availableFrom}
              onChange={(e) =>
                handleTextChange("availableFrom", e.target.value)
              }
            />
          </Box>

          {data.wantto === "rent" && (
            <>
              {/* <Box>
              <Typography
                sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
                className="mb-2 mt-4"
              >
                Tenant Type
              </Typography>
              <OutlinedFormInput
                placeholder={"Mr. Sachin Kumar"}
                value={data.tenant}
                onChange={(e) => handleTextChange("tenant", e.target.value)}
              />
            </Box> */}

              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <Typography
                    sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
                    className="mb-2 mt-4"
                  >
                    Tenant Type
                  </Typography>
                  <Select
                    placeholder="Select"
                    labelId="demo-simple-select-label22"
                    id="demo-simple-select22"
                    value={data.tenant}
                    onChange={(e) => handleTextChange("tenant", e.target.value)}
                  >
                    <MenuItem value={10}>Family</MenuItem>
                    <MenuItem value={20}>Bachelor</MenuItem>
                    <MenuItem value={30}>Family and bachelor both</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </>
          )}

          <Box>
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2 mt-4"
            >
              Floor Number
            </Typography>
            <OutlinedFormInput
              placeholder={"10"}
              value={data.floorNumber}
              onChange={(e) => handleTextChange("floorNumber", e.target.value)}
            />
          </Box>

          <Box>
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2 mt-4"
            >
              Property Age
            </Typography>
            <OutlinedFormInput
              placeholder={"10 years"}
              value={data.propertyAge}
              onChange={(e) => handleTextChange("propertyAge", e.target.value)}
            />
          </Box>

          <Box>
            <FormControl fullWidth>
              <Typography
                sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
                className="mb-2 mt-4"
              >
                Car Parking
              </Typography>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.parking}
                onChange={(e) =>
                  handleTextChange("parking", e.target.value)
                }
              >
                <MenuItem
                  onClick={() => {
                    setParking(true);
                  }}
                  value={1}
                >
                  Yes
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setParking(true);
                  }}
                  value={2}
                >
                  No
                </MenuItem>
              </Select>

              {/* <OutlinedFormInput
              placeholder={"Parking details"}
              value={data.parking}
              onChange={(e) => handleTextChange("parking", e.target.value)}
            /> */}
            </FormControl>
          </Box>

          <Box>
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2 mt-4"
            >
              Facing
            </Typography>
            <OutlinedFormInput
              placeholder={"East"}
              value={data.facing}
              onChange={(e) => handleTextChange("facing", e.target.value)}
            />
          </Box>

          <Box>
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2 mt-4"
            >
              Flooring
            </Typography>
            <OutlinedFormInput
              placeholder={"Wooden"}
              value={data.flooring}
              onChange={(e) => handleTextChange("flooring", e.target.value)}
            />
          </Box>

          <Box>
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2 mt-4"
            >
              Water
            </Typography>
            <OutlinedFormInput
              placeholder={"Available"}
              value={data.water}
              onChange={(e) => handleTextChange("water", e.target.value)}
            />
          </Box>

          <Box>
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2 mt-4"
            >
              Bathroom
            </Typography>
            <OutlinedFormInput
              placeholder={"2"}
              value={data.bathroom}
              onChange={(e) => handleTextChange("bathroom", e.target.value)}
            />
          </Box>

          <Box>
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2 mt-4"
            >
              Power Backup
            </Typography>
            <OutlinedFormInput
              placeholder={"2 hrs"}
              value={data.powerBackup}
              onChange={(e) => handleTextChange("powerBackup", e.target.value)}
            />
          </Box>

          <Box>
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2 mt-4"
            >
              Over Looking
            </Typography>
            <OutlinedFormInput
              placeholder={"overlook"}
              value={data.overlooking}
              onChange={(e) => handleTextChange("overlooking", e.target.value)}
            />
          </Box>
          <Box className="mt-3">
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2"
            >
              Facilities
            </Typography>
            <OutlinedFormInput
              disabled={data.propertyType === "land"}
              placeholder="Lift"
              sx={[
                { height: "initial" },
                {
                  [theme.breakpoints.between("xs", "sm")]: [
                    {
                      maxWidth: "300px",
                      minWidth: "300px",
                    },
                  ],
                },
              ]}
              rows={4}
              multiline={true}
              onChange={(e) => handleTextChange("facilities", e.target.value)}
            />
          </Box>

          {/* <Box className="mt-4 d-flex w-100 pb-5">
            <ButtonOutlined
              onClick={() => {
                activeStep !== 0 && setActiveStep(--activeStep);
                document.getElementById("step-container").scrollTo(0, 0);
              }}
            >
              {activeStep === 0 ? "Cancel" : "Previous"}
            </ButtonOutlined>
            <ButtonContained
              onClick={() => {
                setActiveStep(++activeStep);
                document.getElementById("step-container").scrollTo(0, 0);
              }}
            >
              Next
            </ButtonContained>
          </Box> */}
        </Box>
      </Box>
    </div>
  );
};

export default AddPropertyStep2;
