import { combineReducers } from "redux";
import authReducer from './auth';
import propertyReducer from './property';
import furnitureReducer from './furniture';
import otherServiceReducer from './othersevice'
const rootReducer = combineReducers({
    auth: authReducer,
    property: propertyReducer,
    furniture: furnitureReducer,
    otherservices: otherServiceReducer
})

export default rootReducer;
