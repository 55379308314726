import { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import { privateReq } from "../../config/axiosConfig";
import ButtonContained from "../../components/Buttons/ButtonContained";
import OutlinedFormInput from "../../components/Input/OutlinedInput";
import { useSelector } from "react-redux";
import PageNotFound from "../../components/PageNotFound";
import { Link } from "react-router-dom";

const EditServices = () => {
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [services, setServices] = useState([]);
  const [addService, setAddService] = useState({
    service_name: "",
    service_des: "",
    active: true,
  });
  const [au, setAu] = useState("");

  const [showPage, setShowPage] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user !== null) {
      if (user?.role === "admin") {
        setShowPage(true);
      }
    }
  }, []);

  useEffect(() => {
    // setHeaderHeight(headerRef.current.offsetHeight);
  }, [headerHeight]);

  useEffect(() => {
    getServices();
  }, []);

  const getServices = async () => {
    const res = await privateReq.get("/admin-get-services");
    setServices(res.data.services);
  };

  const onChangeAddService = (e) => {
    const { name, value } = e.target;
    setAddService((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddService = async () => {
    try {
      await privateReq.post("/admin-add-services", { ...addService });
      getServices();
    } catch (err) {
      console.log(err);
    }
  };

  const fillAddService = async (id) => {
    const service = services.find((s) => id === s._id);
    setAu(id);
    setAddService({
      service_des: service.service_des,
      active: service.active,
      service_name: service.service_name,
    });
  };

  const handleEditService = async () => {
    try {
      await privateReq.put(`/admin-update-services/${au}`, { ...addService });
      getServices();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteService = async (id) => {
    try {
      await privateReq.delete(`/admin-delete-services/${id}`);
      getServices();
    } catch (err) {
      console.log(err);
    }
  };

  return showPage ? (
    <>
      <Header innerRef={headerRef} />
      <div
        style={{
          marginTop: "100px",
          padding: "0 50px",
        }}
      >
        <div
          style={{
            fontSize: "30px",
            fontFamily: "Montserrat",
          }}
        >
          Edit Services
        </div>
        <div>
          <div>
            <div style={{ marginLeft: "7px" }}>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginBottom: "10px",
                }}
              >
                <OutlinedFormInput
                  style={{
                    width: "300px",
                  }}
                  type="text"
                  name="service_name"
                  value={addService.service_name}
                  onChange={onChangeAddService}
                  placeholder="Service Name"
                />
                <OutlinedFormInput
                  style={{
                    width: "300px",
                  }}
                  type="text"
                  name="service_des"
                  value={addService.service_des}
                  onChange={onChangeAddService}
                  placeholder="Service Description"
                />
                <select
                  name="active"
                  value={addService.active}
                  onChange={onChangeAddService}
                  style={{
                    backgroundColor: "white",
                    border: "1px solid lightgray",
                    padding: "5px 10px",
                    borderRadius: "12px",
                  }}
                >
                  <option value="" disabled hidden>
                    Active
                  </option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </div>
            </div>

            <ButtonContained
              style={{
                width: "150px",
              }}
              onClick={au === "" ? handleAddService : handleEditService}
            >
              {au === "" ? "Add" : "Edit"} Service
            </ButtonContained>
          </div>
        </div>
        <div
          style={{
            marginTop: "20px",
          }}
        >
          <div
            style={{
              fontSize: "30px",
              fontFamily: "Montserrat",
            }}
          >
            Service list:
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              marginBottom: "20px",
              maxWidth: "1000px",
            }}
          >
            <div style={{ width: "200px", fontWeight: "bold" }}>Name</div>
            <div style={{ width: "200px", fontWeight: "bold" }}>
              Description
            </div>
            <div style={{ width: "200px", fontWeight: "bold" }}>Is active?</div>
            <div style={{ width: "200px", fontWeight: "bold" }}>Actions</div>
          </div>
          {services?.map((service) => (
            <div
              style={{
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                borderBottom: "1px solid gray",
                maxWidth: "950px",
              }}
            >
              <div style={{ width: "200px" }}>{service.service_name}</div>
              <div style={{ width: "200px" }}>{service.service_des}</div>
              <div style={{ width: "200px" }}>
                {service.active ? "Yes" : "No"}
              </div>
              <ButtonContained
                style={{
                  width: "100px",
                }}
                onClick={() => fillAddService(service._id)}
              >
                Edit
              </ButtonContained>
              <ButtonContained
                style={{
                  width: "100px",
                }}
                onClick={() => deleteService(service._id)}
              >
                Delete
              </ButtonContained>
            </div>
          ))}
        </div>
      </div>
    </>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <PageNotFound />
      <Link to="/">
        <button>Go To Home</button>
      </Link>
    </div>
  );
};

export default EditServices;
