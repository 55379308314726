import { useScrollTrigger } from '@material-ui/core';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useOutletContext } from 'react-router-dom';

const FormFurnishingDetails = () => {
  const navigate = useNavigate();
  const [
    role,
    setRole,
    ownerData,
    setOwnerData,
    tenantData,
    setTenantData,
    propertyDetails,
    setPropertyDetails,
    contractDetails,
    setContractDetails,
    furnitureId,
    setFurnitureId,
    furnishedDetails,
    setFurnishedDetails,
  ] = useOutletContext();

  useEffect(() => {
    if (!verify(tenantData) && !verify(ownerData)) {
      navigate('/agreement/basic-details');
    }

    for (let item in tenantData) {
      if (!verify(tenantData[item])) {
        navigate('/agreement/basic-details');
      }
    }
    for (let item in ownerData) {
      if (!verify(ownerData[item])) {
        navigate('/agreement/basic-details');
      }
    }
  }, []);

  useEffect(() => {
    if (!verify(contractDetails)) {
      navigate('/agreement/contract-details');
    }

    for (let item in contractDetails) {
      if (!verify(contractDetails[item])) {
        navigate('/agreement/contract-details');
      }
    }
  }, []);

  const verify = (data) => {
    for (let key in data) {
      if (data[key] === null || data[key] === undefined || data[key] === '') {
        return false;
      }
    }

    return true;
  };

  // const [addMore, setAddMore] = useState(false);

  // function handleAddMore(event) {
  //   event.preventDefault();
  //   setAddMore(true);
  // }

  const handleIncrement = (field) => {
    setFurnishedDetails((prev) => ({ ...prev, [field]: prev[field] + 1 }));
  };

  const handleDecrement = (field) => {
    setFurnishedDetails((prev) => ({ ...prev, [field]: Math.max(0, prev[field] - 1) }));
  };

  return (
    <>
      <div className='header'>Furnishing Details</div>
      <div className='form-button-container'>
        <button
          className={`form-button ${furnishedDetails.type === 'unfurnished' && 'form-button_active'}`}
          onClick={() => setFurnishedDetails((prev) => ({ ...prev, type: 'unfurnished' }))}
        >
          Unfurnished
        </button>

        <button
          className={`form-button ${furnishedDetails.type === 'semifurnished' && 'form-button_active'}`}
          onClick={() => setFurnishedDetails((prev) => ({ ...prev, type: 'semifurnished' }))}
        >
          Semifurnished
        </button>

        <button
          className={`form-button ${furnishedDetails.type === 'furnished' && 'form-button_active'}`}
          onClick={() => setFurnishedDetails((prev) => ({ ...prev, type: 'furnished' }))}
        >
          Furnished
        </button>
      </div>

      {furnishedDetails.type !== 'unfurnished' && furnishedDetails.type !== '' && (
        <div>
          <div style={{ fontWeight: 'bolder', marginBottom: '10px' }}>Adding Furnishing items is optional</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            {Object.keys(furnishedDetails).map(
              (key) =>
                key !== 'type' && (
                  <>
                  <div
                    key={key}
                    style={{ display: 'flex', gap: '60px'}}
                  >
                    <div style={{ width: '120px', display: 'flex', alignItems: 'center' }}>{key.charAt(0).toUpperCase() + key.slice(1)}</div>
                    <div style={{ display: 'flex', gap: "10px",}}>
                      <button
                        onClick={() => handleDecrement(key)}
                        disabled={furnishedDetails[key] === 0}
                      >
                        -
                      </button>
                      <span style={{ display: 'flex', alignItems: 'center' }}>{furnishedDetails[key]}</span>
                      <button style={{marginLeft: "8px"}} onClick={() => handleIncrement(key)}>+</button>
                    </div>
                  </div>

                  {/* <div onChange={handleAddMore} style={{ fontWeight: 'bolder', marginTop: '20px', color: "#dc3545", cursor: "pointer" }}> + Add more</div>
                  {addMore && (
                   <>
                    <div style={{display: "flex", gap: "20px"}}>
                     <input placeholder='Add Items' />
                     <div style={{display: "flex", gap: "10px"}}>
                       <button>-</button>
                       <span>0</span>
                       <button>+</button>
                     </div>
                     <div style={{ fontWeight: 'bolder', color: "#dc3545", cursor: "pointer" }}>Remove</div>
                    </div>
                  </>
                  )} */}

                  </>
                )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FormFurnishingDetails;
