import {
  addFurnitureService,
  getAllFurnitureService,
} from "../../config/furniture";

import { SET_FURNITURES, SET_FURNITURE_DETAILS } from "./types";

export const getFurnitures = (data) => async (dispatch) => {
  try {
    const res = await getAllFurnitureService(data);
    dispatch({
      type: SET_FURNITURES,
      payload: {
        data: res.data,
      },
    });
  } catch (e) {
    console.log(e);
  }
};
