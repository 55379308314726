import React, { useState, useRef } from "react";
import toast from "react-hot-toast";
import { registerService, updateRole } from "../../config/user";
import classes from "../../styles/styles.js";
import { Chip, Typography } from "@mui/material";
import styles from "../../styles/styles.js";
import { useTheme } from "@mui/material/styles";
import OneMonth from "../../assets/images/3-month.png";
import SixMonth from "../../assets/images/6-month.png";
import NineMonth from "../../assets/images/9-month.png";
import TwelveMonth from "../../assets/images/12-month.png";
import "./index.css";
import DoneIcon from "@mui/icons-material/Done";

export default function ChoosePlan({
  furnitureDetails,
  onContinue = () => {},
  setBookingPeriod,
  setPlanModal,
  bookingPeriod,
}) {

  console.log(furnitureDetails)
  const [role, setRole] = useState("broker");
  const theme = useTheme();
  const [selectThreeMonths, setSelectThreeMonths] = useState(false);
  const [selectSixMonths, setSelectSixMonths] = useState(false);
  const [selectNineMonths, setSelectNineMonths] = useState(false);
  const [selectTwelveMonths, setSelectTwelveMonths] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!bookingPeriod) {
      toast("Please select any plan");
      return;
    } else {
      onContinue(bookingPeriod);
    }
  };

  function handleSelectThreeMonths(event) {
    event.preventDefault();
    setSelectThreeMonths(true);
    setSelectSixMonths(false);
    setSelectNineMonths(false);
    setSelectTwelveMonths(false);
    setBookingPeriod("3")

  }

  function handleSelectSixMonths(event) {
    event.preventDefault();
    setSelectThreeMonths(false);
    setSelectSixMonths(true);
    setSelectNineMonths(false);
    setSelectTwelveMonths(false);
    setBookingPeriod("6")
  }

  function handleSelectNineMonths(event) {
    event.preventDefault();
    setSelectThreeMonths(false);
    setSelectSixMonths(false);
    setSelectNineMonths(true);
    setSelectTwelveMonths(false);
    setBookingPeriod("9")
  }

  function handleSelectTwelveMonths(event) {
    event.preventDefault();
    setSelectThreeMonths(false);
    setSelectSixMonths(false);
    setSelectNineMonths(false);
    setSelectTwelveMonths(true);
    setBookingPeriod("12")
  }

  return (
    // <div className="container">
    //   <div class="row g-3 align-items-center">
    //     <form onSubmit={onSubmit}>
    //       <div className="mt-3">
    //         <Typography
    //           sx={{marginBottom:"30px", textAlign:"center", fontWeight: "bold"}}
    //         >
    //           Select Rental Period
    //         </Typography>
    //       </div>
    //       <div className="d-flex flex flex-wrap justify-content-evenly mt-2">
    //         <div onClick={() => setBookingPeriod("3")}>
    //           {bookingPeriod==="3"?  <img className="month-image1" src={OneMonth}></img> : <img className="month-image" src={OneMonth}></img>}
    //          <p className="mt-3 text-center">Price : ₹{Number(furnitureDetails?.threeMonthPayment)}</p>
    //         </div>
    //         <div onClick={() => setBookingPeriod("6")}>
    //         {bookingPeriod==="6"?  <img className="month-image1" src={SixMonth}></img> : <img className="month-image" src={SixMonth}></img>}
    //         <p className="mt-3 text-center">Price : ₹{Number(furnitureDetails?.sixMonthPayment)}</p>

    //         </div>
    //         <div onClick={() => setBookingPeriod("9")}>
    //         {bookingPeriod==="9"?  <img className="month-image1" src={NineMonth}></img> : <img className="month-image" src={NineMonth}></img>}
    //         <p className="mt-3 text-center">Price : ₹{Number(furnitureDetails?.nineMonthPayment)}</p>

    //         </div>
    //         <div onClick={() => setBookingPeriod("12")}>
    //         {bookingPeriod==="12"?  <img className="month-image1" src={TwelveMonth}></img> : <img className="month-image" src={TwelveMonth}></img>}
    //         <p className="mt-3 text-center">Price : ₹{Number(furnitureDetails?.twelveMonthPayment)}</p>

    //         </div>
    //       </div>

    //       <div
    //         style={{
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           marginTop: "60px",
    //         }}
    //         class="col-lg-12 col-md-12 col-sm-12 form-group mb-2"
    //       >
    //         <button style={{ width: "380px" }} className="btn-auth-signup">
    //           Continue
    //         </button>
    //       </div>
    //     </form>
    //   </div>
    // </div>

    <div className="container">
      <div
        className="rental-tenure-container"
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "30px",
        }}
      >
        <div className="compare-rental-tenures" style={{ height: "400px", width: "270px", paddingBlock: "30px" }}>
          <div style={{ fontSize: "20px", marginBottom: "20px", fontWeight:"bold", color:"#9a291b" }}>
            Gharwala<br></br>
            Tenures Information
          </div>
          <div
            style={{
              backgroundColor: "#9a291b1f",
              paddingInline: "6px",
              paddingBlock: "12px",
              fontSize: "14px",
            }}
          >
            Your savings
          </div>
          <div
            style={{
              paddingInline: "6px",
              paddingBlock: "12px",
              fontSize: "14px",
            }}
          >
            Early closure charges <sup style={{fontWeight: "bolder", color: "#9a291b"}}>FREE</sup>
          </div>
          <div
            style={{
              backgroundColor: "#9a291b1f",
              paddingInline: "6px",
              paddingBlock: "12px",
              fontSize: "14px",
            }}
          >
            Relocation period <sup style={{fontWeight: "bolder", color: "#9a291b"}}>FREE</sup>
          </div>
          <div
            style={{
              paddingInline: "6px",
              paddingBlock: "12px",
              fontSize: "14px",
            }}
          >
            Upgradation <sup style={{fontWeight: "bolder", color: "#9a291b"}}>FREE</sup>
          </div>
        </div>

        <div
          className="three-month-rental-tenure"
          style={{
            height: "400px",
            border: "1px solid #9a291b1f",
            borderRadius: "4px",
            textAlign: "center",
            width: "280px",
            paddingBlock: "30px",
          }}
        >
          <div style={{ fontSize: "20px", marginBottom: "20px" }}>
            <span style={{ fontSize: "16px" }}>Min 3 months</span>
            <br></br>
            <span style={{ fontSize: "16px" }}>₹</span> <span>  {(furnitureDetails?.threeMonthPayment?.threeMonthPayment)||252}</span>{" "}
            <span style={{ fontSize: "16px" }}>/mo</span>
          </div>
          <div
            style={{
              backgroundColor: "#9a291b1f",
              paddingInline: "6px",
              paddingBlock: "12px",
              color: "#9a291b",
              fontSize: "14px",
            }}
          >
          {furnitureDetails?.threeMonthPayment?.monthlyRentalSavings|| '0%'}
          </div>
          <div
            style={{ padding: "6px", paddingBlock: "12px", fontSize: "14px" }}
          >
           {furnitureDetails?.threeMonthPayment?.earlyClosureCharge || '1 month'}
          </div>
          <div
            style={{
              backgroundColor: "#9a291b1f",
              paddingInline: "6px",
              paddingBlock: "12px",
              fontSize: "14px",
            }}
          >
            {furnitureDetails?.threeMonthPayment?.freeAllocation || 'after 6 months'}
          </div>
          <div
            style={{ padding: "6px", paddingBlock: "12px", fontSize: "14px" }}
          >
          {furnitureDetails?.threeMonthPayment?.freeUpgrade || 'after 9 months'}
          </div>
          {selectThreeMonths ? (
            <div>
              <button
                style={{
                  marginTop: "16px",
                  paddingInline: "36px",
                  paddingBlock: "6px",
                  color: "#9a291b",
                  backgroundColor: "white",
                  border: "1px solid #9a291b",
                  fontSize: "14px",
                }}
              >
                <DoneIcon />
              </button>
            </div>
          ) : (
            <div>
              <button
                style={{
                  marginTop: "16px",
                  paddingInline: "36px",
                  paddingBlock: "6px",
                  backgroundColor: "#9a291b",
                  fontSize: "14px",
                }}
                onClick={handleSelectThreeMonths}
              >
                Select
              </button>
            </div>
          )}
        </div>

        <div
          className="six-month-rental-tenure"
          style={{
            height: "400px",
            border: "1px solid #9a291b1f",
            borderRadius: "4px",
            textAlign: "center",
            width: "280px",
            paddingBlock: "30px",
          }}
        >
          <div style={{ fontSize: "20px", marginBottom: "20px" }}>
            <span style={{ fontSize: "16px" }}>Min 6 months</span>
            <br></br>
            <span style={{ fontSize: "16px" }}>₹</span> <span>{(furnitureDetails?.sixMonthPayment?.sixMonthPayment)||252}</span>{" "}
            <span style={{ fontSize: "16px" }}>/mo</span>
          </div>
          <div
            style={{
              backgroundColor: "#9a291b1f",
              paddingInline: "6px",
              paddingBlock: "12px",
              color: "#9a291b",
              fontSize: "14px",
            }}
          >
       {furnitureDetails?.sixMonthPayment?.monthlyRentalSavings || '69%'}
          </div>
          <div
            style={{ padding: "6px", paddingBlock: "12px", fontSize: "14px" }}
          >
                {furnitureDetails?.sixMonthPayment?.earlyClosureCharge || 'after 9 months'}
          </div>
          <div
            style={{
              backgroundColor: "#9a291b1f",
              paddingInline: "6px",
              paddingBlock: "12px",
              fontSize: "14px",
            }}
          >
            {furnitureDetails?.sixMonthPayment?.freeAllocation || 'after 9 months'}
          </div>
          <div
            style={{ padding: "6px", paddingBlock: "12px", fontSize: "14px" }}
          >
               {furnitureDetails?.sixMonthPayment?.freeUpgrade || 'after 9 months'}
          </div>

          {selectSixMonths ? (
            <div>
              <button
                style={{
                  marginTop: "16px",
                  paddingInline: "36px",
                  paddingBlock: "6px",
                  color: "#9a291b",
                  backgroundColor: "white",
                  border: "1px solid #9a291b",
                  fontSize: "14px",
                }}
              >
                <DoneIcon />
              </button>
            </div>
          ) : (
            <div>
              <button
                style={{
                  marginTop: "16px",
                  paddingInline: "36px",
                  paddingBlock: "6px",
                  backgroundColor: "#9a291b",
                  fontSize: "14px",
                }}
                onClick={handleSelectSixMonths}
              >
                Select
              </button>
            </div>
          )}
        </div>

        <div
          className="nine-month-rental-tenure"
          style={{
            height: "400px",
            border: "1px solid #9a291b1f",
            borderRadius: "4px",
            textAlign: "center",
            width: "280px",
            paddingBlock: "30px",
          }}
        >
          <div style={{ fontSize: "20px", marginBottom: "20px" }}>
            <span style={{ fontSize: "16px" }}>Min 9 months</span>
            <br></br>
            <span style={{ fontSize: "16px" }}>₹</span> <span>{(furnitureDetails?.nineMonthPayment?.nineMonthPayment)||252}</span>{" "}
            <span style={{ fontSize: "16px" }}>/mo</span>
          </div>
          <div
            style={{
              backgroundColor: "#9a291b1f",
              paddingInline: "6px",
              paddingBlock: "12px",
              color: "#9a291b",
              fontSize: "14px",
            }}
          >
       {furnitureDetails?.nineMonthPayment?.monthlyRentalSavings || '69%'}
          </div>
          <div
            style={{ padding: "6px", paddingBlock: "12px", fontSize: "14px" }}
          >
                {furnitureDetails?.nineMonthPayment?.earlyClosureCharge || 'after 9 months'}
          </div>
          <div
            style={{
              backgroundColor: "#9a291b1f",
              paddingInline: "6px",
              paddingBlock: "12px",
              fontSize: "14px",
            }}
          >
            {furnitureDetails?.nineMonthPayment?.freeAllocation || 'after 9 months'}
          </div>
          <div
            style={{ padding: "6px", paddingBlock: "12px", fontSize: "14px" }}
          >
               {furnitureDetails?.nineMonthPayment?.freeUpgrade || 'after 9 months'}
          </div>

          {selectNineMonths ? (
            <div>
              <button
                style={{
                  marginTop: "16px",
                  paddingInline: "36px",
                  paddingBlock: "6px",
                  color: "#9a291b",
                  backgroundColor: "white",
                  border: "1px solid #9a291b",
                  fontSize: "14px",
                }}
              >
                <DoneIcon />
              </button>
            </div>
          ) : (
            <div>
              <button
                style={{
                  marginTop: "16px",
                  paddingInline: "36px",
                  paddingBlock: "6px",
                  backgroundColor: "#9a291b",
                  fontSize: "14px",
                }}
                onClick={handleSelectNineMonths}
              >
                Select
              </button>
            </div>
          )}
        </div>

        <div
          className="twelve-month-rental-tenure"
          style={{
            height: "400px",
            border: "1px solid #9a291b1f",
            borderRadius: "4px",
            textAlign: "center",
            width: "280px",
            paddingBlock: "30px",
          }}
        >
          <div style={{ fontSize: "20px", marginBottom: "20px" }}>
            <span style={{ fontSize: "16px" }}>Min 12 months</span>
            <br></br>
            <span style={{ fontSize: "16px" }}>₹</span> <span>{(furnitureDetails?.twelveMonthPayment?.twelveMonthPayment)||252}</span>{" "}
            <span style={{ fontSize: "16px" }}>/mo</span>
          </div>
          <div
            style={{
              backgroundColor: "#9a291b1f",
              paddingInline: "6px",
              paddingBlock: "12px",
              color: "#9a291b",
              fontSize: "14px",
            }}
          >
             {furnitureDetails?.twelveMonthPayment?.monthlyRentalSavings || '69%'}
          </div>
          <div
            style={{ padding: "6px", paddingBlock: "12px", fontSize: "14px" }}
          >
         {furnitureDetails?.twelveMonthPayment?.earlyClosureCharge || '2000'}
          </div>
          <div
            style={{
              backgroundColor: "#9a291b1f",
              paddingInline: "6px",
              paddingBlock: "12px",
              fontSize: "14px",
            }}
          >
          {furnitureDetails?.twelveMonthPayment?.freeAllocation || '2000'}
          </div>
          <div
            style={{ padding: "6px", paddingBlock: "12px", fontSize: "14px" }}
          >
               {furnitureDetails?.twelveMonthPayment?.freeUpgrade || '6 MONTH'}
          </div>

          {selectTwelveMonths ? (
            <div>
              <button
                style={{
                  marginTop: "16px",
                  paddingInline: "36px",
                  paddingBlock: "6px",
                  color: "#9a291b",
                  backgroundColor: "white",
                  border: "1px solid #9a291b",
                  fontSize: "14px",
                }}
              >
                <DoneIcon />
              </button>
            </div>
          ) : (
            <div>
              <button
                style={{
                  marginTop: "16px",
                  paddingInline: "36px",
                  paddingBlock: "6px",
                  backgroundColor: "#9a291b",
                  fontSize: "14px",
                }}
                onClick={handleSelectTwelveMonths}
              >
                Select
              </button>
            </div>
          )}
        </div>
      </div>


      <div
        className="rental-tenure-button"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <button style={{ backgroundColor: "#9a291b" }} onClick={()=>{
          setPlanModal(prev=>!prev)

        }}>Done</button>
      </div>
    </div>
  );
}
