import { Switch, ToggleButton, capitalize } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BsBuilding } from "react-icons/bs";
import { checkTabsStatus, updateTabStatus } from "../../config/user";
import toast from "react-hot-toast";

const AdminToggleSection = () => {
  const [broker, setBroker] = useState(true);
  const [owner, setOwner] = useState(true);
  const [service, setService] = useState(false);
  const [property, setProperty] = useState(false);
  const [furnitureSell,setFurnitureSell] = useState(false);
  const [furnitureBuy,setFurnitureBuy]= useState(false)

  async function checkTabStatus() {
    try {
      const promises = [
        checkTabsStatus({ propertyName: "BROKERS" }),
        checkTabsStatus({ propertyName: "OWNERS" }),
        checkTabsStatus({ propertyName: "SERVICES" }),
        checkTabsStatus({ propertyName: "PROPERTY" }),
        checkTabsStatus({propertyName:'FURNITURESELL'}),
        checkTabsStatus({propertyName:'FURNITUREBUY'})
      ];

      const results = await Promise.all(promises);
      console.log('results',results);

      setBroker(results[0].data.message.enabled);
      setOwner(results[1].data.message.enabled);
      setService(results[2].data.message.enabled);
      setProperty(results[3].data.message.enabled);
      setFurnitureSell(results[4].data.message.enabled)
      setFurnitureBuy(results[5].data.message.enabled);
    } catch (error) {
      toast.error("something went wrong");
    }
  }

  useEffect(() => {
    checkTabStatus();
  }, []);

  async function toggleService(name, checked) {
    // name can be SERVICES, BROKERS, OWNERS

    try {
      const updateResp = await updateTabStatus({
        propertyName: name,
        enabled: !checked,
      });
      // const resp = await checkTabsStatus({propertyName: name});
      if (checked) {
        toast.success(capitalize(name) + " TAB IS DISABLED NOW");
      } else {
        toast.success(name + " TAB IS ENABLED NOW");
      }
      if (name === "BROKERS") setBroker(!checked);
      if (name === "OWNERS") setOwner(!checked);
      if (name === "SERVICES") setService(!checked);
      if (name === "PROPERTY") setProperty(!checked);
      if(name === 'FURNITURESELL') setFurnitureSell(!checked);
      if(name === 'FURNITUREBUY') setFurnitureBuy(!checked)
    } catch (error) {
      toast.error("Something went wrong!");
    }
  }

  return (
    <div
      style={{
        marginTop: "150px",
        marginBottom: "70px",
        justifyContent: "center",
        display: "flex",
        flexWrap: "wrap",
      }}
      className="mt-10"
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "40px",
          alignItems: "center",
          marginTop: "120px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
            borderRadius: "10px",
            gap: "10px",
            border: "2px solid #9a291b",
            margin: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{
                height: "250px",
                width: "250px",
              }}
              src="./assests/images/broker.png"
            ></img>

            <div
              style={{
                display: "flex",
                marginTop: "10px",
              }}
            >
              <h4>Broker Panel</h4>
              <Switch
                onChange={() => toggleService("BROKERS", broker)}
                checked={broker}
                color="success"
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
            borderRadius: "10px",
            gap: "10px",
            border: "2px solid #9a291b",
            margin: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{
                height: "250px",
                width: "250px",
              }}
              src="./assests/images/contractor.png"
            ></img>

            <div
              style={{
                display: "flex",
                marginTop: "10px",
              }}
            >
              <h4>Owner Panel</h4>
              <Switch
                onChange={() => toggleService("OWNERS", owner)}
                checked={owner}
                color="success"
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
            borderRadius: "10px",
            gap: "10px",
            border: "2px solid #9a291b",
            margin: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{
                height: "250px",
                width: "250px",
              }}
              src="./assests/images/service.png"
            ></img>

            <div
              style={{
                display: "flex",
                marginTop: "10px",
              }}
            >
              <h4>Services</h4>
              <Switch
                onChange={() => toggleService("SERVICES", service)}
                checked={service}
                color="success"
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
            borderRadius: "10px",
            gap: "10px",
            border: "2px solid #9a291b",
            margin: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{
                height: "250px",
                width: "250px",
              }}
              src="./assests/images/service.png"
            ></img>

            <div
              style={{
                display: "flex",
                marginTop: "10px",
              }}
            >
              <h4>Furniture buy </h4>
              <Switch
                onChange={() => toggleService("FURNITUREBUY", furnitureBuy)}
                checked={furnitureBuy}
                color="success"
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
            borderRadius: "10px",
            gap: "10px",
            border: "2px solid #9a291b",
            margin: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{
                height: "250px",
                width: "250px",
              }}
              src="./assests/images/service.png"
            ></img>

            <div
              style={{
                display: "flex",
                marginTop: "10px",
              }}
            >
              <h4>Furniture Rent </h4>
              <Switch
                onChange={() => toggleService("FURNITURESELL", furnitureSell)}
                checked={furnitureSell}
                color="success"
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
            borderRadius: "10px",
            gap: "10px",
            border: "2px solid #9a291b",
            margin: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{
                height: "250px",
                width: "250px",
              }}
              src="./assests/images/service.png"
            ></img>

            <div
              style={{
                display: "flex",
                marginTop: "10px",
              }}
            >
              <h4>PROPERTY</h4>
              <Switch
                onChange={() => {
                  toggleService("PROPERTY", property);
                  console.log("hi");
                }}
                checked={property}
                color="success"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminToggleSection;
