import React from "react";
import Button from "@mui/material/Button";
import styles from "../../styles/styles";

export default function ButtonContained({
  title,
  classes,
  children,
  onClick,
  disabled,
  sx,
  ...other
}) {

  return (
    // <Button variant="contained" title={title} classes={classes} >{title}</Button>
    <Button
      sx={[styles.containedButton, ...(sx || [])]}
      {...other}
      variant="contained"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}
