import React, { useState, useEffect } from "react";
import { getUserService } from "../../config/user.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
// import Hedaer from '../../components/Hedaer';
import { Box, Typography, Avatar, IconButton } from "@mui/material";
import ButtonContained from "../../components/Buttons/ButtonContained.js";
import ButtonOutlined from "../../components/Buttons/ButtonOutlined.js";
import { assetUrl, imageReq } from "../../config/axiosConfig.js";
import styles from "../../styles/styles.js";
import BedSvg from "../../assets/images/bed.svg";
import BathSvg from "../../assets/images/bed.svg";
// import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import Header from "../../components/Header.js";
import {
  updatePropertyStatus,
  onGetPropertietsByUserId,
} from "../../config/property.js";
import toast from "react-hot-toast";

const UserDetails = () => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [loader, setLoader] = useState(false);
  const [properties, setProperties] = useState([]);
  const { allUsers } = useSelector((state) => state.auth);
  //   const { properties } = useSelector((state) => state.property);
  useEffect(() => {
    onGetUserById();
    // onGetUserProperties();
  }, []);

  const onGetUserById = async () => {
    const filterQuery = params.id;
    let res = await getUserService(filterQuery);
    if (res.data) {
      setUserDetails(res.data);
      onGetUserProperties();
      setLoader(false);
    }
  };

  const onGetUserProperties = async () => {
    const filterQuery = params.id;
    let res = await onGetPropertietsByUserId(filterQuery);
    if (res.data) {
      setProperties(res.data);
      setLoader(false);
    }
  };
  const onUpdateUserStatus = async (id, status) => {
    try {
      const res = await updatePropertyStatus({
        active: status,
        propertyId: id,
      });
      if (res.data.status) {
        toast.success(`Status updated successfully!`);
        onGetUserProperties();
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Header />
      <div className="container" style={{ paddingTop: "130px" }}>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card-container card-container-user-desc">
              <Box
                sx={[styles.borderRadius12, styles.border]}
                className="card shadow-none px-5 py-3"
              >
                <div className="d-flex align-items-center">
                  <Avatar
                    sx={{ width: "72px", height: "72px" }}
                    alt={userDetails?.userDetails?.name || ""}
                    src={assetUrl + userDetails?.avatarImage}
                    // src={card.imageSrc}
                  />
                  <Box className="px-2">
                    <Typography
                      className="text-capitalize"
                      sx={[
                        styles.fontFamilyDS,
                        styles.fbBold,
                        styles.fs20,
                        styles.color240501,
                        styles.textTransformCapitalize,
                      ]}
                    >
                      {userDetails?.name}
                    </Typography>
                    <Typography
                      className="text-capitalize text-color979797"
                      sx={[styles.fontFamilyDS, styles.fs20]}
                    >
                      {userDetails?.role}
                    </Typography>
                  </Box>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid" style={{ paddingBottom: "50px" }}>
        <p className="user-properties-h3">
          Properties Lists by this {userDetails?.role}
        </p>
        <div className="d-flex flex-wrap px-2">
          {properties &&
            properties?.map((card, index) => (
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div className="card-container">
                  <div key={index} className="card">
                    <div
                      className="card-image-properties"
                      style={{
                        backgroundImage: `url(${card.image})`,
                        backgroundSize: "100%",
                        backgroundRepeat: "no-repeat",
                        backgroundClip: "content-box",
                        backgroundPosition: "center",
                      }}
                    >
                      <div className="d-flex justify-content-end"></div>
                    </div>
                    <div className="text-info">
                      <div className="slider-card-header">
                        <h2 className="slider-title text-capitalize">
                          {card.title}
                        </h2>
                        
                        <span className="slider-title price-text">
                          {card.expectedMonthlyRent}
                        </span>
                      </div>
                      <p className="property-desc">{card.description}</p>
                      <div className="propety-icons">
                        <div className="property-icon">
                          <img src={BedSvg} />
                          <span className="text-color979797 property-icon-text">
                            {card.bedrooms}
                          </span>
                        </div>
                        
                        {card.bathrooms && (
                          <div className="property-icon">
                            <img src={BathSvg} />
                            <span className="property-icon-text text-color979797">
                              {card.bathrooms}
                            </span>
                            
                          </div>
                        )}
                      </div>
                      {card?.created ?
                      <div>Listing Date: {card?.created.slice(0,10)}</div>:""}
                    </div>
                    <div className="property-slider-buttons px-2">
                      <ButtonContained
                        sx={[
                          styles.fontFamilySGB,
                          !card.status && styles.btnBgGreen,
                          styles.p10,
                          styles.borderRadius12,
                        ]}
                        onClick={(e) => {
                          e.preventDefault();
                          onUpdateUserStatus(
                            card._id,
                            card.status ? false : true
                          );
                        }}
                      >
                        {card.status ? "Deactivate" : "Activate"}
                      </ButtonContained>
                      <ButtonOutlined
                        onClick={() => navigate(`/properties/${card._id}`)}
                        sx={[
                          styles.fontFamilySGB,
                          styles.p10,
                          styles.borderRadius12,
                        ]}
                        className="m-0 mx-1 my-2"
                      >
                        View Detail
                      </ButtonOutlined>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
export default UserDetails;
