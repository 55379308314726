import {
    Box,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
  } from "@mui/material";
  import React, { useState } from "react";
  import { useTheme } from "@mui/material/styles";
import styles from "../../../styles/styles";
  
  const AddFurnitureStep1 = ({ handleTextChange, data, sellingOptions, setSellingOptions }) => { 
    
    const theme = useTheme();
    return (
      <Box
        sx={[
          { marginLeft: "12px", paddingLeft: "20px", paddigRight: "8px" },
          {
            [theme.breakpoints.between("xs", "md")]: [
              { marginLeft: 0, paddingLeft: 0, paddigRight: 0 },
            ],
          },
        ]}
      >
        <Typography
          sx={[
            styles.fs30,
            styles.fontFamilySGB,
            styles.fbBold,
            styles.color240501,
          ]}
        >
          Basic Details
        </Typography>
        <Box sx={[styles.mt40]}>
          <Box>
            <Typography
              sx={[styles.fs16, styles.fontFamilySG, styles.color240501]}
              className="mb-2"
            >
              I want to
            </Typography>
            <RadioGroup defaultValue="female" name="radio-buttons-group">
              <FormControlLabel
                value="sell"
                label={
                  <Typography
                    variant="body2"
                    sx={[styles.fontFamilyDS, styles.fs16, styles.color240501]}
                  >
                    Sell a furniture
                  </Typography>
                }
                control={
                  <Radio
                    checked={data.wantto === "sell" && setSellingOptions("buy")}
                    onChange={(e) => handleTextChange("wantto", e.target.value)}
                    sx={{
                      "&.Mui-checked": styles.color9A291B,
                    }}
                  />
                }
              />
              <FormControlLabel
                value="rent"
                checked={data.wantto === "rent" && setSellingOptions("rent")}
                label={
                  <Typography
                    variant="body2"
                    sx={[styles.fontFamilyDS, styles.fs16, styles.color240501]}
                  >
                    Rent a furniture
                  </Typography>
                }
                control={
                  <Radio
                    onChange={(e) => handleTextChange("wantto", e.target.value)}
                    sx={{
                      "&.Mui-checked": styles.color9A291B,
                    }}
                  />
                }
              />
             
            </RadioGroup>
          </Box>
        </Box>
      </Box>
    );
  };
  
  export default AddFurnitureStep1;
  