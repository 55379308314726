import { useState, useEffect } from 'react';
import './style.css';
import Header from '../../components/Header';
import AgreementContent from './components/AgreementContent';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Bottombar from './components/Bottombar';
import { Bathroom } from '@mui/icons-material';

const AgreementForm = () => {
  const [role, setRole] = useState('owner');
  const location = useLocation();

  const date = new Date();
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const [ownerData, setOwnerData] = useState({
    initial: 'Mr',
    name: null,
    relation: "S/O",
    parentName: null,
    countryCode: '+91',
    number: null,
    chooseDoc: 'Adhar No.',
    docNumber: null,
    email: null,
    faith: null,
    profession: null,
    address: null,
    pincode: null,
    city: null,
    state: null,
  });

  const [tenantData, setTenantData] = useState([
    {
      initial: 'Mr',
      name: null,
      relation: "S/O",
      parentName: null,
      countryCode: '+91',
      number: null,
      chooseDoc: 'Adhar No.',
      docNumber: null,
      email: null,
      faith: null,
      profession: null,
      address: null,
      pincode: null,
      state: null,
      city: null,
    },
  ]);

  const [furnitureId, setFurnitureId] = useState();

  const [propertyDetails, setPropertyDetails] = useState({
    type: 'residential',
    floor: '',
    rooms: '',
    bedroom: 0,
    bathroom: 0,
    balcony: 0,
    others: [],
    area: '',
    address: '',
    pincode: '',
    city: '',
    state: '',
    addressSame: false,
    parking: false,
  });

  const [contractDetails, setContractDetails] = useState({
    startDate: new Date().toISOString().split('T')[0],
    duration: 0,
    endDate: null,
    rentPaymentDate: '1st of every month',
    rentAmount: null,
    rentAmountInWords: null,
    maintenanceChoice: null,
    maintenanceCharges: null,
    escalationPrice: null,
    escalationPriceAmount: null,
    securityDeposit: null,
    securityDepositInWords: null,
    noticePeriod: null,
  });

  useEffect(() => {
    const calculateEndDate = () => {
      let startDate = new Date(contractDetails.startDate);
      startDate.setMonth(startDate.getMonth() + contractDetails.duration);
      return startDate.toISOString().split('T')[0];
    };

    setContractDetails(prevDetails => ({
      ...prevDetails,
      endDate: calculateEndDate(),
    }));
  }, [contractDetails.startDate], contractDetails.duration);

  const [furnishedDetails, setFurnishedDetails] = useState({
    type: 'unfurnished',
    ac: 0,
    washingMachine: 0,
    sofa: 0,
    bed: 0,
    wardrobe: 0,
    table: 0,
    geyser: 0,
    stove: 0,
    fans: 0,
    tubelights: 0,
  });

  return (
    <div>
      <Header />
      <div className='rent-container'>
        <Sidebar />
        <div
          className='form-container'
          style={{
            width: location.pathname.includes('final') && '1000px',
            height: location.pathname.includes('final') && '100vh',
          }}
        >
          <Outlet
            context={[
              role,
              setRole,
              ownerData,
              setOwnerData,
              tenantData,
              setTenantData,
              propertyDetails,
              setPropertyDetails,
              contractDetails,
              setContractDetails,
              furnitureId,
              setFurnitureId,
              furnishedDetails,
              setFurnishedDetails,
            ]}
          />
        </div>
        <AgreementContent
          ownerData={ownerData}
          tenantData={tenantData}
          propertyDetails={propertyDetails}
          setPropertyDetails={setPropertyDetails}
          contractDetails={contractDetails}
          setContractDetails={setContractDetails}
          furnitureId={furnitureId}
          setFurnitureId={setFurnitureId}
          furnishedDetails={furnishedDetails}
          setFurnishedDetails={setFurnishedDetails}
        />
      </div>
      <Bottombar
        ownerData={ownerData}
        tenantData={tenantData}
        propertyDetails={propertyDetails}
        setPropertyDetails={setPropertyDetails}
        contractDetails={contractDetails}
        setContractDetails={setContractDetails}
        furnitureId={furnitureId}
        setFurnitureId={setFurnitureId}
      />
    </div>
  );
};

export default AgreementForm;
