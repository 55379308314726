import { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import { privateReq } from "../../config/axiosConfig";
import ButtonContained from "../../components/Buttons/ButtonContained";
import OutlinedFormInput from "../../components/Input/OutlinedInput";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import PageNotFound from "../../components/PageNotFound";
import { Link } from "react-router-dom";

const AdminCategory = () => {
  const headerRef = useRef();
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [typeCategory, setTypeCategory] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const [addCategory, setAddCategory] = useState();

  const [headerHeight, setHeaderHeight] = useState(0);
  const [pincodes, setPincodes] = useState();
  const [addNotificationDate, setAddNotificationDate] = useState({
    username: "",
    phone: "",
    description: "",
  });

  const [showPage, setShowPage] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user !== null) {
      if (user?.role === "admin") {
        setShowPage(true);
      }
    }
  }, []);

  // useEffect(() => {
  //   setHeaderHeight(headerRef.current.offsetHeight);
  // }, [headerHeight]);

  useEffect(() => {
    onGetCategory();
  }, []);

  const onGetCategory = async () => {
    try {
      const res = await privateReq.get("get-category");
      setCategoryList(res?.data?.category);
      let ids = res?.data?.category.map((id) => id._id);

      onGetSubCategory(ids);
    } catch (err) {
      console.log(err);
    }
  };

  const onGetSubCategory = async (id) => {
    try {
      const res = await privateReq.post(`get-subcategory`, {
        id,
      });
      setSubCategoryList(res?.data?.getSubcategory);
    } catch (err) {
      console.log(err);
    }
  };

  const onAddCategory = async () => {
    if (!typeCategory) {
      toast.error("Please select type", {
        position: "top-right",
        style: {
          zIndex: 99994,
        },
      });
    } else if (!addCategory) {
      toast.error("Please enter category name", {
        position: "top-right",
        style: {
          zIndex: 99994,
        },
      });
    } else {
      const res = await privateReq.post("add-category", {
        name: addCategory,
        type: typeCategory,
      });
      console.log("res", res);
      onGetCategory();
    }
  };

  const onAddSubCategory = async () => {
    if (!selectedCategory) {
      toast.error("Please select category", {
        position: "top-right",
        style: {
          zIndex: 99994,
        },
      });
    } else if (!selectedSubCategory) {
      toast.error("Please enter sub category name", {
        position: "top-right",
        style: {
          zIndex: 99994,
        },
      });
    } else {
      const res = await privateReq.post("add-subcategory", {
        name: selectedSubCategory,
        category: selectedCategory,
      });
      console.log("res", res);
      onGetCategory();
    }
  };

  const onDeleteCategory = async (id) => {
    const res = await privateReq.delete(`admin-delete-category/${id}`);

    onGetCategory();
  };
  const onDeleteSubCategory = async (id) => {
    const res = await privateReq.delete(`admin-delete-subcategory/${id}`);
    onGetCategory();
  };
  return showPage ? (
    <>
      <Header innerRef={headerRef} />
      <div
        style={{
          padding: "0 50px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "100px",
        }}
      >
        <div
          style={{
            fontSize: "30px",
            fontFamily: "Montserrat",
          }}
        >
          Category Manage
        </div>

        <p style={{ marginTop: "50px" }}>Add Category</p>
        <div
          style={{
            padding: "0 50px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginRight: "50px",
              padding: "0 50px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label htmlFor="categorySelect">Select a Type:</label>
            <select
              id="categorySelect"
              onChange={(e) => setTypeCategory(e.target.value)}
            >
              <option value="">Select Type</option>
              <option value={"buy"}>Buy</option>
              <option value={"rent"}>Rent</option>
            </select>
          </div>
          <div>
            <OutlinedFormInput
              style={{
                width: "300px",
              }}
              type="text"
              name="selectedSubCategory"
              value={addCategory}
              onChange={(e) => setAddCategory(e.target.value)}
              placeholder="Category"
            />
          </div>
          <ButtonContained
            style={{
              width: "150px",
            }}
            onClick={() => onAddCategory()}
          >
            Save
          </ButtonContained>
        </div>

        <p style={{ marginTop: "50px" }}>Add SubCategory</p>
        <div
          style={{
            padding: "0 50px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginRight: "50px",
              padding: "0 50px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label htmlFor="categorySelect">Select a Category:</label>
            <select
              id="categorySelect"
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Select Category</option>
              {categoryList.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name} {`(${category.type})`}
                </option>
              ))}
            </select>
          </div>
          <div>
            <OutlinedFormInput
              style={{
                width: "300px",
              }}
              type="text"
              name="selectedSubCategory"
              value={selectedSubCategory}
              onChange={(e) => setSelectedSubCategory(e.target.value)}
              placeholder="Sub Category"
            />
          </div>
          <ButtonContained
            style={{
              width: "150px",
            }}
            onClick={() => onAddSubCategory()}
          >
            Save
          </ButtonContained>
        </div>

        <div>
          <div
            style={{
              padding: "0 50px",
              display: "flex",
              alignItems: "start",
            }}
          >
            <div
              style={{
                marginTop: "20px",
                marginRight: "50px",
              }}
            >
              <div
                style={{
                  fontSize: "30px",
                  fontFamily: "Montserrat",
                }}
              >
                Category list:
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  marginBottom: "20px",
                  maxWidth: "1000px",
                }}
              >
                <div style={{ width: "150px", fontWeight: "bold" }}>Name</div>
                <div style={{ width: "150px", fontWeight: "bold" }}>Type</div>
                <div style={{ width: "150px", fontWeight: "bold" }}>Action</div>
              </div>
              {categoryList?.map((item) => (
                <div
                  style={{
                    display: "flex",
                    justifyItems: "center",
                    alignItems: "center",
                    borderBottom: "1px solid gray",
                    maxWidth: "950px",
                  }}
                >
                  <div style={{ width: "150px" }}>{item.name}</div>
                  <div style={{ width: "150px" }}>{item.type}</div>
                  <ButtonContained
                    style={{
                      width: "100px",
                    }}
                    onClick={() => onDeleteCategory(item?._id)}
                  >
                    Delete
                  </ButtonContained>
                </div>
              ))}
            </div>
            <div
              style={{
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  fontSize: "30px",
                  fontFamily: "Montserrat",
                }}
              >
                Sub-Category List:
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  marginBottom: "20px",
                  maxWidth: "1000px",
                }}
              >
                <div style={{ width: "150px", fontWeight: "bold" }}>Name</div>
                <div style={{ width: "150px", fontWeight: "bold" }}>
                  Category Name
                </div>{" "}
                <div style={{ width: "150px", fontWeight: "bold" }}>
                  Category Type
                </div>
                <div style={{ width: "150px", fontWeight: "bold" }}>Action</div>
              </div>
              {subCategoryList?.map((item) => (
                <div
                  style={{
                    display: "flex",
                    justifyItems: "center",
                    alignItems: "center",
                    borderBottom: "1px solid gray",
                    maxWidth: "950px",
                  }}
                >
                  <div style={{ width: "150px" }}>{item.name}</div>
                  <div style={{ width: "150px" }}>{item.category?.name}</div>
                  <div style={{ width: "150px" }}>{item.category?.type}</div>
                  <ButtonContained
                    style={{
                      width: "100px",
                    }}
                    onClick={() => onDeleteSubCategory(item?._id)}
                  >
                    Delete
                  </ButtonContained>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh"}}>
      <PageNotFound />
      <Link to="/">
        <button>Go To Home</button>
      </Link>
    </div>
  );
};

export default AdminCategory;
