import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import "./style.css";
import heroart1 from "../../assets/images/heroart-1.svg";
import heroart2 from "../../assets/images/heroart-7.svg";
import heroart3 from "../../assets/images/heroart-3.svg";
import heroart4 from "../../assets/images/heroart-4.svg";
import heroart5 from "../../assets/images/heroart-5.svg";
import heroart6 from "../../assets/images/heroart-6.svg";
import heroart7 from "../../assets/images/heroart-new.jpeg";
import heroart8 from "../../assets/images/heroart8.svg";
import heroart9 from "../../assets/images/banner-6.png";
import heroart10 from "../../assets/images/banner-7.png";

import Signup from "../Signup";
import ModalSignUp from "../ModalSignup";
import Profession from "../Profession/Profession";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const HomeHeroSection = () => {
	const responsive = {
		superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 3 },
		desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
		tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
		mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
	};

	const [closeModal, setCloseModal] = useState(false);
	const [isPropertyFree, setIsPropertyFree] = useState(false);
	const [firstPropty, setFirstPropty] = useState(false);

	const [closeLoginModal, setCloseLoginModal] = useState(false);
	const { user } = useSelector((state) => state.auth);

	function handleClickListNow() {
		setIsPropertyFree(true);
		if (user === null) {
			setCloseModal(true);
		} else if (user?.role === "guest") {
			setFirstPropty(true);
			// toast("Please select List my Property on Navbar");
		} else {
			window.location.href = "/add-property";
		}
	}

	return (
		<React.Fragment>
			<div className="home-hero-section-container">
				<Carousel
					responsive={responsive}
					removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
					infinite={true}
					showDots={true}
					autoPlay={true}
					autoPlaySpeed={3500}
				>
					<div className="slide-container gradient-2">
						<div className="content">
							<div className="title">
								List your property for sale and rent, from
								ANYWHERE in India, for{" "}
								<span style={{ color: "yellow" }}>FREE</span>
							</div>
							{/* <div className='subtitle'>Flat, Independent House, PG, Commercial Building, Land</div> */}

							<div className="banner-1-text">
								<div className="address-container">
									<button
										// onClick={() => {
										//   setShowSignUpModal(true)
										// }}

										onClick={() => {
											handleClickListNow();
										}}
										className="btn list-now-btn"
									>
										List Now
									</button>

									<div>
										<div style={{ color: "white" }}>
											www.gharwalah.com
										</div>
										<div style={{ color: "#FFDB58" }}>
											+91 8240444587
										</div>
										<div style={{ color: "white" }}>
											support@gharwalah.com
										</div>
									</div>
								</div>
								<div>
									<div className="subtitle banner-1-subtext">
										Flats<br></br> Independent House{" "}
										<br></br> PG<br></br> Commercial
										Building<br></br> Land
									</div>
								</div>
							</div>
						</div>
						<img src={heroart7} alt="" className="image" />
					</div>
					{/* <div className="slide-container gradient-3">
						<div className="content">
							<div className="title">
								1000+ satisfied customers in Kolkata
							</div>
							<div className="subtitle">
								Rent Furniture, Air Conditioner, & Other
								Appliances <br></br>{" "}
								<span
									style={{ marginTop: "20px" }}
									className=""
								>
									{" "}
									Price Starts From:{" "}
								</span>
								<span
									style={{
										color: "yellow",
										fontWeight: "bold",
									}}
								>
									₹139/mo.
								</span>{" "}
							</div>
							<Link to="/furniture" className="btn rent-now-btn">
								Rent Now
							</Link>

							<div style={{ color: "white" }}>
								www.gharwalah.com
							</div>
							<div style={{ color: "#FFDB58" }}>
								+91 8240444587
							</div>
							<div style={{ color: "white" }}>
								support@gharwalah.com
							</div>
						</div>
						<img src={heroart3} alt="" className="image" />
					</div> */}
					{/* <div className="slide-container gradient-4">
						<div className="content">
							<div className="title">
								100+ Happy customers booked AC repairing and
								salon-at-home services
							</div>
							<a
								href="/other-services"
								className="btn book-now-btn"
							>
								Book Now
							</a>
							<div style={{ color: "white" }}>
								www.gharwalah.com
							</div>
							<div style={{ color: "#FFDB58" }}>
								+91 8240444587
							</div>
							<div style={{ color: "white" }}>
								support@gharwalah.com
							</div>
						</div>
						<img src={heroart8} alt="" className="image" />
						<div className="overlay"></div>
					</div> */}

					{/* <div className="slide-container gradient-6">
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								padding: "0px",
							}}
							className="content content-special"
						>
							<div>
								<div className="title-6">
									Lowest price on furniture rentals & air
									conditioner rental
									<br />
								</div>
								<div
									style={{ textTransform: "uppercase" }}
									className="subtitle-6"
								>
									Kolkata's best kept secret
								</div>
								<div className="rent-now-btn-2-container">
									<Link
										to="/furniture"
										className="btn rent-now-btn-2"
									>
										Rent Now
									</Link>
								</div>
								<div
									className="new-web-desc"
									style={{ color: "white" }}
								>
									www.gharwalah.com
								</div>
								<div
									className="new-web-desc"
									style={{ color: "white" }}
								>
									+91 8240444587
								</div>
								<div
									className="new-web-desc"
									style={{ color: "white" }}
								>
									support@gharwalah.com
								</div>
							</div>

							<div
								style={{
									display: "flex",
									flexDirection: "column",
									gap: "10px",
									width: "500px",
									marginTop: "10px",
								}}
								className="rent-now-furniture-price-container"
							>
								<div
									style={{
										display: "flex",
										width: "500px",
										justifyContent: "space-between",
									}}
									className="rent-now-furniture-price-detail"
								>
									<div
										style={{
											width: "60%",
											fontSize: "24px",
											fontWeight: "bolder",
										}}
										className="each-furniture-price-desc"
									>
										3 Seater sofa
									</div>
									<div
										style={{
											width: "40%",
											fontSize: "24px",
											fontWeight: "bolder",
											color: "#9a291b",
										}}
										className="each-furniture-price-desc"
									>
										₹ 679 / mo.
									</div>
								</div>

								<div
									style={{
										display: "flex",
										width: "500px",
										justifyContent: "space-between",
									}}
									className="rent-now-furniture-price-detail"
								>
									<div
										style={{
											width: "60%",
											fontSize: "24px",
											fontWeight: "bolder",
										}}
										className="each-furniture-price-desc"
									>
										2 Burner cooking oven
									</div>
									<div
										style={{
											width: "40%",
											fontSize: "24px",
											fontWeight: "bolder",
											color: "#9a291b",
										}}
										className="each-furniture-price-desc"
									>
										₹ 197 / mo.
									</div>
								</div>

								<div
									style={{
										display: "flex",
										width: "500px",
										justifyContent: "space-between",
									}}
									className="rent-now-furniture-price-detail"
								>
									<div
										style={{
											width: "60%",
											fontSize: "24px",
											fontWeight: "bolder",
										}}
										className="each-furniture-price-desc"
									>
										Ceiling fan
									</div>
									<div
										style={{
											width: "40%",
											fontSize: "24px",
											fontWeight: "bolder",
											color: "#9a291b",
										}}
										className="each-furniture-price-desc"
									>
										₹ 139 / mo.
									</div>
								</div>

								<div
									style={{
										display: "flex",
										width: "500px",
										justifyContent: "space-between",
									}}
									className="rent-now-furniture-price-detail"
								>
									<div
										style={{
											width: "60%",
											fontSize: "24px",
											fontWeight: "bolder",
										}}
										className="each-furniture-price-desc"
									>
										Study table
									</div>
									<div
										style={{
											width: "40%",
											fontSize: "24px",
											fontWeight: "bolder",
											color: "#9a291b",
										}}
										className="each-furniture-price-desc"
									>
										₹ 139 / mo.
									</div>
								</div>

								<div
									style={{
										display: "flex",
										width: "500px",
										justifyContent: "space-between",
									}}
									className="rent-now-furniture-price-detail"
								>
									<div
										style={{
											width: "60%",
											fontSize: "24px",
											fontWeight: "bolder",
										}}
										className="each-furniture-price-desc"
									>
										Office(revolving) chair
									</div>
									<div
										style={{
											width: "40%",
											fontSize: "24px",
											fontWeight: "bolder",
											color: "#9a291b",
										}}
										className="each-furniture-price-desc"
									>
										₹ 189 / mo.
									</div>
								</div>

								<div
									style={{
										display: "flex",
										width: "500px",
										justifyContent: "space-between",
									}}
									className="rent-now-furniture-price-detail"
								>
									<div
										style={{
											width: "60%",
											fontSize: "24px",
											fontWeight: "bolder",
										}}
										className="each-furniture-price-desc"
									>
										Fridge (single door)
									</div>
									<div
										style={{
											width: "40%",
											fontSize: "24px",
											fontWeight: "bolder",
											color: "#9a291b",
										}}
										className="each-furniture-price-desc"
									>
										₹ 529 / mo.
									</div>
								</div>

								<div
									style={{
										display: "flex",
										width: "500px",
										justifyContent: "space-between",
									}}
									className="rent-now-furniture-price-detail"
								>
									<div
										style={{
											width: "60%",
											fontSize: "24px",
											fontWeight: "bolder",
										}}
										className="each-furniture-price-desc"
									>
										AC (split)
									</div>
									<div
										style={{
											width: "40%",
											fontSize: "24px",
											fontWeight: "bolder",
											color: "#9a291b",
										}}
										className="each-furniture-price-desc"
									>
										₹ 1249 / mo.
									</div>
								</div>
							</div>
						</div>

						
						<div className="overlay"></div>
					</div> */}

					{/* <div className="slide-container gradient-2">
						<div className="content">
							<div className="title">
								GET THE most affordable <br></br> REFRIGERATOR
								ON RENT AT <br></br>{" "}
								<span style={{ color: "yellow" }}>
									₹ 529/mo.
								</span>{" "}
								onwards in Kolkata
							</div>

							<div className="banner-1-text">
								<div className="address-container">
									<Link
										to="/furniture"
										className="btn rent-now-btn-3"
									>
										Rent Now
									</Link>

									<div>
										<div style={{ color: "white" }}>
											www.gharwalah.com
										</div>
										<div style={{ color: "#FFDB58" }}>
											+91 8240444587
										</div>
										<div style={{ color: "white" }}>
											support@gharwalah.com
										</div>
									</div>
								</div>
								<div></div>
							</div>
						</div>
						<img src={heroart6} alt="" className="image" />
					</div> */}

					

					<div className="slide-container gradient-4">
						<div className="content">
							<div className="title">
								{/* Services For Your <br /> Domestic Needs */}
								Refer Now and Earn Money
							</div>
							{/* <div className='subtitle'>Gharwala, Your Seamless Housing Solution.</div> */}

							<Link to="/refer-now" className="btn refer-now-btn">
								REFER NOW
							</Link>

							<div style={{ color: "white" }}>
								www.gharwalah.com
							</div>
							<div style={{ color: "#FFDB58" }}>
								+91 8240444587
							</div>
							<div style={{ color: "white" }}>
								support@gharwalah.com
							</div>
						</div>
						<div className="flex">
							<img className="girl-sound" src={heroart10}></img>
							<img src={heroart9} alt="" className="image33" />
						</div>
						<div className="overlay"></div>
					</div>
				</Carousel>
			</div>

			<ModalSignUp
				state={closeModal}
				stateFunc={setCloseModal}
				title="Gharwala Registration"
			>
				<Signup
					setSignupModal={setCloseModal}
					isPropertyFree={isPropertyFree}
					setCloseModal={setCloseLoginModal}
				/>
			</ModalSignUp>

			<ModalSignUp
				state={firstPropty}
				stateFunc={() => setFirstPropty(false)}
				title="Choose profession"
			>
				<Profession
					user={user}
					isPropertyFree={firstPropty}
					setCloseModal={() => setFirstPropty(false)}
				/>
			</ModalSignUp>
		</React.Fragment>
	);
};

export default HomeHeroSection;
