import { Button, Container, Input, InputAdornment, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, Box, InputLabel, NativeSelect } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import classes from '../../styles/styles.js';
import { shadows } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTheme } from '@mui/material/styles';
import OutlinedFormInput from '../Input/OutlinedInput.js';
import publicReq, { API_KEY, privateReq } from '../../config/axiosConfig.js';
import ReactGoogleAutocomplete, { usePlacesWidget } from 'react-google-autocomplete';
import { useNavigate } from 'react-router-dom';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { servicesArr } from '../../util/json/helpers/index.js';
import './search.css';
import toast from 'react-hot-toast';

export default function SearchBar() {
  // const classes = useStyles();
  const { ref } = usePlacesWidget({
    apiKey: API_KEY,
    options: {
      componentRestrictions: { country: 'in' },
      types: ['(regions)'],
    },
    // libraries: ["regions", "places", "cities"],
    onPlaceSelected: (place) => {
      setDistrict([place.geometry.location.lng(), place.geometry.location.lat()]);
    },
  });

  const placesRef = useRef();
  const navigate = useNavigate();
  const theme = useTheme();
  const [districtopen, setdistrictopen] = useState(false);
  const [propertyopen, setpropertyopen] = useState(false);
  const [district, setDistrict] = useState('');
  const [searchIndex, setSearchIndex] = useState(0);
  const [category, setCategory] = useState([]);
  const [productType, setProductType] = useState([]);

  const [serviceNameOpen, setServiceNameOpen] = useState('');
  const [serviceType, setServiceType] = useState('rent');
  const [maxPrice, setMaxPrice] = useState('');
  const [maxPriceOpen, setMaxPriceOpen] = useState(false);
  const [propertyType, setpropertyType] = useState('');
  const [serviceTypeOpen, setServiceTypeOpen] = useState(false);
  const [serviceName, setServiceName] = useState('');
  const [buyOrRent, setBuyOrRent] = useState("rent");

  const [categoryData, setCategoryData] = useState({
    Category: [],
  });

  useEffect(() => {
    onGetCategory();
  }, []);

  const onGetCategory = async () => {
    try {
      const res = await privateReq.get("get-category");
      const rentData = res?.data?.category.filter(
        (item) => item.type.toLowerCase() === buyOrRent.toLowerCase()
      );
      setCategoryData((prevState) => ({
        ...prevState,
        Category: rentData,
      }));
    } catch (err) {
      console.log(err);
    }
  };


  const getLocLatLng = () => {
    return geocodeByAddress(district?.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log('Successfully got latitude and longitude', { lat, lng });
        return { lat, lng };
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onSearch = async () => {
    let obj = await getLocLatLng();
    if (searchIndex === 0) {
      let query = `propertyType=${propertyType}&wantto=${serviceType}&max=${maxPrice}&latitude=${obj?.lat}&longitude=${obj?.lng}`;
      navigate(`/properties?${query}`);
    } else if (searchIndex === 1) {
      let query = `category=${propertyType}&productType=${serviceType}`;
      navigate(`/furniture?${query}`);
    } else {
      let query = `serviceName=${serviceName}&latitude=${obj?.lat}&longitude=${obj?.lng}`;
      navigate(`/other-services?${query}`);
    }
  };

  console.log(searchIndex);

  const [showProperty, setShowProperty] = useState(false);
  const [showServices, setShowServices] = useState(false);

  async function checkTabS() {
    try {
      const result = await publicReq.post('/get-access-for-basic-features', {
        propertyName: 'PROPERTY',
      });

      // const results = await Promise.all(promises);
      console.log('tab', result);
      setShowProperty(result.data.message.enabled);

      // setBroker(results[0].data.message.enabled);
      // setOwner(results[1].data.message.enabled);
      // setService(results[2].data.message.enabled);
      // setProperty(results[3].data.message.enabled);
    } catch (error) {
      toast.error('something went wrong');
      console.log(error);
    }
  }
  async function checkTabS2() {
    try {
      const result = await publicReq.post('/get-access-for-basic-features', {
        propertyName: 'SERVICES',
      });

      // const results = await Promise.all(promises);
      setShowServices(result.data.message.enabled);

      // setBroker(results[0].data.message.enabled);
      // setOwner(results[1].data.message.enabled);
      // setService(results[2].data.message.enabled);
      // setProperty(results[3].data.message.enabled);
    } catch (error) {
      toast.error('something went wrong');
      console.log(error);
    }
  }

  useEffect(() => {
    checkTabS();
    checkTabS2();
  }, []);

  return (
    <Container sx={[classes.searchBarContainer, { mt: 20 }]}>
      <Box
        // fullWidth
        sx={{
          width: '100%',
          marginTop: '-75px',
          bottom: 0,
          top: '40rem',
          width: 'initial',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          className='search-bar-container'
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
            marginTop: '20px',
          }}
        >
          {showProperty && (<Button
            onClick={() => setSearchIndex(0)}
            sx={[
              classes.searcButtons,
              searchIndex !== 0 && classes.searcButtonsProperties,
              {
                borderRadius: '16px',
                margin: '5px',
                border: '2px solid #9a291b',
                backgroundColor: searchIndex === 0 ? '#9A291B' : '#FFF5E3',
                color: searchIndex === 0 ? 'white' : 'black',
              },
              {
                [theme.breakpoints.only('xs')]: [classes.searcButtonsEsm],
              },
            ]}
          >
            Properties
          </Button>)}
          {/* <Button
            onClick={() => setSearchIndex(1)}
            sx={[
              classes.searcButtons,
              searchIndex !== 1 && classes.searcButtonsProperties,
              {
                borderRadius: '16px',
                margin: '5px',
                border: '2px solid #9a291b',
                backgroundColor: searchIndex === 1 ? '#9A291B' : '#FFF5E3',
                color: searchIndex === 1 ? 'white' : 'black',
              },

              {
                [theme.breakpoints.only('xs')]: [classes.searcButtonsEsm],
              },
            ]}
          >
            Furniture & Home Appliances
          </Button> */}
          {showServices && (<Button
            onClick={() => setSearchIndex(2)}
            sx={[
              classes.searcButtons,
              searchIndex !== 2 && classes.searcButtonsProperties,
              {
                borderRadius: '16px',
                margin: '5px',
                border: '2px solid #9a291b',
                backgroundColor: searchIndex === 2 ? '#9A291B' : '#FFF5E3',
                color: searchIndex === 2 ? 'white' : 'black',
              },
              {
                [theme.breakpoints.only('xs')]: [classes.searcButtonsEsm],
              },
            ]}
          >
            Services
          </Button>)}
        </Box>
        <Box
          sx={{
            display: 'flex',
            boxShadow: '0px 12px 41px rgba(0, 0, 0, 0.15)',
            ...classes.searchBarContainer,
          }}
        >
          <Grid container>
            <Grid
              container
              item
              xs={10}
              md={10}
              lg={11}
            >
              <Grid
                item
                xs={3}
                md={3}
                lg={3}
              >
                <div sx='search-select-icon'>
                  {searchIndex !== 2 && (
                    <>
                      <GooglePlacesAutocomplete
                        apiKey={API_KEY}
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: ['in'],
                          },
                        }}
                        selectProps={{
                          district: district,
                          onChange: setDistrict,
                          placeholder: 'Enter City, Locality',
                        }}
                      />
                    </>
                  )}
                </div>
              </Grid>
              {searchIndex !== 2 ? (
                <Grid
                  item
                  xs={3}
                  md={3}
                  lg={3}
                >
                  <div sx='search-select-icon'>
                    <Button
                      variant={'text'}
                      className='w-100'
                      sx={[
                        classes.selectContainer,
                        {
                          [theme.breakpoints.between('sm', 'md')]: [classes.selectContainerSm],
                        },
                        {
                          [theme.breakpoints.only('xs')]: [classes.selectContainerESm],
                        },
                      ]}
                      endIcon={<KeyboardArrowDownIcon sx={{ color: '#979797' }} />}
                      onClick={() => {
                        setServiceTypeOpen(!serviceTypeOpen);
                      }}
                    >
                      {searchIndex === 0 ? 'Want To' : 'Service Type'}
                    </Button>
                  </div>
                  <Select
                    className='w-100 text-center text-capitalize'
                    onChange={(e) => setServiceType(e.target.value)}
                    onClose={() => setServiceTypeOpen(false)}
                    onOpen={() => setServiceTypeOpen(true)}
                    value={serviceType}
                    open={serviceTypeOpen}
                    sx={{
                      color: 'rgba(0, 0, 0, 0.87)',
                      '.MuiOutlinedInput-notchedOutline': {
                        border: 0,
                      },
                    }}
                    inputProps={{
                      name: 'district',
                      id: 'uncontrolled-native',
                      IconComponent: () => null,
                      sx: {
                        padding: '0px 14px',
                      },
                    }}
                    MenuProps={{
                      style: {
                        marginLeft: 30,
                      },
                      disableScrollLock: true,
                    }}
                  >
                    <MenuItem value={''}>Select</MenuItem>
                    {(searchIndex === 0 ? ['rent', 'buy',] : ['rent']).map((item) => (
                      <MenuItem
                        className='text-capitalize'
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={3}
                  md={3}
                  lg={3}
                  className='mx-2'
                >
                  <div sx='search-select-icon'>
                    <Button
                      variant={'text'}
                      className='w-100'
                      sx={[
                        classes.selectContainer,
                        {
                          [theme.breakpoints.between('sm', 'md')]: [classes.selectContainerSm],
                        },
                        {
                          [theme.breakpoints.only('xs')]: [classes.selectContainerESm],
                        },
                      ]}
                      endIcon={<KeyboardArrowDownIcon sx={{ color: '#979797' }} />}
                      onClick={() => {
                        setServiceNameOpen(!serviceNameOpen);
                      }}
                    >
                      Service Name
                    </Button>
                  </div>
                  <Select
                    className='w-100 text-center text-capitalize'
                    onChange={(e) => setServiceName(e.target.value)}
                    onClose={() => setServiceNameOpen(false)}
                    onOpen={() => setServiceNameOpen(true)}
                    value={serviceName}
                    open={serviceNameOpen}
                    sx={{
                      color: 'rgba(0, 0, 0, 0.87)',
                      '.MuiOutlinedInput-notchedOutline': {
                        border: 0,
                      },
                    }}
                    inputProps={{
                      name: 'district',
                      id: 'uncontrolled-native',
                      IconComponent: () => null,
                      sx: {
                        padding: '0px 14px',
                      },
                    }}
                    MenuProps={{
                      style: {
                        marginLeft: 30,
                      },
                      disableScrollLock: true,
                    }}
                  >
                    <MenuItem value={''}>Select</MenuItem>
                    {servicesArr.map((item) => (
                      <MenuItem
                        className='text-capitalize'
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* </div> */}
                </Grid>
              )}

              <Grid
                item
                xs={3}
                md={3}
                lg={3}
              >
                {searchIndex !== 2 ? (
                  <div sx='search-select-icon'>
                    <Button
                      className='w-100'
                      variant={'text'}
                      sx={[
                        classes.selectContainer,
                        {
                          [theme.breakpoints.between('sm', 'md')]: [classes.selectContainerSm],
                        },
                        {
                          [theme.breakpoints.only('xs')]: [classes.selectContainerESm],
                        },
                      ]}
                      endIcon={<KeyboardArrowDownIcon sx={{ color: '#979797' }} />}
                      onClick={() => {
                        setpropertyopen(!propertyopen);
                      }}
                    >
                      {searchIndex === 0 ? 'Property Type' : 'Category'}
                    </Button>
                    <Select
                      label='Property Type'
                      className='w-100 text-center text-capitalize'
                      onChange={(e) => setpropertyType(e.target.value)}
                      onClose={() => setpropertyopen(false)}
                      onOpen={() => setpropertyopen(true)}
                      value={propertyType}
                      open={propertyopen}
                      sx={{
                        color: 'rgba(0, 0, 0, 0.87)',
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      }}
                      inputProps={{
                        IconComponent: () => null,
                        sx: {
                          padding: '0px 14px',
                        },
                      }}
                    >
                      <MenuItem value={''}>Select</MenuItem>
                      {console.log("teset data is thei", searchIndex)}
                      {(searchIndex === 0 ? ( serviceType ==="rent"?  ['flat', 'house', 'villa', 'land', 'pg', 'commercial', 'Flat mate / Room mate'] : ['flat', 'house', 'villa', 'land', 'commercial']  ): categoryData?.Category).map((item) => (
                        <MenuItem
                          className='text-capitalize'
                          value={searchIndex === 0 ? item : item?.name}
                        >
                          {searchIndex === 0 ? item : item?.name}
                        </MenuItem>
                      ))}


                      {/* {categoryData?.Category?.map((item) => {
                        return (
                          <MenuItem
                            className='text-capitalize'
                            value={item?.name}
                          >
                            {item?.name}
                          </MenuItem>
                        );
                      })} */}

                    </Select>
                  </div>
                ) : (
                  <GooglePlacesAutocomplete
                    apiKey={API_KEY}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ['in'],
                      },
                    }}
                    selectProps={{
                      district: district,
                      onChange: setDistrict,
                      placeholder: 'Enter City, Locality',
                    }}
                  />
                )}
              </Grid>
              {searchIndex === 0 && (
                <Grid
                  item
                  xs={3}
                  md={3}
                  lg={3}
                >
                  <div sx='search-select-icon'>
                    <Button
                      variant={'text'}
                      className='w-100'
                      sx={[
                        classes.selectContainer,
                        {
                          [theme.breakpoints.between('sm', 'md')]: [classes.selectContainerSm],
                        },
                        {
                          [theme.breakpoints.only('xs')]: [classes.selectContainerESm],
                        },
                      ]}
                      endIcon={<KeyboardArrowDownIcon sx={{ color: '#979797' }} />}
                      onClick={() => {
                        setMaxPriceOpen(!maxPriceOpen);
                      }}
                    >
                      Max Price
                    </Button>
                  </div>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    // label="Age"
                    open={maxPriceOpen}
                    onChange={(e) => setMaxPrice(e.target.value)}
                    onClose={() => setMaxPriceOpen(false)}
                    onOpen={() => setMaxPriceOpen(true)}
                    className='w-100 text-center'
                    sx={{
                      color: 'rgba(0, 0, 0, 0.87)',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    }}
                    inputProps={{
                      IconComponent: () => null,
                      sx: {
                        padding: '0px 14px',
                      },
                    }}
                  >
                    <MenuItem value={''}>Select</MenuItem>
                    {[10000, 25000, 50000, 75000, 100000, 1000000, 5000000, 10000000, 100000000, 1000000000].map((i) => (
                      <MenuItem value={i}>{Intl.NumberFormat().format(i)}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              item
              xs={2}
              md={2}
              lg={1}
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
              >
                <Button
                  onClick={onSearch}
                  variant='contained'
                  sx={[
                    classes.defualtMarronButton,
                    {
                      height: '68px',
                      width: '68px',
                    },
                    {
                      [theme.breakpoints.between('sm', 'md')]: [
                        classes.defualtMarronButtonSMMD,
                        {
                          height: '68px',
                          width: '68px',
                        },
                      ],
                    },
                    {
                      [theme.breakpoints.only('xs')]: [
                        classes.defualtMarronButtonESM,
                        {
                          height: '50px',
                          width: '50px',
                        },
                      ],
                    },
                  ]}
                >
                  <SearchIcon
                    sx={{
                      height: '28px',
                      width: '28px',
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
