import * as React from "react";
import { BrowserRouter, useParams } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { store } from "../src/app/store";
import RootStackScreen from "./screens/RootstackScreen";
import { Toaster } from "react-hot-toast";
import ModalCredit from "./components/ModalCredits/ModalCredit";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import ScrollToTop from "./components/ScrollToTop";
import {useState, useEffect } from "react";

function App() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const theme = createTheme();
  const { user } = useSelector((state) => state.auth);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [closeLoginModal, setCloseLoginModal] = React.useState(false);
  const params = useParams();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  };

  // React.useEffect(() => {
  //   scrollToTop();
  // }, [params]);

  return (
    <div
    className="container-scrollbar"
    // style={isMobile ? {} : {
    //   overflowY: "scroll",
    //   height: "calc(100vh)",
    //   postiion: "absolute",
    //   zIndex: "99999",
    // }}

      //  style={{ marginTop: "100px", padding: "20px",}}
    >
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <RootStackScreen
              closeLoginModal={closeLoginModal}
              setCloseLoginModal={setCloseLoginModal}
              setOpenPopUp={setOpenPopUp}
            />
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
      <Toaster
        position="top-right"
        containerStyle={{
          zIndex: 99994,
        }}
        toastOptions={{
          style: {
            zIndex: 99994,
          },
        }}
      />

      <div>
        {openPopUp && <ModalCredit setOpenPopUp={setOpenPopUp} user={user} />}
      </div>
    </div>
  );
}

export default App;
