import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import HomeBottomSection from "../../components/HomeBottomSections/HomeBottomSection";
import HomeAbouSection from "../../components/HomeAboutSection/HomeAboutSection";
import { useMediaQuery } from "react-responsive";
import StickyFooterMobile from "../../components/FooterMobile";
import StickyFooter from "../../components/Footer";
import AdminToggleSection from "../../components/AdminToggleSection";
import { checkTabsStatus } from "../../config/user";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import PageNotFound from "../../components/PageNotFound";
import { Link } from "react-router-dom";

const TabsAccess = ({ isLogin }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [showPage, setShowPage] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user !== null) {
      if (user?.role === "admin") {
        setShowPage(true);
      }
    }
  }, []);


  return showPage ? (
    <div>
      
      <Header />
      <AdminToggleSection />
      <HomeAbouSection />
      <HomeBottomSection />

      {isMobile ? <StickyFooterMobile /> : <StickyFooter />}
    </div>
  ) : (
    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh"}}>
      <PageNotFound />
      <Link to="/">
        <button>Go To Home</button>
      </Link>
    </div>
  );
};

export default TabsAccess;
