import {
	Button,
	Container,
	Input,
	InputAdornment,
	TextField,
	Typography,
} from "@mui/material";
import { useState, useRef, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, Box, InputLabel, NativeSelect } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import classes from "../../styles/styles.js";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import ReactGoogleAutocomplete, {
	usePlacesWidget,
} from "react-google-autocomplete";
import publicReq, { API_KEY, privateReq } from "../../config/axiosConfig.js";
import OutlinedFormInput from "../Input/OutlinedInput.js";
import GooglePlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from "react-google-places-autocomplete";
import toast from "react-hot-toast";
export default function SearchBarMobile() {
	const navigate = useNavigate();
	const theme = useTheme();
	const [districtopen, setdistrictopen] = useState(false);
	const [propertyopen, setpropertyopen] = useState(false);
	const [district, setDistrict] = useState("");
	const [searchIndex, setSearchIndex] = useState(0);
	const [category, setCategory] = useState([]);
	const [productType, setProductType] = useState([]);

	const [serviceType, setServiceType] = useState("rent");
	const [maxPrice, setMaxPrice] = useState("");
	const [maxPriceOpen, setMaxPriceOpen] = useState(false);
	const [propertyType, setpropertyType] = useState("");
	const [serviceTypeOpen, setServiceTypeOpen] = useState(false);
	const [serviceName, setServiceName] = useState("");
	const [showProperty, setShowProperty] = useState(false);
	const [showServices, setShowServices] = useState(false);

	async function checkTabS() {
		try {
			const result = await publicReq.post(
				"/get-access-for-basic-features",
				{
					propertyName: "PROPERTY",
				}
			);

			// const results = await Promise.all(promises);
			console.log("tab", result);
			setShowProperty(result.data.message.enabled);

			// setBroker(results[0].data.message.enabled);
			// setOwner(results[1].data.message.enabled);
			// setService(results[2].data.message.enabled);
			// setProperty(results[3].data.message.enabled);
		} catch (error) {
			// toast.error("something went wrong");
			console.log(error);
		}
	}
	async function checkTabS2() {
		try {
			const result = await publicReq.post(
				"/get-access-for-basic-features",
				{
					propertyName: "SERVICES",
				}
			);

			// const results = await Promise.all(promises);
			setShowServices(result.data.message.enabled);

			// setBroker(results[0].data.message.enabled);
			// setOwner(results[1].data.message.enabled);
			// setService(results[2].data.message.enabled);
			// setProperty(results[3].data.message.enabled);
		} catch (error) {
			// toast.error("something went wrong");
			console.log(error);
		}
	}

	useEffect(() => {
		checkTabS();
		checkTabS2();
	}, []);

	const getLocLatLng = () => {
		return geocodeByAddress(district?.label)
			.then((results) => getLatLng(results[0]))
			.then(({ lat, lng }) => {
				// console.log("Successfully got latitude and longitude", { lat, lng });
				return { lat, lng };
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const onSearch = async () => {
		let obj = await getLocLatLng();
		if (searchIndex === 0) {
			let query = `propertyType=${propertyType}&wantto=${serviceType}&latitude=${obj?.lat}&longitude=${obj?.lng}&max=${maxPrice}`;
			navigate(`/properties?${query}`);
		} else if (searchIndex === 1) {
			let query = `category=${propertyType}&productType=${serviceType}`;
			navigate(`/furniture?${query}`);
		} else {
			let query = `serviceName=${serviceName}&latitude=${obj?.lat}&longitude=${obj?.lng}`;
			navigate(`/other-services?${query}`);
		}
	};

	const [buyOrRent, setBuyOrRent] = useState("rent");
	const [categoryData, setCategoryData] = useState({
		Category: [],
	});

	useEffect(() => {
		onGetCategory();
	}, []);

	const onGetCategory = async () => {
		try {
			const res = await privateReq.get("get-category");
			const rentData = res?.data?.category.filter(
				(item) => item.type.toLowerCase() === buyOrRent.toLowerCase()
			);
			setCategoryData((prevState) => ({
				...prevState,
				Category: rentData,
			}));
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Container sx={[classes.searchBarContainerMobile]}>
			<Box
				className="search-bar-container"
				sx={{
					width: "100%",
					display: "flex",
					justifyContent: "center",
					marginBottom: "20px",
				}}
			>
				{showProperty && (
					<Button
						onClick={() => setSearchIndex(0)}
						sx={[
							classes.searcButtons,
							searchIndex !== 0 && classes.searcButtonsProperties,
							{
								borderRadius: "16px",
								margin: "5px",
								border: "2px solid #9a291b",
								backgroundColor:
									searchIndex === 0 ? "#9A291B" : "#FFF5E3",
								color: searchIndex === 0 ? "white" : "black",
							},
							{
								[theme.breakpoints.only("xs")]: [
									classes.searcButtonsEsm,
								],
							},
						]}
					>
						Properties
					</Button>
				)}

				{/* <Button
					onClick={() => setSearchIndex(1)}
					sx={[
						classes.searcButtons,
						searchIndex !== 1 && classes.searcButtonsProperties,
						{
							borderRadius: "16px",
							margin: "5px",
							border: "2px solid #9a291b",
							backgroundColor:
								searchIndex === 1 ? "#9A291B" : "#FFF5E3",
							color: searchIndex === 1 ? "white" : "black",
						},

						{
							[theme.breakpoints.only("xs")]: [
								classes.searcButtonsEsm,
							],
						},
					]}
				>
					Furniture & Home Appliances
				</Button> */}

				{showServices && (
					<Button
						onClick={() => setSearchIndex(2)}
						sx={[
							classes.searcButtons,
							searchIndex !== 2 && classes.searcButtonsProperties,
							{
								borderRadius: "16px",
								margin: "5px",
								border: "2px solid #9a291b",
								backgroundColor:
									searchIndex === 2 ? "#9A291B" : "#FFF5E3",
								color: searchIndex === 2 ? "white" : "black",
							},
							{
								[theme.breakpoints.only("xs")]: [
									classes.searcButtonsEsm,
								],
							},
						]}
					>
						Services
					</Button>
				)}
			</Box>
			<Box
				sx={{
					display: "flex",
					boxShadow: "0px 12px 41px rgba(0, 0, 0, 0.15)",
					...classes.searchBarContainerMobile,
					backgroundColor: "#fff",
				}}
			>
				<Grid container className="p-4">
					<Grid
						container
						item
						xs={6}
						md={12}
						lg={12}
						sx={[
							classes.searchGridItemMobile,
							{
								[theme.breakpoints.between("xs", "sm")]: [
									classes.searchGridItemMobile,
								],
							},
						]}
					>
						<Grid item xs={12} md={2} lg={6}>
							<div sx="search-select-icon">
								{searchIndex === 0 && (
									<>
										<GooglePlacesAutocomplete
											apiKey={API_KEY}
											autocompletionRequest={{
												componentRestrictions: {
													country: ["in"],
												},
											}}
											selectProps={{
												district: district,
												onChange: setDistrict,
												placeholder:
													"Enter City, Locality",
											}}
										/>
									</>
								)}
							</div>
						</Grid>
						{searchIndex !== 2 ? (
							<Grid
								item
								xs={12}
								md={2}
								lg={searchIndex == 0 ? 6 : 12}
							>
								<div sx="search-select-icon">
									<Button
										variant={"text"}
										className="w-100 text-center"
										sx={[classes.selectContainerMobile]}
										endIcon={
											<KeyboardArrowDownIcon
												sx={{ color: "#979797" }}
											/>
										}
										onClick={() => {
											setServiceTypeOpen(
												!serviceTypeOpen
											);
										}}
									>
										{searchIndex === 0
											? "Want To"
											: "Want To"}
									</Button>
								</div>
								<Select
									//   labelId="demo-simple-select-label"
									//   id="demo-simple-select"
									//   label="Age"
									className="w-100 text-center text-capitalize"
									sx={[classes.homeSelectSearch]}
									inputProps={{ IconComponent: () => null }}
									onChange={(e) =>
										setServiceType(e.target.value)
									}
									onClose={() => setServiceTypeOpen(false)}
									onOpen={() => setServiceTypeOpen(true)}
									value={serviceType}
									open={serviceTypeOpen}
									MenuProps={{
										style: {
											marginLeft: 30,
										},
										disableScrollLock: true,
									}}
								>
									<MenuItem value={""}>Select</MenuItem>
									{(searchIndex === 0
										? ["rent", "buy"]
										: ["rent", "buy"]
									).map((item) => (
										<MenuItem
											className="text-capitalize"
											value={
												item === "buy" ? "sell" : item
											}
										>
											{item}
										</MenuItem>
									))}
								</Select>
							</Grid>
						) : (
							<Grid
								item
								xs={12}
								md={2}
								lg={searchIndex == 2 ? 6 : 12}
							>
								<div sx="search-select-icon">
									<OutlinedFormInput
										placeholder={"Service name"}
										value={serviceName}
										onChange={(e) =>
											setServiceName(e.target.value)
										}
										sx={[{ height: "initial" }]}
									/>
								</div>
							</Grid>
						)}
					</Grid>
					<Grid
						container
						item
						xs={6}
						md={12}
						lg={12}
						sx={[
							classes.searchGridItemMobile,
							{
								[theme.breakpoints.between("xs", "sm")]: [
									classes.searchGridItemMobile,
								],
							},
						]}
					>
						{searchIndex !== 2 ? (
							<Grid
								item
								xs={12}
								md={12}
								lg={searchIndex == 0 ? 6 : 12}
							>
								<div sx="search-select-icon">
									<Button
										variant={"text"}
										className="w-100 text-center"
										sx={[classes.selectContainerMobile]}
										endIcon={
											<KeyboardArrowDownIcon
												sx={{ color: "#979797" }}
											/>
										}
										onClick={() => {
											setpropertyopen(!propertyopen);
										}}
									>
										{searchIndex === 0
											? "Property Type"
											: "Category"}
									</Button>
								</div>
								<Select
									//   labelId="demo-simple-select-label"
									//   id="demo-simple-select"
									className="w-100 text-center text-capitalize"
									//   label="Age"
									sx={[classes.homeSelectSearch]}
									onChange={(e) =>
										setpropertyType(e.target.value)
									}
									onClose={() => setpropertyopen(false)}
									onOpen={() => setpropertyopen(true)}
									value={propertyType}
									open={propertyopen}
									inputProps={{ IconComponent: () => null }}
									MenuProps={{
										style: {
											marginLeft: 30,
										},
										disableScrollLock: true,
									}}
								>
									<MenuItem value={""}>Select</MenuItem>
									{(searchIndex === 0
										? [
												"flat",
												"house",
												"villa",
												"land",
												"pg",
												"commercial",
												"Flat mate / Room mate",
										  ]
										: [
												"Mattress",
												"AC",
												"Sofa",
												"Fridge",
												"Table",
										  ]
									).map((item) => (
										<MenuItem
											className="text-capitalize"
											value={item}
										>
											{item}
										</MenuItem>
									))}
								</Select>
							</Grid>
						) : (
							<Grid
								className="mx-1"
								item
								xs={12}
								md={12}
								lg={searchIndex == 2 ? 6 : 12}
							>
								<div sx="search-select-icon">
									<GooglePlacesAutocomplete
										apiKey={API_KEY}
										autocompletionRequest={{
											componentRestrictions: {
												country: ["in"],
											},
										}}
										selectProps={{
											district: district,
											onChange: setDistrict,
											placeholder: "Enter City, Locality",
										}}
									/>
								</div>
							</Grid>
						)}
						{searchIndex === 0 && (
							<Grid item xs={12} md={12} lg={6}>
								<div sx="search-select-icon">
									<Button
										variant={"text"}
										className="w-100 text-center"
										sx={[classes.selectContainerMobile]}
										endIcon={
											<KeyboardArrowDownIcon
												sx={{ color: "#979797" }}
											/>
										}
										onClick={() => {
											setMaxPriceOpen(!maxPriceOpen);
										}}
									>
										Max Price
									</Button>
								</div>
								<Select
									// labelId="demo-simple-select-label"
									// id="demo-simple-select"
									className="w-100 text-center text-capitalize"
									sx={[classes.homeSelectSearch]}
									inputProps={{ IconComponent: () => null }}
									open={maxPriceOpen}
									onChange={(e) =>
										setMaxPrice(e.target.value)
									}
									onClose={() => setMaxPriceOpen(false)}
									onOpen={() => setMaxPriceOpen(true)}
									MenuProps={{
										style: {
											marginLeft: 30,
										},
										disableScrollLock: true,
									}}
								>
									<MenuItem value={""}>Select</MenuItem>
									{[
										10000, 25000, 50000, 75000, 100000,
										1000000, 5000000, 10000000,
									].map((i) => (
										<MenuItem value={i}>
											{Intl.NumberFormat().format(i)}
										</MenuItem>
									))}
								</Select>
							</Grid>
						)}
					</Grid>
					<Grid container item xs={12} md={12} lg={12}>
						<Grid container item xs={12} md={12} lg={12}>
							<Grid item xs={12} md={12} lg={12}>
								<Button
									onClick={onSearch}
									variant="contained"
									className="w-100"
									sx={[
										classes.defualtMarronButton,
										{
											height: "40px",
											// width: "90%",
											borderRadius: "5px",
											alignSelf: "center",
										},
									]}
								>
									<Typography>Search</Typography>
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
}
