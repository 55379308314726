import {
    SET_SERVICES
} from './types';

import {
    getAllOtherServicesApi
} from '../../config/otherservices';

export const getAllOtherServicesAction = (data) => async (dispatch) => {
    try {
        const res = await getAllOtherServicesApi(data);

        dispatch({
            type: SET_SERVICES,
            payload: res.data.services,
        });
    } catch (e) {
        console.log(e);
    }
};