import React from "react";
import PageErrorImage from "../../assets/images/page-not-found.png";

const PageNotFound = () => {
    return (
        <>
         <img style={{width: "700px"}} src={PageErrorImage} alt="page-not-found" />
        </>
    )
}

export default PageNotFound;