import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { getAllFurnitureService } from '../../../config/furniture';
import { getFurnitures } from '../../../redux/action/furniture';
import { useDispatch, useSelector } from 'react-redux';

const FormContractDetails = () => {
  const [
    role,
    setRole,
    ownerData,
    setOwnerData,
    tenantData,
    setTenantData,
    propertyDetails,
    setPropertyDetails,
    contractDetails,
    setContractDetails,
    furnitureId,
    setFurnitureId,
    furnishedDetails,
    setFurnishedDetails,
  ] = useOutletContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { furnitures } = useSelector((state) => state.furniture);

  useEffect(() => {
    getFurniture();
  }, []);

  console.log(contractDetails);

  const getFurniture = async () => {
    if (localStorage.getItem('token') && propertyDetails?.type === 'furniture') {
      dispatch(getFurnitures(''));
    }
  };
  useEffect(() => {
    if (!verify(ownerData) && !verify(tenantData)) {
      navigate('/agreement/basic-details');
    }

    for (let item in ownerData) {
      if (!verify(ownerData[item])) {
        navigate('/agreement/basic-details');
      }
    }

    for (let item in tenantData) {
      if (!verify(tenantData[item])) {
        navigate('/agreement/basic-details');
      }
    }
  }, []);

  const verify = (data) => {
    for (let key in data) {
      if (data[key] === null || data[key] === undefined || data[key] === '') {
        return false;
      }
    }
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContractDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleFurnitureChange = (e) => {
    const selectedFurnitureId = e;
    console.log('selectedFurnitureId', selectedFurnitureId);
    setFurnitureId(selectedFurnitureId);
  };
  return (
    <>
      <div className='header'>Contract Details</div>
      <form className='form'>
        <div className='field-container'>
          <div style={{ display: 'flex', gap: '60px' }}>
            <div>
              <label
                style={{ display: 'block' }}
                htmlFor='startDate'
              >
                Start Date
              </label>
              <input
                id='startDate'
                type='date'
                name='startDate'
                value={contractDetails.startDate}
                onChange={handleChange}
              />
            </div>
            <div>
              <label
                style={{ display: 'block' }}
                htmlFor='duration'
              >
                Duration
              </label>
              <select
                htmlFor='duration'
                name='duration'
                onChange={handleChange}
                value={contractDetails.duration}
                style={{ width: 'auto' }}
              >
                <option
                  value=''
                  hidden
                  selected
                >
                  Months
                </option>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
                <option value='6'>6</option>
                <option value='7'>7</option>
                <option value='8'>8</option>
                <option value='9'>9</option>
                <option value='10'>10</option>
                <option value='11'>11</option>
                <option value='12'>12</option>
                <option value='13'>13</option>
                <option value='14'>14</option>
                <option value='15'>15</option>
                <option value='16'>16</option>
                <option value='17'>17</option>
                <option value='18'>18</option>
                <option value='19'>19</option>
                <option value='20'>20</option>
                <option value='21'>21</option>
                <option value='22'>22</option>
                <option value='23'>23</option>
                <option value='24'>24</option>
                <option value='25'>25</option>
                <option value='26'>26</option>
                <option value='27'>27</option>
                <option value='28'>28</option>
                <option value='29'>29</option>
                <option value='30'>30</option>
                <option value='31'>31</option>
                <option value='32'>32</option>
                <option value='33'>33</option>
                <option value='34'>34</option>
                <option value='35'>35</option>
                <option value='36'>36</option>
                <option value='37'>37</option>
                <option value='38'>38</option>
                <option value='39'>39</option>
                <option value='40'>40</option>
                <option value='41'>41</option>
                <option value='42'>42</option>
                <option value='43'>43</option>
                <option value='44'>44</option>
                <option value='45'>45</option>
                <option value='46'>46</option>
                <option value='47'>47</option>
                <option value='48'>48</option>
                <option value='49'>49</option>
                <option value='50'>50</option>
                <option value='51'>51</option>
                <option value='52'>52</option>
                <option value='53'>53</option>
                <option value='54'>54</option>
                <option value='55'>55</option>
                <option value='56'>56</option>
                <option value='57'>57</option>
                <option value='58'>58</option>
                <option value='59'>59</option>
                <option value='60'>60</option>

              </select>
            </div>

            <div>
              <label
                style={{ display: 'block' }}
                htmlFor='endDate'
              >
                End Date
              </label>
              <input
                id='endDate'
                type='date'
                name='endDate'
                value={contractDetails.endDate}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        
        <div className='field-container'>
          <div>
            <label
              style={{ display: 'block' }}
              htmlFor='rentPaymentDate'
            >
              Rent Payment Date
            </label>
            <select
              id='rentPaymentDate'
              name='rentPaymentDate'
              onChange={handleChange}
              value={contractDetails.rentPaymentDate}
              style={{ width: 'auto' }}
            >
              <option value='1st of every month'>1st of every month</option>
              <option value='2nd of every month'>2nd of every month</option>
              <option value='3rd of every month'>3rd of every month</option>
              <option value='4th of every month'>4th of every month</option>
              <option value='5th of every month'>5th of every month</option>
              <option value='6th of every month'>6th of every month</option>
              <option value='7th of every month'>7th of every month</option>
              <option value='Last day of every month'>Last day of every month</option>
            </select>
          </div>
        </div>

        <div style={{ display: 'flex', gap: '20px' }}>
          <div>
            <label
              display={{ display: 'block' }}
              htmlFor='rentAmount'
            >
              Rent Amount
            </label>
            <input
              id='rentAmount'
              type='text'
              name='rentAmount'
              value={contractDetails.rentAmount}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                handleChange(e);
              }}
              placeholder='Monthly Rent amount'
            />
          </div>

          <div>
            <label
              display={{ display: 'block' }}
              htmlFor='rentAmountInWords'
            >
              Rent Amount (in words)
            </label>
            
            <input
              id='rentAmountInWords'
              type='text'
              name='rentAmountInWords'
              value={contractDetails.rentAmountInWords}
              onChange={handleChange}
              placeholder='Monthly Rent amount'
            /> 
          </div>

        </div>

        <div style={{ display: 'flex', gap: '20px', marginTop: "20px" }}>
          <div>
            <select
              id='maintenanceChoice'
              name='maintenanceChoice'
              onChange={handleChange}
              value={contractDetails.maintenanceChoice}
              style={{ width: 'auto', border: "1px solid black", borderRadius: "8px" }}
            >
              <option value='including'>including</option>
              <option value='excluding'>excluding</option>
            </select>
          </div>

          <div>
            <label
              display={{ display: 'block' }}
              htmlFor='maintenanceCharges'
            >
              Maintenance Charges
            </label>
            <input
              type='text'
              name='maintenanceCharges'
              value={contractDetails.maintenanceCharges}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                handleChange(e);
              }}
              placeholder='Maintenance Charges(optional)'
            />
          </div>
        </div>

        <div style={{ display: 'flex', gap: '20px' }}>
          <div>
            <label
              display={{ display: 'block' }}
              htmlFor='escalationPrice'
            >
              Escalation Prices/Charges (%)
            </label>
            <input
              type='text'
              name='escalationPrice'
              value={contractDetails.escalationPrice}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                handleChange(e);
              }}
              placeholder='Escalation Price'
            />
          </div>

          <div>
            <label
              display={{ display: 'block' }}
              htmlFor='escalationPriceAmount'
            >
              Escalation Prices/Charges(amount)
            </label>
            <input
              type='text'
              name='escalationPriceAmount'
              value={contractDetails.escalationPriceAmount}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                handleChange(e);
              }}
              placeholder='Escalation Price'
            />
          </div>
        </div>

        <div style={{ display: 'flex', gap: '20px' }}>
          <div>
            <label
              style={{ display: 'block' }}
              htmlFor='securityDeposit'
            >
              Security Deposit
            </label>
            <input
              id='securityDeposit'
              type='text'
              name='securityDeposit'
              value={contractDetails.securityDeposit}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                handleChange(e);
              }}
              placeholder='Security Deposit'
            />
          </div>

          <div>
            <label
              style={{ display: 'block' }}
              htmlFor='securityDepositInWords'
            >
              Security Deposit (in words)
            </label>
            <input
              id='securityDepositInWords'
              type='text'
              name='securityDepositInWords'
              value={contractDetails.securityDepositInWords}
              onChange={handleChange}
              placeholder='Security Deposit'
            />
          </div>
        </div>

        <div style={{ display: 'flex', gap: '20px' }}>
          <div>
            <label
              style={{ display: 'block' }}
              htmlFor='noticePeriod'
            >
              Notice Period
            </label>
            <select
              id='noticePeriod'
              name='noticePeriod'
              onChange={handleChange}
              style={{ width: 'auto' }}
              value={contractDetails.noticePeriod}
            >
              <option
                value=''
                hidden
                selected
              >
                Months
              </option>
              <option value='1'>1</option>
              <option value='2'>2</option>
              <option value='3'>3</option>
              <option value='4'>4</option>
              <option value='5'>5</option>
              <option value='6'>6</option>
            </select>
          </div>
        </div>

        {localStorage.getItem('token') && propertyDetails?.type === 'furniture' && (
          <div>
            <label
              style={{ display: 'block' }}
              htmlFor='noticePeriod'
            >
              Select Furniture
            </label>
            <div>
              {furnitures?.data?.map((furniture) => (
                <div key={furniture._id}>
                  <input
                    type='radio'
                    id={`furniture_${furniture._id}`}
                    name='furniture'
                    value={furniture.i_idd}
                    checked={furniture._id === furnitureId}
                    onChange={() => {
                      handleFurnitureChange(furniture._id);
                    }}
                  />
                  <label htmlFor={`furniture_${furniture._id}`}>{furniture.furnitureName}</label>
                </div>
              ))}
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default FormContractDetails;
