import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Avatar } from "@mui/material";
import { assetUrl } from "../../config/axiosConfig";
import { useMediaQuery } from "react-responsive";
import {
	getKyc,
	updateKyc,
	updateProfileService,
	uploadImage,
} from "../../config/user";
import toast from "react-hot-toast";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Header from "../../components/Header";
import axios from "axios";

export default function Profile({
	closeLoginModal,
	setCloseLoginModal,
	setOpenPopUp,
	closeProfileModal,
}) {
	const { user } = useSelector((state) => state.auth);
	const [loader, setLoader] = useState(false);
	const isMobile = useMediaQuery({ maxWidth: 767 });
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [referralPoints, setReferralPoints] = useState(0);
	const [disabled, setDisabled] = useState(true);

	const handleImages = (e) => {};
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [pinCode, setPinCode] = useState();
	const [desc, setDesc] = useState();
	const [language, setLanguage] = useState();
	const [phone, setPhone] = useState("");
	const [address, setAddress] = useState("");
	const [addressProof, setAddressProof] = useState("");
	const [referralCode, setReferralCode] = useState("");
	const [isPanCard, setIsPanCard] = useState(true);
	const [panCard, setPanCard] = useState("");
	const [selfie, setSelfie] = useState("");
	const [bankStatement, setBankStatement] = useState("");
	const [aadharCard, setAadharCard] = useState("");
  const [lesseeName, setLesseeName] = useState("");
  const [lesseeType, setLesseeType] = useState("");
  const [lesseePhone, setLesseePhone] = useState("");
  const [mobileNumberLesse, setMobileNumberLesse] = useState("");
	const [adharNumber, setAdharNumber] = useState("");
	const [deliveryAddress, setDeliveryAddress] = useState({
		address: "",
		houseNo: "",
		street: "",
		city: "",
		state: "",
		pinCode: "",
	});
	const [contactNumber, setContactNumber] = useState("");
	const [isCheckBox, setCheckBox] = useState(false);

	const [avatarImage, setAvtarImage] = useState({});
	const [imageUrls, setImageUrls] = useState([]);
	const [images, setImages] = useState({});
	const [sidebarOption, setSidebarOption] = useState("profile");

	const [userDetails, setUserDetails] = useState(user);

	const [data, setData] = useState({
		photo: {},
	});

	useEffect(() => {
		if (user === null) {
			setCloseLoginModal(true);
		}
	}, []);

	useEffect(() => {
		setUserDetails(user);
	}, [user]);

	const onSubmit = async (e) => {
		e.preventDefault();

		try {
			if (images.length === 0) {
				toast.error("ID Proof images are required");
				return;
			}
			setLoader(true);

			const obj = {
				name,
				phone,
				email,
				pinCode,
				userLanguage: language,
				desc,
				address,
				idProof: images,
			};

			const formData = new FormData();
			for (let key in obj) {
				if (key === "idProof") {
					let images = Array.from(obj[key]);
					for (let i = 0; i < images.length; i++) {
						formData.append("idProof", images[i]);
					}
				} else if (Array.isArray(obj[key])) {
					obj[key].forEach((item) => {
						formData.append(key, item);
					});
				} else {
					formData.append(key, obj[key]);
				}
			}

			// Retrieve JWT token
			const token = localStorage.getItem("token");

			// Check if the token is present
			if (!token) {
				setLoader(false);
				toast.error("No JWT token found. Please log in.");
				return;
			}

			const config = {
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "multipart/form-data", // Ensure proper content type for FormData
				},
			};

			// Make POST request to backend
			const res = await axios.post(
				"https://gharwala-5466fddd6458.herokuapp.com/api/profile/edit",
				formData,
				config
			);

			if (res.data.success) {
				setLoader(false);
				toast.success("Profile Updated Successfully");
				navigate("/");
				window.location.reload();
			}
		} catch (err) {
			setLoader(false);
			if (err.response) {
				console.log("Error response data:", err.response.data);
				console.log("Error response status:", err.response.status);
				console.log("Error response headers:", err.response.headers);
			} else if (err.request) {
				console.log("Error request:", err.request);
			} else {
				console.log("Error message:", err.message);
			}
			console.log(err.config);
		}
	};

	const onSubmitKyc = async (e) => {
		e.preventDefault();
		if (isPanCard && !panCard) {
			toast.error("Please upload pan card image");
			return;
		}
		if (!aadharCard) {
			toast.error("Please upload aadhar card image");
			return;
		}
		if (!selfie) {
			toast.error("Please upload selfie image");
			return;
		}
		if (!deliveryAddress.address) {
			toast.error("Please enter address");
			return;
		}
		if (!deliveryAddress.houseNo) {
			toast.error("Please enter house no");
			return;
		}
		if (!adharNumber) {
			toast.error("Please enter adhar no");
			return;
		}
		if (!deliveryAddress.street) {
			toast.error("Please enter street");
			return;
		}
		if (!deliveryAddress.city) {
			toast.error("Please enter city");
			return;
		}
		if (!deliveryAddress.state) {
			toast.error("Please enter state");
			return;
		}
		if (!deliveryAddress.pinCode) {
			toast.error("Please enter pinCode");
			return;
		}
		if (!contactNumber) {
			toast.error("Please enter contact number");
			return;
		}
		if (!addressProof) {
			toast.error("Please upload address proof image");
			return;
		}
		if (!isCheckBox) {
			toast.error("Please check terms and conditions");
			return;
		}
    if(!lesseeName || !lesseePhone || !lesseeType || !mobileNumberLesse){
      toast.error("Please fill Lessee Information");
      return;
    }

		try {
			setLoader(true);

			const pan = new FormData();
			pan.append("image", panCard);
			const uplaodPan = await uploadImage(pan);

			const AadharCard = new FormData();
			AadharCard.append("image", aadharCard);
			const uplaodAadharCard = await uploadImage(AadharCard);

			const Selfie = new FormData();
			Selfie.append("image", selfie);
			const uplaodSelfie = await uploadImage(Selfie);

			const AddressProof = new FormData();
			AddressProof.append("image", addressProof);
			const uplaodAddressProof = await uploadImage(AddressProof);

			let data = {
				have_pan_card: isPanCard,
				pan_card: uplaodPan.data.imageUrl,
				aadhar_card: uplaodAadharCard.data.imageUrl,
				adhar_number: adharNumber,
				selfie: uplaodSelfie.data.imageUrl,
				address: deliveryAddress.address,
				house_no: deliveryAddress.houseNo,
				street: deliveryAddress.street,
				city: deliveryAddress.city,
				state: deliveryAddress.state,
				pin_code: deliveryAddress.pinCode,
				contact_number: contactNumber,
				address_proof: uplaodAddressProof.data.imageUrl,
        name_of_lessee: lesseeName,
    name_of_lessee_type: lesseeType,
    mobile_number_reference: lesseePhone,
    mobile_number_nominee: mobileNumberLesse,

			};
			console.log(data);
			const res = await updateKyc(data);
			console.log("res", res);
			if (res.status == 201) {
				setLoader(false);
				toast.success("KYC Updated Successfully");
				window.location.reload();
			}
		} catch {
			setLoader(false);
		}
	};

	useEffect(() => {
		setName(user?.name || "");
		setAddress(user?.address || "");
		setPhone(user?.phone || "");
		setEmail(user?.email || "");
		setDesc(user?.desc || "");
		setPinCode(user?.pinCode || "");
		setLanguage(user?.language || "");
		setReferralCode(user?.referral_code);
		setReferralPoints(user?.referral_points);

		console.log("user info ", user);
	}, [closeProfileModal, user]);

	const handleImageChange = (event, field) => {
		const file = event.target.files[0];
		if (file) {
			if (field === "panCard") setPanCard(file);
			else if (field === "addressProof") setAddressProof(file);
			else if (field === "bankStatement") setBankStatement(file);
			else if (field === "aadharCard") setAadharCard(file);
			else if (field === "selfie") setSelfie(file);
			// };
			// reader.readAsDataURL(file);
		}
	};

	const handleDeliveryAddressChange = (e) => {
		const { name, value } = e.target;
		setDeliveryAddress((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleDelete = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.delete(
				"https://gharwala-5466fddd6458.herokuapp.com/api/profile",
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem(
							"token"
						)}`,
					},
				}
			);
			if (response.status === 200) {
				toast.success("Profile deleted successfully");
				localStorage.removeItem("token");
				navigate("/");
			}
		} catch (error) {
			console.error("There was an error deleting the profile!", error);
			if (error.response) {
				console.log("Data:", error.response.data);
				console.log("Status:", error.response.status);
				console.log("Headers:", error.response.headers);
			} else if (error.request) {
				console.log("Request:", error.request);
			} else {
				console.log("Error Message:", error.message);
			}
			alert("Error deleting profile");
		}
	};

	return (
		<>
			{/* <Header closeLoginModal={closeLoginModal} setCloseLoginModal={setCloseLoginModal} setOpenPopUp={setOpenPopUp} /> */}
			<div>
      <Header />
      </div>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "start",
					marginTop: isMobile ? "50px" : "120px",
					gap: "30px",
				}}
			>
				{/* SIDEBAR */}

				{/* MAIN */}
				{sidebarOption === "profile" && (
					<div
						className=""
						style={{
							width: isMobile ? "80%" : "60%",
						}}
					>
						<div className="d-flex align-items-center justify-content-between">
							<div></div>
							<h2
								style={{
									color: "#240501",
									fontWeight: "bold",
									fontSize: "30px",
									textAlign: "center",
								}}
							>
								Profile
							</h2>
							<a
								style={{
									display: "inline-block",
									fontSize: 30,
									color: "#000",
								}}
								href="#"
								onClick={(e) => {
									e.preventDefault();
									//   stateFunc(false);
									navigate(-1);
								}}
							>
								{sidebarOption==="kyc" && <CloseRoundedIcon fontSize="25px" />}
								{/* <i class="fa fa-times" aria-hidden="true"></i> */}
							</a>
						</div>
						<div className="row g-3 align-items-center">
							<div className="col-lg-12 col-md-12 col-sm-12">
								<form
									onSubmit={(e) => {
										onSubmit(e);
									}}
								>
									<div className="mt-4 mb-5">
										<Avatar
											sx={{
												marginTop: "25px",
												marginBottom: "25px",
												height: "48px",
												width: "48px",
												marginLeft: "auto",
												marginRight: "auto",
												// display: "flex",
												// width: "fit-content",
												// padding: "12px 16px",
												borderRadius: "48px",
												// margin: "0px 20px"
											}}
											alt={user?.name}
											src={assetUrl + user?.avatarImage}
										/>
									</div>

									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											height: "calc(100% - 90px)",
											width: "100%",
											gap: "20px",
											marginBottom: "50px",
										}}
									>
										<button
											type="button"
											style={{
												color: "#9B291B",
												width: "48%",
												fontWeight: "bolder",
												fontFamily:
													"Montserrat",
												backgroundColor: "transparent",
												padding: "10px",
												borderRadius: "15px",
												border:
													sidebarOption === "profile"
														? "1px #9B291B solid"
														: "1px gray solid",
											}}
											onClick={(e) => {
												e.preventDefault();
												setSidebarOption("profile");
											}}
										>
											Profile
										</button>
										{!user?.is_kyc && (
											<button
												type="button"
												style={{
													color: "#9B291B",
													fontWeight: "bolder",
													width: "48%",
													fontFamily:
														"Montserrat",
													backgroundColor:
														"transparent",
													padding: "10px",
													borderRadius: "15px",
													border:
														sidebarOption === "kyc"
															? "1px #9B291B solid"
															: "1px gray solid",
												}}
												onClick={(e) => {
													e.preventDefault();
													setSidebarOption("kyc");
												}}
											>
												KYC
											</button>
										)}
									</div>

									<div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label className="col-form-label profile-labels-modal">
												Full Name
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
												className="form-control form-control-profile"
												value={name}
												onChange={(e) => {
													setName(e.target.value);
												}}
												name="name"
											/>
										</div>
									</div>

									<div className="col-lg-12 form-group div-profile-modal  mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label
												for="inputEmail"
												className="col-form-label profile-labels-modal"
											>
												Email
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
												id="inputEmail"
												className="form-control form-control-profile"
												value={email}
												onChange={(e) => {
													setEmail(e.target.value);
												}}
												autocomplete="off"
												name="email"
											/>
										</div>
									</div>
									<div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label className="col-form-label profile-labels-modal">
												Phone Number
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
												disabled
												id="inputPhone"
												className="form-control form-control-profile"
												value={phone}
												onChange={(e) => {
													setPhone(e.target.value);
												}}
												name="phone"
												autocomplete="off"
											/>
										</div>
									</div>
									{/* <div className="col-sm-12 form-group div-profile-modal mb-3">
                    <div className={!isMobile ? "col-2" : "col-sm-12"}>
                      <label className="col-form-label profile-labels-modal">
                        Language
                      </label>
                    </div>
                    <div className={!isMobile ? "col-8" : "col-sm-12"}>
                      <input
                        id="inputLanguage"
                        className="form-control form-control-profile"
                        value={language}
                        onChange={(e) => {
                          setLanguage(e.target.value);
                        }}
                        name="language"
                        autocomplete="off"
                      />
                    </div>
                  </div> */}
									<div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label className="form-label profile-labels-modal">
												Full Address
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<textarea
												className="form-control form-control-profile"
												name="address"
												rows="2"
												value={address}
												onChange={(e) => {
													setAddress(e.target.value);
												}}
												autocomplete="off"
											></textarea>
										</div>
									</div>
									<div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label className="col-form-label profile-labels-modal">
												Pin Code
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
												id="inputPinCode"
												className="form-control form-control-profile"
												name="pinCode"
												value={pinCode}
												onChange={(e) => {
													setPinCode(e.target.value);
												}}
												autocomplete="off"
											/>
										</div>
									</div>
									<div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label className="form-label profile-labels-modal">
												Your Referral Code
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<textarea
												className="form-control form-control-profile"
												name="desc"
												rows="2"
												value={
													referralCode ||
													"referral code here"
												}
												autoComplete="off"
												disabled={true}
											></textarea>
											{/* <button
                        className="btn btn-primary mt-2"
                        onClick={handleCopy}
                        disabled={disabled}
                      >
                        Copy
                      </button> */}
										</div>
									</div>
									<div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label className="form-label profile-labels-modal">
												Description
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<textarea
												className="form-control  form-control-profile"
												name="desc"
												rows="2"
												value={desc}
												onChange={(e) => {
													setDesc(e.target.value);
												}}
												autocomplete="off"
											></textarea>
										</div>
									</div>
									{/* <div className="col-sm-12 form-group div-profile-modal mb-3">
                    <div className={!isMobile ? "col-2" : "col-sm-12"}>
                      <label className="form-label profile-labels-modal">
                        Adhar number
                      </label>
                    </div>
                    <div className={!isMobile ? "col-8" : "col-sm-12"}>
                      <textarea
                        className="form-control  form-control-profile"
                        name="adharNumber"
                        rows="2"
                        value={adharNumber}
                        onChange={(e) => {
                          setAdharNumber(e.target.value);
                        }}
                        autocomplete="off"
                      ></textarea>
                    </div>
                  </div> */}
									<div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label className="form-label profile-labels-modal">
												Current address proof
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
												type="file"
												accept="image/*"
												multiple
												onChange={(e) =>
													setImages(e.target.files)
												}
											/>
										</div>
									</div>

									<div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										></div>
										{user?.idProof?.map((img, index) => (
											<div className="py-1">
												<a
													className="text-color979797"
													target="_blank"
													href={ img}
												>
													- Proof {index + 1}
												</a>
											</div>
										))}
									</div>
									<button
										type="submit"
										disabled={loader}
										className="btn-auth-signup mb-2 mt-2"
									>
										{loader
											? "Please wait..."
											: "Save changes"}
									</button>
									<button
										type="submit"
										disabled={loader}
										className="btn-auth-signup mb-2 mt-2"
										onClick={handleDelete}
									>
										{loader
											? "Please wait..."
											: "Delete account"}
									</button>
								</form>
							</div>
						</div>
					</div>
				)}
				{sidebarOption === "kyc" && (
					<div
						className=""
						style={{
							width: isMobile ? "80%" : "60%",
						}}
					>
						<div className="d-flex align-items-center justify-content-between">
							<div></div>
							<h2
								style={{
									color: "#240501",
									fontWeight: "bold",
									fontSize: "30px",
									textAlign: "center",
								}}
							>
								KYC
							</h2>
							<a
								style={{
									display: "inline-block",
									fontSize: 30,
									color: "#000",
								}}
								href="#"
								onClick={(e) => {
									e.preventDefault();
									//   stateFunc(false);
									setSidebarOption("profile")
								}}
							>
								{sidebarOption==="kyc" && <CloseRoundedIcon fontSize="25px" />}
								{/* <i class="fa fa-times" aria-hidden="true"></i> */}
							</a>
						</div>
						<div className="row g-3 align-items-center">
							<div className="col-lg-12 col-md-12 col-sm-12">
								<form
									onSubmit={(e) => {
										onSubmitKyc(e);
									}}
								>
									<div className="mt-4 mb-5">
										<Avatar
											sx={{
												marginTop: "25px",
												marginBottom: "25px",
												height: "48px",
												width: "48px",
												marginLeft: "auto",
												marginRight: "auto",
												// display: "flex",
												// width: "fit-content",
												// padding: "12px 16px",
												borderRadius: "48px",
												// margin: "0px 20px"
											}}
											alt={user?.name}
											src={assetUrl + user?.avatarImage}
										/>
									</div>
									<div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<div className="col-form-label profile-labels-modal">
												Have Pan Card?
											</div>
										</div>
										<div
											style={{
												display: "flex",
												gap: "20px",
											}}
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<div
												style={{
													display: "flex",
													gap: "4px",
												}}
											>
												<input
													type="radio"
													name="isPanCard"
													id="isPanCard_true"
													value={true}
													checked={isPanCard}
													onChange={(e) =>
														setIsPanCard(true)
													}
												/>
												<label
													htmlFor="isPanCard_true"
													style={{
														marginRight: "10px",
													}}
												>
													Yes
												</label>
											</div>

											<div
												style={{
													display: "flex",
													gap: "4px",
												}}
											>
												<input
													type="radio"
													name="isPanCard"
													id="isPanCard_false"
													value={false}
													checked={!isPanCard}
													onChange={(e) =>
														setIsPanCard(false)
													}
												/>
												<label htmlFor="isPanCard_false">
													No
												</label>
											</div>
										</div>
									</div>

									{isPanCard ? (
										<div className="col-lg-12 form-group div-profile-modal  mb-3">
											<div
												className={
													!isMobile
														? "col-4"
														: "col-sm-12"
												}
											>
												<label
													htmlFor="panCard"
													className="col-form-label profile-labels-modal"
												>
													Pan Card
												</label>
											</div>
											<div
												className={
													!isMobile
														? "col-8"
														: "col-sm-12"
												}
											>
												<input
													type="file"
													accept="image/*"
													id="panCard"
													onChange={(e) =>
														handleImageChange(
															e,
															"panCard"
														)
													}
													className="form-control form-control-profile"
												/>
											</div>
										</div>
									) : (
										<div className="col-lg-12 form-group div-profile-modal  mb-3">
											<div
												className={
													!isMobile
														? "col-4"
														: "col-sm-12"
												}
											>
												<label
													htmlFor="bankStatement"
													className="col-form-label profile-labels-modal"
												>
													Bank Statement
												</label>
											</div>
											<div
												className={
													!isMobile
														? "col-8"
														: "col-sm-12"
												}
											>
												<input
													type="file"
													accept="application/pdf"
													id="bankStatement"
													onChange={(e) =>
														handleImageChange(
															e,
															"bankStatement"
														)
													}
													className="form-control form-control-profile"
												/>
											</div>
										</div>
									)}

									<div className="col-lg-12 form-group div-profile-modal  mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label
												htmlFor="aadharCard"
												className="col-form-label profile-labels-modal"
											>
												Aadhar Card
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
												type="file"
												accept="image/*"
												id="aadharCard"
												onChange={(e) =>
													handleImageChange(
														e,
														"aadharCard"
													)
												}
												className="form-control form-control-profile"
											/>
										</div>
									</div>
									<div className="col-lg-12 form-group div-profile-modal  mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label
												htmlFor="aadharCardNumber"
												className="col-form-label profile-labels-modal"
											>
												Aadhar Card number
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
												type="text"
												// accept="image/*"
												id="aadharCardNumber"
												onChange={(e) =>
													setAdharNumber(
														e.target.value
													)
												}
												className="form-control form-control-profile"
											/>
										</div>
									</div>

									<div className="col-lg-12 form-group div-profile-modal  mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label
												htmlFor="selfie"
												className="col-form-label profile-labels-modal"
											>
												Selfie
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
												type="file"
												accept="image/*"
												id="selfie"
												onChange={(e) =>
													handleImageChange(
														e,
														"selfie"
													)
												}
												className="form-control form-control-profile"
											/>
										</div>
									</div>

									<div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label
												className="col-form-label profile-labels-modal"
												htmlFor="address"
											>
												Address
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
												id="address"
												className="form-control form-control-profile"
												name="address"
												value={deliveryAddress.address}
												onChange={
													handleDeliveryAddressChange
												}
											/>
										</div>
									</div>
									<div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label
												className="col-form-label profile-labels-modal"
												htmlFor="houseNo"
											>
												House No
											</label>
										</div>

										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
												id="houseNo"
												className="form-control form-control-profile"
												name="houseNo"
												value={deliveryAddress.houseNo}
												onChange={
													handleDeliveryAddressChange
												}
											/>
										</div>
									</div>

									<div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label
												className="col-form-label profile-labels-modal"
												htmlFor="street"
											>
												Street
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
												id="street"
												className="form-control form-control-profile"
												name="street"
												value={deliveryAddress.street}
												onChange={
													handleDeliveryAddressChange
												}
											/>
										</div>
									</div>

									<div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label
												className="col-form-label profile-labels-modal"
												htmlFor="city"
											>
												City
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
												id="city"
												className="form-control form-control-profile"
												name="city"
												value={deliveryAddress.city}
												onChange={
													handleDeliveryAddressChange
												}
											/>
										</div>
									</div>

									<div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label
												className="col-form-label profile-labels-modal"
												htmlFor="state"
											>
												State
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
												id="state"
												className="form-control form-control-profile"
												name="state"
												value={deliveryAddress.state}
												onChange={
													handleDeliveryAddressChange
												}
											/>
										</div>
									</div>

									<div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label
												className="col-form-label profile-labels-modal"
												htmlFor="pinCode"
											>
												Pin Code
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
												id="pinCode"
												className="form-control form-control-profile"
												name="pinCode"
												value={deliveryAddress.pinCode}
												onChange={
													handleDeliveryAddressChange
												}
											/>
										</div>
									</div>

									<div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label
												className="col-form-label profile-labels-modal"
												htmlFor="contactNumber"
											>
												Contact Number
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
												id="contactNumber"
												className="form-control form-control-profile"
												name="contactNumber"
												value={contactNumber}
												onChange={(e) =>
													setContactNumber(
														e.target.value
													)
												}
											/>
										</div>
									</div>

									<div className="col-lg-12 form-group div-profile-modal  mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label
												htmlFor="addressProof"
												className="col-form-label profile-labels-modal"
											>
												Address Proof
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
												type="file"
												accept="image/*"
												id="addressProof"
												onChange={(e) =>
													handleImageChange(
														e,
														"addressProof"
													)
												}
												className="form-control form-control-profile"
											/>
										</div>
									</div>













                  <div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label
												className="col-form-label profile-labels-modal"
												htmlFor="lesseeName"
											>
												Reference Name
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
												id="lesseeName"
												className="form-control form-control-profile"
												name="lesseeName"
												value={lesseeName}
												onChange={(e) =>
													setLesseeName(
														e.target.value
													)
												}
											/>
										</div>
									</div>

                  <div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label
												className="col-form-label profile-labels-modal"
												htmlFor="lesseeType"
											>
												Reference Type
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>

                      <select id="lesseeType"
												className="form-control form-control-profile"
												name="lesseeType"
                        defaultValue={lesseeType}
                        onChange={(e)=> setLesseeType(e.target.value)}>
                      <option value={""}>--choose--</option>
                        <option value={"friend"}>Friend</option>
                        <option value={"family"}>Family</option>
                        <option value={"landLord"}>Land Lord</option>
                        <option value={"owner"}>Owner</option>
                      </select>
										</div>
									</div>



                  <div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label
												className="col-form-label profile-labels-modal"
												htmlFor="lesseeAddress"
											>
												Lessee Address
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
												id="lesseeAddress"
												className="form-control form-control-profile"
												name="lesseeAddress"
												value={lesseePhone}
												onChange={(e) =>
													setLesseePhone(
														e.target.value
													)
												}
											/>
										</div>
									</div>

                  
                  <div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											className={
												!isMobile
													? "col-4"
													: "col-sm-12"
											}
										>
											<label
												className="col-form-label profile-labels-modal"
												htmlFor="lesseeAddress"
											>
												Lessee Mobile
											</label>
										</div>
										<div
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
                      type="number"
												id="mobileNumberLesse"
												className="form-control form-control-profile"
												name="mobileNumberLesse"
												value={mobileNumberLesse}
												onChange={(e) =>
													setMobileNumberLesse(
														e.target.value
													)
												}
											/>
										</div>
									</div>



















									<div className="col-sm-12 form-group div-profile-modal mb-3">
										<div
											style={{
												display: "flex",
												gap: "10px",
											}}
											className={
												!isMobile
													? "col-8"
													: "col-sm-12"
											}
										>
											<input
												onChange={(e) =>
													setCheckBox(!isCheckBox)
												}
												style={{ width: "18px" }}
												type="checkbox"
											/>
											<label
												style={{ paddingTop: "8px" }}
												className="form-label profile-labels-modal"
											>
												Accept terms and conditions
											</label>
										</div>
									</div>

									<button
										type="submit"
										disabled={loader}
										className="btn-auth-signup mb-2 mt-2"
									>
										{loader
											? "Please wait..."
											: "Save changes"}
									</button>
								</form>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}
