import React from "react";
import styles from "../../styles/styles";
import { TextField } from "@mui/material";

export default function OutlinedFormInput({
  title,
  classes,
  onChange,
  disabled,
  sx,
  inputProps,
  ref,
  ...other
}) {
  function getStyles(arr) {
    let obj = {};
    if (arr)
      arr.forEach((item) => {
        obj[Object.keys(item)[0]] = item[Object.keys(item)[0]];
      });
    return obj;
  }
  return (
    // <Button variant="contained" title={title} classes={classes} >{title}</Button>
    <TextField
      sx={[
        styles.outlineInput,
        ...(sx || []),
        {
          "& .MuiOutlinedInput-root.Mui-focused": {
            "& > fieldset": { borderColor: "#9A291B" },
          },
        },
      ]}
      {...other}
      variant="outlined"
      ref={ref}
      InputProps={{
        style: { ...(getStyles(sx) || {}) },
        ...inputProps,
      }}
      disabled={disabled}
      onChange={onChange}
    />
  );
}
