import React, { useState } from "react";
import Slider from "react-slick";
import SaveSvg from "../../assets/images/save.svg";
import BusSvg from "../../assets/images/bus.svg";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import { IconButton, Chip } from "@mui/material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import StarIcon from "@mui/icons-material/Star";
import ButtonContained from "../Buttons/ButtonContained";
import ButtonOutlined from "../Buttons/ButtonOutlined";
import classes from "../../styles/styles.js";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { assetUrl } from "../../config/axiosConfig";
import { Link, useNavigate } from "react-router-dom";
import { Watermark } from "@hirohe/react-watermark";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from "react-responsive";
import {ArrowRight} from 'lucide-react';

const FurnitureSlider = () => {
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 3 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  const [sliderRef, setSliderRef] = useState(null);
  const { furnitures } = useSelector((state) => state.furniture);
  const theme = useTheme();
  const navigate = useNavigate();
  const sliderSettings = {
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    centerMode: false,
    // centerPadding: "25%",
    draggable: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const handleViewAllClick = () => {
    navigate("/furniture");
    // You can also perform other actions here, like fetching properties from an API
  };

  return (
    <div
      style={{
        marginTop: isMobile ? "-20px" : "30px",
      }}
      className="furniture-container"
    >
      {/* <div className="find-all-furniture-main-container">
        <div className="controls content pt-2">
          <div className="slider-header-text-container">
            <div
              className="slider-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>Rent Furniture,AC & Other Home Appliances</span>
              {!isMobile && (
                <div
                  className="view-all-hover"
                  onClick={handleViewAllClick}
                  label="view all furnitures"
                  style={{
                    marginLeft: "auto",
                    cursor: "Pointer",
                  }}
              >
                <span
                  style={{
                    fontSize: "20px",
                  }}
                >
                  view all furnitures <ArrowRight />
                </span>
              </div>
              )}
            </div>
            <div className="slider-sub-header">
              Find all furnitures here to rent.
            </div>
          </div>
          
        </div>

        {furnitures?.data?.length > 0 && (
          <Carousel
            responsive={responsive}
            removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
            infinite={true}
            showDots={false}
            autoPlay={true}
            autoPlaySpeed={3500}
          >
            
            {furnitures?.data?.map((card, index) => (
              <div className="card-container card-container-furniture  bg-transparent mb-5 mx-3">
                <div key={index} className="card card-furniture">
                  <Watermark
                    gutter={80}
                    rotate={0}
                    text="Gharwalah.com"
                    textSize={30}
                    multiline={false}
                  >
                    <div
                      onClick={() => navigate(`/furniture/${card._id}`)}
                      className="card-image"
                      style={{
                        backgroundImage: `url(${card.image})`,
                        backgroundSize: "100%",
                        backgroundRepeat: "no-repeat",
                        backgroundClip: "content-box",
                        backgroundPosition: "center",
                      }}
                    >
                      <div className="d-flex flex-column justify-content-between h-100">
                        <div className="d-flex justify-content-end">
                          
                        </div>
                       
                      </div>
                    </div>
                  </Watermark>

                  <div className="text-info text-info-furniture">
                    <div className="slider-card-header">
                      <h2
                        className="slider-title slider-title-furniture"
                        style={{ textTransform: "capitalize" }}
                      >
                        {card.furnitureName}
                      </h2>
                    </div>
                    <div>
                      <span
                        style={{ color: "black", textTransform: "capitalize" }}
                      >
                        {card.sellingOptions}
                      </span>
                    </div>
                    <div
                      style={{
                        color: "#9a291b",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                      className="price-text-furniture justify-content-between"
                    >
                      ₹{" "}
                      {Intl.NumberFormat("en-IN").format(
                        card.twelveMonthPayment.twelveMonthPayment
                      )}
                    </div>
                  </div>
                  <div className="property-slider-buttons">
                    
                    <ButtonContained
                      onClick={() => navigate(`/furniture/${card._id}`)}
                      sx={[
                        classes.borderRadius12,
                        classes.fs12,
                        classes.margin4,
                        classes.p10,
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs9,
                            ...classes.margin4,
                            ...classes.borderRadius7,
                            ...classes.fs6,
                            ...classes.p10,
                          },
                        },
                      ]}
                    >
                      Book your plan
                    </ButtonContained>
                    <ButtonOutlined
                      onClick={() => {
                        navigate(`/furniture/${card._id}`);
                        window.location.reload();
                      }}
                      sx={[
                        classes.borderRadius12,
                        classes.fs12,
                        classes.margin4,
                        classes.p10,
                        {
                          [theme.breakpoints.between("xs", "md")]: {
                            ...classes.fs9,
                            ...classes.margin4,
                            ...classes.borderRadius7,
                            ...classes.fs6,
                            ...classes.p10,
                          },
                        },
                      ]}
                    >
                      View Detail
                    </ButtonOutlined>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        )}
      </div> */}
    </div>
  );
};

export default FurnitureSlider;
