import React, { useRef, useState, useEffect } from "react";
import { Box, Typography, Container,Card,CardContent,CardActions,Button } from "@mui/material";
import Header from "../../components/Header";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import ButtonContained from "../../components/Buttons/ButtonContained";
import { useTheme } from "@mui/material/styles";
import OutlinedFormInput from "../../components/Input/OutlinedInput";
import { validateField } from "../../util/json/helpers";
import { toast } from "react-hot-toast";
import { addAccessoryService } from "../../config/property";
import { privateReq } from "../../config/axiosConfig";

const AddAccessories = () => {
  const [accessoryName, setAccessoryName] = useState("");
  const [loader, setLoader] = useState(false);
  const theme = useTheme();
  const [data,setData] = useState();
  const getList = () => {
    privateReq.get('/list-accessory').then((Res)=>{
      console.log('DATA',Res.data[0].data);
      setData(Res.data[0].data)
    }).catch((err)=>{
      console.log(err)
    })

  }

  const handleDelete =(id) =>{
    privateReq.delete(`/delete-accessory/${id}`).then((res)=>{
      console.log(res);
      toast.success('deleted');
    }).catch((err)=>{
      console.log(err)
    }).finally(()=>{
      getList()
    })

  }

  useEffect(()=>{
    getList();
  },[])
  const navigate = useNavigate();
  const onSubmit = async (e) => {
    e.preventDefault();
    let validate = true;

    if (accessoryName.trim() === "") {
      validateField("accessoryName", "Accesory field is required");
      validate = false;
    }
    if (!validate) {
      toast("Please fill required fields");
      return;
    }

    try {
      setLoader(true);
      const res = await addAccessoryService({
        accessoryName,
      });

      if (res.data.success) {
        setLoader(false);
        setAccessoryName("");
        toast("Accesory Added Successfully");
      }
    } catch (err) {
      setLoader(false);
    }
  };
  return (
    <>
      <Header />
      <div className="container-fluid px-0">
        <div className="properties-container">
          <div className="w-100">
            <div className="border-box px-0">
              <Box
                className="d-flex align-items-center py-3 border-bottom p-4 w-100"
                sx={[styles.color9A291B]}
              >
                <Link
                  onClick={() => navigate(-1)}
                  className="d-flex align-items-center text-decoration-none items-center"
                >
                  <KeyboardBackspaceIcon sx={[styles.color9A291B]} />
                  <p style={{fontWeight:"bold", color:"#950000"}}>Listing</p>
                 
                </Link>
              </Box>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px', padding: '16px' }}>
        {data?.map(item => (
          <Card key={item.id} sx={{ width: '200px', margin: '8px' }}>
            <CardContent>
              <Typography variant="h5" component="div">
                {item.accessoryName}
              </Typography>
            </CardContent>
            <CardActions>
            <ButtonContained
                      sx={[
                        styles.fs16,
                        styles.borderRadius12,
                        { padding: "0.75rem" },
                        {
                          [theme.breakpoints.between("xs", "sm")]: [
                            {
                              maxWidth: "180px",
                              minWidth: "180px",
                            },
                          ],
                        },
                      ]}
                      onClick={()=>handleDelete(item._id)}
                    >
                      Delete
                    </ButtonContained>
            </CardActions>
          </Card>
        ))}
      </div>
            </div>
            <div className="mt-4 mb-5">
              <Container
                className="w-50 p-4"
                sx={[
                  styles.borderRadius24,
                  { border: "1px solid #dee2e6" },
                  {
                    [theme.breakpoints.between("xs", "md")]: [
                      { border: "none" },
                    ],
                  },
                ]}
              >
                <Box className="text-center">
                  <Typography
                    sx={[
                      styles.fontFamilySGB,
                      styles.fs30,
                      {
                        [theme.breakpoints.between("xs", "md")]: [styles.fs14],
                      },
                    ]}
                  >
                    Add Accessories
                  </Typography>
                </Box>
                <Box
                  sx={[
                    styles.mt40,
                    styles.alignItemCenter,
                    styles.jcSCenter,
                    styles.marginAuto,
                  ]}
                  className="col-lg-12 col-md-12 col-xs-12 d-flex flex-column justify-content-center align-items-center"
                >
                  <Box className="col-lg-6 col-md-6 col-sm-12 px-2">
                    <Typography
                      sx={[
                        styles.fs16,
                        styles.fontFamilySG,
                        styles.color240501,
                        {
                          [theme.breakpoints.between("xs", "md")]: [
                            styles.fs12,
                          ],
                        },
                      ]}
                      className="mb-2"
                    >
                      Accessory
                    </Typography>
                    <OutlinedFormInput
                      placeholder={"Wardrobe"}
                      value={accessoryName}
                      onChange={(e) => {
                        setAccessoryName(e.target.value);
                      }}
                      autocomplete="off"
                      name="accessoryName"
                      sx={[
                        { height: "45px" },
                        {
                          [theme.breakpoints.between("xs", "sm")]: [
                            {
                              maxWidth: "300px",
                              minWidth: "300px",
                            },
                          ],
                        },
                      ]}
                    />
                  </Box>
                  <Box className="col-lg-6 col-xs-12 col-md-6 px-2 mt-3 mb-3">
                    <ButtonContained
                      sx={[
                        styles.fs16,
                        styles.borderRadius12,
                        { padding: "0.75rem" },
                        {
                          [theme.breakpoints.between("xs", "sm")]: [
                            {
                              maxWidth: "180px",
                              minWidth: "180px",
                            },
                          ],
                        },
                      ]}
                      onClick={onSubmit}
                    >
                      Submit
                    </ButtonContained>
                  </Box>
                </Box>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAccessories;
