import React from "react";
import Header from "../../components/Header";
import { useMediaQuery } from "react-responsive";
import StickyFooterMobile from "../../components/FooterMobile";
import StickyFooter from "../../components/Footer";
const DataSecurity = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{
        height: "100vh",
      }}
    >
      <Header />
      <div className="container" style={{ paddingTop: "125px" }}>
        <h2 className="h3-about-tems" style={{ marginBottom: "15px" }}>
          Data Security
        </h2>{" "}
        <br />
        <p className="para-about-terms">
          {" "}
          Ensuring Your Peace of Mind: Gharwala's Commitment to Data Security At
          Gharwala, we prioritize the security and confidentiality of your
          information. Our robust data security measures are designed to
          safeguard every piece of data you entrust us with, ensuring a safe and
          trustworthy online experience. Whether you're exploring property
          listings, making bookings for services, or engaging in any other
          interaction on our platform, you can trust that your data is treated
          with the utmost care.<br></br> Key Features of Our Data Security
          Protocol:<br></br> 1. Encryption: All data transmitted between your
          device and our servers is encrypted, preventing unauthorized access
          during the transfer process.<br></br> 2. Secure Storage: Once your
          data reaches our servers, it's stored in highly secure environments
          with advanced access controls and monitoring. <br></br>3. Access
          Controls: Access to your data is restricted to authorized personnel
          only. We implement stringent access controls to prevent any
          unauthorized viewing or modification. <br></br>4. Regular Audits: Our
          systems undergo regular security audits to identify and address any
          potential vulnerabilities promptly. <br></br>5. User Authentication:
          Multi-layered authentication mechanisms ensure that only authorized
          users have access to specific data, adding an extra layer of
          protection. <br></br>6. Secure Transactions: For any financial
          transactions or sensitive data exchanges, we employ industry-standard
          security measures to prevent fraud and unauthorized access. <br></br>
          Your Trust Matters: Gharwala understands the value of your trust, and
          we are committed to upholding the highest standards of data security.
          Feel confident as you navigate our platform, knowing that your
          information is protected by state-of-the-art security protocols. Your
          journey with Gharwala is not just about finding your dream property or
          booking services; it's about experiencing a secure and reliable
          digital space designed with your privacy in mind.
        </p>
      </div>
      <div>{isMobile ? <StickyFooterMobile /> : <StickyFooter />}</div>
    </div>
  );
};

export default DataSecurity;
