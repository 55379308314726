import React from "react";
import cashImage from "../../assets/images/agreement-cash-image.svg";

const AgreementPage = () => {
    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "slategray"}}>
            <div style={{maxWidth: "800px", width: "800px", fontFamily: "-moz-initial"}}>
                 {/* page 1: */}

                <div style={{width: "800px", backgroundColor: "white", height: "140vh", padding: "50px"}}>
                    {/* <div>
                      <img style={{width: "100%"}} src={cashImage} alt="cash-img" />
                    </div> */}

                    <div style={{fontSize: "20px", fontWeight: "bolder", textAlign: "center"}}>EQUIPMENT RENTAL AGREEMENT</div>

                    <div style={{marginTop: "10px", fontSize: "18px", lineHeight: "2rem"}}>
                        <span style={{fontWeight: "bolder", fontSize: "18px"}}>THIS ARTICLE OF AGREEMENT</span> is made at kolkata on 31<sup>st</sup> day of january,
                        2023 between <span style={{fontWeight: "bolder", fontSize: "18px"}}>GHARWALA HOME SOLUTIONS LLP, (PAN NO.</span> AAXFG2882P,
                        GSTIN NO. 19AAXFG2882P1Z7), Office at 2, New Tollygung, Purba Putiary, Kolkata-93. here-in-after referred to as the 
                        <span style={{fontWeight: "bolder", fontSize: "18px"}}> "LESSOR"</span> (which expression shall unless repugnant to the context or
                        meaning thereof e deemed to include her heirs, administrators, leagal representatives, executors and assigns) of the
                        <span style={{fontWeight: "bolder", fontSize: "18px"}}> ONE PART.</span>
                    </div>

                    <div style={{marginTop: "10px", fontSize: "18px", lineHeight: "2rem", padding: "20px"}}>
                        <div style={{fontWeight: "bolder", fontSize: "18px", textAlign: "center"}}>AND</div>
                        <div style={{display: "flex", gap: "6px"}}>
                          <span>1.</span>
                          <div>
                            <span style={{fontWeight: "bolder", fontSize: "18px"}}>NILANJANA DUTTA, (AADHAR No. 254716492583), </span>
                            by faith - Hindu, by occupation - Service, residing at 49/4, J. M. LANE, ALIPORE H.O, KOLKATA WEST BENGAL - 700027.
                            Mobile No: 9748800228. which is hereinafter referred to as the <span style={{fontWeight: "bolder", fontSize: "18px"}}> "LESSEE"</span>
                            (which expression shall unless repugnant to the context or meaning thereof, include their heirs, executors, administrators, assigns,
                            successors, etc.) of the <span style={{fontWeight: "bolder", fontSize: "18px"}}> OTHER PART.</span>
                          </div>
                        </div>
                    </div>
                </div>

                {/* page 2: */}
                <div style={{width: "800px", backgroundColor: "white", height: "140vh", padding: "50px", marginTop: "20px"}}>
                    <div style={{marginTop: "10px", fontSize: "18px", lineHeight: "1.5rem"}}>
                        IN WITNESS WHERE OF, the parties have caused this Agreement to be executed the day and year first above written.
                    </div>

                    <div style={{marginTop: "10px", fontSize: "18px", lineHeight: "2rem"}}>
                        WITNESS NAME, MOBILE NUMBER, SIGNATURE:
                    </div>
                </div>

                {/* page 3: */}
                <div style={{width: "800px", backgroundColor: "white", height: "140vh", padding: "50px", marginTop: "20px"}}>
                    <div style={{marginTop: "10px", fontSize: "18px", lineHeight: "1.5rem"}}>
                        <span style={{fontWeight: "bolder", fontSize: "18px"}}>1. EQUIPMENT : </span> Lessor hereby leases to Lessee the following equipment: <br></br>
                        <span style={{fontSize: "16px"}}> BED - 01 PCS, MATTRESS - 01 PCS.</span>
                    </div>

                    <div style={{fontSize: "16px", lineHeight: "2rem"}}>
                        GAS OVEN- 01 PCS, WASHING MACHINE- 01 PCS, FRIDGE- 01 PCS
                    </div>

                    <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                        <span style={{fontWeight: "bolder", fontSize: "18px"}}>2. LEASE TERM: </span> The lease will start on 28/01/2024(begin date)
                        and will end on 27/11/2024(end date) (Lease Term).
                    </div>

                    <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                        <span style={{fontWeight: "bolder", fontSize: "18px"}}>3. LEASE PAYMENTS: </span> Lessee agrees to pay to Lessor as rent for 
                        the equipment the amount of Rs. 2500 ("Rent") each month in advance on the first 7 days of each month at:
                        <span style={{fontSize: "16px"}}>GHARWLA HOME SOLUTIONS LLP. ACCOUNT NUMBER - 10210010295589, IFS CODE- BDBL0001603, SARAT BOSE ROAD BRANCH.</span> <br></br>
                        (bank account for rent payment) or at any other address designated by Lessor. If the Lease Term doesnot start on the first day of the month or end on the last day of 
                        a month, the rent will be prorated accordingly.
                    </div>

                    <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <span style={{fontWeight: "bolder", fontSize: "18px"}}>4. LATE CHARGES: </span> If any amount under this agreement is more than 10 days
                      late, Lessee agree to pay a late fee of RS. 100 PER MONTH.
                    </div>

                    <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <span style={{fontWeight: "bolder", fontSize: "18px"}}>5. SECURITY DEPOSIT : </span>
                      Prior to taking possession of the equipment, Lessee shall deposit with Lessor, in trust a security deposit of RS 5000 as security for the performance by Lessee of the
                      terms under this Agreement under and for any damages caused by Lessee or Lessee's agent to the Equipment during the Lease Term. However, Lessor is not just limited to the security deposit
                      amount and Lessee remains liable for any balance. Lessee shall not apply or deduct any portion of any security deposit from the last or any month's rent. 
                      Lessee shall not use or apply any such security deposit from the last or any month's rent.
                      Lessee shall not use or apply any such security deposit at any time in lieu of paymnet of rent. If Lessee breaches any terms 
                      or conditions of this Agreement, Lessee shall forfeit any deposit, as permitted by law.
                    </div>

                    <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <span style={{fontWeight: "bolder", fontSize: "18px"}}>6. DELIVERY : </span>
                      Lesse shall be responsible for all expenses and costs : <br></br> 
                      1. at the beginning of the Lease Term, of shipping
                      the Equipment to Lessee Premises and lessor shall responsible for all expenses and cost at the end of 
                      the Lease Term, of shipping the Equipment back to Lessor premises.
                    </div>

                </div>

                {/* page 4: */}
                <div style={{width: "800px", backgroundColor: "white", height: "140vh", padding: "50px", marginTop: "20px"}}>
                   <div style={{marginTop: "10px", fontSize: "18px", lineHeight: "1.5rem"}}>
                     <span style={{fontWeight: "bolder", fontSize: "18px"}}>5. DEFAULTS : </span>
                     If Lessee fails to perform of fulfill any obligation under this Agreement, Lessee shall be in default of
                     this agreement. Subject to any statute, ordinance or law to the contrary. Lessee shall have (7) days from the
                     date of notice of default by Lessor to cure the default. In the event, Lessee does not cure a default,
                     Lessor may at Lessor's option : <br></br>
                     (a) cure such default and the cost of such action may be added to Lessee's 
                     financial obligations under this agreement; or (b) declare Lessee in default of this Agreement.  If Lessee
                     shall become insolvent, cease to do business as a going concern or if a petition has been filed by or against 
                     Lessee under the Bankruptcy Act or similar federal or state statute, Lessor may immediately declare Lessee in default
                     of this Agreement. In the event of default Lessor may, as permitted by law, re-take possession of the Equipment.
                     Lessor may, at its option, hold Lessee liable for any difference between the Rent that would have been payable under this 
                     Agreement during the balance of the unexpired term and any rent paid by any successive lessee if the 
                     Equipment is re-let minus the cost and expenses of such reletting. In the event Lessor is unable 
                     to re-let the Equipment during any ramaining term of this Agreement, after default by Lessee, Lessor may
                     at its option hold Lessee liable for the balance of the unpaid rent under this Agreement had continued in force.
                   </div>

                   <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <span style={{fontWeight: "bolder", fontSize: "18px"}}>8.POSSESSION AND SURRENDER OF EQUIPMENT : </span>
                      Lessee shall be entitled to possession of the equipment on the first day of the Lease Term. At the expiration
                      of the Lease Term, Lessee shall surrender the Equipment to Lessor by delivering the Equipment to Lessor
                      or Lessor’s agent in good condition and working order, ordinary wear and tear excepted, as it was at the commencement of the Agreement. 
                    </div>

                    <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <span style={{fontWeight: "bolder", fontSize: "18px"}}>9. USE OF EQUIPMENT: </span>
                      Lessee shall only use the Equipment in careful and proper manner and will comply with all laws, rules, 
                      ordinances, statutes and orders regarding the use maintenance of storage of the Equipment. 
                    </div>

                    <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <span style={{fontWeight: "bolder", fontSize: "18px"}}>10. CONDITION OF EQUIPMENT AND REPAIR : </span>
                      Lessee or Lessee’s agent has inspected the Equipment and acknowledges that the Equipment is in good and acceptable 
                      condition.
                    </div>

                    <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <span style={{fontWeight: "bolder", fontSize: "18px"}}>11. MAINTENANCE, DAMAGE AND LOSS: </span>
                      Lessor will, at Lessor's sole expense, keep and maintain the Equipment clean and in good working order and repair during the 
                      Lease Term. In the event the Equipment is lost or damaged beyond repair, Lessee shall pay to Lessor the replacement cost of the
                      Equipment; in addition, the obligations of this agreement shall continue  in full force and effect through 
                      the Lease Term. 
                    </div>

                    <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <span style={{fontWeight: "bolder", fontSize: "18px"}}>12. LOCK IN PERIOD: </span>
                      Minimum six months. if lessee break lockin period lessor will not refund any security deposit.
                    </div>
                </div>

                {/* 5th page: */}
                <div style={{width: "800px", backgroundColor: "white", height: "140vh", padding: "50px", marginTop: "20px"}}>
                    <div style={{marginTop: "10px", fontSize: "18px", lineHeight: "1.5rem"}}>
                        IN WITNESS WHERE OF, the parties have caused this Agreement to be executed the day and year first above written.
                    </div>

                    <div style={{marginTop: "10px", fontSize: "18px", lineHeight: "2rem"}}>
                        WITNESS NAME, MOBILE NUMBER, SIGNATURE:
                    </div>

                    <div style={{marginTop: "100px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <div style={{fontWeight: "bolder", fontSize: "18px"}}>LESSOR : </div>
                      <div style={{fontWeight: "bolder", fontSize: "18px", marginTop: "20px"}}>GHARWALA HOME SOLUTIONS LLP </div>
                      <div><input style={{borderStyle: "none", borderBottom: "2px solid black"}} /></div>
                      <div>(Name)</div>

                      <div><input style={{borderStyle: "none", borderBottom: "2px solid black"}} /></div>
                      <div>(Position, if applicable)</div>
                      <div style={{marginTop: "20px"}}>SIGNATURE WITH SEAL: </div>
                    </div>

                    <div style={{marginTop: "100px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <div style={{fontWeight: "bolder", fontSize: "18px"}}>LESSEE : </div>
                      <div style={{fontWeight: "bolder", fontSize: "18px", marginTop: "20px"}}>VARU VIKAS RANE (9930122949) </div>
                      <div><input style={{borderStyle: "none", borderBottom: "2px solid black"}} /></div>
                      <div>(Name)</div>

                      <div><input style={{borderStyle: "none", borderBottom: "2px solid black"}} /></div>
                      <div>(Position, if applicable)</div>
                      <div style={{marginTop: "20px"}}>SIGNATURE: </div>
                    </div>

                </div>

               {/* page 6th: */}
                <div style={{width: "800px", backgroundColor: "white", height: "140vh", padding: "50px", marginTop: "20px"}}>
                   {/* <div style={{marginTop: "10px", fontSize: "18px", lineHeight: "1.5rem"}}>
                     <span style={{fontWeight: "bolder", fontSize: "18px"}}>13. ENCUMBRANCES, TAXES AND OTHERS LAWS: </span>
                      Lesse shall keep the Equipment free and clear of any liens or other encumbrances, and shall not permit 
                      any act where Lessor’s title or rights may be negatively affected. Lessee shall be responsible for
                      complying with and conforming to all laws and regulations relating to the possession use or maintenance of
                      the Equipment. Furthermore, Lessee shall promptly pay all taxes, fees, licences and governmental charges, 
                      together with any penalties or interest theorem. relating to the possession, use or maintenance of the Equipment.
                   </div> */}

                   <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <span style={{fontWeight: "bolder", fontSize: "18px"}}>14. LESSORS REPRENTATIONS: </span>
                      Lessor represents and Warrants that he/ghe has the right to lease the Equipment as provided in this agreement
                      and that Lessee shall be entitled to quitely hold and possess the Equipment, and Lessor will not interfare
                      with that right as long as lessee pays the Rent in a timely manner and performs all other obligations under this Agreement.
                   </div>

                   <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                     <span style={{fontWeight: "bolder", fontSize: "18px"}}>15. OWNERSHIP: </span>
                     The Equipment is and shall remain the exclusive Property of Lessor.
                   </div>

                   <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <span style={{fontWeight: "bolder", fontSize: "18px"}}>16. SEVERABILITY: </span>
                      If any part or parts of this Agreement shall be held unenforceable for any reason, the remainder of this
                      Agreement shall continue in full force and effect. If any provision of this Agreement is deemed invalid
                      or unenforceable by any court of competent jurisdiction. and if limiting such provision would make the provision valid, 
                      then such provision shall be deemed to be construed as so limited.
                  </div>

                  <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <span style={{fontWeight: "bolder", fontSize: "18px"}}>17. ASSIGNMENT: </span>
                      Neither this Agreement nor Lessee’s rights hereunder are assignable except with Lessor’s prior, written consent.    
                  </div>

                  <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <span style={{fontWeight: "bolder", fontSize: "18px"}}>18. BINDING EFFECT : </span>
                      The covenants and conditions contained in the Agreement shall apply to and bind the Parties and heirs, 
                      legal representatives, successors any permitted assigns of the Parties. 
                  </div>

                  <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <span style={{fontWeight: "bolder", fontSize: "18px"}}>19. NOTICE : </span>
                      Any notice required or otherwise given pursuant to this Agreement shall be in writing and mailed 
                      certified return receipt requested, postage prepaid, or delivered by overnight delivery service to:  
                  </div>

                  {/* <div style={{display: "flex", justifyContent: "space-between", fontSize: "18px", marginTop: "20px"}}>
                    <div>
                      <div>Lessor :  </div>
                      <div><input style={{borderStyle: "none", borderBottom: "2px solid black"}}  /></div>
                      <div><input style={{borderStyle: "none", borderBottom: "2px solid black"}}  /></div>
                      <div><input style={{borderStyle: "none", borderBottom: "2px solid black"}}  /></div>
                    </div> 

                    <div>
                      <div>Lessee : </div>
                      <div><input  style={{borderStyle: "none", borderBottom: "2px solid black"}} /></div>
                      <div><input  style={{borderStyle: "none", borderBottom: "2px solid black"}} /></div>
                      <div><input  style={{borderStyle: "none", borderBottom: "2px solid black"}} /></div>
                    </div>
                  </div> */}

                  <div style={{marginTop: "10px", fontSize: "18px", lineHeight: "1.5rem", marginTop: "20px"}}>
                     Either party may change such ddresses from time to time by providing notice as set fourth above.
                  </div>
                </div>

                {/* page 7th:  */}
                <div style={{width: "800px", backgroundColor: "white", height: "140vh", padding: "50px", marginTop: "20px"}}>
                    <div style={{marginTop: "10px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <span style={{fontWeight: "bolder", fontSize: "18px"}}>20. ENTIRE AGREEMENT: </span>
                      This Agreement constitutes the entire agreement between the parties and supersedes any prior understanding or 
                      representations of any kind proceding the date of this Agreement. There are no other promises, conditions, 
                      understandings or other agreements. whether oral or written, relating to the subject matter of this Agreement.
                      This Agreement may be modified in writing and must be signed by both Lessor and Lessee.
                    </div>

                    <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <span style={{fontWeight: "bolder", fontSize: "18px"}}>21. CUMULATIVE RIGHTS:  </span>
                      Lessor's and Lessee's rights under this agreement are cumulative, and shall not be construed as exclusive of each
                      other unless otherwise required by law.
                    </div>

                    {/* <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <span style={{fontWeight: "bolder", fontSize: "18px"}}>22. WAIVER: </span>
                      The failure of either party to enforce any provisions of this Agreement shall not be deemed a waiver
                      or limitation of that party's right to subsequently enforce and compel strict compliance with every
                      provision of this Agreement. The acceptance of rent by Lessor does not waive Lessor's right to enforce 
                      any provisions of this Agreement.  
                    </div> */}

                    <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <span style={{fontWeight: "bolder", fontSize: "18px"}}>23.INDEMNIFICATION:  </span>
                      Except for damages, claims or losses due to Lessor's acts or negligence, Lessee. to the extent permitted by law,
                      will idemnify and hold Lessor and Lessor's property. free and harmless from any liablity for losses, claims, injury to or 
                      death of any person, including Lessee, or for damage to property arising from Lessee using and possessing the Equipment 
                      or from the acts or omissionss of any person or persons, including Lessee, using of possessing the Equipment
                      with Lessee’s express or implied consent.
                    </div>

                    {/* <div style={{marginTop: "20px", fontSize: "18px", lineHeight: "1.5rem"}}>
                      <span style={{fontWeight: "bolder", fontSize: "18px"}}>24.ADDITIONAL TERMS & CONDITIONS : </span>
                      (Specify “none” if there are no additional provisions) <br></br>

                      <div style={{marginTop: "20px", fontSize: "16px",}}>
                        <div style={{borderBottom: "2px solid black"}}>LOCK IN PERIOD FOR EQUIPMENTS SIX MONTHS </div>
                        <div style={{borderBottom: "2px solid black"}}>ONE MONTH NOTICE PERIOD REQUIRED FOR VACCANCY OF EQUIPMENTS. </div>
                        <div style={{borderBottom: "2px solid black"}}>BEFORE SIX MONTHS VACCANCY BY LESSE, THEN SECURITY DEPOSIT NOT BE REFUNDABLE.</div>
                        <div style={{borderBottom: "2px solid black"}}>AFTER SIX MONTHS VACCANCY BY LESSE, FULLY AMOUNT DEPOSIT REFUNDABLE. </div>
                      </div>

                    </div> */}
                </div>

            </div>
        </div>
    );
}

export default AgreementPage;