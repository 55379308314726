import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { useState } from 'react';
import MyDocument from './MyDocument';
import { useOutletContext } from 'react-router-dom';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { addAggrementFurniture, addAggrementProperty } from '../../../config/property';

function SimpleDialog(props) {
  const { onClose, open, ownerData, tenantData, propertyDetails, contractDetails, furnitureId } = props;

  console.log('props', props);
  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const onFreeProperty = async () => {
    let data = {
      tenant: props.tenantData,
      owner: props.ownerData,
      contractDetails: props.contractDetails,
      furnitureId: props.furnitureId,
      aggrementType: 'offline',
    };
    const res = await addAggrementFurniture(data);
    if (res.data.status) {
      console.log('res', res);
    }
  };
  const onPayProperty = async () => {
    if (props.propertyDetails?.type === 'furniture') {
      let data = {
        tenant: props.tenantData,
        owner: props.ownerData,
        contractDetails: props.contractDetails,
        furnitureId: props.furnitureId,
        aggrementType: 'online',
      };
      const res = await addAggrementFurniture(data);
      if (res.data.status) {
        window.location.href = res.data.url;
      }
    } else {
      let data = {
        tenant: props.tenantData,
        owner: props.ownerData,
        contractDetails: props.contractDetails,
      };
      const res = await addAggrementProperty(data);
      if (res.data.status) {
        window.location.href = res.data.url;
      }
    }
  };
  return (
    <Dialog
      onClose={handleClose}
      open={open}
    >
      <DialogTitle>Download Options</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
          <div
            style={{
              border: '1px #aaa solid',
              borderRadius: '10px',
              padding: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <div style={{ fontSize: '22px' }}>Free</div>
              <PDFDownloadLink
                onClick={() => (props.propertyDetails?.type === 'furniture' ? onFreeProperty() : null)}
                style={{
                  backgroundColor: '#9a291b',
                  border: 0,
                  padding: '10px 20px',
                  borderRadius: '100px',
                  color: 'white',
                  display: 'block',
                  textDecoration: 'none',
                }}
                document={
                  <MyDocument
                    ownerData={ownerData}
                    tenantData={tenantData}
                    propertyDetails={propertyDetails}
                    contractDetails={contractDetails}
                  />
                }
                fileName='agreement.pdf'
              >
                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now! ')}
              </PDFDownloadLink>
            </div>
            <div>
              You will get only the agreement copy without stamps. 
            </div>
          </div>
          <div
            style={{
              border: '1px #aaa solid',
              borderRadius: '10px',
              padding: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <div style={{ fontSize: '22px' }}>Paid</div>
              <button
                onClick={() => onPayProperty()}
                style={{
                  backgroundColor: '#9a291b',
                  border: 0,
                  padding: '10px 20px',
                  borderRadius: '100px',
                  color: 'white',
                  display: 'block',
                }}
              >
                Pay
              </button>
            </div>
            <div>
            You will get the agreement copy with authorized government stamp. 
            You have to pay INR 299 and your agreement will be deliver at the owner address.
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const FinalAgreement = () => {
  const [
    role,
    setRole,
    ownerData,
    setOwnerData,
    tenantData,
    setTenantData,
    propertyDetails,
    setPropertyDetails,
    contractDetails,
    setContractDetails,
    furnitureId,
    setFurnitureId,
    furnishedDetails,
    setFurnishedDetails,
  ] = useOutletContext();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <PDFViewer>
        <MyDocument
          ownerData={ownerData}
          tenantData={tenantData}
          propertyDetails={propertyDetails}
          contractDetails={contractDetails}
          furnishedDetails={furnishedDetails}
        />
      </PDFViewer>
      <div
        style={{
          height: '50px',
          width: '100%',
        }}
      >
        <SimpleDialog
          open={open}
          onClose={handleClose}
          ownerData={ownerData}
          tenantData={tenantData}
          propertyDetails={propertyDetails}
          contractDetails={contractDetails}
          furnishedDetails={furnishedDetails}
          furnitureId={furnitureId}
        />
        <button
          style={{
            backgroundColor: '#9a291b',
            border: 0,
            padding: '10px 20px',
            borderRadius: '100px',
            color: 'white',
            margin: '20px auto 0 auto',
            display: 'block',
          }}
          onClick={handleClickOpen}
        >
          Download
        </button>
      </div>
    </div>
  );
};

export default FinalAgreement;
