import React, { useEffect } from "react";
import { checkPaymentProperty } from "../config/property";

const AgreementPaymentStatus = () => {
  useEffect(async () => {
    // Extract the 'id' from the URL query string
    const urlSearchParams = new URLSearchParams(window.location.search);
    const id = urlSearchParams.get("id");

    console.log("ID from URL:", id);
    if (id) {
      const res = await checkPaymentProperty({
        transactionId: id,
      });
    }
    // Add your logic here for using the 'id'
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6 text-center">
          {/* Loader */}
          <div
            className="loader"
            style={{
              border: "8px solid #f3f3f3",
              borderTop: "8px solid #3498db",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              animation: "spin 1s linear infinite",
              margin: "20px auto", // Adjust margin to center the loader vertically
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default AgreementPaymentStatus;
