import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Avatar } from "@mui/material";
import { assetUrl } from "../../config/axiosConfig";
import { useMediaQuery } from "react-responsive";
import { updateProfileService } from "../../config/user";
import toast from "react-hot-toast";

export default function Profile() {
  const { user } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleImages = (e) => {};
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [pinCode, setPinCode] = useState();
  const [desc, setDesc] = useState();
  const [language, setLanguage] = useState();
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const [avatarImage, setAvtarImage] = useState({});
  const [imageUrls, setImageUrls] = useState([]);
  const [images, setImages] = useState({});

  const [data, setData] = useState({
    photo: {},
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      if (images.length === 0) {
        toast.error("ID Proof images are required");
        return;
      }
      setLoader(true);
      const obj = {
        name,
        phone,
        email,
        pinCode,
        language,
        desc,
        address,
        // profilePic: data.photo,
        idProof: images,
        // locality: JSON.stringify(locality),
      };
      const fd = new FormData();
      for (let key in obj) {
        if (key === "idProof") {
          let images = Array.from(obj[key]);
          for (let i = 0; i < images.length; i++) {
            fd.append("idProof", images[i]);
          }
        } else if (Array.isArray(obj[key])) {
          obj[key].map((i) => {
            fd.append(key, i);
          });
        } else fd.append(key, obj[key]);
      }
      const res = await updateProfileService(fd);

      if (res.data.success) {
        setLoader(false);

        toast.success("Profile Updated Successfully");
        window.location.reload();
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  useEffect(() => {
    setName(user?.name || "");
    setAddress(user?.address || "");
    setPhone(user?.phone || "");
    setEmail(user?.email || "");
    setDesc(user?.desc || "");
    setLanguage(user?.language || "");
  }, [user]);
  return (
    <div className="container">
      <div className="row g-3 align-items-center">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <form
            onSubmit={(e) => {
              onSubmit(e);
            }}
          >
            <div className="mt-4 mb-5">
              <Avatar
                sx={{ 
                  marginTop: "25px",
                  marginBottom: "25px",
                  height: "48px",
                  width: "48px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  // display: "flex",
                  // width: "fit-content",
                  // padding: "12px 16px",
                  borderRadius: "48px",
                  // margin: "0px 20px"
                }}
                alt={user?.name}
                src={assetUrl + user?.avatarImage}
              />
            </div>
            <div className="col-sm-12 form-group div-profile-modal mb-3">
              <div className={!isMobile ? "col-2" : "col-sm-12"}>
                <label className="col-form-label profile-labels-modal">
                  Full Name
                </label>
              </div>
              <div className={!isMobile ? "col-8" : "col-sm-12"}>
                <input
                  className="form-control form-control-profile"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  name="name"
                />
              </div>
            </div>

            <div className="col-lg-12 form-group div-profile-modal  mb-3">
              <div className={!isMobile ? "col-2" : "col-sm-12"}>
                <label
                  for="inputEmail"
                  className="col-form-label profile-labels-modal"
                >
                  Email
                </label>
              </div>
              <div className={!isMobile ? "col-8" : "col-sm-12"}>
                <input
                  id="inputEmail"
                  className="form-control form-control-profile"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  autocomplete="off"
                  name="email"
                />
              </div>
            </div>
            <div className="col-sm-12 form-group div-profile-modal mb-3">
              <div className={!isMobile ? "col-2" : "col-sm-12"}>
                <label className="col-form-label profile-labels-modal">
                  Phone Number
                </label>
              </div>
              <div className={!isMobile ? "col-8" : "col-sm-12"}>
                <input
                  disabled
                  id="inputPhone"
                  className="form-control form-control-profile"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  name="phone"
                  autocomplete="off"
                />
              </div>
            </div>
            <div className="col-sm-12 form-group div-profile-modal mb-3">
              <div className={!isMobile ? "col-2" : "col-sm-12"}>
                <label className="col-form-label profile-labels-modal">
                  Language
                </label>
              </div>
              <div className={!isMobile ? "col-8" : "col-sm-12"}>
                <input
                  id="inputLanguage"
                  className="form-control form-control-profile"
                  value={language}
                  onChange={(e) => {
                    setLanguage(e.target.value);
                  }}
                  name="language"
                  autocomplete="off"
                />
              </div>
            </div>
            <div className="col-sm-12 form-group div-profile-modal mb-3">
              <div className={!isMobile ? "col-2" : "col-sm-12"}>
                <label className="form-label profile-labels-modal">
                  Full Address
                </label>
              </div>
              <div className={!isMobile ? "col-8" : "col-sm-12"}>
                <textarea
                  className="form-control  form-control-profile"
                  name="address"
                  rows="2"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  autocomplete="off"
                ></textarea>
              </div>
            </div>
            <div className="col-sm-12 form-group div-profile-modal mb-3">
              <div className={!isMobile ? "col-2" : "col-sm-12"}>
                <label className="col-form-label profile-labels-modal">
                  Pin Code
                </label>
              </div>
              <div className={!isMobile ? "col-8" : "col-sm-12"}>
                <input
                  id="inputPinCode"
                  className="form-control form-control-profile"
                  value={pinCode}
                  onChange={(e) => {
                    setPinCode(e.target.value);
                  }}
                  name="pinCode"
                  autocomplete="off"
                />
              </div>
            </div>
            <div className="col-sm-12 form-group div-profile-modal mb-3">
              <div className={!isMobile ? "col-2" : "col-sm-12"}>
                <label className="form-label profile-labels-modal">
                  Description
                </label>
              </div>
              <div className={!isMobile ? "col-8" : "col-sm-12"}>
                <textarea
                  className="form-control  form-control-profile"
                  name="desc"
                  rows="2"
                  value={desc}
                  onChange={(e) => {
                    setDesc(e.target.value);
                  }}
                  autocomplete="off"
                ></textarea>
              </div>
            </div>
            <div className="col-sm-12 form-group div-profile-modal mb-3">
              <div className={!isMobile ? "col-2" : "col-sm-12"}>
                <label className="form-label profile-labels-modal">
                  ID Proof
                </label>
              </div>
              <div className={!isMobile ? "col-8" : "col-sm-12"}>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={(e) => setImages(e.target.files)}
                />
              </div>
            </div>
            <div className="col-sm-12 form-group div-profile-modal mb-3">
              <div className={!isMobile ? "col-2" : "col-sm-12"}></div>
              {user?.idProof?.map((img, index) => (
                <div className="py-1">
                  <a
                    className="text-color979797"
                    target="_blank"
                    href={assetUrl + img}
                  >
                    - Proof {index + 1}
                  </a>
                </div>
              ))}
            </div>
            <button
              type="submit"
              disabled={loader}
              className="btn-auth-signup mb-2 mt-2"
            >
              {loader ? "Please wait..." : "Edit profile"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
