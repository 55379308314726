import React from "react";
import Header from "../../components/Header";
import "./index.css";

const BuyingFurniture = () => {
  return (
    <div>
      <Header />
      <div className="box-text">
        <h1>FURNITURE and HOME APPLIANCES BUYING</h1>
        <div>
          <h2>
            Welcome to Gharwala's Furniture and Home Appliances Buying Service!
          </h2>
          <p>
            At Gharwala, we believe that every home deserves the best in
            furniture and home appliances to create a comfortable and functional
            living space. With our furniture and home appliances buying service,
            you can shop for high-quality products at affordable prices, all
            from the comfort of your home.
          </p>
        </div>
        <div>
          <h2>Extensive Selection:</h2>
          <p>
            Discover a wide range of furniture and home appliances to suit every
            style and budget. From trendy sofas and elegant dining sets to
            state-of-the-art refrigerators and smart TVs, we have everything you
            need to elevate your living space.
          </p>
        </div>
        <div>
          <h2>Top Brands, Trusted Quality:</h2>
          <p>
            Shop with confidence knowing that all our products are sourced from
            top brands known for their quality and reliability. We prioritize
            durability and performance to ensure that you get the best value for
            your money with every purchase.
          </p>
        </div>
        <div>
          <h2>Convenient Online Shopping:</h2>
          <p>
            Experience the convenience of shopping online with Gharwala. Browse
            through our user-friendly website or mobile app, explore our
            extensive catalog, and place your order with just a few clicks.
            Enjoy a seamless shopping experience from start to finish.
          </p>
        </div>
        <div>
          <h2>Secure Payment Options:</h2>
          <p>
            Rest assured, your online transactions are safe and secure with
            Gharwala. Choose from a variety of payment options, including
            credit/debit cards, net banking, and cash on delivery, to complete
            your purchase with ease and peace of mind.
          </p>
        </div>
        <div>
          <h2>Doorstep Delivery:</h2>
          <p>
            Say goodbye to the hassle of transporting bulky furniture and
            appliances yourself. With Gharwala, enjoy doorstep delivery of your
            purchases right to your home. Our delivery team ensures prompt and
            professional service, so you can start enjoying your new items right
            away.
          </p>
        </div>
        <div>
          <h2>Easy Returns and Exchanges:</h2>
          <p>
            Not satisfied with your purchase? No problem! We offer hassle-free
            returns and exchanges within a specified period, allowing you to
            shop with confidence. Our customer service team is always available
            to assist you with any queries or concerns.
          </p>
        </div>
        <div>
          <h2>Affordable Prices, Great Value:</h2>
          <p>
            Enjoy competitive prices and great value for money on all our
            products. We believe in offering affordable options without
            compromising on quality, so you can furnish your home without
            breaking the bank.
          </p>
        </div>
        <div>
          <h2>Exceptional Customer Service:</h2>
          <p>
            At Gharwala, customer satisfaction is our top priority. Our
            dedicated team is committed to providing you with exceptional
            service at every step of your shopping journey. From product
            recommendations to after-sales support, we're here to help you make
            the best choices for your home.
          </p>
        </div>
        <div>
          <h2>Start Shopping with Gharwala Today:</h2>
          <p>
            Ready to transform your home with quality furniture and appliances?
            Shop online with Gharwala and experience the convenience of buying
            from a trusted source. Discover the perfect pieces to enhance your
            living space and make your house feel like home.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BuyingFurniture;
