import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./testimonial.css";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PageNotFound from "../../components/PageNotFound";

function AddTestimonial() {
  const [name, setName] = useState("");
  const [testimonial, setTestimonial] = useState("");
  const [title, setTitle] = useState("");
  // const [pincode, setPincode] = useState(""); 

   //for 404 auth
   const [showPage, setShowPage] = useState(false);
   const { user } = useSelector((state) => state.auth);
 
   useEffect(() => {
     if (user !== null) {
       if (user?.role === "admin") {
         setShowPage(true);
       }
     }
   }, []);
   
 
   //end 404 auth

  const submitTestimonial = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        "https://gharwala-5466fddd6458.herokuapp.com/api/add-testimonial",
        {
          name,
          description: testimonial,
          title,
          // pincode, 
        }
      );

      if (response.data.message === "Testimonial created successfully") {
        toast.success("Testimonial added");
      }

     
      setName("");
      setTestimonial("");
      setTitle("");
      // setPincode(""); 
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return showPage ? 
  (
    <>
      <Header />
      <div className="testimonial-container">
        <h2>Create a New Testimonial</h2>
        <form onSubmit={submitTestimonial}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          {/* <div className="form-group">
            <label htmlFor="pincode">Pincode:</label> 
            <input
              type="text"
              id="pincode"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
            />
          </div> */}
          <div className="form-group">
            <label htmlFor="testimonial">Testimonial:</label>
            <textarea
              id="testimonial"
              value={testimonial}
              onChange={(e) => setTestimonial(e.target.value)}
            />
          </div>
          <button type="submit">Submit</button>
        </form>
        <div style={{marginTop: "20px"}}>
          <Link to='/get-testimonial'>
           <button> See all testimonial</button>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  ): (
    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh"}}>
      <PageNotFound />
      <Link to="/">
        <button>Go To Home</button>
      </Link>
    </div>
  );
};

export default AddTestimonial;
