import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
const customStyles = {
  Modal: {
    borderRadis: "25px",
    overlay: "hidden",
    maxWidth:"1300px"
  },
  customModal: {
    border: "none",
    maxWidth:"1300px"
  },
  overlay: {
    position: "fixed",
    inset: 0,
    backgroundColor: "#00000075",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 9999,
    left: 0,
  },
  content: {
    maxWidth: "1300px",
    margin: "0 auto",
    borderRadius: "20px",
  },
};
const customStylesMobile = {
  ReactModal__Content: {
    border: "none",
    maxWidth:"1300px"
  },
  customModal: {
    border: "none",
    marginTop: "100px",
    maxWidth:"1300px"
  },
  overlay: {
    position: "fixed",
    inset: 0,
    backgroundColor: "#00000075",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 9999,
    left: 0,
  },
  content: {
    maxWidth: "1300px",
    border: "none",
    margin: "0 auto",
    marginTop: "75px",
  },
};
export default function ModalSignUp({
  children,
  state,
  stateFunc,
  title,
  handleClose,
  setCloseLoginModal
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Modal
      isOpen={state}
      style={!isMobile ? customStyles : customStylesMobile}
      contentLabel="Example Modal"
      contentClassName="custom-modal"
    >
      <h2
      style={{
        color: "#240501",
        fontWeight: "bold",
        fontSize: isMobile ? "16px" : "30px",
        textAlign: isMobile? "left" : "center",
        marginBottom: isMobile ? "40px" : '5px',
      }}
    >
      {title}
    </h2>
      <a
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          display: "inline-block",
          fontSize: 30,
          color: "#000",
        }}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          stateFunc(false);
        }}
      >
        <CloseRoundedIcon fontSize="25px" />
        {/* <i class="fa fa-times" aria-hidden="true"></i> */}
      </a>

      {children}
    </Modal>
  );
}
